import {
  ZonedDateTime,
  DateTimeFormatter,
  Instant,
  LocalDate,
  LocalTime,
  ZoneId,
  convert,
  nativeJs,
  LocalDateTime} from '@js-joda/core'
import { Locale } from '@js-joda/locale_en-us'
import '@js-joda/timezone'

export { Instant } from '@js-joda/core'

const getFormatter = (v:string) => DateTimeFormatter.ofPattern(v).withLocale(Locale.US)
export const now = () => ZonedDateTime.now()
export const nowInstant = () => now().toInstant()
export const today = () => now().toLocalDate()

export const parseInstant = (v:string) => Instant.parse(v)
export const parseDate = (v:string) => LocalDate.parse(v)

export const parseInstantFromLocalDate_YYYY_MM_DD = (v:string) => {
  var localDate = LocalDate.parse(v, DateTimeFormatter.ofPattern("uuuu-MM-dd"));
  var zone = ZoneId.systemDefault();
  return localDate.atStartOfDay(zone).toInstant();
}

export const formatInstantLastUsed = (v:Instant) => {
  return v.atZone(now().zone()).toLocalDate().format(getFormatter('MM/dd/uu'))
}
export const formatInstantTime = (v:Instant) => {
  return v.atZone(now().zone()).toLocalDateTime().format((getFormatter('hh:mm a')))
}
export const formatInstantDateTime = (v:Instant) => {
  return v.atZone(now().zone()).toLocalDateTime().format((getFormatter('M/d/uu @ h:mma'))).toLowerCase()
}
export const formatInstantDate_YYYY_MM_DD = (v:any) => v.atZone(now().zone()).toLocalDateTime().format((getFormatter('uuuu-MM-dd')))
export const formatInstantDate = (v:any) => v?.atZone(now().zone()).toLocalDateTime().format((getFormatter('MM/dd/uuuu')))
export const formatDate = (v:any) => v.format(getFormatter('MM/dd/uuuu'))
export const formatDate_NoLeadingZeros = (v:any) => v.format(getFormatter('MM/dd/uuuu'))

export const formatServerDate = (v:LocalDate) => v.format(getFormatter('uuuu-MM-dd'))

export const localDateTimeToInstant = (v:LocalDateTime) => v.toInstant(now().offset());
export const instantToLocalDateTime = (v:Instant) => v.atZone(now().zone()).toLocalDateTime();

export const localDateTimeToJSDate = (v:LocalDateTime) => convert(v).toDate()
export const jsDateToLocalDateTime = (v:Date) => LocalDateTime.from(nativeJs(v));

export const dateIsOnOrAfter = (inclusiveLowerBound:LocalDate, target:LocalDate) => (
  target.isEqual(inclusiveLowerBound) || target.isAfter(inclusiveLowerBound)
)
export const dateIsBefore = (exclusiveUpperBound:LocalDate, target:LocalDate) => (
  target.isBefore(exclusiveUpperBound)
)
export const dateIsBetween = (inclusiveLowerBound:LocalDate, exclusiveUpperBound:LocalDate, target:LocalDate) => (
  dateIsOnOrAfter(inclusiveLowerBound,target) && dateIsBefore(exclusiveUpperBound,target)
)