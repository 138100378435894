import React, { useEffect, useState } from 'react'
import { formatUSPhoneNumber } from '../../../util/stringformat';
import { currentuser } from '../../../util/currentuser';

const AppForm = (props: any) => { 
    const {handleChange, notifyForm, validation} = props.data 

 
    return (
        <>
           <div className="form-group">
                <input type="text" placeholder="Name" name='user_name' className="form-control" onChange={(e: any) => {
                    handleChange(e);

                }} value={notifyForm?.user_name ? notifyForm?.user_name : ""} />
                <Validation state={validation?.user_name} />
            </div>
            <div className="form-group">
                <input type="text" placeholder="Email" name='email' className="form-control" onChange={(e: any) => {
                    handleChange(e);

                }} value={notifyForm?.email ? notifyForm?.email : ""} />
                <Validation state={validation?.email} />
            </div>
            <div className="form-group">
                <input type="phone" placeholder="Phone" name='phone' className="form-control" minLength={10}  maxLength={14} onChange={(e: any) => {
                    handleChange(e);
                }} value={notifyForm?.phone ? formatUSPhoneNumber(notifyForm?.phone) : ""} />
                <Validation state={validation?.phone} />
            </div>
            {/* <button class="cont-us" onClick={()=> setNotified(true)}>Submit</button> */}
            
        </>
    )
}


export default AppForm
export const Validation = ({ state }: { state: any }) => {
    return state && <p style={{ color: "red" }}>{state}</p>;
};
