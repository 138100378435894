import React from 'react'
import CIcon from '@coreui/icons-react'
import * as icons from '@coreui/icons-pro'

export const ICON_KIND_LOOKUP = {
  'Clear'        : icons.cilX,
  'Edit'         : icons.cilPencil,
  'Trash'        : icons.cilTrash,
  'Archive'      : icons.cilArchive,
  'Copy'         : icons.cilCopy,
  'Restore'      : icons.cilRestore,
  'AlertManager' : icons.cilBellRing,
  'Relocate'     : icons.cilShare,
  'Collapsed'    : icons.cilCaretTop,
  'Expanded'     : icons.cilCaretBottom,
  'ArrowRight'   : icons.cilArrowRight,
  'ArrowLeft'    : icons.cilArrowLeft,
  'cilArrowBottom'  : icons.cilArrowBottom,
  'ArrowTop'    : icons.cilArrowTop,
  'Check'        : icons.cisCheck,
  'Swap'         : icons.cilSwapHorizontal,
  'Add'          : icons.cilPlus,
  'Minus'        : icons.cilMinus,
  'Remove'       : icons.cilX,
  'Import'       : icons.cisDataTransferDown,
  'Smile'        : icons.cilSmile,
  'Doc'          : icons.cilNotes,
  'Order'        : icons.cilBox,
  'Match'        : icons.cisVerticalAlignCenter,
  'AddItem'      : icons.cilCartPlus,
  'Print'        : icons.cilPrinter,
  'Note'         : icons.cilStickyNote,
  'Lock'         : icons.cisLockLocked,
  'Download'     : icons.cilCloudDownload,
  'Invoice'      : icons.cisTags,
  'QuickInfo'    : icons.cisGauge,
  'Customer'     : icons.cisUser,
  'Location'     : icons.cisLocationPin,
  'Billing'      : icons.cisCreditCard,
  'Shipping'     : icons.cisTruck,
  'Contacts'     : icons.cisContacts,
  'Calendar'     : icons.cilCalendar,
  'Ban'          : icons.cisBan,
  'History'      : icons.cilHistory,
  'Sticky'       : icons.cibPinboard,
  'View'         : icons.cilMagnifyingGlass,
  'FolderShare'  : icons.cilFolderShared,
  'FolderPlus'   : icons.cilFolderPlus,
  'Phone'        : icons.cilPhone,
  'Flag'         : icons.cilFlag,
  'Time'         : icons.cilClock,
  'Bullhorn'     : icons.cilBullhorn,
  'Handshake'    : icons.cilHandshake,
  'SwapHorizontal': icons.cisSwapHorizontal,
  'Sad'          : icons.cilSad,
  'Search'       : icons.cisSearch,
  'ListFilter'   : icons.cisListFilter,
  'BellExclamation'   : icons.cisBellExclamation,
  'Upload'       : icons.cilCloudUpload,
  'Down'         : icons.cisChevronBottomAlt,
  'Dollar'       : icons.cisDollar,
  'Probot'       : icons.cibProbot,
  'EnvelopeClosed'   : icons.cisEnvelopeClosed,
  'Attachment'   : icons.cilPaperclip,
  'X'            : icons.cilX,
  'External'     : icons.cilExternalLink,
  'Send'         : icons.cisSend,
  'comment' : icons.cilCommentBubble,
  'relation': icons.cilWc,
  'Dashboard': icons.cidApplications,
  'Permission': icons.cisAccessibility,
  'Skyliner': icons.cibSkyliner,
}

export const ICON_KIND =
  Object.keys(ICON_KIND_LOOKUP).reduce((agg,x) =>
    ({ ...agg,[x]:x }),{}
  ) as { [key in keyof typeof ICON_KIND_LOOKUP]:keyof typeof ICON_KIND_LOOKUP }

export type IconSize = 'xl'|'lg'|'md'|'sm'

type Props = {
  hidden?:boolean
  kind:keyof typeof ICON_KIND
  className?: string
  name?: string
  size?: IconSize
  title?: string
  use?: string
  style?: any
  onClick?:(_:React.MouseEvent<any>) => void
}

export default class Icon extends React.PureComponent<Props> {
  render() {
    return (
      <>
      <CIcon
        { ...{ ...this.props,kind:undefined } }
        hidden={this.props.hidden}
        content={ ICON_KIND_LOOKUP[this.props.kind] }
        className={
          ([] as string[]).concat(
            this.props.className ? [this.props.className] : [],
            this.props.onClick ? ['clickable-icon'] : []
          ).join(' ')
        }
      />
      </>
    )
  }
}
