
export const formatUSPhoneNumber = (phone:string) => {
  const temp = phone ? phone.replace(/^\+\d/,'').replace(/\D/g,'') : ''
  switch(temp.length)
  {
    case 0:
      return ``
    case 1:
    case 2:
    case 3:
      return `(${temp}`
    case 4:
    case 5:
    case 6:
      return `(${temp.substring(0,3)}) ${temp.substring(3,6)}`
    default:
      return `(${temp.substring(0,3)}) ${temp.substring(3,6)}-${temp.substring(6,10)}`
  }
}

export const convertUSPhoneNumberToE164 = (phone:string) => {
  if ((phone === null) || (phone.trim() === '')) {
    return '';
  }

  let pieces = phone.match(/[0-9]{0,14}/g);

  if (pieces === null) {
    return '';
  }

  let e164Phone = pieces.join('');
  e164Phone = '+1' + e164Phone;
  e164Phone = e164Phone.substring(0, 15);
  return e164Phone;
}

export const formatZipCode = (country:string|null, zipCode:string) => {
  if (country && country === 'USA') {
    const temp = zipCode ? zipCode.replace('-','') : ''
    switch(temp.length)
    {
      case 0:
        return ``
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return `${temp}`
      case 6:
      case 7:
      case 8:
      case 9:
        return `${temp.substring(0,5)}-${temp.substring(5,temp.length)}`
      default:
        return `${temp.substring(0,5)}-${temp.substring(5,9)}`
    }
  }

  return zipCode;
}

export const substrContainsCI = (a:string, b:string) => a.toLocaleLowerCase().includes(b.toLocaleLowerCase())
