import React, { useState , useEffect } from "react";

type Props = {
  item: any;
  permissionsofselectedrole: any[];
  loader?:boolean;
  setPermissionsOfSelectedRole: (permissions: any[]) => void;
};

export interface currentmatchedpermissionobj {
  id: string;
  permission: string;
  view: boolean;
  edit: boolean;
}

export interface currentpermissiontypeobj {
  permission : boolean
  view : boolean
  edit : boolean
}

const PermissionsCheckbox : React.FC<Props> = (props) => {
  const { item , permissionsofselectedrole , setPermissionsOfSelectedRole} = props
  const [ currentmatchedpermission , setCurrentMatchedPermission] = useState<currentmatchedpermissionobj[]>([]);
  const [isChecked , setIsChecked] = useState(false);
  
  /** Used to map permissions of selected role when page is loaded */
  useEffect(() => {
    async function matchedPermission() {
      let tempisChecked = permissionsofselectedrole?.some((permissionObj: any) => permissionObj.permission === item.permission);
      setIsChecked(tempisChecked);
      if(permissionsofselectedrole) {
        permissionsofselectedrole.map( (v) => { 
          if(v.permission === item.permission){
            setCurrentMatchedPermission((prevPermissions) => [...prevPermissions,{ id: v.id, permission: v.permission, view: v.view, edit: v.edit }]);
          }else {
            setCurrentMatchedPermission((prevPermissions) => [...prevPermissions , {id: "",permission: "",view: false,edit: false}])
          } 
        })
      }
    }
    matchedPermission()
  }, [permissionsofselectedrole])

  /** when click on checkbox  */
  const handleChecked = (permissionType: string) => {
    const updatedPermissions = [...permissionsofselectedrole];
    const index = updatedPermissions.findIndex((permissionObj: any) => permissionObj.permission === item.permission);
  
    if (index === -1) {
      // Permission is not present, add it with default values
      updatedPermissions.push({ id: item.permission, permission: item.permission, view: true, edit: false });
    } else {
      // Permission is already present
      if (permissionType === "view") {
        // Toggle the view property
          setIsChecked(false);
      } else if (permissionType === "edit") {
        // Toggle the edit property
        updatedPermissions[index].edit = !updatedPermissions[index].edit;
      } else if (permissionType === "permission") {
        updatedPermissions.splice(index, 1); // Remove the permission at the specified index
      } 
    }
    setPermissionsOfSelectedRole(updatedPermissions);
  };
  
  const index = permissionsofselectedrole?.findIndex((permissionObj: any) => permissionObj.permission === item.permission);  

  return (
    <>
      <div className="permission-check" onClick={(e) => { e.stopPropagation(); }}>
        <input
          type="checkbox"
          value={item?.permission}
          id={item?.permission}
          checked={isChecked}
          onChange={() => handleChecked("permission")}
        />
        <label className="ml-1 " htmlFor={item?.permission}>{item?.permissionname}</label>
        {(item.permissionType === "Column") ? <div className={isChecked ? "icon" : "icon d-none"}>
          <span className="custom_icon_style">
            <div className="d-flex ml-4">
              <input
                type="checkbox"
                value={isChecked && permissionsofselectedrole[index]?.view}
                id={`${item?.permission}-view`}
                checked={ permissionsofselectedrole[index]?.view ? permissionsofselectedrole[index]?.view : false }
                onChange={() => handleChecked("view")}
              />
              <span className="ml-2 mr-2">View</span>
              <span className="seperate-line">|</span>
              <input
                type="checkbox"
                value={isChecked && permissionsofselectedrole[index]?.edit}
                id={`${item?.permission}-edit`}
                checked={permissionsofselectedrole[index]?.edit ? permissionsofselectedrole[index]?.edit : false}
                onChange={() => handleChecked("edit")}
              />
              <span className="ml-1">Edit</span>
            </div>
          </span>
        </div> : ""}
      </div>
    </>
  );
};

export default PermissionsCheckbox;
