import React, { useEffect, useRef, useState } from "react";
import { FC } from "react";
import { Border } from "victory";
import '../externaldoc.scss'
import { LeadBasePaint1  } from "../SharedocHTML";
import Esign from "../Esign";
import useToggle from "../../../../hooks/toggle";
import { selecteddocumentesign } from "../ReviewDocs";
import { useDispatch, useSelector } from "react-redux";
import { notification_success } from "../../../../state/actions";
import { getexternaldocuments, saveEsign, sendleadbasepaint } from "../../../../api/transaction";
import store, { stor1 } from "../../../../store";
import moment from 'moment'

export const LeadBasePaint: FC = () => {

    const [canvasmodal, setCanvasModal] = useToggle(false);
    const [htmlContent, setHtmlContent] = useState("");
    const [reviewdocs, setReviewdocs] = useState(false);
const[leadbasepaint,setLeadBasePaint] = useState({
    offer_id: 130,
    address :"",
    seller_disclosure_chk1 :false,
    seller_disclosure_chk2 :false,
    seller_disclosure_chk3 :false,
    seller_disclosure_chk4 :false,
    purchaseer_ack_value1 :"",
    purchaseer_ack_value2 :"",
    purchaser_ack_chk1 :false,
    purchaser_ack_chk2 :false,
    agent_ackhnowledge :"",
    seller1 :"",
    seller2 :"",
    seller3 :"",
    purchaser1  :"",
    purchaser2 :"",
    purchaser3 :"",
    seller1_date :null,
    seller2_date :null,
    seller3_date :null,
    purchaser1_date :null,
    purchaser2_date :null,
    purchaser3_date :null,
})
const [spinner,setspinner] = useState(false)
const [selecteddocumentesign, setselectedDocumentEsign] = useState<selecteddocumentesign>({
    offerid : "",
  document_name: "",
  image_base64: "",
  sign_role: 1
  })
const leadpaintref = useRef(null)
const printf =async () => {
const res =await getexternaldocuments(127,"leadbasedpaint") 


    const parser = new DOMParser();
    const doc = parser.parseFromString(LeadBasePaint1, "text/html");

    if(res?.status === "success"){
         doc.getElementById('address').setAttribute('value',res?.data?.address)
    doc.getElementById('seller1').setAttribute('src',res?.data?.seller1)
       doc.getElementById('seller_disclosure_chk1').setAttribute('checked',res?.data?.seller_disclosure_chk1)
       doc.getElementById('seller_disclosure_chk2').setAttribute('checked',res?.data?.seller_disclosure_chk2)
       doc.getElementById('seller_disclosure_chk3').setAttribute('checked',res?.data?.seller_disclosure_chk3)
       doc.getElementById('seller_disclosure_chk4').setAttribute('checked',res?.data?.seller_disclosure_chk4)
       doc.getElementById('purchaseer_ack_value1').setAttribute('value',res?.data?.purchaseer_ack_value1)
       doc.getElementById('purchaseer_ack_value2').setAttribute('value',res?.data?.purchaseer_ack_value2)
       doc.getElementById('purchaser_ack_chk1').setAttribute('checked',res?.data?.purchaser_ack_chk1)
       doc.getElementById('purchaser_ack_chk2').setAttribute('checked',res?.data?.purchaser_ack_chk2)
       doc.getElementById('agent_ackhnowledge').setAttribute('value',res?.data?.agent_ackhnowledge)

       const htmlcontentdata = document.documentElement.outerHTML;
      
       const printWindow = window
      
       
       printWindow.document.write(htmlcontentdata)
       printWindow.print();
  
   printWindow.document.close();
    }

       

}
const dispatch = useDispatch()
const handleSaveDocumentEsign = async() => {
    setspinner(true)

    // toggle();
    // setReview(!review);
    let savedsignres:any = await saveEsign(selecteddocumentesign);
 
    if(savedsignres?.statuscode === 200){
      dispatch(
        notification_success({
          id: "4",
          message: `E-sign saved succesfully.`,
        })
      );
    }
    setspinner(false)
  }
const[sign,setsign] = useState("")
// @ts-ignore
const val = useSelector((state)=>state?.new?.signset)
const seller_date = ()=>{const d =  new Date();setLeadBasePaint({...leadbasepaint,seller1_date: new Date()})  }



const send = async()=>{
  
     
       await seller_date()
        const run =async ()=>{return setLeadBasePaint({...leadbasepaint,seller1:val})}
       await run()
       if(leadbasepaint?.seller1){
        console.log(leadbasepaint,"njervrj");
        const res = await sendleadbasepaint(leadbasepaint)
        console.log(res,"rhgegbrtgb");
       }

}   

    return (
        <>
          <section ref={leadpaintref} className="leadbasepaint">
    <table  className="leadbasepaint_table">
        <tr>
            <td>
                <table className="w-100">
                    <tr>
                        <td className="w-90"><img className="w-250px"
                                src="https://storagemilforddev.blob.core.windows.net/images/logo.svg" /></td>
                            
                            <td>
                                <img style={{width:"30px"}}
                                src="https://storagemilforddev.blob.core.windows.net/images/realtor.svg" alt="" />
                                <img style={{width:'50px'}}
                                src="https://storagemilforddev.blob.core.windows.net/images/eh.svg" alt=""/>
                        </td>
                       
                    </tr>
                </table>
            </td>
        </tr>
        <tr >
            <td className="p-10-0">
                <span style={{fontWeight:700,fontSize:"25px"}} >Disclosure of Information on Lead-Based Paint &/or Lead-Based Paint Hazards</span>
            </td>
        </tr>
        <tr>
            <td className="p-10-0">
              
                <span >  <span style={{fontWeight:600, color:"#3b0083"}}>ADDRESS:</span>
                <input type="text" id="address" value={leadbasepaint?.address} onChange={(e)=>{setLeadBasePaint({...leadbasepaint,address:e.target.value})}} 
                    // style="  outline: none; border: none; border-bottom:1px solid black; width:950px;" 
                    style={{border:"none",borderBottom:"1px solid black",width:"950px"}}
                    />
                    </span>
            </td>
        </tr>
        <tr >
            <td className="p-10-0">
                <span style={{fontWeight:600, color:"#3b0083",fontSize:"20px"}} >LEAD WARNING STATEMENT</span><br/>
                <span>Every purchaser of any interest in residential real property on which a residential dwelling was built prior to 1978 is notified that such property may present exposure to lead from lead-based 
                    paint that may place young children at risk of developing lead poisoning. Lead poisoning in young children may produce permanent neurological damage, including learning disabilities, reduced 
                    intelligence quotient, behavioral problems, and impaired memory. Lead poisoning also poses a particular risk to pregnant women. The seller of any interest in residential real property is required to 
                    provide the buyer with any information on lead-based paint hazards from risk assessments or inspections in the seller’s possession and notify the buyer of any known lead-based paint hazards. A risk 
                    assessment or inspection for possible lead-based paint hazards is recommended prior to purchase.</span>
            </td>
        </tr>
        <tr>
            <td style={{paddingTop:"5px"}}>
                <span style={{fontWeight:600, color:"#3b0083",fontSize:"20px"}}>Seller’s Disclosure</span>
            </td>
        </tr>
        <tr>
            <td style={{paddingTop:"5px"}}>
                <span>( a ) Presence of lead-based paint and/or lead-based paint hazards (check (i) or (ii) below):</span>
            </td>
        </tr>
        <tr>
            <td style={{paddingTop:"5px"}}>
                <span style={{paddingLeft:"80px"}}>( i )
                 <input type="checkbox" id="seller_disclosure_chk1" checked={leadbasepaint?.seller_disclosure_chk1} onChange={(e)=>{setLeadBasePaint({...leadbasepaint,seller_disclosure_chk1:!leadbasepaint?.seller_disclosure_chk1})}} />
                 Known lead-based paint and/or lead-based paint hazards are present in the housing (explain).
                
                   
                </span>
            </td>
        </tr>
        <tr>
            <td  style={{paddingTop:"5px"}}>
                <span style={{paddingLeft:"80px"}}>   <input type="text"
                    style={{width:"1000px",borderBottom:"1px solid black"}}/></span>
            </td>
        </tr>
        <tr>
            <td style={{paddingTop:"5px"}}>
                <span style={{paddingLeft:"80px"}}>( ii ) <input type="checkbox" id="seller_disclosure_chk2" checked={leadbasepaint?.seller_disclosure_chk2}  onChange={(e)=>{setLeadBasePaint({...leadbasepaint,seller_disclosure_chk2:!(leadbasepaint?.seller_disclosure_chk2)})}} />
                Seller has no knowledge of lead-based paint and/or lead-based paint hazards in the housing.
                
                   
                </span>
            </td>
        </tr>
        <tr>
            <td style={{paddingTop:"5px"}}>
                <span>( b )  Records and reports available to the seller (check (i) or (ii) below):</span>
            </td>
        </tr>
        <tr>
            <td style={{paddingTop:"5px"}}>
                <span style={{paddingLeft:"80px"}}>( i ) <input type="checkbox" id="seller_disclosure_chk3" checked={leadbasepaint?.seller_disclosure_chk3}  onChange={(e)=>{setLeadBasePaint({...leadbasepaint,seller_disclosure_chk3:!leadbasepaint?.seller_disclosure_chk3})}}/>Seller has provided the purchaser with all available records and reports pertaining to lead-based paint and/or 
                    lead-based paint hazards in the housing (list documents below).
                
                   
                </span>
            </td>
        </tr>
        <tr>
            <td style={{paddingTop:"5px"}}>
                <span style={{paddingLeft:"80px"}}>   <input type="text"
                    style={{width:"1000px",borderBottom:"1px solid black"}}/></span>
            </td>
        </tr>
        <tr>
            <td style={{paddingTop:"5px"}}>
                <span style={{paddingLeft:"80px"}}>( ii ) <input type="checkbox" id="seller_disclosure_chk4" checked={leadbasepaint?.seller_disclosure_chk4}  onChange={(e)=>{setLeadBasePaint({...leadbasepaint,seller_disclosure_chk4:!leadbasepaint?.seller_disclosure_chk4})}}/>Seller has no reports or records pertaining to lead-based paint and/or lead-based paint hazards in the housing.
                
                   
                </span>
            </td>
        </tr>
        <tr>
            <td style={{paddingTop:"5px"}}>
                <span style={{fontWeight:600, color:"#3b0083"}}>Purchaser’s Acknowledgment</span>
                <span>(initial)</span>
            </td>
        </tr>
        <tr>
            <td style={{paddingTop:"5px"}}>
                <span>( c ) <input type="text" id="purchaseer_ack_value1" value={leadbasepaint?.purchaseer_ack_value1 } onChange={(e)=>{setLeadBasePaint({...leadbasepaint,purchaseer_ack_value1 :e.target.value})}}
                    style={{width:"100px"}}/>Purchaser has received copies of all information listed above.</span>
            </td>
        </tr>
        <tr>
            <td style={{paddingTop:"5px"}}>
                <span>(  d ) <input type="text" id="purchaseer_ack_value2" value={leadbasepaint?.purchaseer_ack_value2 } onChange={(e)=>{setLeadBasePaint({...leadbasepaint,purchaseer_ack_value2 :e.target.value})}}
                   style={{width:"100px"}}/>Purchaser has received the pamphlet Protect Your Family from Lead in Your Home.</span>
            </td>
        </tr>
        <tr>
            <td style={{paddingTop:"5px"}}>
                <span>( e ) Purchaser has (check (i) or (ii) below):</span>
            </td>
        </tr>
        <tr>
            <td style={{paddingTop:"5px"}}>
                <span style={{paddingLeft:"80px"}}>( i ) <input type="checkbox" id="purchaser_ack_chk1" checked={leadbasepaint?.purchaser_ack_chk1}  onChange={(e)=>{setLeadBasePaint({...leadbasepaint,purchaser_ack_chk1:!(leadbasepaint?.purchaser_ack_chk1)})}} />
                Known lead-based paint and/or lead-based paint hazards are present in the housing (explain).
                
                   
                </span>
            </td>
        </tr>
      
        <tr>
            <td style={{paddingTop:"5px"}}>
                <span style={{paddingLeft:"80px"}}>( ii ) <input type="checkbox" id="purchaser_ack_chk2" checked={leadbasepaint?.purchaser_ack_chk2}   onChange={(e)=>{setLeadBasePaint({...leadbasepaint,purchaser_ack_chk2:!(leadbasepaint?.purchaser_ack_chk2)})}} />
                Seller has no knowledge of lead-based paint and/or lead-based paint hazards in the housing.
                
                   
                </span>
            </td>
        </tr>
        <tr>
            <td style={{paddingTop:"5px"}}>
                <span style={{fontWeight:600, color:"#3b0083"}}>Agent Acknowledgment</span>
                <span>(initial)</span>
            </td>
        </tr>
        <tr>
            <td style={{paddingTop:"5px"}}>
                <span>( f ) <input type="text" id="agent_ackhnowledge" value={leadbasepaint?.agent_ackhnowledge  } onChange={(e)=>{setLeadBasePaint({...leadbasepaint,agent_ackhnowledge  :e.target.value})}}
                style={{width:"100px"}}/>Agent has informed the seller of the seller’s obligations under 42 U.S.C. 4852(d) and is aware of his/her responsibility to ensure compliance.</span>
            </td>
        </tr>
        <tr>
            <td style={{paddingTop:"5px"}}>
                <span style={{fontWeight:600, color:"#3b0083"}}>Certification of Accuracy</span><br/>
                <span>The following parties have reviewed the information above and certify, to the best of their knowledge, that the information they have provided is true and accurate. </span>
            </td>
        </tr>
        <tr>
            <td style={{paddingTop:"5px"}}>
                <table>
                    <tr>
                        <td style={{textAlign:"center"}}>
                            <span>
                                {/* <input type="text" id="seller1"
                                style={{width:'500px'}}/> */}
                                </span>
                                <span style={{paddingRight:"250px"}}> Seller</span>Date
                        </td>
                        <td style={{textAlign:"center"}}>
                            <span>
                                {/* <input type="text" id="seller1_date"
                                style={{width:"250px"}}/> */}
                                </span>
                                <span style={{paddingRight:"250px"}}> Purchaser </span>Date
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td style={{paddingTop:"5px"}}>
                <table>
                    <tr>
                        <td style={{textAlign:"center"}}>
                            <span>
                                {/* <input type="text" id="seller2"
                                style={{width:'500px'}}/> */}
                                </span>
                                <span  style={{paddingRight:"250px"}}> Seller</span>Date
                        </td>
                        <td style={{textAlign:"center"}}>
                            <span>
                                {/* <input type="text" id="seller2_date"
                               style={{width:'500px'}}/> */}
                               </span>
                                <span  style={{paddingRight:"250px"}}> Purchaser </span>Date
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td style={{paddingTop:"5px"}}>
                <table>
                    <tr>
                        <td style={{textAlign:"center"}}>
                            <span>
                                {/* <input type="text" id="seller3"
                                style={{width:'500px'}}/> */}
                                </span>
                                <span style={{paddingRight:"250px"}}> Seller</span>Date
                        </td>
                        <td style={{textAlign:"center"}}>
                            <span>
                                {/* <input type="text" id="seller3_date"
                             style={{width:'500px'}}/> */}
                             </span>
                                <span style={{paddingRight:"250px"}}> Purchaser </span>Date
                        </td>
                    </tr>
                </table>
                <img src={val} alt="" />
            </td>
        </tr>
        <tr>
            <td style={{paddingTop:"10px"}}>
                <span>Other provisions: Earnest deposit to be delivered within (5) business days</span>
            </td>
        </tr>
    </table>
</section>
<button onClick={send}>Submit</button>
<button onClick={printf}>print</button>
<React.Fragment>
            {/* <button
              className="btn btn-primary modal-createoffer-btn"
              onClick={() =>{ handleSaveDocumentEsign(); setReviewdocs(!reviewdocs)}}
            >
            Save
            </button> */}
            <button className="signaturebtn btn" onClick={() => setCanvasModal()}>Signature</button> 

          </React.Fragment>
<Esign
        canvasmodal={canvasmodal}
        setCanvasModal={setCanvasModal}
        htmlContent={htmlContent}
        setHtmlContent={setHtmlContent}
        selecteddocumentesign={selecteddocumentesign}
        setselectedDocumentEsign={setselectedDocumentEsign}
        type={"seller"}
      ></Esign>
        </>
    )
};
