import { ActionType } from './actions'
import { _reducer } from './_common'
import { Notification } from '../types'
export interface StateNotifications
{
  notifications:Notification[]
}

export const reducerNotifications = _reducer<StateNotifications>({
  notifications:[],
},[
  [ActionType.NOTIFICATION_SUCCESS, (state,v) => ({
    notifications:state.notifications.concat([{
      type:'success',
      id:v.id,
      message:v.message,
      dismissed:false,
    }])
  })],
  [ActionType.NOTIFICATION_DISMISS, (state,action) => ({
    notifications:state.notifications.map((v) => ({
      ...v,
      dismissed:v.id == action.id ? true : v.dismissed,
    }))
  })],
])
