import React from 'react';
import nodata from "../../../assets/image/nodata.jpg";

const NoImageFound = () => {
  return (
    <div>
        <div className="nofound_clas">
                <img src={nodata} alt="random" />
              </div>
    </div>
  )
}

export default NoImageFound