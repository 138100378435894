import { sygnet } from './sygnet'
import { logo } from './logo'
import { logoNegative } from './logo-negative'

import {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilAddressBook,
  cilUserPlus,
  cilUser,
  cilGroup,
  cilSearch,
  cilListFilter,
  cilLink,
  cilLinkBroken,
} from '@coreui/icons/js/free'

export const icons = Object.assign({}, {
  sygnet,
  logo,
  logoNegative
}, {
  cilApplicationsSettings,
  cilSpeedometer,
  cilSun,
  cilMoon,
  cilAddressBook,
  cilUserPlus,
  cilUser,
  cilGroup,
  cilSearch,
  cilListFilter,
  cilLink,
  cilLinkBroken,
})
