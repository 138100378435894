import { Instant, LocalDate } from "@js-joda/core"
import {
  LeadContactErrors,
  LeadDetailsErrors,
  LeadErrors,
  LeadLastContactedInfoErrors,
  LeadNotesErrors,
  LeadSlideInErrors,
  LeadStageErrors,
  PaginatedData,
  Paginator
} from "."
import { UserMeta,AgentMeta } from './user'
import Store, { State } from '../store'

export interface LeadContact {
  contacts?: any
  id:string
  firstName:string|null
  lastName:string|null
  streetOne:string|null
  streetTwo:string|null
  city:string|null
  state:string|null
  zip:string|null
  country:string|null
  email:string|null
  phone:string
  isPrimary:boolean
  relationship:string
  referralEmail:string|null
  dateOfBirth:LocalDate|null
  tShirtSize:string|null
}

export interface LeadProperty {
  url:string|null
  status:string|null
  mlsId:string|null
  mlsName:string|null
  mlsAbbreviation:string|null
  priceType:string|null
  price:number|null
  propertyType:string|null
  streetAddress:string|null
  city:string|null
  stateCode:string|null
  zip:string|null
  latitude:number|null
  longitude:number|null
  numberOfBeds:number|null
  numberOfBaths:number|null
  listingStatus:string|null
  listingDate:Date|null
}

export interface Request {
  id:string
  leadId:string
  created:Instant
  payload:string|null
}

export interface LastConnectedAgentMeta {
  id:string|null,
  leadsid:string|null,
  userid:string|null,
  updatedOn:string|null,
  userrole:string|null,
  connectedOn:string|null,
  connectedby:string|null,
}

export interface LastWorkedAgentMeta {
  id:string|null,
  leadsid:string|null,
  userid:string|null,
  updatedOn:string|null,
  userrole:string|null,
  workedOn:string|null,
  workedby:string|null,
}

export interface Lead {
  lender_id: any
  id:string
  contacts:LeadContact
  secondaryLeadContact:LeadContact[]
  property:LeadProperty|null
  agent:UserMeta|null
  concierge:UserMeta|null
  requests:Request[]
  role:string
  stage:string
  lastContacted:Instant|null
  lastConnectedAgent:Instant|null
  lastConnectedLender:Instant|null
  lastWorkedAgent:Instant|null
  leadLastConnectedAgentMeta:LastConnectedAgentMeta[]
  leadLastConnectedLenderMeta:LastConnectedAgentMeta[]
  leadLastWorkedAgentMeta:LastWorkedAgentMeta[]
  leadLastWorkedLenderMeta:LastWorkedAgentMeta[]
  source:string
  subSource:string|null
  subSourceAgent:string|null
  referralFirstName:string|null
  referralLastName:string|null
  referralEmail:string|null
  referralPhone:string|null
  engagementLevel:string|null
  leadResponseLevel:string|null
  leadContactStatus:string|null
  contactStatusLevel:string|null
  notes:string|null
  buyerPacket:boolean|null
  buyerGreenFolder:boolean|null
  sellerMagazine:boolean|null
  sellerPacket:boolean|null
  sellerMeeingAgenda:boolean|null
  sellerGreenFolder:boolean|null
  responseLevel:string|null
  lastWorked:LocalDate|null
  lastWorkedLender:LocalDate|null
  loanPreApproved:boolean|null
  lenderCompanyName:string|null
  lenderEmailAddress:string|null
  lenderPhone:string|null
  closerFirstName:string|null
  closerLastName:string|null
  homeSearchSetup:boolean|null
  closeoutReview:boolean|null
  closeoutGoogle:boolean|null
  closeoutZillow:boolean|null
  closeoutRealtorCom:boolean|null
  closeoutYelp:boolean|null
  closeoutNextdoor:boolean|null
  closeoutFacebook:boolean|null
  homebot:boolean|null
  mailingLabel:string|null
  tShirtSizes:string|null
  closingBagOption:string|null
  giftReferral:boolean|null
  giftGiveaway:boolean|null
  giftNewBaby:boolean|null
  giftHoliday:boolean|null
  giftHalloweenBag:boolean|null
  thankYouFromOwnerPostcard:boolean|null
  clientCareMessage:boolean|null
  setUpMagazineSubscription:boolean|null
  closingPhoto:string|null
  closingPhotoFileName:string|null
  closingLocation:string|null
  createdOn:string|null
}

export interface LeadMeta {
  id:string
  stage:string
  lastConnectedAgent:Instant|null
  firstName:string
  lastName:string|null
  streetOne:string|null
  streetTwo:string|null
  city:string|null
  state:string|null
  zip:string|null
  country:string|null
  email:string|null
  phone:string|null
  receiptOccurred:string
  role:string
  relationship:string
  secondaryName:string|null
  secondaryRelationship:string|null
  secondaryEmail:string|null
  secondaryPhone:string|null
  secondaryStage:string|null
  agent:UserMeta|null
  concierge:UserMeta|null
  totalNumberOfContacts:number
  source:string
  subSource:string|null
  subSourceAgent:string|null
  lender_id:string|null
  externalLender:string|null
  externalLenderPhone:string|null
  lenderType:string|null
  lender:string|null
  lendername:string|null
  lenderstatus:string|null
  lenderNotes:string|null
  lenderPhone:string|null
  engagementLevel:string|null
  leadContactStatus:string|null
  leadResponseLevel:string|null
  referralFirstName:string|null
  referralLastName:string|null
  referralName:string|null
  referralEmail:string|null
  referralPhone:string|null
  lastWorked:LocalDate|null
  lastWorkedLender:LocalDate|null
  lastConnectedLender:Instant|null
  lastWorkedAgent:Instant|null
  agentLastConnectedCount:string|null
  lenderLastConnectedCount:string|null
  agentLastWorkedCount:string|null
  lastWorkedAgentUpdatedOn:string|null
  lastWorkedLenderUpdatedOn:string|null
  lastConnectedAgentUpdatedOn:string|null
  lastConnectedLenderUpdatedOn:string|null
  lenderLastWorkedCount:string|null
  notes:string|null
  loanPreApproved:boolean|null
  homeSearchSetup:boolean|null
  homebot:boolean|null
  createdOn:string|null
  agents:string|null
  secondaryContact:string|null
  conciergelist:string|null
}

export interface Unclaimed {
  id:string
  firstName:string
  lastName:string
  phone:string
  email:string
  received:Instant
}

type ServerError = {field:string;message:string}

export interface InputLeadContact {
  contacts?:any
  id:string
  firstName:string|null
  lastName:string|null
  streetOne:string|null|undefined
  streetTwo:string|null
  city:string|null
  state:string|null
  zip:string|null
  country:string|null
  email:string|null
  phone:string
  isPrimary:boolean
  relationship:string
  referralEmail:string|null
  dateOfBirth:LocalDate|null
  tShirtSize:string|null
}

export const mapLeadContactToInputLeadContact = (v: LeadContact): InputLeadContact => {
  return ({
    id:v.id,
    firstName:v.firstName,
    lastName:v.lastName,
    streetOne:v.streetOne,
    streetTwo:v.streetTwo,
    city:v.city,
    state:v.state,
    zip:v.zip,
    country:v.country,
    email:v.email,
    phone:v.phone,
    isPrimary:v.isPrimary,
    relationship:v.relationship,
    referralEmail:v.referralEmail,
    dateOfBirth:v.dateOfBirth,
    tShirtSize:v.tShirtSize
  })
}

export const getLeadContactErrors = (v:ServerError[]) : LeadContactErrors => {
  let temp = v.reduce(
    (agg:{[x:string]:any},v) => {
      const arr = v.field.split(']')
      const prop = v.field.split('.')
      if(arr.length > 1)
      {
        const innerProp = arr[0].split('[')[0]
        const i = arr[0].replace(/\D/g,'')
        agg[innerProp][i] = {
          ...agg[innerProp][i],
          [prop[1]]:`${ map(prop[1]) } ${ v.message }`
        }
      }
      else
      {
        if(prop.length > 1)
        {
          agg[prop[0]] = {
            ...(agg[prop[0]] || {}),
            [prop[1]]:`${ map(prop[1]) } ${ v.message }`
          }
        }
        else
        {
          agg[v.field] = `${ map(v.field) } ${ v.message }`
        }
      }
      return agg
    },{
      contacts:[]
    }
  )
  return temp as LeadDetailsErrors
}

export const leadContactDisplayProp = (v: keyof InputLeadContact) => {
  switch (v) {
    case 'id':
      return 'Id'
    case 'firstName':
      return 'First Name'
    case 'lastName':
      return 'Last Name'
    case 'streetOne':
      return 'Address Line 1'
    case 'streetTwo':
      return 'Address Line 2'
    case 'city':
      return 'City'
    case 'state':
      return 'State'
    case 'zip':
      return 'Zip'
    case 'country':
      return 'Country'
    case 'email':
      return 'Email'
    case 'phone':
      return 'Phone'
    case 'isPrimary':
      return 'Is Primary'
    case 'relationship':
      return 'Relationship'
    case 'dateOfBirth':
      return 'Date of Birth'
    case 'tShirtSize':
      return 'T-shirt Size'
    default:
      return ''
  }
}

export interface InputCreateLead
{
  
  id:string
  role:string
  stage:string
  source:string
  subSource:string|null
  subSourceAgent:string|null
  referralFirstName:string|null
  referralLastName:string|null
  referralEmail:string|null
  referralPhone:string|null
  engagementLevel:string|null
  responseLevel:string|null
  leadStatus:string|null
  contactStatusLevel:string|null
  primaryContact:InputLeadContact
  agent:AgentMeta|null
  concierge:UserMeta|null
  createdOn:string|null
}

export const mapLeadToInputCreateLead = (v: Lead): InputCreateLead => {
  // @ts-ignore
  let stage = Store.store.getState().leads.leadStatuses.find((y) => y.id == v.stage)
  return ({
    id:v.id,
    primaryContact:mapLeadContactToInputLeadContact(v.contacts),
    agent:null,
    role:v.role,
    stage:stage ? stage.display : v.stage,
    source:v.source,
    subSource:v.subSource,
    subSourceAgent:v.subSourceAgent,
    referralFirstName:v.referralFirstName,
    referralLastName:v.referralLastName,
    referralEmail:v.referralEmail,
    referralPhone:v.referralPhone,
    engagementLevel:v.engagementLevel,
    responseLevel:v.leadResponseLevel,
    leadStatus:v.leadContactStatus,
    contactStatusLevel:v.contactStatusLevel,
    createdOn:v.createdOn,
    concierge:null,
  })
}

export const getLeadErrors = (v:ServerError[]) : LeadErrors => {
  let temp = v?.reduce(
    (agg:{[x:string]:any},v) => {
      const arr = v?.field.split(']')
      const prop = v?.field.split('.')
      if(arr.length > 1)
      {
        const innerProp = arr[0].split('[')[0]
        const i = arr[0].replace(/\D/g,'')
        agg[innerProp][i] = {
          ...agg[innerProp][i],
          [prop[1]]:`${ map(prop[1]) } ${ v.message }`
        }
      }
      else
      {
        if(prop.length > 1)
        {
          agg[prop[0]] = {
            ...(agg[prop[0]] || {}),
            [prop[1]]:`${ map(prop[1]) } ${ v.message }`
          }
        }
        else
        {
          agg[v.field] = `${ map(v.field) } ${ v.message }`
        }
      }
      return agg
    },{
      contacts:[]
    }
  )
  return temp as LeadErrors
}

export const leadDisplayProp = (v: keyof InputCreateLead) => {
  switch (v) {
    case 'id':
      return 'Id'
    case 'agent':
      return 'Agent'
    case 'role':
      return 'Role'
    case 'stage':
      return 'Stage'
    default:
      return ''
  }
}

export interface InputLeadLastContactedInfo
{
  id:string
  lastContacted:Instant|null
}

export const mapLeadToInputLeadLastContactedInfo = (v: Lead): InputLeadLastContactedInfo => {
  return ({
    id:'null',
    lastContacted:v.lastContacted
  })
}

export const leadLastContactedInfoDisplayProp = (v: keyof InputLeadLastContactedInfo) => {
  switch (v) {
    case 'id':
      return 'Id'
    case 'lastContacted':
      return 'Last Contacted'
    default:
      return ''
  }
}

export interface InputLeadNotes
{
  id:string
  notes:string|null
}

export const mapLeadToInputLeadNotes = (v: Lead): InputLeadNotes => {
  return ({
    id:v.id,
    notes:v.notes
  })
}

export const leadNotesDisplayProp = (v: keyof InputLeadNotes) => {
  switch (v) {
    case 'id':
      return 'Id'
    case 'notes':
      return 'Notes'
    default:
      return ''
  }
}

export interface InputLeadStage
{
  id:string
  stage:string
}

export interface LenderStatus
{
  id:string
  lenderstatus:string|null
  internallender:string|null|undefined
  externalLender:string|null|undefined
  lenderType:string|null|undefined
}

export interface AgentNotes
{
  id:string
  notes:string|null
}

export interface Homebot 
{
  id: string
  homebot: boolean|null
}

export interface preapproved {
  id: string
  loanPreApproved: boolean|null
}

export interface Homesearch {
  id: string
  homeSearchSetup: boolean|null
}

export interface lastconnected {
  id: string,
  leadsid: string|undefined,
  userid: string|undefined,
  connectedOn: Instant|null,
  updatedOn: string|null,
}

export interface lastconnectedlender {
  id: string,
  leadsid: string|undefined,
  userid: string|undefined,
  connectedOn: Instant|null,
  updatedOn: string|null,
}

export interface lastworked {
  id: string ,
  leadsid: string|undefined,
  userid: string|undefined,
  workedOn: string|LocalDate|null,
  updatedOn: string|null, 
}

export interface lastworkedlender {
  id: string,
  leadsid: string|undefined,
  userid: string|undefined,
  workedOn: string|LocalDate|null,
  updatedOn: string|null,
}

export interface assignlender {
  id: string|null,
  lenderId: string|null
}

export const mapLeadMetaToInputLeadStage = (v: LeadMeta): InputLeadStage => {
  // @ts-ignore
  let stage = Store.store.getState().leads.leadStatuses.find((y) => y.id == v.stage)
  
  return ({
    id:v.id,
    stage:stage ? stage.display : v.stage,
  })
}

export interface InputLeadEngagementLevel {
  id:string
  engagement:string|null
}

export interface InputLeadStage {
  id:string
  stage:string
}

export const mapLeadMetaToInputLeadEngagmentLevel = (v: LeadMeta): InputLeadEngagementLevel => {
  // @ts-ignore
  let engagement = Store.store.getState().leads.engagementLevel.find((y) => y.id == v.engagementLevel)
  return ({
    id:v.id,
    engagement:engagement ? engagement.display : v.engagementLevel,
  })
}

export interface InputLeadContactStatusLevel {
  id:string
  leadContactStatus:string|null
}

export const mapLeadMetaToInputLeadContactStatusLevel = (v: LeadMeta): InputLeadContactStatusLevel => {
  // @ts-ignore
  let leadContactStatus = Store.store.getState().leads.responseLevel.find((y) => y.id == v.leadContactStatus)
   
  return ({
    id:v.id,
    leadContactStatus:leadContactStatus ? leadContactStatus.display : v.leadContactStatus,
  })
}

export interface InputLeadResponseLevel {
  id:string
  leadResponseLevel:string|null
}

export const mapLeadMetaToInputLeadResponseStatusLevel = (v: LeadMeta): InputLeadResponseLevel => {
  // @ts-ignore
  let leadResponseLevel = Store.store.getState().leads.responseLevel.find((y) => y.id == v.leadResponseLevel)
  
  return ({
    id:v.id,
    leadResponseLevel:leadResponseLevel ? leadResponseLevel.display : v.leadResponseLevel,
  })
}

export const mapLeadToInputLeadStage = (v: Lead): InputLeadStage => {
  // @ts-ignore
  let stage = Store.store.getState().leads.leadStatuses.find((y) => y.id == v.stage)
  return ({
    id:v.id,
    stage:stage ? stage.display : v.stage,
  })
}

export const leadEnagementlevel = (v: keyof InputLeadEngagementLevel) => {
  switch (v) {
    case 'id':
      return 'Id'
    case 'engagement':
      return 'engagement'
    default:
      return ''
  }
}

export const leadContactStatuslevel = (v: keyof InputLeadContactStatusLevel) => {
  switch (v) {
    case 'id':
      return 'Id'
    case 'leadContactStatus':
      return 'leadContactStatus'
    default:
      return ''
  }
}

export const leadStageDisplayProp = (v: keyof InputLeadStage) => {
  switch (v) {
    case 'id':
      return 'Id'
    case 'stage':
      return 'Stage'
    default:
      return ''
  }
}

export const lenderStatusDisplayProp = (v: keyof LenderStatus):string => {
  switch (v) {
    case 'id':
      return 'Id'
    case 'lenderstatus':
      return 'Lender Status'
    default:
      return ''
  }
}

export interface InputLeadRole {
  id:string
  role:string
}

export const mapLeadToInputLeadRole = (v: Lead): InputLeadRole => {
  // @ts-ignore
  let stage = Store.store.getState().leads.leadStatuses.find((y) => y.id == v.stage)
  return ({
    id:v.id,
    role:v.role
  })
}

export const leadRoleDisplayProp = (v: keyof InputLeadRole) => {
  switch (v) {
    case 'id':
      return 'Id'
    case 'role':
      return 'Role'
    default:
      return ''
  }
}

export interface InputLeadDetails {
  id:string
  buyerPacket:boolean|null
  buyerGreenFolder:boolean|null
  sellerMagazine:boolean|null
  sellerPacket:boolean|null
  sellerMeeingAgenda:boolean|null
  sellerGreenFolder:boolean|null
  responseLevel:string|null
  lastWorked:LocalDate|null
  lastWorkedLender:LocalDate|null
  loanPreApproved:boolean|null
  lenderCompanyName:string|null
  lenderEmailAddress:string|null
  lenderPhone:string|null
  closerFirstName:string|null
  closerLastName:string|null
  homeSearchSetup:boolean|null
  closeoutReview:boolean|null
  closeoutGoogle:boolean|null
  closeoutZillow:boolean|null
  closeoutRealtorCom:boolean|null
  closeoutYelp:boolean|null
  closeoutNextdoor:boolean|null
  closeoutFacebook:boolean|null
  homebot:boolean|null
  mailingLabel:string|null
  tShirtSizes:string|null
  closingBagOption:string|null
  giftReferral:boolean|null
  giftGiveaway:boolean|null
  giftNewBaby:boolean|null
  giftHoliday:boolean|null
  giftHalloweenBag:boolean|null
  thankYouFromOwnerPostcard:boolean|null
  clientCareMessage:boolean|null
  setUpMagazineSubscription:boolean|null
  closingPhoto:string|null
  closingPhotoFileName:string|null
  closingLocation:string|null
}

export const mapLeadToInputLeadDetails = (v: Lead): InputLeadDetails => {
  return ({
    id:v.id,
    buyerPacket:v.buyerPacket,
    buyerGreenFolder:v.buyerGreenFolder,
    sellerMagazine:v.sellerMagazine,
    sellerPacket:v.sellerPacket,
    sellerMeeingAgenda:v.sellerMeeingAgenda,
    sellerGreenFolder:v.sellerGreenFolder,
    responseLevel:v.responseLevel,
    lastWorked:v.lastWorked,
    lastWorkedLender:v.lastWorkedLender,
    loanPreApproved:v.loanPreApproved,
    lenderCompanyName:v.lenderCompanyName,
    lenderEmailAddress:v.lenderEmailAddress,
    lenderPhone:v.lenderPhone,
    closerFirstName:v.closerFirstName,
    closerLastName:v.closerLastName,
    homeSearchSetup:v.homeSearchSetup,
    closeoutReview:v.closeoutReview,
    closeoutGoogle:v.closeoutGoogle,
    closeoutZillow:v.closeoutZillow,
    closeoutRealtorCom:v.closeoutRealtorCom,
    closeoutYelp:v.closeoutYelp,
    closeoutNextdoor:v.closeoutNextdoor,
    closeoutFacebook:v.closeoutFacebook,
    homebot:v.homebot,
    mailingLabel:v.mailingLabel,
    tShirtSizes:v.tShirtSizes,
    closingBagOption:v.closingBagOption,
    giftReferral:v.giftReferral,
    giftGiveaway:v.giftGiveaway,
    giftNewBaby:v.giftNewBaby,
    giftHoliday:v.giftHoliday,
    giftHalloweenBag:v.giftHalloweenBag,
    thankYouFromOwnerPostcard:v.thankYouFromOwnerPostcard,
    clientCareMessage:v.clientCareMessage,
    setUpMagazineSubscription:v.setUpMagazineSubscription,
    closingPhoto:v.closingPhoto,
    closingPhotoFileName:v.closingPhotoFileName,
    closingLocation:v.closingLocation
  })
}

export const getLeadDetailsErrors = (v:ServerError[]) : LeadDetailsErrors => {
  let temp = v.reduce(
    (agg:{[x:string]:any},v) => {
      const arr = v.field.split(']')
      const prop = v.field.split('.')
      if(arr.length > 1)
      {
        const innerProp = arr[0].split('[')[0]
        const i = arr[0].replace(/\D/g,'')
        agg[innerProp][i] = {
          ...agg[innerProp][i],
          [prop[1]]:`${ map(prop[1]) } ${ v.message }`
        }
      }
      else
      {
        if(prop.length > 1)
        {
          agg[prop[0]] = {
            ...(agg[prop[0]] || {}),
            [prop[1]]:`${ map(prop[1]) } ${ v.message }`
          }
        }
        else
        {
          agg[v.field] = `${ map(v.field) } ${ v.message }`
        }
      }
      return agg
    },{
      contacts:[]
    }
  )
  return temp as LeadDetailsErrors
}

export const leadDetailsDisplayProp = (v: keyof InputLeadDetails) => {
  switch (v) {
    case 'id':
      return 'Id';
    case 'buyerPacket':
      return 'Buyer Packet';
    case 'buyerGreenFolder':
      return 'Buyer Green Folder';
    case 'sellerMagazine':
      return 'Seller Magazine';
    case 'sellerPacket':
      return 'Seller Packet';
    case 'sellerMeeingAgenda':
      return 'Seller Meeting Agenda';
    case 'sellerGreenFolder':
      return 'Seller Green Folder';
    case 'responseLevel':
      return 'Response Level';
    case 'lastWorked':
      return 'Last Worked';
    case 'loanPreApproved':
      return 'Loan Pre-approved';
    case 'lenderCompanyName':
      return 'Lender Company Name';
    case 'lenderEmailAddress':
      return 'Lender Email Address';
    case 'lenderPhone':
      return 'Lender Phone';
    case 'closerFirstName':
      return 'Closer First Name';
    case 'closerLastName':
      return 'closer Last Name';
    case 'homeSearchSetup':
      return 'Home Search Setup';
    case 'closeoutReview':
      return 'Review';
    case 'closeoutGoogle':
      return 'Google';
    case 'closeoutZillow':
      return 'Zillow';
    case 'closeoutRealtorCom':
      return 'Realtor.com';
    case 'closeoutYelp':
      return 'Yelp';
    case 'closeoutNextdoor':
      return 'Nextdoor';
    case 'closeoutFacebook':
      return 'Facebook';
    case 'homebot':
      return 'Homebot';
    case 'mailingLabel':
      return 'Mailing Label';
    case 'tShirtSizes':
      return 'T-ShirtSizes';
    case 'closingBagOption':
      return 'Closing Bag Option';
    case 'giftReferral':
      return 'Referral';
    case 'giftGiveaway':
      return 'Giveaway';
    case 'giftNewBaby':
      return 'New Baby';
    case 'giftHoliday':
      return 'Holiday';
    case 'giftHalloweenBag':
      return 'Halloween Bag';
    case 'thankYouFromOwnerPostcard':
      return 'Thank You From Owner Postcard';
    case 'clientCareMessage':
      return 'Client Care Message';
    case 'setUpMagazineSubscription':
      return 'Set Up Magazine Subscription';
    case 'closingPhoto':
      return 'Closing Photo';
    case 'closingPhotoFileName':
      return 'Closing Photo File Name';
    case 'closingLocation':
      return 'Location';
    default:
      return '';
  }
}

export interface InputLeadSlideIn {
  id:string
  role:string|null
  stage:string|null
  concierge?:UserMeta|null
  agent?:UserMeta|null
  lastContacted:Instant|null
  lastWorked:LocalDate|null
  lastContactedLender:Instant|null
  lastWorkedLender:LocalDate|null
  lenderPhone:string|null
  lenderId:string|null
  externalLender:string|null
  externalLenderPhone:string|null
  homeSearchSetup:boolean|null
  loanPreApproved:boolean|null
  notes:string|null
  lenderNotes:string|null
  homebot:boolean|null
}

export const mapLeadMetaToInputLeadSlideIn = (v: LeadMeta): InputLeadSlideIn => {
  return ({
    id:v.id,
    role:v.role,
    stage:v.stage,
    concierge:v.concierge,
    agent:v.agent,
    lastContacted:v.lastConnectedAgent,
    lastWorked:v.lastWorked,
    lastContactedLender:v.lastConnectedLender,
    lastWorkedLender:v.lastWorkedLender,
    externalLender:v.externalLender,
    externalLenderPhone:v.externalLenderPhone,
    lenderId:v.lender_id,
    lenderPhone:v.lenderPhone,
    homeSearchSetup:v.homeSearchSetup,
    loanPreApproved:v.loanPreApproved,
    notes:v.notes,
    lenderNotes:v.lenderNotes,
    homebot:v.homebot,
    })
}

export const getLeadSlideInErrors = (v:ServerError[]) : LeadSlideInErrors => {
  let temp = v.reduce(
    (agg:{[x:string]:any},v) => {
      const arr = v.field.split(']')
      const prop = v.field.split('.')
      if(arr.length > 1)
      {
        const innerProp = arr[0].split('[')[0]
        const i = arr[0].replace(/\D/g,'')
        agg[innerProp][i] = {
          ...agg[innerProp][i],
          [prop[1]]:`${ map(prop[1]) } ${ v.message }`
        }
      }
      else
      {
        if(prop.length > 1)
        {
          agg[prop[0]] = {
            ...(agg[prop[0]] || {}),
            [prop[1]]:`${ map(prop[1]) } ${ v.message }`
          }
        }
        else
        {
          agg[v.field] = `${ map(v.field) } ${ v.message }`
        }
      }
      return agg
    },{
      contacts:[]
    }
  )
  return temp as LeadSlideInErrors
}

export const leadSlideInDisplayProp = (v: keyof InputLeadSlideIn) => {
  switch (v) {
    case 'id':
      return 'Id';
    case 'role':
      return 'Role';
    case 'stage':
      return 'State';
    case 'agent':
      return 'Agent';
    case 'concierge':
      return 'Concierge';
    case 'lastContacted':
      return 'Last Connected';
    case 'lastWorked':
      return 'Last Worked';
    case 'lastContactedLender':
        return 'Last Connected Lender';
    case 'lastWorkedLender':
        return 'Last Worked Lender';
    case 'homeSearchSetup':
      return 'Home Search Setup';
    case 'loanPreApproved':
      return 'Loan Pre-Approved';
    case 'notes':
      return 'Notes';
    default:
      return '';
  }
}

const map = (v: string) => {
  let a = leadDisplayProp(v as keyof InputCreateLead)
  return a ? a : leadContactDisplayProp(v as keyof LeadContact)
}

export type LeadSearchCriteria = {
  role:string,
  search:string,
  agent:string,
  stage:string|null,
  status:string|null
}

export const defaultLeadSearchCriteria : LeadSearchCriteria = {
  role:'',
  search:'',
  agent:'',
  stage:null,
  status:null
}
