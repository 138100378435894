import { ActionType } from './actions'
import { _reducer } from './_common'
import { Paginator, User } from '../types'
import { SortedPaginator, defaulSortedtPaginator } from '../types/pagination'

export interface StateUsers
{
  loading:boolean,
  user_loading:boolean
  users:User[],
  user:User|null,
  usersPaginator:SortedPaginator
}

const defaultState : StateUsers =
{
  loading:false,
  user_loading:false,
  users:[],
  user:null,
  usersPaginator:defaulSortedtPaginator
}

export const reducerUsers = _reducer<StateUsers>(defaultState,[
  [ActionType.USERS_PAGINATOR_SET, (state,action) => ({
    ...state,
    usersPaginator:action.usersPaginator,
  })],
  [ActionType.USERS_FETCH, (state,_) => ({
    ...state,
    loading:true,
  })],
  [ActionType.USERS_FETCH_SUCCESSFUL, (state,action) => ({
    ...state,
    loading:false,
    users:action.users,
  })],
  [ActionType.USERS_FETCH_ERROR, (state,action) => ({
    ...state,
    loading:false,
  })],
  [ ActionType.USER_FETCH_SUCCESSFUL, (state, action) => ({
    ...state,
    user_loading:false,
    user:action.user
  })],
  [ActionType.USER_FETCH, (state,action) => ({
    ...state,
    user_loading:true,
    user:null
  })],
  [ActionType.USER_FETCH_ERROR, (state,action) => ({
    ...state,
    user_loading:false,
    user:null,
  })],
  [ActionType.LOGOUT,() => defaultState]
])