import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { login_success } from "../../../state/actions";
import { useDispatch } from "react-redux";

const NavbarMobile = () => {
  const [loggedin, setLoggedIn] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [toggleNav, settoggleNav] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    async function fetchCurrentUser() {
      let user: any = localStorage.getItem("user");
      let user_id = JSON.parse(user);
      if (user_id) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    }
    fetchCurrentUser();
  }, []);

  const handlelogin = (e: any) => {
    e.preventDefault();
    history.push("/login");
  };

  const handleLogout = () => {
    debugger
   localStorage.clear()
    history.push("/login");
  };

  const handlePopup = () => {
    settoggleNav(!toggleNav);
  };

  const handleClickOutside = (event: any) => {
    if (event.target.closest(".profile-dropdown") === null) {
      setToggle(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const dispatch = useDispatch()
  const gotoleads = () => {

    let data: any = localStorage.getItem('logindata')
    let res = JSON.parse(data)
    // console.log(dia1, "newdata");
    let user = JSON.parse(atob(res?.jwtToken.split('.')[1])) 
    // let JWTtoken = atob(data?.jwtToken.split('.')[1])
    // const user = JSON.parse(JWTtoken);
    dispatch(login_success({
      token: res?.jwtToken,
      user: {
        ...user,
        permissions: user.permissions.split(",")

      },
    }))
    // navigate_to('/leads');
  }
  return (
    <>
      {loggedin === false ? (
        <button className="btn btn-login signupbtn loginbtn-top mobileview">
          <Link to="/login" onClick={(e) => handlelogin(e)}>
            Login
          </Link>
        </button>
      ) : (
        // <div className="profile flex mobileview">
        //   <span className="profileCircle loginLogout">
        //     <div className="half" onClick={() => handlePopup()}>
        //       <label htmlFor="profile2" className="profile-dropdown">
        //         <img src="https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png" />

        //         {toggle === true ? (
        //           <ul>
        //             <li>
        //               <NavLink to="/property-list/clientprofile">
        //                 Profile
        //               </NavLink>
        //             </li>
        //             <li>
        //               <a href="#">Messages</a>
        //             </li>
        //             <li>
        //               <NavLink
        //                 to={{
        //                   pathname: "/property-list/favourites",
        //                   state: { prevPath: location.pathname },
        //                 }}
        //               >
        //                 Favorites
        //               </NavLink>
        //             </li>
        //             <li>
        //               <NavLink
        //                 to={{
        //                   pathname: "/property-list/savedlists",
        //                   state: { prevPath: location.pathname },
        //                 }}
        //               >
        //                 Saved Lists
        //               </NavLink>
        //             </li>
        //             <li>
        //               <NavLink
        //                 to={{
        //                   pathname: "/property-list/dislike",
        //                   state: { prevPath: location.pathname },
        //                 }}
        //               >
        //                 Dislikes
        //               </NavLink>
        //             </li>
        //             <li>
        //               <NavLink
        //                 to={{
        //                   pathname: "/property-list/recentlyviewed",
        //                   state: { prevPath: location.pathname },
        //                 }}
        //               >
        //                 Recently Viewed
        //               </NavLink>
        //             </li>
        //             <li>
        //               <NavLink
        //                 to={{
        //                   pathname: "/property-list/yourteam",
        //                   state: { prevPath: location.pathname },
        //                 }}
        //               >
        //                 Your Team
        //               </NavLink>
        //             </li>
        //             <li>
        //               <NavLink
        //                 to={{
        //                   pathname: "/property-list/account",
        //                   state: { prevPath: location.pathname },
        //                 }}
        //               >
        //                 Account Settings
        //               </NavLink>
        //             </li>
        //             <hr></hr>
        //             <li>
        //               <NavLink
        //                 to="/login"
        //                 className="btn sign-out-btn"
        //                 onClick={() => handleLogout()}
        //               >
        //                 SIGN OUT
        //               </NavLink>
        //             </li>
        //           </ul>
        //         ) : (
        //           ""
        //         )}
        //       </label>
        //     </div>
        //   </span>
        // </div>
        <div className="nav-heart-user desktopHidden">
        <div className="nav-heart">
          <Link to="/property-list/favourites">
            <i className="fa-solid fa-heart link-dark"></i>
          </Link>
        </div>

        <div className="profile-dropdown">
          <div
            className="nav-user profile-dropdown"
            onClick={() => handlePopup()}
          >
            <i className="fa-solid fa-user"></i>
          </div>
          {toggleNav === true ? (
            <ul>
              <li>
                <Link to="/property-list/clientprofile">Profile</Link>
              </li>
              <li >

                      <Link to={{ 
                            pathname: "/leads", 
                            state: { prevPath: location.pathname }, 
                          }}  onClick={gotoleads}> Dashboard</Link> 
                      </li>
              <li>
                <Link to="/property-list/messages">Messages</Link>
              </li>
              <li>
                <Link to="/property-list/savedlists">Save Searches</Link>
              </li>
              <li>
                  <Link to="/property-list/favourites">Favourites</Link>
                </li>
              <li>
                <Link to="/property-list/dislike">Dislikes</Link>
              </li>
              <li>
                <Link to="/property-list/recentlyviewed">
                  Recently Viewed
                </Link>
              </li>
              <li>
                <Link to="/property-list/yourteam">Your Team</Link>
              </li>
              <li>
                <Link to="/property-list/account">Account Settings</Link>
              </li>
              <hr />
              <li onClick={handleLogout}>
                <Link className="btn sign-out-btn" to="/login">
                  SIGN OUT
                </Link>
              </li>
            </ul>
          ) : (
            ""
          )}
        </div>
      </div>
      )}
    </>
  );
};

export default NavbarMobile;



