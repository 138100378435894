import React, { Suspense,useEffect } from 'react'
import {
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom'
import { CContainer, CFade } from '@coreui/react'
import { connect,DispatchProp } from 'react-redux'
import { GuardProvider, GuardedRoute } from 'react-router-guards'
import { RouteComponentProps } from 'react-router'
// routes config
import routes from '../../../routes'
import Websocket from '../../../socket/Component'
import { State } from '../../../store'
import { hasPermission } from '../../../types/user'
import { Permission } from '../../../types/permissions'
import { loginNeeded,navigated } from '../../../state/actions'
import Notifications from '../../../components/Notifications'

const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  )

  type Props = {
    user:State['auth']['user']
  } & DispatchProp
  const Content : React.FC<Props> = (props) => {

    // const requireIdxUser = (_to:any, _from:any, next:any) => {
    //     console.log("requireIdxUserrequireIdxUsercalled"); 
    //     let user = localStorage.getItem("user");
    //     let user_id = user ? JSON.parse(user) : null ;
    //     console.log(user_id,"requireIdxUserrequireIdxUser"); 
    //     if(user_id?.id){
    //         console.log("cojndjfhkdfbhkfdjkdhfb"); 
    //       next()
    //     }else {
    //       next.redirect("/property-list");
    //     }
    //   }

      const requireIdxUser = (to:any, _from:any, next:any) => {
        let user = localStorage.getItem("user");
        let user_id = user ? JSON.parse(user) : null ;
    if(user_id)
    {
      if(user_id)
      {
        next()
      } else {
        props.dispatch(loginNeeded(window.location.pathname))
        next.redirect('/property-list')
      }
    }
    else
    {
      next()
    }
  }

  return (
    <>
          <Switch>
            {routes?.map((route, idx) => {
              return route.component && (
                  <GuardedRoute
                    key={idx}
                    path={route.path}
                    exact={true}
                    meta={ { auth:true,permissions:route.permissions } }
                    guards={ [requireIdxUser] }
                    component={ (props:RouteComponentProps) => <CFade className="h-100"><route.component {...props }/></CFade> }
                  />
              )
            })}
            <Redirect from="/" to="/property-list" />
          </Switch>
    </>
  )
}

// export default Content
export default connect((state:State) => ({
    user:state.auth.user,
  }))(Content)