import { createStore,combineReducers,applyMiddleware } from 'redux'
import { persistStore,persistReducer } from 'redux-persist'
import Storage from 'redux-persist/lib/storage'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import { reducerAuth,StateAuth } from './state/auth'
import { reducerNavigate,StateNavigate } from './state/navigate'
import { reducerLeads,StateLeads } from './state/leads'
import { reducerUsers,StateUsers } from './state/users'
import { reducerNotifications,StateNotifications } from './state/notifications'
import { addresssearch, datastore, Offerid, searchbarstate } from './state/datastore'

export interface State
{
  auth:StateAuth
  navigate:StateNavigate
  leads:StateLeads
  users:StateUsers
  notifications:StateNotifications
  currentLocation:string

}
const persistedDatastore = persistReducer({
  key: 'datastore',
  storage: Storage,
}, datastore);
const persistedOfferidStore = persistReducer({
  key: 'offerid',
  storage: Storage,
}, Offerid);

const addresssave = persistReducer({
  key:'address',
  storage:Storage,
},addresssearch)

const searchaddresssave = persistReducer({
  key:'addressbar',
  storage:Storage,
},searchbarstate)

const initialState = {
  signset: ""
};


const counterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET':
      return { ...state, signset: action.payload };
 
    default:
      return state;
  }
};

const initialproperties = {
  properties:{}
};

const counterproperties = (state = initialproperties, action) => {
  switch (action.type) {
    case 'UPDATE_PROPERTIES':
      return { ...state, properties: action.payload };
 
    default:
      return state;
  }
};

 const store = createStore(combineReducers({
  auth: persistReducer(
    {
      key:'auth',
      storage:Storage,
      whitelist: ['user','token','rolename'],
    },
    reducerAuth
  ),
  navigate:reducerNavigate,
  leads:reducerLeads,
  users:reducerUsers,
  location:persistedDatastore,
  newofferid:persistedOfferidStore,
  newadd:addresssave,
  newsearchbar:searchaddresssave,
  newprop:counterproperties,
  new:counterReducer,
  notifications:reducerNotifications,
}),composeWithDevTools(applyMiddleware(thunkMiddleware)))
 const persistor = persistStore(store)


 export const propertiesStore = createStore(counterproperties);
export const stor1 = createStore(counterReducer);


export default { store,persistor ,stor1,propertiesStore}


