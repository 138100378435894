import React from "react";

export const AnimateAN = () => {
  return (
    <>
      <div className="text-container-animation">
        <p className="item-1">It all starts at <i>home</i>.</p>

        <p className="item-2"> <i>Love</i>  where you live.</p> 
      </div>
    </>
  );
};
