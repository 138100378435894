import React, { useState, useEffect } from "react";
import { connect, DispatchProp } from "react-redux";
import Dropdown from "../../components/form/input/Dropdown";
import {
  AssignAllPermissions,
  GetAllPermissions,
  GetUserPermissionsBasedonRoles,
  getUser,
} from "../../api/users";
import { GetRoles } from "../../api/users";
import { notification_dismiss, notification_success } from "../../state/actions";
import PermissionsCheckbox from "./PermissionsCheckbox";
import Page from '../../containers/Page';
import Store from "../../store";
import Skeleton from "react-loading-skeleton";

type Props = {} & DispatchProp;
const Useraccess: React.FC<Props> = (props) => {
  const [user, setUser] = useState<any>([]);
  const [selectedrole, setSelectedRole] = useState<any>("");
  const [selectedrolepermission, setSelectedRolePermission] = useState<Boolean>(false);
  const [permissions, setPermissions] = useState<any>([]);
  const [permissionsofselectedrole, setPermissionsOfSelectedRole] = useState<{id: any;permission: any;view: boolean;edit: boolean;}[]>([]);
  const [isLoading, setLoading] = useState(false);

  /** It is used to fetch all the permissions of the selected role on page load */
  useEffect(() => {
    async function anonymous() {
      let userselectedrole = user?.find((v:any) => v?.rolename === selectedrole?.rolename)
      let permissions = await GetUserPermissionsBasedonRoles(userselectedrole?.name);
      setPermissionsOfSelectedRole(permissions?.permission);
    }
    if (selectedrolepermission === true) {
      anonymous();
      setSelectedRolePermission(false);
    }
  }, [selectedrolepermission]);

  /** It is used to get list of roles */
  useEffect(() => {
    async function fetchroles() {
         // @ts-ignore
      let user = Store?.store?.getState().auth.user;
      let role = await GetRoles();
      let id = user?.id
      let current_user = await getUser(id);
      let loggedinrole = current_user?.role?.role;
      if(loggedinrole !== "super-admin") {
        let filtered_roles = role?.filter((item:any) => item?.name !== loggedinrole);
        setUser(filtered_roles); 
      }else {
        setUser(role);
      }
    }
    fetchroles();
  }, [])

  /** It is used to get all roles and permissions on page load */
  useEffect(() => {
    async function anonymous() {
      let permissions = await GetAllPermissions();
      const groupedPermissions = permissions?.reduce((acc: any, permission: any) => {
          acc[permission.permissionType].push(permission);
          return acc;
        },
        {
          Lead: [],
          Column: [],
          Transaction: [],
          Stage: [],
        }
      );
      let finaldata = [];
      finaldata.push(groupedPermissions);
      setPermissions(finaldata);
    }
    anonymous();
  }, []);

  /** It runs when value is selected from drop-down */
  const handleChange = (e: any) => {
    setSelectedRole(e);
    setSelectedRolePermission(true);
  };

  /** It is used to assign permission to the selected role */
  const handleSubmit = () => {
    if (permissionsofselectedrole.length > 0) {
      setLoading(true);
      let user_role = user?.find((v:any) => v?.rolename === selectedrole?.rolename);
    let updated_permissions = {
      role: user_role?.name,
      permission: permissionsofselectedrole,
    };

    AssignAllPermissions(updated_permissions, {
      successful: async (id) => {
        props.dispatch(
          notification_success({ message: `User permissions updated successfully` })
        );
        setLoading(false);
      },
      unprocessable: () => {
        props.dispatch(
          notification_success({
            message: `User permissions are not updated succesfully`,
          })
        );
        setLoading(false);
      },
    });
    } else {
      props.dispatch(
        notification_success({ message: `Select atleast one permission` })
      );
    }
    
  };

  return (
    <Page title={ 'User Permission'} textClass={true}>
    <>
    
      <div className="card filter-bar">
        <div className="card-body p-3 d-flex align-items-center flex-wrap justify-content-between">
          <div className="d-flex filters">
            <div>
              <Dropdown
                placeholder={"Select Role"}
                options={user?.map((v: any) => v)}
                projection={(v: any) => (v ? v.rolename : "")}
                value={selectedrole}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>
      </div>

      {selectedrole ? (
        <div className="main-content-wrapper card permission-card">
          <div className={"card-body d-flex flex-column"}>
            <div className="row">
              <div className="col-md-12 col-12 mb-4 mt-3">
                {isLoading ? 
                   permissions?.map((v: any) => {
                          
                    return (
                      <>
                        <div className="checklistMain">
                          <h1 className="mb-4">Dashboard Columns</h1>
                          <div className="checklistitem dashboardColumn">
                            {v["Column"]?.map((item: any) => (
                              <Skeleton />
                            ))}
                          </div>
                          <h1 className="mb-4">Leads Detail</h1>
                          <div className="checklistitem">
                            {v["Lead"]?.map((item: any) => (
                              <Skeleton />
                            ))}
                          </div>
                          <h1 className="mb-4">Sidebar</h1>
                          <div className="checklistitem">
                            {v["Stage"]?.map((item: any) => (
                              <Skeleton />
                            ))}
                          </div>
                          <h1 className="mb-4 mt-5">Transaction</h1>
                          <div className="checklistitem">
                            {v["Transaction"]?.map((item: any) => (
                              <Skeleton />
                            ))}
                          </div>
                        </div>
                      </>
                    );
                  }
                  )
                : (
                  <div className="checklistMain">
                    <div className="checklist-container">
                      <div className="">                        
                        {permissions?.map((v: any) => {
                          
                          return (
                            <>
                              <div>
                                <h1 className="mb-4">Dashboard Columns</h1>
                                <div className="checklistitem dashboardColumn">
                                  {v["Column"]?.map((item: any) => (
                                    <PermissionsCheckbox
                                      key={item}
                                      item={item}
                                      permissionsofselectedrole={
                                        permissionsofselectedrole
                                      }
                                      setPermissionsOfSelectedRole={
                                        setPermissionsOfSelectedRole
                                      }
                                      loader={isLoading}
                                    />
                                  ))}
                                </div>
                                <h1 className="mb-4">Leads Detail</h1>
                                <div className="checklistitem">
                                  {v["Lead"]?.map((item: any) => (
                                    <PermissionsCheckbox
                                      key={item}
                                      item={item}
                                      permissionsofselectedrole={
                                        permissionsofselectedrole
                                      }
                                      setPermissionsOfSelectedRole={
                                        setPermissionsOfSelectedRole
                                      }
                                    />
                                  ))}
                                </div>
                                <h1 className="mb-4">Sidebar</h1>
                                <div className="checklistitem">
                                  {v["Stage"]?.map((item: any) => (
                                    <PermissionsCheckbox
                                      key={item}
                                      item={item}
                                      permissionsofselectedrole={
                                        permissionsofselectedrole
                                      }
                                      setPermissionsOfSelectedRole={
                                        setPermissionsOfSelectedRole
                                      }
                                    />
                                  ))}
                                </div>
                                <h1 className="mb-4 mt-5">Transaction</h1>
                                <div className="checklistitem">
                                  {v["Transaction"]?.map((item: any) => (
                                    <PermissionsCheckbox
                                      key={item}
                                      item={item}
                                      permissionsofselectedrole={
                                        permissionsofselectedrole
                                      }
                                      setPermissionsOfSelectedRole={
                                        setPermissionsOfSelectedRole
                                      }
                                    />
                                  ))}
                                </div>
                              </div>
                            </>
                          );
                        }
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="AssignPermissionbtn">
              <button type="button" onClick={() => handleSubmit()}>
                Assign Permission
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="main-content-wrapper card">
          <div className={"card-body d-flex flex-column"}>
            <div className="col-md-12 col-12 mb-4 mt-3">
            <div className="user-role-heading">
                 <h1> Please Select Role From Dropdown</h1>
              </div>

              <div className="user-role">
                {/* <img src={userrole} alt="sfdwfd" className="img-fluid"/> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
    </Page>
  );
};

export default connect(() => ({}))(Useraccess);
