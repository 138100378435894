import React, { useEffect, PropsWithChildren } from 'react'
import { connect, DispatchProp } from 'react-redux'

import { setTitle } from '../state/actions'

type Props = {
  list?:React.ReactNode
  actions?:React.ReactNode[]
  header?:React.ReactNode
  title:string
  skeleton?:boolean
  className?:string
  textClass?:boolean
  children:React.ReactNode
  style?:string
} & DispatchProp

const Page: React.FC<Props> = (props: PropsWithChildren<Props>) => {



  useEffect(() => { props.dispatch(setTitle(props.title)) },[])

  return (
    <React.Fragment>
      {props.className === "display-none" ? "" :  <div className="page-wrapper">
        <div className="content-wrapper row">
          <div className={ 'col d-flex flex-column overflow-scroll' }>
            {
              props.header ?
                <div className="card filter-bar">
                  <div className={props.skeleton === false ? "card-body p-3 d-flex align-items-center flex-wrap justify-content-between" : ""}>
                    { props.header }
                  </div>
                </div>
                :
                null
            }
            <div>
              {
                (props.actions || []).map((v,i) => <React.Fragment key={ i }>{ v }</React.Fragment>)
              }
            </div>
            <div className={props.textClass === true ? "main-content-wrapper" : "main-content-wrapper card"} >
              <div className={ 'card-body d-flex flex-column' }>
                { props.children }
              </div>
            </div>
          </div>
        </div>
      </div>}
     
    </React.Fragment>
  )
}

export default connect(() => ({}))(Page)
