import React, { useState, RefObject, useRef } from "react";
import { forgotPassword } from "../../api/idx";
import { DispatchProp, connect } from "react-redux";
import Navbar from "./Navbar";
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import lockIcon from "../../assets/icons/Wired-lock.png";

type InputRefType = RefObject<HTMLButtonElement>;
type Props = DispatchProp<any>;
const Resetpassword: React.FC<Props> = (props:any) => {
  const [email, setEmail] = useState("");
  const [isloading , setisLoading] = useState(false);
  const [validation, setValidation] = useState<any>("");
  const history = useHistory();

  const [showModal, setShowModal] = useState(false); 
  /* This function runs when enter on the login button */
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if(inputRef.current){
        inputRef.current.click(); 
      }
    }
  };
  const handleInput = (e: any) => {
    let value = e.target.value;
    setEmail(value);
  };

  const checkValidation = () => {
    let errors = JSON.parse(JSON.stringify(validation));
    // Email validation
    const emailCond = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    switch (true) {
      case !email.trim():
        errors = "Email is required";
        break;
      case !emailCond.test(email):
        errors = "Please enter a registered email address";
        break;
      default:
        errors = "";
    }
    return errors;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let validations = await checkValidation();
    let api_result:any;
    if (validations === "") {
      setValidation("");
      setisLoading(true)
      api_result = await forgotPassword(email).catch((err)=>console.log(err.response.status,'fujgb'));
      
      if (api_result == 200) {
        setisLoading(false)
        setShowModal(true);
        setEmail("")
        
      }else {
        setisLoading(false)
        setValidation("Please enter a registered email address")  
      }
    } else {
      setisLoading(false)
      setValidation(validations);
    }
  };

  const inputRef: InputRefType = useRef(null);

  const closeModalAndRedirect = () => {
    setShowModal(false);
    history.push('/login');
  }

  return (
    <>
    <Navbar/>
    {/* Check your email modal popup */}
    {showModal && (
    <div
        className="modal fade resetPassword show"
        id="exampleModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div className="modal-content">
            <div className="modalCloseIcon" onClick={closeModalAndRedirect}>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path d="M3 30L30 3" stroke="#1E1D1E" stroke-width="1.52" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M30 30L3 3" stroke="#1E1D1E" stroke-width="1.52" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div className="modal-body d-flex align-items-center justify-content-center">
              <div className="text-center">
                <img src={lockIcon} alt="lock icon img"/>
                <h1 className="title">Check your email</h1>
                <p className="mt-5"><b>Please check your email to reset your password.</b></p>
                <p className="mt-4"><b>Need assistance?</b> Just click the purple chat icon at the bottom of your screen to be<br/> connected to our concierge team.  </p>
                <button type="button" className="btn btn-secondary closePopupBtn-white" data-dismiss="modal" onClick={closeModalAndRedirect}>Close</button>
              </div>
            </div>
          </div>
        </div>
    </div>
    )}

      {/* reset password code starts here */}
      <div className="login-bg reset-bg" id="reset">
      <svg
          className="tringle-bg"
          xmlns="http://www.w3.org/2000/svg"
          width="1011"
          height="985"
          viewBox="0 0 1011 985"
          fill="none"
        >
          <path d="M94 1210L1011 0H-125V1193L94 1210Z" fill="white" />
        </svg>
        <div className="row justify-content-center">
          <div className="col-md">
            <div className="container">
            <h1 className="reset-heading">Reset Password</h1>
            <div className="card overflow-hidden">
              <div className="card-body py-0">
                <div className="row">
                  {/* <div className="col-md d-none d-md-flex logo-graphic-wrapper">
                    <img
                      className="login-graphic"
                      src="/images/mark-large.png"
                      alt=""
                    />
                  </div> */}
                  <div className="col py-5 login-btn">
                    {/* <div className="d-flex align-items-center justify-content-center">
                      <img
                        className="login-logo pb-0 mb-4"
                        src="/images/primary-logo.png"
                        alt=""
                      />
                    </div> */}
                    <section className="signup">
                      <div className="row">
                        <div className="col-lg-12 col-xl-12">
                          <div className="text-black">
                            <p className=" h2 fw-bold mx-1 mx-md-4 mt-4 mb-2">
                              Reset Password
                            </p>
                            <p className="mb-5 mx-1 mx-md-4">
                              Enter your email and we’ll send an instructions to
                              reset your password
                            </p>

                            <form className="mx-1 mx-md-4">
                              <div className="mb-5">
                                <div>
                                  <div className="form-outline flex-fill mb-0">
                                    <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                                    <label
                                      className="form-label"
                                      htmlFor="form3Example3c"
                                    >
                                      Email
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Enter Your Email Address"
                                      value={email.toLocaleLowerCase()}
                                      onChange={(e) => {
                                        handleInput(e);
                                      }}
                                      onKeyDown={handleKeyPress}
                                    />
                                  </div>
                                  {validation && (
                                    <p className="regiter-errorMsg">
                                      {validation}
                                    </p>
                                  )}
                                </div>
                              </div>

                              <div className="d-flex justify-content-center mb-2 mb-lg-4">
                                <button
                                  type="button"
                                  className="btn btn-lg signupBtn"
                                  onClick={(e: any) => handleSubmit(e)}
                                  ref={inputRef}
                                >
                                  {isloading ? "Loading..." : "Confirm"}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
            </div>
            
          </div>
        </div>
      </div>
      <ToastContainer
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
    </>
  );
};

export default connect(() => ({}))(Resetpassword);
