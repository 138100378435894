import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function errorMessage(value:any) {
    toast.error(value, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      toastId:"1",
      progress: undefined,
    });
  }
  export function successMessage(value:any) {
    toast.success(value, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      toastId:"1",
      progress: undefined,
    });
    

  }
  
  