import React from 'react'
import Skeleton from 'react-loading-skeleton';
import ProductListing from '../Components/ProductListing';
import NoImageFound from '../Util/NoImageFound';
interface NewProductDetailsProps {
    count: number;
    propertylisting: any;
    handleSorting: (type: string) => void;
    loading: boolean;
    currentPage: number;
    pagescountstartend: { start: number; end: number; };
    prevPage: () => void;
    nextPage: () => void;
    oddata:any
  }
export const NewProductDetails: React.FC<NewProductDetailsProps> = (props) =>{
    const {count,propertylisting,handleSorting,loading,currentPage,prevPage,nextPage,pagescountstartend,oddata}=props;

  return (  
    <>
     {count !== 0 ? (
        <section className="productdetails">
          <div className="container-fluid custom-container">
            {(propertylisting?.length > 0  && !loading)? (
              <div className="d-flex justify-content-between">
                <h4 className="product-title">{oddata} results</h4>
                <div className="sorting">
                  <select name="" id="" className="form-select mr-2">
                <option value="">--------Select--------</option>
                <option value="">Newest listings</option>
                <option value="">Lowest price</option>
                <option value="">Highest price</option>
                <option value="">Year built</option>
                <option value="">Open house date</option>
                <option value="">Recently reduced</option>
                <option value="">Largest sqft</option>
                <option value="">Lot size</option>
              </select>
                  <span className="mr-2">Sort</span>
                  <div onClick={() => handleSorting("ascending")}>
                    <span>
                      <i className="fa-solid fa-arrow-down"></i>
                    </span>
                  </div>
                  <div onClick={() => handleSorting("descending")}>
                    <span>
                      <i className="fa-solid fa-arrow-up"></i>
                    </span>
                  </div>
                </div>
              </div>
            ) : ("")}

            <div className="productlist mt-3">
              <div className="row">
                {loading ? (
                  <>
                    <div className="col-md-12">
                      <div className="d-flex justify-content-between">
                        <h4 className="product-title">
                          <span className="d-flex ">
                            <Skeleton width={100} />
                            <Skeleton width={100} className="ml-2" />
                          </span>
                        </h4>
                        <div className="sorting">
                        <Skeleton width={160} />
                          <span>
                            <Skeleton />
                          </span>
                          <div>
                            <span>
                              <Skeleton />
                            </span>
                          </div>
                          <div>
                            <span>
                              <Skeleton />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {Array.from({ length: 4 }).map((_, index) => (
                      <div className="col-lg-3 col-md-6" key={index}>
                        <Skeleton count={4} height={190} />
                      </div>
                    ))}
                  </>
                ) : propertylisting?.length > 0 ? (
                  propertylisting?.map((v: any) => {
                    return (
                      <div className="col-lg-3 col-md-6">
                        <ProductListing
                          propertylisting={v}
                          currentPage={currentPage}
                        />
                      </div>
                    );
                  })
                ) : (
                  <NoImageFound
                   />
                )}
              </div>
            </div>
            {propertylisting?.length > 0 ? (
              <div className="productpagination">
                <button
                  onClick={prevPage}
                  disabled={currentPage === 1}
                  className="productlistPrev"
                >
                  Previous
                </button>
                <span>
                  Showing {pagescountstartend.start} - {pagescountstartend.end}{" "}
                  {/* of {count} */}
                </span>
                <button
                  onClick={nextPage}
                  // disabled={indexOfLastItem >= count?.length}
                  className="productlistNext"
                >
                  Next
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </section>
      ) : (
        <NoImageFound />
      )}
    </>
  )
}
