import React, { useEffect, useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setsearchbaraddress } from "../../../state/actions";

type Props = {
  filter: any;
  setFilter: any;
  address: any,
  setAddress: any,
  handleSearchFilteredData: any,
};

const AddressForm: React.FC<Props> = (props) => {
  const { filter, setFilter, address, setAddress } = props;

  // const location = useLocation();
  // const fromHome = location.state;
  // let data = fromHome?.data;
  // useEffect(() => {
  //   if(fromHome?.data){
  //     console.log("data::",fromHome)
  //     handleSelect(fromHome?.data)
  //   }
  // }, [fromHome?.data])
  const [currentPage, setCurrentPage] = useState()
  let typesofproperty = {
    city: null,
    country: null,
    zip: null,
    stateOrProvince: null,
    number_of_beds: null,
    number_of_baths: null,
    yearbuilt: null,
    highschool: null,
    elementaryschool: null,
    garageyn: null,
    horseyn: null,
    garagespaces: null,
    subdivisionname: null,
    highschooldistrict: null,
    waterfront: null,
    riverfront: null,
    lakefront: null,
    pool_In_Ground: null,
    pool_Above_Ground: null,
    pool_Access: null,
    horse_Permitted: null,
    out_Building: null,
    golf_Course: null,
    searchdata: null,
    yearbuiltmin: null,
    yearbuiltmax: null,
    garagespacesmin: null,
    garagespacesmax: null,
    propertytype: null,
    propertysubtype: null,
    newconstructionyn: null,
    fireplacemin: null,
    fireplacemax: null,
    squarefeetmin: null,
    squarefeetmax: null,
    pricemin: null,
    pricemax: null,
    lotFeatures: null,
    specialfeature: null,
    userid: null,
    pageSize: 20,
    pageNo: currentPage,
    luxury: null,
    streetNumber: null
  }
  const dispatch = useDispatch()
useEffect(()=>{
let locallsearch = localStorage.getItem('onaddressbar')
if(locallsearch){
  // let searchparse = JSON.parse(locallsearch) 
  setAddress(locallsearch)
}

},[])
window.onload = function() {
    let add = localStorage.getItem('onaddressbar')
    if (add === '') {
      setAddress("")
    }
  }

  const handleSelect = async (selectedAddress: string) => {
localStorage.setItem('searchbaradd','searchbaradd')  
localStorage.setItem('onaddressbar',selectedAddress)  

    setAddress(selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);

      const latLng = await getLatLng(results[0]);

      //   Extract address components
      const addressComponents = results[0].address_components;
      let street = "";
      let city = "";
      let route=""
      let state = "";
      let country = "";
      let county = "";
      let pincode = "";

      addressComponents.forEach((component) => {
  
        if (component.types.includes("street_number")) {
          street = component.long_name;
        } else if (component.types.includes("route")) {
          route = component.long_name;
        } else if (component.types.includes("locality")) {
          city = component.long_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          state = component.short_name;
        } else if (component.types.includes("country")) {
          country = component.long_name;
        } else if (component.types.includes("county")) {
          county = component.long_name;
        } else if (component.types.includes("postal_code")) {
          pincode = component.long_name;
        }
      });


      setFilter({ ...filter, city: city, zip: pincode, country: "US",stateOrProvince:state,streetNumber:street });
      let newf = typesofproperty
      newf.city = city || null
      newf.zip = pincode || null
      newf.country = "US"
      newf.stateOrProvince = state || null
      newf.streetNumber = street || null
      localStorage.setItem('filtervalue',JSON.stringify(newf))
    } catch (error) {
      console.error("Error geocoding address:", error);
    }
  };

  const handleInputr = (v: any) => {
    setAddress(v);
  };

  useEffect(() => {
    (async () => {
      if (address) {
        try {
          const results = await geocodeByAddress(address);
          //   Extract address components
          const addressComponents = results[0].address_components;
          let street = "";
          let city = "";
          let state = "";
          let country = "";
          let pincode = "";

          addressComponents.forEach((component) => {
          
            if (component.types.includes("route")) {
              street = component.long_name;
            } else if (component.types.includes("locality")) {
              city = component.long_name;
            } else if (component.types.includes("administrative_area_level_1")) {
              state = component.long_name;
            } else if (component.types.includes("country")) {
              country = component.long_name;
            } else if (component.types.includes("postal_code")) {
              pincode = component.long_name;
            }
          });
      

          setFilter({ ...filter, city: city, zip: pincode, country: "US" });
          props.handleSearchFilteredData()
          // Handle the extracted address components as needed
        } catch (error) {
          console.error("Error geocoding address:", error);
        }
        // handleSelect(address)
        // props.handleSearchFilteredData()
      }
    })()
  }, [])


  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={(v: any) => handleInputr(v)}
        onSelect={handleSelect}
        searchOptions={{
          componentRestrictions: { country: "us" }, // Restrict to the United States
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: "Search properties by city, zip, neighborhood",
                className: "form-control search-form",
              })}
              type="search"
            />
            <ul
              style={{
                backgroundColor:
                  suggestions.length > 0 ? "#fff" : "transparent",
                padding: "0",
              }}
            >
              {loading && (
                <div
                  style={{
                    backgroundColor: "#fff",
                    padding: "10px 15px",
                  }}
                >
                  Loading...
                </div>
              )}
              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? "#e6e6e6" : "",
                  padding: "10px 15px",
                };

                return (
                  <li
                    // className="respSuggestion"
                    {...getSuggestionItemProps(suggestion, {
                      style,
                    })}
                  >
                    <span className="searchlistdata">
                      {suggestion.description}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default AddressForm;
