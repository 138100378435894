import { useHistory, useLocation } from 'react-router-dom';
import { queryString } from './queryString';

export function useSearchParams() {
    const location = useLocation();
    const history = useHistory();

    // Parse query parameters from the URL
    const queryParams = queryString.parse(location.search);

    // Function to update query parameters
    const updateQueryParams = (newParams: any) => {
        const currentParams = queryString.parse(location.search);
        const updatedParams = { ...currentParams, ...newParams };

        // Update the URL with new query parameters
        history.push({
            pathname: location.pathname,
            search: queryString.stringify(updatedParams),
        });
    };

    return [queryParams, updateQueryParams];
}