import React from 'react'
import ReactDOM from 'react-dom'
import {
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from '@coreui/react'

class ModalPortal extends React.PureComponent
{
  el:HTMLDivElement
  constructor(props:Props)
  {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount()
  {
    document.getElementById('modal-root')?.appendChild(this.el)
  }

  componentWillUnmount()
  {
    document.getElementById('modal-root')?.removeChild(this.el)
  }

  render()
  {
    return ReactDOM.createPortal(this.props.children,this.el)
  }
}

type Props =
{
  show:boolean
  onClose:() => void
  header:React.ReactNode
  body:React.ReactNode
  footer?:React.ReactNode
  className?:any
  size?:'' | 'sm' | 'lg' | 'xl'
  zindex?:number
}
export default class Modal extends React.PureComponent<Props>
{
  render()
  {
    return this.props.show ? (
      <ModalPortal>
        <div className={this.props?.className} style={ { position:'relative',zIndex:2000 + (this.props.zindex || 0) } }>
          <CModal
            size={ this.props.size }
            show={ this.props.show }
            onClose={ () => this.props.onClose()}
            closeOnBackdrop={ false }
          >
            <CModalHeader closeButton><h4 className="mb-0">{ this.props.header }</h4></CModalHeader>
            <CModalBody>{ this.props.body }</CModalBody>
            {
              this.props.footer ?
                <CModalFooter>{ this.props.footer }</CModalFooter>
              :
                null
            }
          </CModal>
        </div>
      </ModalPortal>
    ) : null
  }
}
