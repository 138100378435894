import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { successMessage } from "../Util/Toaster";
import { ContactApi } from "../../../api/idx";
import { Link } from "react-router-dom";
import thankyouimg from "../../../assets/headerpage-icon/ThankYouGif 1.png";
import { CSpinner } from "@coreui/react";
import { currentuser } from "../../../util/currentuser";
import { formatUSPhoneNumber } from "../../../util/stringformat";   
export interface Contactus {
  first_name: string | null;
  last_name: string | null;
  phone: string | null;
  email: string | null;
  message: string | null;
}
const NewContactUs = () => {

  const notify = () => toast("Contact Submitted Succesfully");
  let [showClass, setShow] = useState<boolean>(false);
  let [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [contact, setContact] = useState<Contactus>({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    message: ""
  });
  
  const [validation, setValidation] = useState<Contactus>({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    message: ""
  });
  
  useEffect(() => {
    let user: any = localStorage.getItem("user");
    let user_id = JSON.parse(user);
    if (user_id !== null) {
    
      setContact({...contact, first_name:user_id?.first_name, last_name:user_id?.last_name,email:user_id?.email})
    }  
  }, [])
  const checkValidation = () => {
    let errors = JSON.parse(JSON.stringify(validation)); 
// debugger
    if (contact.first_name === null ) {  
      errors.first_name = "First Name is required";
    } else {
      switch (true) {
        case contact.first_name == "":
          errors.first_name = "First Name is required";
          break;
        case !/^[a-zA-Z]+$/.test(contact.first_name):
          errors.first_name = "First Name can only contain letters";
          break;
        case contact.first_name.length < 2:
          errors.first_name = "First Name must be at least 2 characters";
          break;
        case contact.first_name.length > 25:
          errors.first_name = "First Name cannot exceed 25 characters";
          break;
        default:
          errors.first_name = "";
      }
    }

    // Last Name validation
    if (contact.last_name === null) {
      errors.last_name = "Last Name is required";
    } else {
      switch (true) {
        case !contact.last_name.trim():
          errors.last_name = "Last Name is required";
          break;
        case !/^[a-zA-Z]+$/.test(contact.last_name):
          errors.last_name = "Last Name can only contain letters";
          break;
        case contact.last_name.length < 2:
          errors.last_name = "Last Name must be at least 2 characters";
          break;
        case contact.last_name.length > 25:
          errors.last_name = "Last Name cannot exceed 25 characters";
          break;
        default:
          errors.last_name = "";
      }
    }

    // Email validation
    if (contact.email === null) {
      errors.email = "Email is required";
    } else {
      const emailCond = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      switch (true) {
        case !contact.email.trim():
          errors.email = "Email is required";
          break;
        case !emailCond.test(contact.email):
          errors.email = "Please enter a valid email address";
          break;
        default:
          errors.email = "";
      }
    }
    // phone number validation
    if (contact.phone === null) {
      errors.phone = "Phone number is required";
    } else {
      switch (true) {
        case !contact.phone.trim():
          errors.phone = "Contact Number is required";
          break;
        // case !/^\d+$/.test(contact.phone):
        //   errors.phone = "Contact number can only contain numbers";
        //   break;
        case contact.phone.length !== 14:
          errors.phone = "Contact number must be 10 digits";
          break;
        default:
          errors.phone = "";
      }
    }

    return errors;
  };

  const handleChange =  (e: any) => {
     
    const { name } = e.target;

    setContact({ ...contact, [name]: e.target.value });
 
    setValidation({
      ...validation, [name]: ''
    }) 
    
    // let validMessage =  checkValidation();  
    // const {first_name, last_name, phone, email} = validMessage
    // // const emptyKeys = Object.keys(validMessage).filter(key => validMessage[key] == "");
    //  if(first_name == '') {
    //   validation.first_name = '' 
    //  }
    //  if(last_name == '') {
    //   validation.last_name = '' 
    //  }
    //  if(phone == '') {
    //   validation.phone = '' 
    //  }
    //  if(email == '') {
    //   validation.email = '' 
    //  } 
    } 

  const handleSubmit = async () => { 
    let validations = await checkValidation();
    if (Object.values(validations).every((value) => value == "")) {
      setSpinLoading(true)
      let res = await ContactApi(contact);
      setShow(true);
      notify();
      setSpinLoading(false)
  
      
      switch (res) {
        case "Your  Contact successfully":

          successMessage("Your request has been submitted succesfully");
          setValidation(() => ({
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            message: ""
          }));
          setContact({
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            message: ""
          });
           
          case "Data save successfully":
            setValidation(() => ({
              first_name: "",
              last_name: "",
              phone: "",
              email: "",
              message: ""
            }));
            setContact({
              first_name: "",
              last_name: "",
              phone: "",
              email: "",
              message: ""
            });
      }

    } else {
      
      setValidation((prevState) => ({
        ...prevState,
        first_name: validations.first_name,
        last_name: validations.last_name,
        email: validations.email,
        phone: validations.phone,
        message: validations.message
      }));
    }
  };

  return (
    <>
      <div className="container" id="contactus">
        <div className="form-heading text-center">
          <h3 className="contact-us-heading mb-0">Contact us</h3>
          <p className="contact-us-text mb-4">
            For general questions and inquiries, fill out the form below.
          </p>
        </div>
        <form>
          <div className="row">
            <div className="col-lg-6 col-md-6 mb-4">
              <input
                type="text"
                className="w-100 for"
                placeholder="First Name"
                name="first_name"
                onChange={(e: any) => {
                  handleChange(e);
                }}
                value={contact.first_name ? contact.first_name : ""}
              />
              <Validation state={validation.first_name} />
            </div>
            <div className="col-lg-6 col-md-6 mb-4">
              <input
                type="text"
                className="w-100 for"
                placeholder="Last Name"
                name="last_name"
                onChange={(e) => handleChange(e)}
                value={contact.last_name ? contact.last_name : ""}
              />
              <Validation state={validation.last_name} />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 mb-4">
              <input
                type="text"
                className="w-100 for"
                placeholder="Phone"
                name="phone"
                onChange={(e) => handleChange(e)}
                minLength={10}  maxLength={14}
                value={contact?.phone ? formatUSPhoneNumber(contact?.phone) : ""




                  
                }
              />
              <Validation state={validation.phone} />
            </div>
            <div className="col-lg-6 col-md-6 mb-4">
              <input
                type="email"
                className="w-100 for"
                placeholder="Email"
                name="email"
                onChange={(e) => handleChange(e)}
                value={contact.email ? contact.email : ""}
              />
              <Validation state={validation.email} />
            </div>
          </div>
          <textarea
            className="w-100 text-area mb-5 mt-3 comment-form"
            rows={5}
            name="message"
            defaultValue={""}
            onChange={(e) => handleChange(e)}
            value={contact.message ? contact.message : ""}
          />
          <Validation state={validation.message} />
          <div className="text-center mb-5">
            <Link to="#" className="conatct-btn" type="submit" onClick={() => handleSubmit()}>
              {/* {" "} */}
              { spinLoading ? <button className="cont-us" disabled>Submit Inquiry<CSpinner color="light" size="sm" className={ 'ml-1' } />  </button> :  <button className="cont-us">Submit Inquiry</button> }
            </Link>
          </div>
        </form>
      </div>

      {/* pop up section */}
      <div
        className={
          showClass === true
            ? "custom-modal-wrapper active-modal"
            : "custom-modal-wrapper"
        }
        onClick={() => setShow(false)}
      >
        <div className="custom-modal-box p-3" style={{ background: "#F4F1EE" }}>
          <div className="pop-up-body text-center">
            <img src={thankyouimg} alt="" />
            <p className="mb-3">
              We appreciate you <br /> reaching out.
            </p>
            <small className="fw-bold">
              A team member will be in touch within 1-hour.
            </small>
          </div>
        </div>
      </div>
      {/* pop up section */}
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default connect(() => ({}))(NewContactUs);

export const Validation = ({ state }: { state: any }) => {
  return state && <p style={{ color: "red" }}>{state}</p>;
};
