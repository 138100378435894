import React from "react";
import footer_logo from "../../../assets/headerpage-icon/footer-logo.png";
import { Link } from "react-router-dom";
export const NewFooter = () => {
  return (
    <div className="footer-bg d-flex justify-content-center align-items-center">
      <div className="container-fluid footer-cont text-center">
        <div className="row">
          <div className="col footer-left d-flex justify-content-center align-items-start flex-column">
            <h2>
              Join <span>The Roar</span> Newsletter
            </h2>
            <div className="footer-email">
              {/* <input type="text" placeholder="Email" />{" "} */}
              <a  target="_blank" href="https://milfordmagazine.com/newsletter-subscription/">Join now</a>
            </div>
          </div>

          <div className="col footer-logo">
            <img src={footer_logo} alt="" />
          </div>
    
          <div
            className="col social-link-section"
            style={{ marginTop: "150px" }}
          >
            <div className="row social-links ">
              <div className="col text-left ps-0">
                <h4 className="footer-h">Follow us</h4>

                <p>
                  <a
                    href="https://www.instagram.com/milfordrealestate"
                    target="_blank"
                  >
                    Instagram
                  </a>
                </p>
                <p>
                  <a href="https://www.facebook.com/milfordre" target="_blank">
                    Facebook
                  </a>
                </p>
                <p>
                  <a href="https://x.com/askmilford" target="_blank">
                    X
                  </a>
                </p>
                <p>
                  <a
                    href="https://www.linkedin.com/company/milford-real-estate/?viewAsMember=true"
                    target="_blank"
                  >
                    Linkedin
                  </a>
                </p>
              </div>
              <div className="col">
                <h4 className="footer-h">Info</h4>
                <p>
                  <Link to="privacypolicy">Privacy Policy</Link>
                </p>
                <p>
                  <Link to="##">Terms of Use</Link>
                </p>
                <p>
                  <Link to="##">Cookie Policy</Link>
                </p>
              </div>
              <div className="col">
                <h4 className="footer-h">Learn</h4>
                <p>
                  <Link to="##">About Us</Link>
                </p>
                <p>
                  <Link to="##">FAQs</Link>
                </p>
                <p>
                  <Link to="/contactus">Contact Us</Link>
                </p>
              </div>
            </div>
            <div className="links text-left ps-0">
              <h2>MILFORD REAL ESTATE</h2>
              <p>
                <a href="https://www.google.com/maps/place/1822+N+169th+Plaza,+Omaha,+NE+68118/@41.2766282,-96.1819503,17z/data=!3m1!4b1!4m6!3m5!1s0x8793ee274b64bc8b:0xa85261b4664288ff!8m2!3d41.2766242!4d-96.1793754!16s%2Fg%2F11k3v76r0p?entry=ttu"  target="_blank">
                  1822 N 169th Plaza, Omaha, Nebraska 68118{" "}
                </a>
              </p>
              <p>
                <a href="tel:402-880-0202">402-880-0202</a>
              </p>
              <p>
                <a href="mailto:magazine@milford.com"   target="_blank">concierge@milford.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
