import React, { useEffect, useState } from "react";
import { currentuser } from "../../../util/currentuser";
import { errorMessage, successMessage } from "./Toaster";
import { handleSaveSearchdata, propertyListing } from "../../../api/idx";
import MultiRangeSlider from "../Components/MultiRangeSlider";
import Dropdown from "../../../components/form/input/Dropdown";
import Modal from "../../../components/Modal";
import BeachSun from "../../../assets/icons/Beach Sun Birds.svg";
import SwimmingPool from "../../../assets/icons/Swimming Pool Stairs.svg";
import AnimalHorse from "../../../assets/icons/Animal Horse.svg";
import GolfHole from "../../../assets/icons/Golf Hole Ball.svg";
import Housefilter from "../../../assets/icons/Housefilter.svg";
import BuildingHouse from "../../../assets/icons/HouseBuilding.svg";
import House from "../../../assets/icons/House.svg";
import Townhouse from "../../../assets/icons/House1.svg";
import condo from "../../../assets/icons/House2.svg";
import land from "../../../assets/icons/House4.svg";
import farm from "../../../assets/icons/farm-icon.svg";
import multifamily from "../../../assets/icons/House3.svg";
import luxury from "../../../assets/icons/luxury-icon.svg";
import HouseFilter from "../../../assets/icons/House Filter.svg";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Filters } from "../ProductDetails";
import { cibDebian } from "@coreui/icons-pro";
import { useDispatch, useSelector } from "react-redux";
import { CListGroup } from "@coreui/react";

type Props = {
  handlefilterpopup: any;
  sethandleFilterPopup: any;
  PropertyType: any;
  setPropertyType: any;
  filter: any;
  setFilter: any;
  savesearch: any;
  setsaveSearch: any;
  toggle: any;
  setToggle: any;
  filterlist: any;
  currrentfilter: any;
  setCurrentFilter: any;
  showSuggestions: any;
  setShowSuggestions: any;
  suggestions: any;
  setSuggestions: any;
  propertyTypeVal?: any;
};

const AllFilters: React.FC<Props> = (props) => {
  const {
    handlefilterpopup,
    sethandleFilterPopup,
    PropertyType,
    setPropertyType,
    filter,
    setFilter,
    savesearch,
    setsaveSearch,
    toggle,
    setToggle,
    filterlist,
    currrentfilter,
    setCurrentFilter,
    showSuggestions,
    setShowSuggestions,
    suggestions,
    setSuggestions,
    propertyTypeVal,
  } = props;

  const [savesearchtoggle, setsaveSearchToggle] = useState<boolean>(false);

  const [topFilter, setTopFilter] = useState<Filters>({
    city: null,
    country: null,
    zip: null,
    stateOrProvince: null,
    number_of_beds: null,
    number_of_baths: null,
    yearbuilt: null,
    highschool: null,
    elementaryschool: null,
    garageyn: null,
    horseyn: null,
    garagespaces: null,
    subdivisionname: null,
    highschooldistrict: null,
    waterfrontfeatures: [],
    waterfront: null,
    riverfront: null,
    lakefront: null,
    pool_In_Ground: null,
    pool_Above_Ground: null,
    pool_Access: null,
    horse_Permitted: null,
    out_Building: null,
    golf_Course: null,
    searchdata: null,
    lotFeatures: null,
    yearbuiltmin: null,
    yearbuiltmax: null,
    garagespacesmin: null,
    garagespacesmax: null,
    propertytype: null,
    propertysubtype: null,
    newconstructionyn: null,
    fireplacemin: null,
    fireplacemax: null,
    squarefeetmin: null,
    squarefeetmax: null,
    pricemin: null,
    pricemax: null,
    userid: null,
    pageSize: 20,
    pageNo: 1,
    luxury: null,
    streetNumber: null,
    lotrange:null,
  });

  const defaultfilter = {
    city: null,
    country: null,
    zip: null,
    stateOrProvince: null,
    number_of_beds: null,
    number_of_baths: null,
    yearbuilt: null,
    highschool: null,
    elementaryschool: null,
    garageyn: null,
    horseyn: null,
    garagespaces: null,
    subdivisionname: null,
    highschooldistrict: null,
    waterfrontfeatures: [],
    waterfront: null,
    riverfront: null,
    lakefront: null,
    pool_In_Ground: null,
    pool_Above_Ground: null,
    pool_Access: null,
    horse_Permitted: null,
    out_Building: null,
    golf_Course: null,
    searchdata: null,
    lotFeatures: null,
    yearbuiltmin: null,
    yearbuiltmax: null,
    garagespacesmin: null,
    garagespacesmax: null,
    propertytype: null,
    propertysubtype: null,
    newconstructionyn: null,
    fireplacemin: null,
    fireplacemax: null,
    squarefeetmin: null,
    squarefeetmax: null,
    pricemin: null,
    pricemax: null,
    userid: null,
    pageSize: 20,
    pageNo: 1,
    luxury: null,
    streetNumber: null,
    lotrange:null,
  };



  // const handleSpecialFeatures = (value: string, state: boolean) => {
  //   let checked: any;
  //   if (state === false) {
  //     checked = null;
  //   } else {
  //     checked = state;
  //   }
  //   setFilter((prevFilter: any) => {
  //     const updatedFilter = { ...prevFilter, [value]: checked };
  //     return updatedFilter;
  //   });
  // };

  // const handleSaveSearch = () => {
  //   let current_user = currentuser();
  //   if (current_user === null) {
  //     errorMessage("Please login");
  //   } else {
  //     setsaveSearchToggle(true);
  //     setsaveSearch({
  //       ...savesearch,
  //       sid: null,
  //       userid: current_user?.id,
  //       country: filter.country,
  //       zip: filter.zip,
  //       number_of_beds: filter.number_of_beds,
  //       number_of_baths: filter.number_of_baths,
  //       highschool: filter.highschool,
  //       elementaryschool: filter.elementaryschool,
  //       subdivisionname: filter.subdivisionname,
  //       waterfrontfeatures: filter.waterfrontfeatures,
  //       highschooldistrict: filter.highschooldistrict,
  //       propertytype: filter.propertytype,
  //       propertysubtype: filter.propertysubtype,
  //       newconstructionyn: filter.newconstructionyn,
  //       offmarketdate: null,
  //       onmarketdate: null,
  //       city: filter.city,
  //       yearbuiltmin: filter.yearbuiltmin,
  //       yearbuiltmax: filter.yearbuiltmax,
  //       pricemin: filter.pricemin,
  //       pricemax: filter.pricemax,
  //       squarefeetmin: filter.squarefeetmin,
  //       squarefeetmax: filter.squarefeetmax,
  //       fireplacemin: filter.fireplacemin,
  //       fireplacemax: filter.fireplacemax,
  //       garagespacesmin: filter.garagespacesmin,
  //       garagespacesmax: filter.garagespacesmax,
  //     });
  //   }
  // };

  // const handleSaveSearchName = (e: any) => {
  //   let value = e.target.value;
  //   setsaveSearch({ ...savesearch, filter_name: value });
  // };

  // const handleConfirmokButton = async (e: any) => {
  //   e.preventDefault();
  //   if (savesearch.filter_name !== null) {
  //     let response = await handleSaveSearchdata(savesearch);
  //     switch (response) {
  //       case "filter Saved successfully":
  //         setsaveSearchToggle(false);
  //         successMessage("Filter has been saved successfully.");
  //         break;
  //       case "filter name already exist.":
  //         setsaveSearchToggle(false);
  //         successMessage(
  //           "Filter name already exists. Please choose a different name."
  //         );
  //         break;
  //     }
  //   } else {
  //     errorMessage("Please enter Filter name.");
  //   }
  // };

  // const handleSave = (e: any) => {
  //   e.preventDefault();
  //   sethandleFilterPopup(false);
  //   setToggle(!toggle);
  // };

  const handleDropdownData = (e: any, type: string, data: string) => {
    if (type === "minimum") {
      setFilter({ ...topFilter, [data]: e });
    }

    if (type === "maximum") {
      let errormessage = "Please select a value greater than the minimum.";
      switch (data) {
        case "yearbuiltmax":
          if (e < (topFilter?.yearbuiltmin ?? "")) {
            errorMessage(errormessage);
          } else {
            setFilter({ ...topFilter, [data]: e });
          }
          break;

        case "garagespacesmax":
          if (e < (topFilter?.garagespacesmin ?? "")) {
            errorMessage(errormessage);
          } else {
            setFilter({ ...topFilter, [data]: e });
          }
          break;

        case "fireplacemax":
          if (e < (topFilter?.fireplacemin ?? "")) {
            errorMessage(errormessage);
          } else {
            setFilter({ ...topFilter, [data]: e });
          }
          break;

        case "pricemax":
          if (e < (topFilter?.pricemin ?? "")) {
            errorMessage(errormessage);
          } else {
            setFilter({ ...topFilter, [data]: e });
          }
          break;

        case "squarefeetmax":
          if (e < (topFilter?.squarefeetmin ?? "")) {
            errorMessage(errormessage);
          } else {
            setFilter({ ...topFilter, [data]: e });
          }
          break;

        default:
          break;
      }
    }
  };
  const [lowerfilters, setlowerFIlter] = useState({
    setNoofBed: null,
    priceMin: null,
    priceMax: null,
    setNoofBath:null,
    squareFeetMin:null,
    squareFeetMax:null,
    garagespaceMin:null,
    garagespaceMax:null,
    yearbuiltMin:null,
    yearbuiltMax:null,
    fireplaceMin:null,
    fireplaceMax:null,
    lotRange:null,
    highSchool:null,
    highschoolDistrict:null,
    subdivisionName:null,
    zipCode:null,
    propertySubtype:null,
    cityName:null,
    countryName:null,
    newconstructionynValue:null,
    waterFront:null,
    riverFront:null,
    poolGround: null,
    poolAboveGround: null,
    poolAccess: null,
    lakeFront: null,
    horsePermitted: null,
    outBuilding: null,
    golfCourse: null,
    lotFeatures:null,
  });
  const handleFilterChange = (type: string, selectedValue: { label: string; value: number;state: boolean}) => {

    switch (type) {
      case 'etNoofBed':
        setlowerFIlter({...lowerfilters, setNoofBed: selectedValue });
        setTopFilter({...topFilter, number_of_beds: selectedValue.value });
        break;
      case 'etNoofBath':
        setlowerFIlter({...lowerfilters, setNoofBath: selectedValue });
        setTopFilter({...topFilter, number_of_baths: selectedValue.value });
        break;
      case 'priceMin':
      
        setlowerFIlter({...lowerfilters, priceMin: selectedValue});
        setTopFilter({...topFilter, pricemin: selectedValue.value });
        break;
      case 'priceMax':
        setlowerFIlter({...lowerfilters, priceMax: selectedValue });
        setTopFilter({...topFilter, pricemax: selectedValue.value });
        break;
      case 'squareFeetMin':
        setlowerFIlter({...lowerfilters, squareFeetMin: selectedValue});
        setTopFilter({...topFilter, squarefeetmin: selectedValue.value });
        break;
      case 'squareFeetMax':
        setlowerFIlter({...lowerfilters, squareFeetMax: selectedValue });
        setTopFilter({...topFilter, squarefeetmax: selectedValue.value });
        break;
      case 'garagespaceMin':
        setlowerFIlter({...lowerfilters, garagespaceMin: selectedValue});
        setTopFilter({...topFilter, garagespacesmin: selectedValue.value });
        break;
      case 'garagespaceMax':
        setlowerFIlter({...lowerfilters, garagespaceMax: selectedValue });
        setTopFilter({...topFilter, garagespacesmax: selectedValue.value });
        break;
      case 'yearbuiltMin':
        setlowerFIlter({...lowerfilters, yearbuiltMin: selectedValue });
        setTopFilter({...topFilter, yearbuiltmin: selectedValue.value });
        break;
      case 'yearbuiltMax':
        setlowerFIlter({...lowerfilters, yearbuiltMax: selectedValue});
        setTopFilter({...topFilter, yearbuiltmax: selectedValue.value });
        break;
      case 'fireplaceMin':
        setlowerFIlter({...lowerfilters, fireplaceMin: selectedValue});
        setTopFilter({...topFilter, fireplacemin: selectedValue.value });
        break;
      case 'fireplaceMax':
        setlowerFIlter({...lowerfilters, fireplaceMax: selectedValue });
        setTopFilter({...topFilter, fireplacemax: selectedValue.value });
        break;
      case 'lotRange':
        setlowerFIlter({...lowerfilters, lotRange: selectedValue }); // @ts-ignore
        setTopFilter({...topFilter, lotrange: selectedValue.label.toString() });
        break;
      case 'highSchool':
        setlowerFIlter({...lowerfilters, highSchool: selectedValue });
        setTopFilter({...topFilter, highschool: selectedValue.label.toString() });
        break;
      case 'highSchoolDistrict':
        setlowerFIlter({...lowerfilters, highschoolDistrict:selectedValue });
        setTopFilter({...topFilter, highschooldistrict:selectedValue.label.toString() });
        break;
      case 'subdivisionName':
        setlowerFIlter({...lowerfilters, subdivisionName: selectedValue});
        setTopFilter({...topFilter, subdivisionname: selectedValue.label.toString() });
        break;
        case 'propertySubtype':
          setlowerFIlter({...lowerfilters, propertySubtype: selectedValue});
          setTopFilter({...topFilter, propertysubtype: selectedValue.label.toString() });
          break;
          case 'zipCode':
            setlowerFIlter({...lowerfilters, zipCode: selectedValue});
            setTopFilter({...topFilter, zip: selectedValue.label.toString() });
            break;
            case 'cityName':
              setlowerFIlter({...lowerfilters, cityName: selectedValue });
              setTopFilter({...topFilter, city: selectedValue.label.toString() });
              break;
              case 'countryName':
                setlowerFIlter({...lowerfilters, countryName: selectedValue });
                setTopFilter({...topFilter, country: selectedValue.label.toString() });
                break;
                case 'newconstructionynValue':
                setlowerFIlter({...lowerfilters, countryName: selectedValue });
                setTopFilter({...topFilter, country: selectedValue.label.toString() });
                break;
                case 'waterFront':
                  setlowerFIlter({...lowerfilters, waterFront: selectedValue});
                  setTopFilter({...topFilter, waterfront: selectedValue.state });
                  break;
                  case 'riverFront':
                  setlowerFIlter({...lowerfilters, riverFront: selectedValue });
                  setTopFilter({...topFilter, riverfront: selectedValue.state });
                  break;
                  case 'poolGround':
                  setlowerFIlter({...lowerfilters, poolGround: selectedValue });
                  setTopFilter({...topFilter, pool_In_Ground: selectedValue.state });
                  break;
                  case 'poolAboveGround':
                  setlowerFIlter({...lowerfilters, poolAboveGround: selectedValue });
                  setTopFilter({...topFilter, pool_Above_Ground: selectedValue.state });
                  break;
                  case 'poolAccess':
                  setlowerFIlter({...lowerfilters, poolAccess: selectedValue });
                  setTopFilter({...topFilter, pool_Access: selectedValue.state });
                  break;
                  case 'lakeFront':
                    setlowerFIlter({...lowerfilters, lakeFront: selectedValue });
                    setTopFilter({...topFilter, lakefront: selectedValue.state });
                    break;
                    case 'horsePermitted':
                      setlowerFIlter({...lowerfilters, horsePermitted: selectedValue});
                      setTopFilter({...topFilter, horse_Permitted: selectedValue.state });
                      break;
                      case 'outBuilding':
                        setlowerFIlter({...lowerfilters, outBuilding: selectedValue });
                        setTopFilter({...topFilter, out_Building: selectedValue.state });
                        break;
                        case 'golfCourse':
                          setlowerFIlter({...lowerfilters, golfCourse: selectedValue });
                          setTopFilter({...topFilter, golf_Course: selectedValue.state });
                          break;
                          case 'lotFeatures':
                          setlowerFIlter({...lowerfilters, lotFeatures: selectedValue });
                          setTopFilter({...topFilter, lotFeatures: selectedValue.label });
                          break;
      default:
        console.error(`Unknown filter type: ${type}`);
    }
  };

  // const handleClearall = () => {
  //   setFilter({
  //     city: null,
  //     country: null,
  //     zip: null,
  //     number_of_beds: null,
  //     number_of_baths: null,
  //     yearbuilt: null,
  //     highschool: null,
  //     elementaryschool: null,
  //     garageyn: null,
  //     horseyn: null,
  //     garagespaces: null,
  //     subdivisionname: null,
  //     highschooldistrict: null,
  //     waterfrontfeatures: [],
  //     searchdata: null,
  //     yearbuiltmin: null,
  //     yearbuiltmax: null,
  //     garagespacesmin: null,
  //     garagespacesmax: null,
  //     propertytype: null,
  //     propertysubtype: null,
  //     newconstructionyn: null,
  //     fireplacemin: null,
  //     fireplacemax: null,
  //     userid: null,
  //   });
  //   setsaveSearch({ ...savesearch, filter_name: null });
  //   setPropertyType(null);
  //   setToggle(!toggle);
  // };
  const handleClearAll = () => {
  setTopFilter({
    city: null,
    country: null,
    zip: null,
    stateOrProvince: null,
    number_of_beds: null,
    number_of_baths: null,
    yearbuilt: null,
    highschool: null,
    elementaryschool: null,
    garageyn: null,
    horseyn: null,
    garagespaces: null,
    subdivisionname: null,
    highschooldistrict: null,
    waterfrontfeatures: [],
    waterfront: null,
    riverfront: null,
    lakefront: null,
    pool_In_Ground: null,
    pool_Above_Ground: null,
    pool_Access: null,
    horse_Permitted: null,
    out_Building: null,
    golf_Course: null,
    searchdata: null,
    lotFeatures: null,
    yearbuiltmin: null,
    yearbuiltmax: null,
    garagespacesmin: null,
    garagespacesmax: null,
    propertytype: null,
    propertysubtype: null,
    newconstructionyn: null,
    fireplacemin: null,
    fireplacemax: null,
    squarefeetmin: null,
    squarefeetmax: null,
    pricemin: null,
    pricemax: null,
    userid: null,
    pageSize: 20,
    pageNo: 1,
    luxury: null,
    streetNumber: null,
    lotrange:null,
  })

  // localStorage.setItem('filtervalue',defaultfilter)
   
  dispatch({
    type: 'SET',
    payload: defaultfilter,
  });
  };
  

  const dispatch = useDispatch()
 // @ts-ignore
  const val = useSelector((state)=>state?.new?.signset)

 const handleSubmit =async ( )=>{
  try {
    
      // let listing: any = await propertyListing(topFilter);
    

     
        dispatch({
          type: 'SET',
          payload: topFilter,
        });
      // const res = val()
         // localStorage.setItem('filtervalue',JSON.stringify())
  } catch (error) {
    console.log(error)
  }
 }

  // const handleFilterListInput = (e: any, type: string) => {
  //   let value = e.target.value;
  //   setFilter({ ...filter, [type]: value });
  //   setCurrentFilter({ ...currrentfilter, type: type, value: value });
  //   setShowSuggestions({ ...showSuggestions, [type]: value !== "" });
  // };

  // const handleListData = (e: any, data: string, type: string) => {
  //   e.preventDefault();
  //   setFilter({ ...filter, [type]: data });
  //   setShowSuggestions({ ...showSuggestions, [type]: false });
  // };
  // const [selectedOption, setselectedOption] = useState({
  //   lotRange: "",
  // });

  // const handleChange = async (selectedOption) => {
  //   setselectedOption(selectedOption?.lotRange);
  // };

  // const lotrangeoption = filterlist?.lotrange?.map((e: any) => ({
  //   value: e,
  //   label: e,
  // }));
  const options = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  const handlecontructdata = (selectedOption) => {
    const newValue = selectedOption.value === "Yes";
    setFilter({ ...filter, newconstructionyn: newValue });
  };
  const [activeFilter, setActiveFilter] = useState(null);
   
  const [active, setActive] = useState("Any")
  const handlefilters = (type: string) => {
    // debugger
    if (type == "Any") {
      setActive("Any");
    }
    if (type == "House") {
      setTopFilter({ ...defaultfilter, propertytype: "Residential" });
      setActive("House");
    }
    if (type === "Townhouse") {
      setTopFilter({ ...defaultfilter, propertysubtype: "Townhouse" });
      setActive("Townhouse");
    }
    if (type === "condo") {
      setTopFilter({ ...defaultfilter, propertysubtype: "Condominium" });
      setActive("Condominium");
    }
    if (type === "Villa") {
      setTopFilter({ ...defaultfilter, propertysubtype: "Villa/Patio Home" });
      setActive("Villa");
    }
    if (type === "Luxury") {
      setTopFilter({ ...defaultfilter, luxury: true });
      setActive("Luxury");
    }
    if (type === "multifamily") {
      setTopFilter({ ...defaultfilter, propertysubtype: "Multi Family" });
      setActive("multifamily");
    }
    if (type === "Land") {
      setTopFilter({ ...defaultfilter, propertytype: "Land" });
      setActive("Land");
    }
    if (type === "Farms") {
      setTopFilter({ ...defaultfilter, propertytype: "Farm" });
      setActive("Farms");
    } else {
      return;
    }
    setActiveFilter(type);
  };

  return (
    <div
      className={handlefilterpopup ? "modal fade show" : "modal fade"}
      id="idx-filter-modal"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="exampleModalLabel">
              Filters
            </h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => sethandleFilterPopup(false)}
            ></button>
          </div>
          <div className="modal-body">
            <form className="filter-modal-form">
              <div className="row">
                <div className="col-md-12">
                  <h6 className="mb-4 mt-4">Property Type</h6>
                  <div className="display-flex grid-cusom mb-3">
                    <span
                      className={
                        active === "Any" ? "rectangle active" : "rectangle"
                      }
                      onClick={() => {
                        
                        handlefilters("Any");
                      }}
                    >
                      <div>
                        <span>
                          <img
                            src={BuildingHouse}
                            className="custome-color buildingsvg"
                          />
                        </span>
                        <span>Any</span>
                      </div>
                    </span>
                    <span
                      className={
                        active === "House" ? "rectangle active" : "rectangle"
                         
                      }
                      onClick={() => {
                        handlefilters("House");
                      }}
                    >
                      <div>
                        <span>
                          <img src={House} className="custome-color" />
                        </span>
                        <span>House</span>
                      </div>
                    </span>
                    <span
                      className={
                        active === "Townhouse" ? "rectangle active" : "rectangle"
                      }
                      onClick={() => {
                        handlefilters("Townhouse");
                      }}
                    >
                      <div>
                        <span>
                          <img src={Townhouse} className="custome-color" />
                        </span>
                        <span>Townhouse</span>
                      </div>
                    </span>
                    <span
                      className={
                        active === "condo" ? "rectangle active" : "rectangle"
                      }
                      onClick={() => {
                        handlefilters("condo");
                      }}
                    >
                      <div>
                        <span>
                          <img src={condo} className="custome-color" />
                        </span>
                        <span>Condo</span>
                      </div>
                    </span>
                    <span
                      className={
                        active === "multifamily" ? "rectangle active" : "rectangle"
                      }
                      onClick={() => {
                        handlefilters("multifamily");
                      }}
                    >
                      <div>
                        <span>
                          <img src={multifamily} className="custome-color" />
                        </span>
                        <span>Multi-family</span>
                      </div>
                    </span>
                    <span
                      className={
                        active === "Luxury" ? "rectangle active" : "rectangle"
                      }
                      onClick={() => {
                        handlefilters("Luxury");
                      }}
                    >
                      <div>
                        <span>
                          <img src={luxury} className="custome-color" />
                        </span>
                        <span>Luxury</span>
                      </div>
                    </span>
                    <span
                      className={
                        active === "Villa" ? "rectangle active" : "rectangle"
                      }
                      onClick={() => {
                        handlefilters("Villa");
                      }}
                    >
                      <div>
                        <span>
                          <img src={luxury} className="custome-color" />
                        </span>
                        <span>Villa</span>
                      </div>
                    </span>
                    <span
                      className={
                        active === "Farms" ? "rectangle active" : "rectangle"
                      }
                      onClick={() => {
                        handlefilters("Farms");
                      }}
                    >
                      <div>
                        <span>
                          <img src={farm} className="custome-color" />
                        </span>
                        <span>Farms</span>
                      </div>
                    </span>
                    <span
                      className={
                        active === "Land" ? "rectangle active" : "rectangle"
                      }
                      onClick={() => {
                        handlefilters("Land");
                      }}
                    >
                      <div>
                        <span>
                          <img src={land} className="custome-color" />
                        </span>
                        <span>Land</span>
                      </div>
                    </span>
                  </div>
                  <div className="display-flex"></div>
                </div>
                <div className="col-md-12">
                  <div className="slider-range">
                    <div className="mainrangebg mt-5">
                      <h6>Price Range</h6>
                      <div className="col-md-12">
                        <div className="price-range-main">
                          {/* <VictoryChart domainPadding={75} >
                              <VictoryBar style={{ data: { fill: "#ebe2ff" } }} data={filterlist.price} />
                            </VictoryChart> */}
                          {/* <MultiRangeSlider
                          min={filter.pricemin}
                          max={filter.pricemax}
                          zIndex={1}
                        /> */}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 range-drop">
                          <Select className="range-select"
         value={
                         lowerfilters?.priceMin
                          }
                          onChange={(selectedValue: number) => {// @ts-ignore
                            handleFilterChange("priceMin", selectedValue);
                          }}
                           placeholder="Min Price"
                          options={filterlist.price?.map((v: string) => ({
                            label: v,
                            value: parseInt(v),
                          }))}         
      /> 
                        </div>
                        <div className="col-md-6 range-drop">
                        <Select className="range-select"
         value={
          lowerfilters?.priceMax
                          }
                          onChange={(selectedValue: number) => {// @ts-ignore
                            handleFilterChange("priceMax", selectedValue);
                          }}
                           placeholder="Max Price"
                          options={filterlist.price?.map((v: string) => ({
                            label: v,
                            value: parseInt(v),
                          }))}         
      /> 
                          {/* <Dropdown
                            options={filterlist?.price?.map((v: string) =>
                              parseInt(v)
                            )}
                            projection={(v: any) => v}
                            placeholder="Max Price"
                            value={filter?.pricemax ? filter?.pricemax : ""}
                            onChange={(e: any) =>
                              handleDropdownData(e, "maximum", "pricemax")
                            }
                          /> */}
                        </div>
                      </div>

                      {/* <div
                          className="slider"
                          style={{
                            left: `${
                              ((filter.pricemin - 18000) / 969000) * 100
                            }%`,
                            width: `${
                              ((filter.pricemax - filter.pricemin - 25000) / 969000) *
                              100
                            }%`,
                          }}
                        ></div> */}
                    </div>
                    {/* <div className="price-range">
                    <div>Min: {filter.pricemin}</div>
                    <div>Max: {filter.pricemax}</div>
                  </div> */}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="bedrooms mt-4">
                    <h6 className="mb-3">Bedrooms</h6>
                    <div className="bedrooms-btn">
                      <span
                        className={
                          topFilter.number_of_beds === null
                            ? "btn btn-circle active"
                            : "btn btn-circle"
                        }
                        onClick={() => {
                          handleFilterChange("etNoofBed", { label: "any", value: null,state:null});
                        }}
                      >
                        Any
                      </span>
                      <span
                        className={
                          topFilter.number_of_beds === 1
                            ? "btn btn-circle active"
                            : "btn btn-circle"
                        }
                        onClick={() => {
                          handleFilterChange("etNoofBed", { label: "1", value: 1,state:null });
                        }}
                      >
                        1
                      </span>
                      <span
                      className={
                        topFilter.number_of_beds === 2
                          ? "btn btn-circle active"
                          : "btn btn-circle"
                      }
                      onClick={() => {
                        handleFilterChange("etNoofBed", { label: "2", value: 2 ,state:null});
                      }}
                    >
                      2
                    </span>
                    <span
                      className={
                        topFilter.number_of_beds === 3
                          ? "btn btn-circle active"
                          : "btn btn-circle"
                      }
                      onClick={() => {
                        handleFilterChange("etNoofBed", { label: "3", value: 3,state:null });
                      }}
                    >
                      3
                    </span>
                    <span
                      className={
                        topFilter.number_of_beds === 4
                          ? "btn btn-circle active"
                          : "btn btn-circle"
                      }
                      onClick={() => {
                        handleFilterChange("etNoofBed", { label: "4", value: 4 ,state:null});
                      }}
                    >
                      4
                    </span>
                    <span
                      className={
                        topFilter.number_of_beds === 5
                          ? "btn btn-circle active"
                          : "btn btn-circle"
                      }
                      onClick={() => {
                        handleFilterChange("etNoofBed", { label: "5", value: 5 ,state:null});
                      }}
                    >
                      5
                    </span>
                    <span
                      className={
                        topFilter.number_of_beds === 6
                          ? "btn btn-circle active"
                          : "btn btn-circle"
                      }
                      onClick={() => {
                        handleFilterChange("etNoofBed", { label: "6", value: 6,state:null });
                      }}
                    >
                      6
                    </span>
                    <span
                      className={
                        topFilter.number_of_beds === 7
                          ? "btn btn-circle active"
                          : "btn btn-circle"
                      }
                      onClick={() => {
                        handleFilterChange("etNoofBed", { label: "7", value: 7 ,state:null});
                      }}
                    >
                      7
                    </span>
                    <span
                      className={
                        topFilter.number_of_beds === 8
                          ? "btn btn-circle active"
                          : "btn btn-circle"
                      }
                      onClick={() => {
                        handleFilterChange("etNoofBed", { label: "8", value: 8 ,state:null});
                      }}
                    >
                      8+
                    </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                <div className="bedrooms mt-5">
                  <h6 className="mb-3">Bathrooms</h6>
                  <div className="bedrooms-btn">
                    <span
                      className={
                        topFilter.number_of_baths === null
                          ? "btn btn-circle active"
                          : "btn btn-circle"
                      }
                      onClick={() => {
                        handleFilterChange("etNoofBath", { label: "any", value: null,state:null });
                      }}
                    >
                      Any
                    </span>
                    <span
                      className={
                        topFilter.number_of_baths === 1
                          ? "btn btn-circle active"
                          : "btn btn-circle"
                      }
                      onClick={() => {
                        handleFilterChange("etNoofBath", { label: "1", value: 1 ,state:null});
                      }}
                    >
                      1
                    </span>
                    <span
                      className={
                        topFilter.number_of_baths === 2
                          ? "btn btn-circle active"
                          : "btn btn-circle"
                      }
                      onClick={() => {
                        handleFilterChange("etNoofBath", { label: "2", value: 2 ,state:null});
                      }}
                    >
                      2
                    </span>
                    <span
                      className={
                        topFilter.number_of_baths === 3
                          ? "btn btn-circle active"
                          : "btn btn-circle"
                      }
                      onClick={() => {
                        handleFilterChange("etNoofBath", { label: "3", value:3 ,state:null});
                      }}
                    >
                      3
                    </span>
                    <span
                      className={
                        topFilter.number_of_baths === 4
                          ? "btn btn-circle active"
                          : "btn btn-circle"
                      }
                      onClick={() => {
                        handleFilterChange("etNoofBath", { label: "4", value:4 ,state:null});
                      }}
                    >
                      4
                    </span>
                    <span
                      className={
                        topFilter.number_of_baths === 5
                          ? "btn btn-circle active"
                          : "btn btn-circle"
                      }
                      onClick={() => {
                        handleFilterChange("etNoofBath", { label: "5", value:5 ,state:null});
                      }}
                    >
                      5
                    </span>
                    <span
                      className={
                        topFilter.number_of_baths === 6
                          ? "btn btn-circle active"
                          : "btn btn-circle"
                      }
                      onClick={() => {
                        handleFilterChange("etNoofBath", { label: "6", value:6 ,state:null});
                      }}
                    >
                      {" "}
                      6
                    </span>
                    <span
                      className={
                        topFilter.number_of_baths === 7
                          ? "btn btn-circle active"
                          : "btn btn-circle"
                      }
                      onClick={() => {
                        handleFilterChange("etNoofBath", { label: "7", value:7 ,state:null});
                      }}
                    >
                      7
                    </span>
                    <span
                      className={
                        topFilter.number_of_baths === 8
                          ? "btn btn-circle active"
                          : "btn btn-circle"
                      }
                      onClick={() => {
                        handleFilterChange("etNoofBath", { label: "8", value:8 ,state:null});
                      }}
                    >
                      8+
                    </span>
                  </div>
                </div>
              </div>
                <div className="col-md-6 idx-input-margin-top range-drop">
                  <h6 className="mb-3">Square Feet</h6>
                  <div className="row">
                    <div className="col-md-6 pl-2">
                      <div className="dropdown">
                      <Select className="range-select"
         value={
          lowerfilters?.squareFeetMin
                          }
                          onChange={(selectedValue: number) => {// @ts-ignore
                            handleFilterChange("squareFeetMin", selectedValue);
                          }}
                           placeholder="No min"
                          options={filterlist.squarefeet?.map((v: string) => ({
                            label: v,
                            value: parseInt(v),
                          }))}         
      /> 
                      </div>
                    </div>
                    <div className="col-md-6 pl-2">
                      <div className="dropdown custom-margin">
                           <Select className="range-select"
         value={
          lowerfilters?.squareFeetMax
                          }
                          onChange={(selectedValue: number) => {// @ts-ignore
                            handleFilterChange("squareFeetMax", selectedValue);
                          }}
                           placeholder="No max"
                          options={filterlist.squarefeet?.map((v: string) => ({
                            label: v,
                            value: parseInt(v),
                          }))}         
      /> 
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 idx-input-margin-top range-drop">
                  <h6 className="mb-3">Property Subtype</h6>
                  <Select className="range-select"
         value={
          lowerfilters?.propertySubtype
                          }
                          onChange={(selectedValue: number) => {// @ts-ignore
                            handleFilterChange("propertySubtype", selectedValue);
                          }}
                           placeholder="Search"
                          options={filterlist.propertysubtype?.map((v: string) => ({
                            label: v,
                            value: parseInt(v),
                          }))}         
      /> 
                </div>
                <div className="col-md-6 mt-3 range-drop">
                  <h6 className="mb-3">School District</h6>
                  <Select
  className="range-select"
  value={lowerfilters?.highschoolDistrict}
  onChange={(selectedValue) => {
    handleFilterChange("highSchoolDistrict", selectedValue);
  }}
  placeholder="Search"
  options={filterlist.highschooldistrict?.map((v: string) => ({
    label: v,
    value: parseInt(v),
  }))}
/>
                </div>
                <div className="col-md-6 mt-3 range-drop">
                  <h6 className="mb-3">High School</h6>
                  <Select
  className="range-select"
  value={lowerfilters?.highSchool}
  onChange={(selectedValue) => {
    handleFilterChange("highSchool", selectedValue);
  }}
  placeholder="Search"
  options={filterlist.highschool?.map((v: string) => ({
    label: v,
    value: parseInt(v),
  }))}
/>
                </div>
                <div className="col-md-6 idx-input-margin-top range-drop">
                  <h6 className="mb-3">Subdivision</h6>
                  <Select
  className="range-select"
  value={lowerfilters?.subdivisionName}
  onChange={(selectedValue) => {
    handleFilterChange("subdivisionName", selectedValue);
  }}
  placeholder="Search"
  options={filterlist.subdivisionname?.map((v: string) => ({
    label: v,
    value: parseInt(v),
  }))}
/>
                </div>
                <div className="col-md-6 idx-input-margin-top range-drop">
                  <h6 className="mb-3">Zip code</h6>
                  <Select
  className="range-select"
  value={lowerfilters?.zipCode}
  onChange={(selectedValue) => {
    handleFilterChange("zipCode", selectedValue);
  }}
  placeholder="Search"
  options={filterlist.subdivisionname?.map((v: string) => ({
    label: v,
    value: parseInt(v),
  }))}
/>
                </div>
                <div className="col-md-6 idx-input-margin-top range-drop">
                  <h6 className="mb-3">City</h6>
                  <Select
  className="range-select"
  value={lowerfilters?.cityName}
  onChange={(selectedValue) => {
    handleFilterChange("cityName", selectedValue);
  }}
  placeholder="Search"
  options={filterlist.city?.map((v: string) => ({
    label: v,
    value: parseInt(v),
  }))}
/>
                </div>
                <div className="col-md-6 idx-input-margin-top range-drop">
                  <h6 className="mb-3">County</h6>
                  <Select
  className="range-select"
  value={lowerfilters?.countryName}
  onChange={(selectedValue) => {
    handleFilterChange("countryName", selectedValue);
  }}
  placeholder="Search"
  options={filterlist.country?.map((v: string) => ({
    label: v,
    value: parseInt(v),
  }))}
/>
                </div>
                <div className="col-md-6 idx-input-margin-top range-drop">
                  <h6 className="mb-3">Lot range</h6>
                  <Select
  className="range-select"
  value={lowerfilters?.lotRange}
  onChange={(selectedValue) => {
    handleFilterChange("lotRange", selectedValue);
  }}
  placeholder="Search"
  options={filterlist.lotrange?.map((v: string) => ({
    label: v,
    value: parseInt(v),
  }))}
/>
                </div>
                <div className="col-md-6 idx-input-margin-top range-drop">
                  <h6 className="mb-3">New Construction</h6>
                  <div className="row">
                    <div className="col-md-12 range-drop">
                      {/* <Select
                        className="range-select"
                        value={options.find(
                          (opt) =>
                            opt.value ===
                            (filter.newconstructionyn ? "Yes" : "No")
                        )}
                        onChange={(e) => handlecontructdata(e)}
                        options={options}
                      /> */}
                                        <Select
  className="range-select"
  value={lowerfilters?.newconstructionynValue}
  onChange={(selectedValue) => {
    handleFilterChange("newconstructionynValue", selectedValue);
  }}
  placeholder="Search"
  options={filterlist.newconstructionyn?.map((v: string) => ({
    label: v,
    value: parseInt(v),
  }))}
/>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 idx-input-margin-top">
                  <h6 className="mb-3">Garage spaces</h6>
                  <div className="row">
                    <div className="col-md-6 pl-2">
                      <div className="range-drop">
                        <Select
                          className="range-select"
                          value={
                            lowerfilters.garagespaceMin
                          }
                          onChange={(selectedValue) => {
                            handleFilterChange("garagespaceMin", selectedValue);
                          }}
                          placeholder="No min"
                          options={filterlist.garagespace?.map((v: string) => ({
                            label: v,
                            value: parseInt(v),
                          }))}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 pl-2">
                      <div className="dropdown custom-margin range-drop">
                        <Select
                          className="range-select"
                          value={
                            lowerfilters.garagespaceMax
                          }
                          onChange={(selectedValue) => {
                            handleFilterChange("garagespaceMax", selectedValue);
                          }}
                          placeholder="No max"
                          options={filterlist.garagespace?.map((v: string) => ({
                            label: v,
                            value: parseInt(v),
                          }))}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 idx-input-margin-top">
                  <h6 className="mb-3">Year built</h6>
                  <div className="row">
                    <div className="col-md-6 pl-2">
                      <div className="dropdown range-drop">
                        <Select
                          className="range-select"
                          value={lowerfilters.yearbuiltMin}
                          onChange={(selectedValue) => {
                            handleFilterChange("yearbuiltMin", selectedValue);
                          }}
                          placeholder="No max"
                          options={filterlist.yearbuilt?.map((v: string) => ({
                            label: v,
                            value: parseInt(v),
                          }))}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 pl-2">
                      <div className="dropdown custom-margin range-drop">
                        <Select
                          className="range-select"
                          value={lowerfilters.yearbuiltMax}
                          placeholder="No min"
                          onChange={(selectedValue) => {
                            handleFilterChange("yearbuiltMax", selectedValue);
                          }}
                          options={filterlist.yearbuilt?.map((v: string) => ({
                            label: v,
                            value: parseInt(v),
                          }))}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 idx-input-margin-top">
                  <h6 className="mb-3">Fireplaces</h6>
                  <div className="row">
                    <div className="col-md-6 pl-2">
                      <div className="dropdown range-drop">
                        <Select
                          className="range-select"
                          value={lowerfilters.fireplaceMin}
                          placeholder="No min"
                          onChange={(selectedValue) => {
                            handleFilterChange("fireplaceMin", selectedValue);
                          }}
                          options={filterlist.fireplaces?.map((v: string) => ({
                            label: v,
                            value: parseInt(v),
                          }))}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 pl-2">
                      <div className="dropdown custom-margin range-drop">
                        <Select
                          className="range-select"
                          value={lowerfilters.fireplaceMax}
                          placeholder="No max"
                          onChange={(selectedValue) => {
                            handleFilterChange("fireplaceMax", selectedValue);
                          }}
                          options={filterlist.fireplaces?.map((v: string) => ({
                            label: v,
                            value: parseInt(v),
                          }))}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 idx-input-margin-top range-drop">
                  <h6 className="mb-3">Days on market</h6>
    
                  <Select
  className="range-select"
  value={lowerfilters?.lotFeatures}
  onChange={(selectedValue) => {
    handleFilterChange("lotFeatures", selectedValue);
  }}
  placeholder="Search"
  options={filterlist.lotFeatures?.map((v: string) => ({
    label: v,
    value: parseInt(v),
  }))}
/>
{/* <form className="idx-filter-form" action=""> */}
                    {/* <Select
                      className="range-select"
                      value={selectedOption?.lotRange}
                      onChange={handleChange}
                      options={lotrangeoption}
                    /> */}
                    {/* <div className=" searchbg">
                    <div className="">
                      <input
                        type="search"
                        placeholder="Search"
                        aria-describedby="button-addon1"
                        className="form-control idx-search-input"
                        onChange={(e) =>
                          handleFilterListInput(e, "lotFeatures")
                        }
                        value={filter.lotFeatures ? filter.lotFeatures : ""}
                      />
                      <div className="input-group-append">
                        <button
                          id="button-addon1"
                          type="submit"
                          className="btn btn-link text-primary"
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                    </div>
                    {showSuggestions?.lotFeatures &&
                        (filter?.lotFeatures ? (
                          suggestions?.lotFeatures?.length > 0 ? (
                            <ul className="search-dropdown">
                              {suggestions?.lotFeatures !== null
                                ? suggestions?.lotFeatures.map(
                                    (suggestion: any) => (
                                      <li
                                        key={suggestion}
                                        onClick={(e: any) =>
                                          handleListData(
                                            e,
                                            suggestion,
                                            "lotFeatures"
                                          )
                                        }
                                      >
                                        {suggestion}
                                      </li>
                                    )
                                  )
                                : null}
                            </ul>
                          ) : (
                            <ul className="search-dropdown">
                              {" "}
                              <li> No Data Available</li>{" "}
                            </ul>
                          )
                        ) : (
                          ""
                        ))}
                  </div> */}
                  {/* </form> */}
                </div>
                <div className="col-md-12 idx-input-margin-top ">
                  <h6 className="mb-4">Special Features</h6>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-10">
                      <div className="special-feature">
                        <span className="mr-3">
                          <img src={BeachSun} />
                        </span>
                        <span>Waterfront</span>
                      </div>
                      <div className="special-feature">
                        <span className="mr-3">
                          <img src={BeachSun} />
                        </span>
                        <span>Riverfront</span>
                      </div>
                      <div className="special-feature">
                        <span className="mr-3">
                          <img src={BeachSun} />
                        </span>
                        <span>Lakefront</span>
                      </div>
                      <div className="special-feature">
                        <span className="mr-3">
                          <img src={SwimmingPool} />
                        </span>
                        <span>Pool in ground</span>
                      </div>
                      <div className="special-feature">
                        <span className="mr-3">
                          <img src={SwimmingPool} />
                        </span>
                        <span>Pool above ground</span>
                      </div>
                    </div>
        
                    <div className="col-2">
                      <div className="special-feature-input">
                        <input
                          className="form-check"
                          type="checkbox"
                          value={
                            topFilter.waterfrontfeatures
                              ? topFilter.waterfrontfeatures
                              : ""
                          }
                          onChange={(e) => {
                            const selectedValue = { state: e.target.checked };// @ts-ignore
                            handleFilterChange("waterFront", selectedValue);
                          }}
                          checked={topFilter.waterfront}
                          id="flexCheckDefault"
                        />
                        <input
                          className="form-check"
                          type="checkbox"
                          value={
                            topFilter.waterfrontfeatures
                              ? topFilter.waterfrontfeatures
                              : ""
                          }
                          onChange={(e) => {
                            const selectedValue = { state: e.target.checked };// @ts-ignore
                            handleFilterChange("riverFront", selectedValue);
                          }}
                          checked={filter?.riverfront}
                          id="flexCheckDefault"
                        />
                        <input
                          className="form-check"
                          type="checkbox"
                          value={
                            topFilter.waterfrontfeatures
                              ? topFilter.waterfrontfeatures
                              : ""
                          }
                          onChange={(e) => {
                            const selectedValue = { state: e.target.checked };// @ts-ignore
                            handleFilterChange("lakeFront", selectedValue);
                          }}
                          checked={filter?.lakefront}
                          id="flexCheckDefault"
                        />
                        <input
                          className="form-check"
                          type="checkbox"
                          value={
                            topFilter.waterfrontfeatures
                            ? topFilter.waterfrontfeatures
                            : ""
                          }
                          onChange={(e) => {
                            const selectedValue = { state: e.target.checked };// @ts-ignore
                            handleFilterChange("poolGround", selectedValue);
                          }}
                          checked={filter?.pool_In_Ground}
                          id="flexCheckDefault"
                        />
                        <input
                          className="form-check"
                          type="checkbox"
                          value={
                            filter.waterfrontfeatures
                              ? filter.waterfrontfeatures
                              : ""
                          }
                          onChange={(e) => {
                            const selectedValue = { state: e.target.checked };// @ts-ignore
                            handleFilterChange("poolAboveGround", selectedValue);
                          }}
                          checked={filter?.pool_Above_Ground}
                          id="flexCheckDefault"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-10">
                      <div className="special-feature">
                        <span className="mr-3">
                          <img src={SwimmingPool} />
                        </span>
                        <span>Pool access</span>
                      </div>
                      <div className="special-feature">
                        <span className="mr-3">
                          <img src={AnimalHorse} />
                        </span>
                        <span>Horse permitted</span>
                      </div>
                      <div className="special-feature">
                        <span className="mr-3">
                          <img src={HouseFilter} />
                        </span>
                        <span>Out building</span>
                      </div>
                      <div className="special-feature">
                        <span className="mr-3">
                          <img src={GolfHole} />
                        </span>
                        <span>Golf course</span>
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="special-feature-input">
                        <input
                          className="form-check"
                          type="checkbox"
                          value={
                            filter.waterfrontfeatures
                              ? filter.waterfrontfeatures
                              : ""
                          }
                          onChange={(e) => {
                            const selectedValue = { state: e.target.checked };// @ts-ignore
                            handleFilterChange("poolAccess", selectedValue);
                          }}
                          // checked={filter?.waterfrontfeatures.find((v: any) =>
                          //   v === "pool_Access" ? true : false
                          // )}
                          checked={filter?.pool_Access}
                          id="flexCheckDefault"
                        />
                        <input
                          className="form-check"
                          type="checkbox"
                          value={
                            filter.waterfrontfeatures
                              ? filter.waterfrontfeatures
                              : ""
                          }
                          onChange={(e) => {
                            const selectedValue = { state: e.target.checked };// @ts-ignore
                            handleFilterChange("horsePermitted", selectedValue);
                          }}
                          // checked={filter?.waterfrontfeatures.find((v: any) =>
                          //   v === "horsepermitted" ? true : false
                          // )}
                          checked={filter?.horse_Permitted}
                          id="flexCheckDefault"
                        />
                        <input
                          className="form-check"
                          type="checkbox"
                          value={
                            filter.waterfrontfeatures
                              ? filter.waterfrontfeatures
                              : ""
                          }
                          onChange={(e) => {
                            const selectedValue = { state: e.target.checked };// @ts-ignore
                            handleFilterChange("outBuilding", selectedValue);
                          }}
                          // checked={filter?.waterfrontfeatures.find((v: any) =>
                          //   v === "out_Building" ? true : false
                          // )}
                          checked={filter?.out_Building}
                          id="flexCheckDefault"
                        />
                        <input
                          className="form-check"
                          type="checkbox"
                          value={
                            filter.waterfrontfeatures
                              ? filter.waterfrontfeatures
                              : ""
                          }
                          onChange={(e) => {
                            const selectedValue = { state: e.target.checked };// @ts-ignore
                            handleFilterChange("golfCourse", selectedValue);
                          }}
                          // checked={filter?.waterfrontfeatures.find((v: any) =>
                          //   v === "golfcourse" ? true : false
                          // )}
                          checked={filter?.golf_Course}
                          id="flexCheckDefault"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="savebtndiv">
              <Link
                to="#"
                className="btn savesearchbtn"
                onClick={() => handleSaveSearch()}
              >
                SAVE SEARCH
              </Link>
            </div> */}
              {/* <Modal
                show={savesearchtoggle}
                onClose={() => setsaveSearchToggle(false)}
                header={"Enter the Name"}
                body={
                  <React.Fragment>
                    <input
                      type="text"
                      className="save-input"
                      placeholder="Save search name"
                      value={
                        savesearch.filter_name ? savesearch.filter_name : ""
                      }
                      onChange={(e: any) => handleSaveSearchName(e)}
                    />
                  </React.Fragment>
                }
                footer={
                  <React.Fragment>
                    <Link
                      to="#"
                      onClick={(e: any) => handleConfirmokButton(e)}
                      className="btn savesearchbtn"
                    >
                      SAVE
                    </Link>
                  </React.Fragment>
                }
              /> */}
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn footer-close-btn"
              data-bs-dismiss="modal"
              onClick={() => handleClearAll()}
            >
              Clear all
            </button>
            <button
              type="button"
              className="btn btn-center"
              onClick={(e) => handleSubmit()}
            >
              Apply filters
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllFilters;
