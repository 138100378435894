import { ActionType } from './actions'
import { _reducer } from './_common'

export interface StateNavigate
{
  url:string
  afterLoginURL:string,
  title:string,
}
export const reducerNavigate = _reducer<StateNavigate>({
  url:'',
  afterLoginURL:'',
  title:'',
},[
  [ActionType.LOGIN_NEEDED, (_state,action) => ({
    ..._state,
    afterLoginURL:action.afterLoginURL,
  })],
  [ActionType.LOGIN, (_state,action) => {
    const user:any = localStorage.getItem('logindata');
    const data = JSON.parse(user)?.rolename
   
    return { ..._state,url:(_state.afterLoginURL ?  _state.afterLoginURL : '/leads'), afterLoginURL:'' }
  }],
  [ActionType.REDIRECT, (_state) => ({
    ..._state,
    url:'',
  })],
   [ActionType.LOGOUT, (_state) => ({
    ..._state,
    url:'/login',
  })],
  [ActionType.NAVIGATE_TO, (state,action) => ({
    ...state,
    url:action.url,
  })],
  [ActionType.LEAD_CLAIMED, (state,action) => ({
    ...state,
    url:`/leads/${action.id}`,
  })],
  [ActionType.NOT_FOUND, (state,_) => ({
    ...state,
    url:`/not-found`,
  })],
  [ ActionType.SET_TITLE, (state, action) => ({
    ...state,
    title:action.title,
  })],
])
