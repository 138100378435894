import { NIL as NIL_UUID } from 'uuid'
import { Instant, ZonedDateTime } from '@js-joda/core'
import { Permission } from './permissions'

export const hasPermission = (user: User, x: Permission) => {
  return !!user.permissions.find((v) => v == x)
}

export interface User {
  company: string | null | undefined
  id: string
  email: string
  firstName: string
  lastName: string
  phone: string | null
  companyname: string| null
  role: RoleTypes | null
  created: Instant
  rolename: string
  department: string
  permissions: any[]
  permissionsData: any[]
}

export interface IdxUser {
  first_name: string,
  last_name: string,
  email: string,
  password: string,
  phone: string,
  department: string,
  leadRole: string,
  relationshipStatus: string,
  dateOfBirth: string | null,
  tShirtSize: string,
  address1: string,
  address2: string,
  country: string,
  city: string,
  state: string,
  zip: string
  agreeTermAndCondition: boolean
}

export interface UserDetails {
  email: string | null
  password: string | null
}

export interface UserList {
  id: string
  email: string
  firstName: string
  lastName: string
  role: string
}

export interface SortedUserListData {
    id: string,
    firstname: string,
    lastname: string,
    role: string,
    email: string,
    totalRecords: number
}

export interface UserListMailList {
  firstName: string | null
  lastName: string | null
  email: string | null
  phone: string | null
}

export interface changePassworddata {
  email: string | null,
  newPassword: string | null
}

export interface favroutes {
  userid: string,
  propertiesid: string,
  favorites: boolean,
  dislikes: boolean,
  recently_viewed: boolean
}

type RoleTypes = Superadmin | Agent | Concierge | Admin | Lender | Internalseller | Externalseller | Buyerseller | Coagent | Client | ExternalAgent | ExternalHomeInspector | ExternalHomeWarranty | ExternalLenders
  | ExternalTermiteInspector | ExternalTitleCompany | InternalAccountingTeam | InternalAgent | InternalBuyerTeam | InternalConcierge | InternalLenders

type RoleTypes_temp = Agent | Concierge | Admin | Lender | Internalseller | Externalseller | Buyerseller | Coagent | Client | ExternalAgent | ExternalHomeInspector | ExternalHomeWarranty | ExternalLenders
  | ExternalTermiteInspector | ExternalTitleCompany | InternalAccountingTeam | InternalAgent | InternalBuyerTeam | InternalConcierge | InternalLenders

export type DefaultStage = {
  id: string
  display: string
  active: string | null
}

export type UserMeta = {
  id: string
  firstName: string
  lastName: string
  photo: string | null
}

export type AgentMeta = UserMeta & {
  concierge: UserMeta | null
}

export type Superadmin = { role: Role.SUPERADMIN }
export type Agent = { role: Role.AGENT, concierge: UserMeta}
export type Concierge = { role: Role.CONCIERGE, agents: UserMeta[] }
export type Admin = { role: Role.ADMIN }
export type Lender = { role: Role.LENDER }
export type Internalseller = { role: Role.INTERNALSELLER }
export type Externalseller = { role: Role.EXTERNALSELLER }
export type Buyerseller = { role: Role.BUYERSELLER }
export type Coagent = { role: Role.COAGENT }
export type Client = { role: Role.CLIENT }
export type ExternalAgent = { role: Role.EXTERNALAGENT }
export type ExternalHomeInspector = { role: Role.EXTERNALHOMEINSPECTOR,company:UserMeta }
export type ExternalHomeWarranty = { role: Role.EXTERNALHOMEWARRANTY }
export type ExternalLenders = { role: Role.EXTERNALLENDERS }
export type ExternalTermiteInspector = { role: Role.EXTERNALTERMITEINSPECTOR }
export type ExternalTitleCompany = { role: Role.EXTERNALTITLECOMPANY }
export type InternalAccountingTeam = { role: Role.INTERNALACCOUNTINGTEAM }
export type InternalAgent = { role: Role.INTERNALAGENT }
export type InternalBuyerTeam = { role: Role.INTERNALBUYERTEAM }
export type InternalConcierge = { role: Role.INTERNALCONCIERGE }
export type InternalLenders = { role: Role.INTERNALLENDERS }

export enum Role {
  SUPERADMIN = 'super-admin',
  CONCIERGE = 'concierge',
  ADMIN = 'admin',
  AGENT = 'agent',
  LENDER = 'lender',
  INTERNALSELLER = 'internalseller',
  EXTERNALSELLER = 'externalseller',
  BUYERSELLER = 'buyerseller',
  COAGENT = 'co-agent',
  CLIENT = 'client',
  EXTERNALAGENT = 'external-agents',
  EXTERNALHOMEINSPECTOR = 'external-home-inspector',
  EXTERNALHOMEWARRANTY = 'external-home-warranty',
  EXTERNALLENDERS = 'external-lenders',
  EXTERNALTERMITEINSPECTOR = 'external-termite-inspector',
  EXTERNALTITLECOMPANY = 'external-title-company',
  INTERNALACCOUNTINGTEAM = 'internal-accounting-team',
  INTERNALAGENT = 'internal-agents',
  INTERNALBUYERTEAM = 'internal-buyer-team',
  INTERNALCONCIERGE = 'internal-concierge',
  INTERNALLENDERS = 'internal-lenders',
  
}

export enum Role_temp {
  CONCIERGE = 'concierge',
  ADMIN = 'admin',
  AGENT = 'agent',
  LENDER = 'lender',
  INTERNALSELLER = 'internalseller',
  EXTERNALSELLER = 'externalseller',
  BUYERSELLER = 'buyerseller',
  COAGENT = 'co-agent',
  CLIENT = 'client',
  EXTERNALAGENT = 'external-agents',
  EXTERNALHOMEINSPECTOR = 'external-home-inspector',
  EXTERNALHOMEWARRANTY = 'external-home-warranty',
  EXTERNALLENDERS = 'external-lenders',
  EXTERNALTERMITEINSPECTOR = 'external-termite-inspector',
  EXTERNALTITLECOMPANY = 'external-title-company',
  INTERNALACCOUNTINGTEAM = 'internal-accounting-team',
  INTERNALAGENT = 'internal-agents',
  INTERNALBUYERTEAM = 'internal-buyer-team',
  INTERNALCONCIERGE = 'internal-concierge',
  INTERNALLENDERS = 'internal-lenders',
}

export const defaultRoles: { [Property in Role]: RoleTypes } = {
  [Role.SUPERADMIN]: { role: Role.SUPERADMIN },
  [Role.AGENT]: { role: Role.AGENT, concierge: { id: NIL_UUID, firstName: '', lastName: '', photo: null }},
  [Role.ADMIN]: { role: Role.ADMIN },
  [Role.CONCIERGE]: { role: Role.CONCIERGE, agents: [] },
  [Role.LENDER]: { role: Role.LENDER },
  [Role.INTERNALSELLER]: { role: Role.INTERNALSELLER },
  [Role.EXTERNALSELLER]: { role: Role.EXTERNALSELLER },
  [Role.BUYERSELLER]: { role: Role.BUYERSELLER },
  [Role.COAGENT]: { role: Role.COAGENT },
  [Role.CLIENT]: { role: Role.CLIENT },
  [Role.EXTERNALAGENT]: { role: Role.EXTERNALAGENT },
  [Role.EXTERNALHOMEINSPECTOR]: { role: Role.EXTERNALHOMEINSPECTOR,company:{  id: NIL_UUID, firstName: '', lastName: '', photo: null}  },
  [Role.EXTERNALHOMEWARRANTY]: { role: Role.EXTERNALHOMEWARRANTY },
  [Role.EXTERNALLENDERS]: { role: Role.EXTERNALLENDERS },
  [Role.EXTERNALTERMITEINSPECTOR]: { role: Role.EXTERNALTERMITEINSPECTOR },
  [Role.EXTERNALTITLECOMPANY]: { role: Role.EXTERNALTITLECOMPANY },
  [Role.INTERNALACCOUNTINGTEAM]: { role: Role.INTERNALACCOUNTINGTEAM },
  [Role.INTERNALAGENT]: { role: Role.INTERNALAGENT },
  [Role.INTERNALBUYERTEAM]: { role: Role.INTERNALBUYERTEAM },
  [Role.INTERNALCONCIERGE]: { role: Role.INTERNALCONCIERGE },
  [Role.INTERNALLENDERS]: { role: Role.INTERNALLENDERS },
}
// @ts-ignore 
export const defaultRolestemp: { [Property in Role_temp]: RoleTypes_temp } = {
  [Role.AGENT]: { role: Role.AGENT, concierge: { id: NIL_UUID, firstName: '', lastName: '', photo: null } },
  [Role.ADMIN]: { role: Role.ADMIN },
  [Role.CONCIERGE]: { role: Role.CONCIERGE, agents: [] },
  [Role.LENDER]: { role: Role.LENDER },
  [Role.INTERNALSELLER]: { role: Role.INTERNALSELLER },
  [Role.EXTERNALSELLER]: { role: Role.EXTERNALSELLER }, 
  [Role.BUYERSELLER]: { role: Role.BUYERSELLER },
  [Role.COAGENT]: { role: Role.COAGENT },
  // [Role.CLIENT]: { role: Role.CLIENT },
  [Role.EXTERNALAGENT]: { role: Role.EXTERNALAGENT },
  [Role.EXTERNALHOMEINSPECTOR]: { role: Role.EXTERNALHOMEINSPECTOR ,company:{  id: NIL_UUID, firstName: '', lastName: '', photo: null}  },
  [Role.EXTERNALHOMEWARRANTY]: { role: Role.EXTERNALHOMEWARRANTY },
  [Role.EXTERNALLENDERS]: { role: Role.EXTERNALLENDERS },
  [Role.EXTERNALTERMITEINSPECTOR]: { role: Role.EXTERNALTERMITEINSPECTOR },
  [Role.EXTERNALTITLECOMPANY]: { role: Role.EXTERNALTITLECOMPANY },
  [Role.INTERNALACCOUNTINGTEAM]: { role: Role.INTERNALACCOUNTINGTEAM },
  [Role.INTERNALAGENT]: { role: Role.INTERNALAGENT },
  [Role.INTERNALBUYERTEAM]: { role: Role.INTERNALBUYERTEAM },
  [Role.INTERNALCONCIERGE]: { role: Role.INTERNALCONCIERGE },
  [Role.INTERNALLENDERS]: { role: Role.INTERNALLENDERS },
}

export const RoleTypeToString_temp = (t: RoleTypes_temp | undefined) => {
  if (t === undefined) {
    return 'Unknown'; // Provide a default value or throw an error if appropriate
  }
  switch (t.role) {
    case Role.AGENT:
      return 'Agent'
    case Role.ADMIN:
      return 'Admin'
    case Role.CONCIERGE:
      return 'Concierge'
    case Role.LENDER:
      return 'Lender Internal'
    case Role.INTERNALSELLER:
      return 'Internal Seller Team'
    case Role.EXTERNALSELLER:
      return 'External seller Team'
    case Role.BUYERSELLER:
      return 'Buyer/Seller Team'
    case Role.COAGENT:
      return 'Co-Agent'
    case Role.CLIENT:
      return 'Client'
    case Role.EXTERNALAGENT:
      return 'External-Agent'
    case Role.EXTERNALAGENT:
      return 'External-Agent'
    case Role.EXTERNALHOMEINSPECTOR:
      return 'External-Home-Inspector'
    case Role.EXTERNALHOMEWARRANTY:
      return 'External-Home-Warranty'
    case Role.EXTERNALLENDERS:
      return 'External-Lenders'
    case Role.EXTERNALTERMITEINSPECTOR:
      return 'External-Termite-Inspector'
    case Role.EXTERNALTITLECOMPANY:
      return 'external-title-company'
    case Role.INTERNALACCOUNTINGTEAM:
      return 'Internal-Accounting-Team'
    case Role.INTERNALAGENT:
      return 'Internal-Agents'
    case Role.INTERNALBUYERTEAM:
      return 'Internal-Buyer-Team'
    case Role.INTERNALCONCIERGE:
      return 'Internal-Concierge'
    case Role.INTERNALLENDERS:
      return 'Internal-Lenders'
    default:
      return 'Unknown';
  }
}

export const RoleTypeToString = (t: RoleTypes | undefined) => {
  if (t === undefined) {
    return 'Unknown'; // Provide a default value or throw an error if appropriate
  }
  switch (t.role) {
    case Role.SUPERADMIN:
      return 'Super Admin'
    case Role.AGENT:
      return 'Agent'
    case Role.ADMIN:
      return 'Admin'
    case Role.CONCIERGE:
      return 'Concierge'
    case Role.LENDER:
      return 'Lender Internal'
    case Role.INTERNALSELLER:
      return 'Internal Seller Team'
    case Role.EXTERNALSELLER:
      return 'Internal Buyer Team'
    case Role.BUYERSELLER:
      return 'Buyer/Seller Team'
    case Role.COAGENT:
      return 'Co-Agent'
    case Role.CLIENT:
      return 'Client'
    case Role.EXTERNALAGENT:
      return 'External-Agent'
    case Role.EXTERNALAGENT:
      return 'External-Agent'
    case Role.EXTERNALHOMEINSPECTOR:
      return 'External-Home-Inspector'
    case Role.EXTERNALHOMEWARRANTY:
      return 'External-Home-Warranty'
    case Role.EXTERNALLENDERS:
      return 'External-Lenders'
    case Role.EXTERNALTERMITEINSPECTOR:
      return 'External-Termite-Inspector'
    case Role.EXTERNALTITLECOMPANY:
      return 'external-title-company'
    case Role.INTERNALACCOUNTINGTEAM:
      return 'Internal-Accounting-Team'
    case Role.INTERNALAGENT:
      return 'Internal-Agents'
    case Role.INTERNALBUYERTEAM:
      return 'Internal-Buyer-Team'
    case Role.INTERNALCONCIERGE:
      return 'Internal-Concierge'
    case Role.INTERNALLENDERS:
      return 'Internal-Lenders'
    default:
      return 'Unknown';
  }
}

export const userDisplayProp = (v: keyof User) => {
  switch (v) {
    case 'firstName':
      return 'First Name'
    case 'id':
      return 'Id'
    case 'email':
      return 'Email'
    case 'lastName':
      return 'Last Name'
    case 'phone':
      return 'Phone'
    case 'role':
    case 'permissions':
      return ''
    case 'created':
      return 'test'
    default:
      return ''
  }
}