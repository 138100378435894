import {
  Lead,
  LeadContact,
  Unclaimed,
  LeadMeta,
  InputLeadContact,
  InputCreateLead,
  InputLeadLastContactedInfo,
  InputLeadNotes,
  InputLeadEngagementLevel,
  InputLeadContactStatusLevel,
  InputLeadResponseLevel,
  InputLeadStage,
  LeadSearchCriteria,
  InputLeadDetails,
  InputLeadRole,
  InputLeadSlideIn,
  LenderStatus
} from './lead'
import { User,Role,UserMeta,AgentMeta,UserList,SortedUserListData } from './user'
import { Paginator,PaginatedData,SortedPaginator,SortedPaginatorData } from './pagination'
import Notification from './Notification'
import { ButtonSizes } from './Sizes'
import { Email,EmailContact } from './email'

export let DEFAULT_COUNTRY = 'USA'

export let DEFAULT_LEAD_ROLE = 'Buyer'

export let DEFAULT_RELATIONSHIP = 'Unknown'

type Errors<T> = {
  [Property in keyof T] ?: string
}

type ResponseErrors<T> = {
  field:keyof T
  message:string
}[]

type FromServer<T> = {
  [Property in keyof T] : string
}

type LeadContactErrors = Errors<InputLeadContact>
type LeadErrors = Omit<Errors<InputCreateLead>,"primaryContact"> & {primaryContact:Errors<InputLeadContact>}
type LeadLastContactedInfoErrors = Errors<InputLeadLastContactedInfo>
type LeadNotesErrors = Errors<InputLeadNotes>
type LeadStageErrors = Errors<InputLeadStage>
type lenderStageErrors = Errors<LenderStatus>
type LeadEngagementErrors = Errors<InputLeadEngagementLevel>
type LeadContactStatusErrors = Errors<InputLeadContactStatusLevel>
type LeadReponseStatusErrors = Errors<InputLeadResponseLevel>
type LeadRoleErrors = Errors<InputLeadRole>
type LeadDetailsErrors = Errors<InputLeadDetails>
type LeadSlideInErrors = Errors<InputLeadSlideIn>

export type {
  AgentMeta,
  ButtonSizes,
  LeadContactErrors,
  Email,
  EmailContact,
  LeadErrors,
  LeadLastContactedInfoErrors,
  LeadNotesErrors,
  LeadStageErrors,
  lenderStageErrors,
  LeadEngagementErrors,
  LeadContactStatusErrors,
  LeadReponseStatusErrors,
  LeadRoleErrors,
  LeadDetailsErrors,
  LeadSlideInErrors,
  User,
  Role,
  Errors,
  FromServer,
  PaginatedData,
  Paginator,
  SortedPaginatorData,
  SortedPaginator,
  SortedUserListData,
  ResponseErrors,
  Unclaimed,
  UserMeta,
  UserList,
  Lead,
  LeadContact,
  LeadMeta,
  InputCreateLead,
  InputLeadLastContactedInfo,
  InputLeadNotes,
  InputLeadStage,
  InputLeadRole,
  InputLeadDetails,
  InputLeadSlideIn,
  LeadSearchCriteria,
  Notification
}
