

export const seller_property_disclosure = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap"
        rel="stylesheet">

    <style>
        * {
            font-family: "Poppins", sans-serif;
        }
        
    </style>
</head>

<body style="background: #ddd; font-size: 13px;">
    <section style="page-break-after: always;">
        <table style="width: 1100px; margin:0 auto; background: #fff; padding:10px 15px;">
            <tr>
                <td>
                    <table>
                        <tr>
                            <td style="width: 20%;"><img style="width: 100%;"
                                    src="https://storagemilforddev.blob.core.windows.net/images/logo.svg" /></td>
                            <td style="text-align: center; font-weight: bold; width: 33%; ;">NEBRASKA REAL ESTATE
                                COMMISSION <br>
                                SELLER PROPERTY CONDITION DISCLOSURE STATEMENT<br>
                                Residential Real Property
                            </td>

                            <td style="width: 20%; "><img style="width:100%;"
                                    src="https://storagemilforddev.blob.core.windows.net/images/logo.svg" alt="">
                            </td>

                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td>
                    <div>
                        <span>THIS DISCLOSURE STATEMENT IS BEING COMPLETED AND DELIVERED IN ACCORDANCE WITH NEBRASKA
                            LAW. NEBRASKA LAW REQUIRES THE SELLER TO
                            COMPLETE THIS STATEMENT (NEB. REV. STAT. §76-2,120). </span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="border: 1px solid black; padding: 5px;">
                        <span>How long has the seller owned the property?<input type="text"
                                style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">year(s)
                            <br> Is seller currently occupying the property? <input type="checkbox"> YES <input
                                type="checkbox">NO If yes, how long has the seller occupied the property? <input
                                type="text"
                                style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">year(s)
                            If no, has <br>the seller ever occupied the property?<input type="checkbox"> YES <input
                                type="checkbox">NO If yes, when? From <input type="text"
                                style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">(year)
                            to<input type="text"
                                style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">
                            (year) </span>

                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div>
                        <span>This disclosure statement concerns the real property located at <input type="text"
                                style="  outline: none; border: none; border-bottom:1px solid black; width: 900px;">in
                            the city of <input type="text"
                                style="  outline: none; border: none; border-bottom:1px solid black; width: 300px;">,
                            County of <input type="text"
                                style="  outline: none; border: none; border-bottom:1px solid black; width: 300px;">,
                            State of Nebraska and legally described as:<br><input type="text"
                                style="  outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div>
                        <span style="font-weight: 600;">This statement is a disclosure of the condition of the real
                            property known by the seller on the date on which this statement is signed. This statement
                            is <span style="text-decoration: underline;"> NOT a warranty of any kind </span>by the
                            seller or any agent representing a principal in the transaction, and <span
                                style="text-decoration: underline;"> should NOT be accepted as a substitute for
                                any inspection or warranty that the purchaser may wish to obtain.</span> Even though the
                            information provided in this statement is NOT a warranty, the
                            purchaser may rely on the information contained herein in deciding whether and on what terms
                            to purchase the real property. Any agent
                            representing a principal in the transaction may provide a copy of this statement to any
                            other person in connection with any actual or possible sale of
                            the real property. The information provided in this statement is the representation of the
                            seller and NOT the representation of any agent, and is NOT
                            intended to be part of any contract between the seller and purchaser. </span>
                    </div>
                </td>
            </tr>

            <tr>
                <td>
                    <div style="border: 1px solid black;">
                        <span>
                            Seller please note: you are required to complete this disclosure statement IN FULL. If any
                            particular item or matter does not apply and there is no
                            provision or space for indicating, insert “N/A” in the appropriate box. If age of items is
                            unknown, write “UNK” on the blank provided. If the property
                            has more than one item as listed below please put the numbered in the appropriate box. For
                            example – if the home has three room air conditioners,
                            one working, one not working, and one not included, put a “1” in each of the “Working”, “Not
                            Working”, and “None/Not Included” boxes for that item,
                            and a “3” on the line provided next to the item description to indicate total number of
                            item. You may also provide additional explanation of any item in
                            the comments section in PART III.

                        </span>

                    </div>
                    <div style="border: 1px solid black; ">
                        <span style="font-weight: 600;">
                            SELLER STATES THAT, TO THE BEST OF THE SELLER’S KNOWLEDGE AS OF THE DATE THIS DISCLOSURE
                            STATEMENT IS COMPLETED AND SIGNED BY
                            THE SELLER, THE CONDITION OF THE REAL PROPERTY IS:


                        </span>

                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <span><span style="font-weight: 600 ;">PART I –</span> If there is more than one of any item in this
                        Part, the statement made applies to each and all of such items unless otherwise noted in the
                        Comments section in PART III of this disclosure statement, or number separately as provided in
                        the instructions above. If an item in this Part is not on the
                        property, or will not be included in the sale, check only the “None/Not included” column for
                        that item. </span>
                </td>
            </tr>
            <tr>
                <td>
                    <table>
                        <tr>
                            <td style="width: 50%; vertical-align: text-top;">
                                <table border="1" cellpadding="7" cellspacing="0"  style="font-size: 11px; width: 100%;">
                                    <tr>
                                        <th style="font-weight: 600;">
                                            Section A -Appliances
                                        </th>
                                        <th style="font-weight: 600;">
                                            Working
                                        </th>
                                        <th style="font-weight: 600;">
                                            Not<br>
                                            Working
                                        </th>
                                        <th style="font-weight: 600;">
                                            Do Not<br>
                                            Know If<br>
                                            Working
                                        </th>
                                        <th style="font-weight: 600;">
                                            None /<br>
                                            Not<br>
                                            Included<br>

                                        </th>
                                        <tbody>
                                            <tr>
                                                <td style="font-weight: 600;">1. Refrigerator</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">2. Clothes Dryer</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">3. Clothes Washer</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">4. Dishwasher</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">5. Garbage Disposal
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">6. Freezer</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">7. Oven</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">8. Range</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">9. Cooktop
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>        <tr>
                                                <td style="font-weight: 600;">10. Microwave oven</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>

                                            <tr>
                                                <td style="font-weight: 600;">11. Built-In vacuum system and equipment</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">12. Range ventilation systems
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">13. Gas grill</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">14. Room air conditioner ( <input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">number )</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">15. TV antenna / Satellite dish</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">16. Trash compactor</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>

                                        </tbody>
                                    </tr>
                                </table>
                            </td>
                            <td style="width: 50%; ">
                                <table border="1" cellpadding="2" cellspacing="0" style="font-size: 11px; width: 100%;">
                                    <tr>
                                        <th style="font-weight: 600;">
                                            Section B - Electrical Systems

                                        </th>
                                        <th style="font-weight: 600;">
                                            Working
                                        </th>
                                        <th style="font-weight: 600;">
                                            Not<br>
                                            Working
                                        </th>
                                        <th style="font-weight: 600;">
                                            Do Not<br>
                                            Know If<br>
                                            Working
                                        </th>
                                        <th style="font-weight: 600;">
                                            None /<br>
                                            Not<br>
                                            Included<br>

                                        </th>
                                        <tbody>
                                            <tr>
                                                <td style="font-weight: 600;">1. Electrical service panel Capacity<input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">AMP Capacity (if known)<input type="checkbox">fuse <input type="checkbox">  circuit breakers
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">2. Ceiling fan(s) (<input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;"> number) </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">3. Garage door opener(s) (<input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;"> number) </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">4. Garage door remote(s) (<input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;"> number)</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">5. Garage door keypad(s) (<input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;"> number)
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">6. Telephone wiring and jacks</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">7. Cable TV wiring and jacks</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">8. Intercom or sound system wiring</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">9. Built-In speakers
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>        <tr>
                                                <td style="font-weight: 600;">10. Smoke detectors (<input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;"> number) </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>

                                            <tr>
                                                <td style="font-weight: 600;">11. Fire alarm</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">12. Carbon Monoxide Alarm (<input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;"> number) 
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">13. Room ventilation/exhaust fan (<input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;"> number)</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">14. 220 volt service ( <input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">number )</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">15. Security System <input type="checkbox">  Owned <input type="checkbox"> Leased <input type="checkbox"> Central station monitoring </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">16. Have you experienced any problems with the
                                                    electrical system or its components?
                                                    <input type="checkbox"> YES <input type="checkbox"> NO</td>
                                                <td colspan="4" style="font-weight: 600;"> If YES, explain the condition in the 
                                                    comments section in PART III of this 
                                                    disclosure statement.</td>
                                                
                                            </tr>

                                        </tbody>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    
                    </table>
                </td>
            </tr>
            <tr>
                <td>
                    <div>
                        <span style="font-weight: 600;"><span style="font-weight: 700;">Seller’s Initials</span><input type="text"
                        style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">/<input type="text"
                        style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;"> Property Address <input type="text"
                        style="  outline: none; border: none; border-bottom:1px solid black; width: 400px;">Buyer’s Initials<input type="text"
                        style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">/<input type="text"
                        style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;"></span>
                    </div>
                </td>
            </tr>
        </table>
    </section>

    <section>
        <table style="width: 1100px; margin:0 auto; background: #fff; padding:10px 15px;">
            <tr>
                <td>
                    <table>
                        <tr>
                            <td style="width: 50%; vertical-align: text-top;">
                                <table border="1" cellpadding="6" cellspacing="0"  style="font-size: 11px; width: 100%;">
                                    <tr>
                                        <th style="font-weight: 600;">
                                            ection C - Heating and Cooling Systems
                                        </th>
                                        <th style="font-weight: 600;">
                                            Working
                                        </th>
                                        <th style="font-weight: 600;">
                                            Not<br>
                                            Working
                                        </th>
                                        <th style="font-weight: 600;">
                                            Do Not<br>
                                            Know If<br>
                                            Working
                                        </th>
                                        <th style="font-weight: 600;">
                                            None /<br>
                                            Not<br>
                                            Included<br>

                                        </th>
                                    </tr>
                                       
                                            <tr>
                                                <td style="font-weight: 600;"> 1. Air purifier
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;"> 2. Attic fan
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">3. Whole house fan</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">4. Central air conditioning <input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">year installed (if known)</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">5. Heating system <input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">year installed (if known) <input type="checkbox"> Gas <input type="checkbox"> Electric <input type="checkbox">Other (specify  <input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">)
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">6. Fireplace / Fireplace Insert</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">7. Gas log (fireplace)
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">8. Gas starter (fireplace)</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;"> 9. Heat pump <input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">year installed (if known)
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>      
                                              <tr>
                                                <td style="font-weight: 600;">10. Humidifier</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>

                                            <tr>
                                                <td style="font-weight: 600;">11. Propane Tank <input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;"> year installed (if known)
                                                    <input type="checkbox">Rent <input type="checkbox">Own
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">12. Wood-burning stove <input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">year installed (if known) 
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                           

                                     
                                  
                                </table>
                            </td>
                            <td style="width: 50%;  vertical-align:top;">
                                <table border="1" cellpadding="5" cellspacing="0" style="font-size: 11px; width: 100%;">
                                    <tr>
                                        <th style="font-weight: 600;">
                                            Section D - Water Systems

                                        </th>
                                        <th style="font-weight: 600;">
                                            Working
                                        </th>
                                        <th style="font-weight: 600;">
                                            Not<br>
                                            Working
                                        </th>
                                        <th style="font-weight: 600;">
                                            Do Not<br>
                                            Know If<br>
                                            Working
                                        </th>
                                        <th style="font-weight: 600;">
                                            None /<br>
                                            Not<br>
                                            Included<br>

                                        </th>
                                    </tr>
                                        <tbody>
                                            <tr>
                                                <td style="font-weight: 600;">1. Hot tub / whirlpool
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">2. Plumbing (water supply)  </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">3. Swimming pool  </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">4. a. Underground sprinkler system </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">b. Back-flow prevention system
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">5. Water heater <input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">year installed (if known)
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">6. Water purifier<input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">year installed (if known)</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">7. Water softener <input type="checkbox"> Rent <input type="checkbox">own</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">8. Well system
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            </tr>       
                                          <tr>
                                    
                                          </tr>
                                        </tbody>
                                   
                                </table>
                                <table border="1"   cellpadding="6" cellspacing="0"  style=" width: 100%;">
                                    <th>
                                        Section E - Sewer Systems

                                </th>
                                <th style="font-weight: 600;">
                                    Working
                                </th>
                                <th style="font-weight: 600;">
                                    Not<br>
                                    Working
                                </th>
                                <th style="font-weight: 600;">
                                    Do Not<br>
                                    Know If<br>
                                    Working
                                </th>
                                <th style="font-weight: 600;">
                                    None /<br>
                                    Not<br>
                                    Included<br>

                                </th>
                                <tbody>
                                    <tr>
                                        <td style="font-weight: 600;">
                                            1. Plumbing (water drainage)
                                        </td>
                                        <td><input type="checkbox"></td>
                                        <td><input type="checkbox"></td>
                                        <td><input type="checkbox"></td>
                                        <td><input type="checkbox"></td>
                                    </tr>
                                    <tr>
                                        <td style="font-weight: 600;">
                                            2. Sump pump (discharges to <input type="text"
                                            style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">)
                                        </td>
                                        <td><input type="checkbox"></td>
                                        <td><input type="checkbox"></td>
                                        <td><input type="checkbox"></td>
                                        <td><input type="checkbox"></td>
                                    </tr>
                                    <tr>
                                        <td style="font-weight: 600;">
                                            3. Septic System
                                        </td>
                                        <td><input type="checkbox"></td>
                                        <td><input type="checkbox"></td>
                                        <td><input type="checkbox"></td>
                                        <td><input type="checkbox"></td>
                                    </tr>
                                </tbody>
                            </table>
                            </td>
                        </tr>
                
                 
                        
                    </table>
                </td>
            </tr> 
            <tr>
                <td>
                    <div>
                        <span>
                            <span style="font-weight: 600;">PART II -</span>
                             In Sections A, B, C, and D if the answer to any item is “YES”, explain the condition in the comments Section in PART III of this disclosure statement.
                        </span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div>
                        <span>
                            <span style="font-weight: 600;">Section A. Structural Conditions -</span>
                            If there is more than one of any item listed in this Section, the statement made applies to each and all of such items unless otherwise 
                            noted in the comment section in PART III of this disclosure statement.
                            
                        </span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <table>
                        <tr>
                            <td style="width: 50%; vertical-align: text-top;">
                                <table border="1" cellpadding="4" cellspacing="0" style="font-size: 11px; width: 100%;">
                                    <tr>
                                        <th style="font-weight: 600;">
                                            Section A - Structural Conditions

                                        </th>
                                        <th style="font-weight: 600;">
                                            YES     
                                        </th>
                                        <th style="font-weight: 600;">
                                            NO
                                        </th>
                                        <th style="font-weight: 600;">
                                            Do Not 
                                            Know
                                        </th>
                                       
                                    </tr>
                                        <tbody>
                                            <tr>
                                                <td style="font-weight: 600;">1. Age of roof (if known) <input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">year(s)
                                                </td>
                                                <td>N / A </td>
                                                <td>N / A</td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">2. Does the roof leak?</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">3. Has the roof leaked?
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                             
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">4. Is there presently damage to the roof?</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                               
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">5. Has there been water intrusion in the 
                                                    basement or crawl space?
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                             
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">6. Has there been any damage to the real 
                                                    property or any of the structures thereon 
                                                    due to the following occurrences including, 
                                                    but not limited to, wind, hail, fire, flood, 
                                                    wood-destroying insects, or rodents?
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                               
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">7. Are there any structural problems with the 
                                                    structures on the real property?</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                             
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">8. Is there presently damage to the chimney?</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">9. Are there any windows which presently 
                                                    leak, or do any insulated windows have any 
                                                    broken seals?
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                               
                                            </tr>       
                                          <tr>
                                    
                                          </tr>
                                        </tbody>
                                   
                                </table>
                            </td>
                            <td style="width: 50%;  vertical-align:top;">
                               
                                <table border="1" cellpadding="4" cellspacing="0" style="font-size: 11px; width: 100%;">
                                    <tr>
                                        <th style="font-weight: 600;">
                                            Section A - Structural Conditions

                                        </th>
                                        <th style="font-weight: 600;">
                                            YES     
                                        </th>
                                        <th style="font-weight: 600;">
                                            NO
                                        </th>
                                        <th style="font-weight: 600;">
                                            Do Not 
                                            Know
                                        </th>
                                       
                                    </tr>
                                        <tbody>
                                            <tr>
                                                <td style="font-weight: 600;">1. Age of roof (if known) <input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">year(s)
                                                </td>
                                                <td>N / A </td>
                                                <td>N / A</td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">10. Year property was built <input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">(if known)</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">11. Has the property experienced any moving or 
                                                    settling of the following:
                                                </td>
                                                <td>---</td>
                                                <td>---</td>
                                                <td>---</td>
                                             
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;"> - Foundation
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                               
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">- Floor
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                             
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;"> - Wall 
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                               
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">- Sidewalk</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                             
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">- Patio
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                            
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">- Driveway

                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                               
                                            </tr>  
                                            <tr>
                                                <td style="font-weight: 600;">- Retaining wall

                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                               
                                            </tr>      
                                            <tr>
                                                <td style="font-weight: 600;">12. Any room additions or structural changes?

                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                               
                                            </tr> 
                                          <tr>
                                    
                                          </tr>
                                        </tbody>
                                   
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td>
                    <div>
                        <span>
                            <span style="font-weight: 600;">Section B. Environmental Conditions -</span>
                            Have any of the following substances, materials, or products been on the real property? If tests have been conducted for any of the 
                            following, provide a copy of all test results, if available.
                            
                        </span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <table>
                        <tr>
                            <td style="width: 50%; vertical-align: top;">
                                <table border="1" cellpadding="6" cellspacing="0" style="font-size: 11px; width: 100%;">
                                    <tr>
                                        <th style="font-weight: 600;">
                                            Section B - Environmental Conditions

                                        </th>
                                        <th style="font-weight: 600;">
                                            YES     
                                        </th>
                                        <th style="font-weight: 600;">
                                            NO
                                        </th>
                                        <th style="font-weight: 600;">
                                            Do Not 
                                            Know
                                        </th>
                                       
                                    </tr>
                                        <tbody>
                                            <tr>
                                                <td style="font-weight: 600;">1. Asbestos 
                                                </td>
                                                <td><input type="checkbox"> </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">2. Contaminated soil or water 
                                                    (including drinking water) </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">3. Landfill or buried materials

                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                             
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">4. Lead-based paint</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                               
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">5. Radon gas
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                             
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">6. Toxic materials
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                               
                                            </tr>
                                    
                                            
                                          <tr>
                                    
                                          </tr>
                                        </tbody>
                                   
                                </table>
                            </td>
                            <td style="width: 50%; vertical-align: text-top;">
                                <table border="1" cellpadding="6" cellspacing="0" style="font-size: 11px; width: 100%;">
                                    <tr>
                                        <th style="font-weight: 600;">
                                            Section B - Environmental Conditions

                                        </th>
                                        <th style="font-weight: 600;">
                                            YES     
                                        </th>
                                        <th style="font-weight: 600;">
                                            NO
                                        </th>
                                        <th style="font-weight: 600;">
                                            Do Not 
                                            Know
                                        </th>
                                       
                                    </tr>
                                        <tbody>
                                            <tr>
                                                <td style="font-weight: 600;">7. Underground fuel, chemical or other type of
                                                    storage tank? 
                                                </td>
                                                <td><input type="checkbox"> </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">8. Have you been notified by the Noxious Weed 
                                                    Control Authority in the last 3 years of the 
                                                    presence of noxious weeds, as defined by 
                                                    Nebraska law (N.A.C. Title 25, Ch. 10), on the 
                                                    property?  </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">9. Hazardous substances, materials or products 
                                                    identified by the Environmental Protection 
                                                    Agency or its authorized Nebraska Designee 
                                                    (excluding ordinary household cleaners)
                                                    

                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                             
                                            </tr>
                                         
                                        
                                    
                                            
                                          <tr>
                                    
                                          </tr>
                                        </tbody>
                                   
                                </table>
                            </td>
                        </tr>

                    </table>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="margin-top: 5px;">
                        <span style="font-weight: 600;"><span style="font-weight: 700;">Seller’s Initials</span><input type="text"
                        style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">/<input type="text"
                        style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;"> Property Address <input type="text"
                        style="  outline: none; border: none; border-bottom:1px solid black; width: 450px;">Buyer’s Initials<input type="text"
                        style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">/<input type="text"
                        style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;"></span>
                    </div>
                </td>
            </tr>
        </table>
    </section>

    <section>
        <table style="width: 1100px; margin:0 auto; background: #fff; padding:10px 15px;" >
            <tr>
                <td>
                    <div>
                        <span>
                            <span style="font-weight: 600;">Section C. Title Conditions -</span>
                            Do any of the following conditions exist with regard to the real property?
                        </span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <table>
                        <tr>
                            <td style="width: 50%; vertical-align: text-top;">
                                <table border="1" cellpadding="4" cellspacing="0"  style="font-size: 11px; width: 100%;">
                                    <tr>
                                        <th style="font-weight: 600;">
                                            Section C - Title Conditions 
                                        </th>
                                        <th style="font-weight: 600;">
                                            YES
                                        </th>
                                        <th style="font-weight: 600;">
                                         NO
                                        </th>
                                        <th style="font-weight: 600;">
                                            Do Not 
                                            Know
                                        </th>
                                     
                                    </tr>
                                       
                                            <tr>
                                                <td style="font-weight: 600;"> 1. Any features, such as walls, fences and driveways 
                                                    which are shared?
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;"> 2. Any easements, other than normal utility
                                                    easements?
                                                   
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                              
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">3. Any encroachments?</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                               
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">4. Any zoning violations, non-conforming uses, or 
                                                    violations of “setback” requirements?</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">5. Any lot-line disputes?
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">6. Have you been notified, or are you aware of, any 
                                                    work planned or to be performed by a utility or 
                                                    municipality close to the real property including, 
                                                    but not limited to sidewalks, streets, sewers, 
                                                    water, power, or gas lines?</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">7. Any planned road or street expansions, 
                                                    improvements, or widening adjacent to the real 
                                                    property?
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">8. Any condominium, homeowners’, or other type of 
                                                    association which has any authority over the real 
                                                    property?</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;"> 9. Any private transfer fee obligation upon sale? 
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>      
                                            

                                     
                                  
                                </table>
                            </td>
                            <td style="width: 50%; vertical-align: text-top;">
                                <table border="1" cellpadding="4" cellspacing="0"  style="font-size: 11px; width: 100%;">
                                    <tr>
                                        <th style="font-weight: 600;">
                                            Section C - Title Conditions 
                                        </th>
                                        <th style="font-weight: 600;">
                                            YES
                                        </th>
                                        <th style="font-weight: 600;">
                                         NO
                                        </th>
                                        <th style="font-weight: 600;">
                                            Do Not 
                                            Know
                                        </th>
                                     
                                    </tr>
                                       
                                            <tr>
                                                <td style="font-weight: 600;"> 10. Does ownership of the property entitle the owner
                                                    to use any “common area” facilities such as pools,
                                                    tennis courts, walkways, or other common use 
                                                    areas?
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">11. Is there a common wall or walls?
                                                   
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                              
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">b. Is there a party wall agreement?</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                               
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">12. Any lawsuits regarding this property during the
                                                    ownership of the seller?</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">13. Any notices from any governmental or quasi-
                                                    governmental agency affecting the real property?
                                                   
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">14. Any unpaid bills or claims of others for labor 
                                                    and/or materials furnished to or for the real 
                                                    property?</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">15. Any deed restrictions or other restrictions of 
                                                    record affecting the real property?
                                                   
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">16. Any unsatisfied judgments against the seller?</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">17. Any dispute regarding a right of access to the real 
                                                    property? 
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>   
                                            <tr>
                                                <td style="font-weight: 600;">18. Any other title conditions which might affect the
                                                    real property?
                                                   
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>    
                                            

                                     
                                  
                                </table>
                            </td>
                        </tr>
                
                 
                        
                    </table>
                </td>
            </tr>
            <tr>
                <td>
                    <div>
                        <span>
                            <span style="font-weight: 600;">Section D. Other Conditions -</span>
                            Do any of the following conditions exist with regard to the real property?
                        </span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <table>
                        <tr>
                            <td style="width: 50%; vertical-align: text-top;">
                                <table border="1" cellpadding="5" cellspacing="0"  style="font-size: 11px; width: 100%;">
                                    <tr>
                                        <th style="font-weight: 600;">
                                            Section D - Other Conditions 
                                        </th>
                                        <th style="font-weight: 600;">
                                            YES
                                        </th>
                                        <th style="font-weight: 600;">
                                         NO
                                        </th>
                                        <th style="font-weight: 600;">
                                            Do Not 
                                            Know
                                        </th>
                                     
                                    </tr>
                                       
                                            <tr>
                                                <td style="font-weight: 600;">1. a. Are the dwelling(s) and the improvements 
                                                    connected to a public water system?
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">b. Is the system operational?
                                                   
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                              
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;"> 2. a. Are the dwelling(s) and the improvements 
                                                    connected to a private, community (non-public), 
                                                    or Sanitary Improvement District (SID) water 
                                                    system?
                                                    </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                               
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">b. Is the system operational?</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">3. If the dwelling(s) and the improvements are 
                                                    connected to a private, community (non-public) 
                                                    or SID water system is there adequate water 
                                                    supply for regular household use (i.e. showers, 
                                                    laundry, etc.)?
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">4. a. Are the dwelling(s) and the improvements 
                                                    connected to a public sewer system?</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">b. Is the system operational?
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">5. a. Are the dwelling(s) and the improvements 
                                                    connected to a community (non-public) or SID 
                                                    sewer system?</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">b. Is the system operational? 
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr> 
                                            <tr>
                                                <td style="font-weight: 600;">6. a. Are the dwelling(s) and the improvements 
                                                    connected to a septic system? 
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>      
                                            <tr>
                                                <td style="font-weight: 600;">b. Is the system operational? 
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr> 
                                            <tr>
                                                <td style="font-weight: 600;">7. Has the main sewer line from the house ever 
                                                    backed up or exhibited slow drainage? 
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr> 
                                            

                                     
                                  
                                </table>
                            </td>
                            <td style="width: 50%; vertical-align: text-top;">
                                <table border="1" cellpadding="6" cellspacing="0"  style="font-size: 11px; width: 100%;">
                                    <tr>
                                        <th style="font-weight: 600;">
                                            Section D - Other Conditions 
                                        </th>
                                        <th style="font-weight: 600;">
                                            YES
                                        </th>
                                        <th style="font-weight: 600;">
                                         NO
                                        </th>
                                        <th style="font-weight: 600;">
                                            Do Not 
                                            Know
                                        </th>
                                     
                                    </tr>
                                       
                                            <tr>
                                                <td style="font-weight: 600;">8. a. Is the real property in a flood plain?
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">b. Is the real property in a floodway?
                                                   
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                              
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;"> 9. Is trash removal service provided to the real
                                                    property? If so, are the trash services <input type="checkbox">public <input type="checkbox">private</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                               
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">10. Have the structures been mitigated for radon?
                                                    If yes, when? <input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 30px;">/<input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 30px;">/<input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 30px;"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">11. Is the property connected to a natural gas system?    
                                                   
                                                   
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">12. Has a pet lived on the property? <input type="text"
                                                    style="  outline: none; border: none; border-bottom:1px solid black; width: 100px;">
                                                    Type(s) </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">13. Are there any diseased or dead trees, or shrubs on 
                                                    the real property?
                                                   
                                                   
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">14. Are there any flooding, drainage, or grading 
                                                    problems in connection to the real property?</td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">15. a. Have you made any insurance or manufacturer 
                                                    claims with regard to the real property?
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>   
                                            <tr>
                                                <td style="font-weight: 600;"> b. Were all repairs related to the above claims 
                                                    completed?
                                                    
                                                   
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>   
                                            <tr>
                                                <td style="font-weight: 600;"> 16. Are you aware of any problem with the exterior 
                                                    wall-covering of the structure including, but not 
                                                    limited to, siding, synthetic stucco, masonry, or 
                                                    other materials?    
                                                    
                                                   
                                                </td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>  
                                            

                                     
                                  
                                </table>
                            </td>
                        </tr>
                
                 
                        
                    </table>
                </td>
            </tr>
            <tr>
                <td>
                    <div>
                        <span>
                            <span style="font-weight: 600;">Section E. Cleaning / Servicing Conditions -</span>
                            Have you ever performed or had performed the following? (State most recent year performed)

                        </span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <table>
                        <tr>
                            <td style="width: 50%; vertical-align: text-top;">
                                <table border="1" cellpadding="3" cellspacing="0"  style="font-size: 11px; width: 100%;">
                                    <tr>
                                        <th style="font-weight: 600;">
                                            Section E – Cleaning / Servicing 
                                            Conditions
                                        </th>
                                        <th style="font-weight: 600;">
                                            YEAR
                                        </th>
                                        <th style="font-weight: 600;">
                                            YES
                                        </th>
                                        <th style="font-weight: 600;">
                                         NO
                                        </th>
                                        <th style="font-weight: 600;">
                                            Do Not 
                                            Know
                                        </th>
                                        <th style="font-weight: 600;">
                                            None / 
                                             Not 
                                            Included
                                        </th>
                                     
                                    </tr>
                                       
                                            <tr>
                                                <td style="font-weight: 600;">1. Servicing of air conditioner
                                                </td>
                                                <td></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">2. Cleaning of fireplace, including chimney
                                                </td>
                                                <td></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>   
                                            <tr>
                                                <td style="font-weight: 600;">2. Cleaning of fireplace, including chimney
                                                </td>
                                                <td></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr> 
                                            <tr>
                                                <td style="font-weight: 600;">3. Servicing of furnace
                                                </td>
                                                <td></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr> 
                                            <tr>
                                                <td style="font-weight: 600;">4. Professional inspection of 
                                                    furnace A/C (HVAC) System
                                                </td>
                                                <td></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">4. Professional inspection of 
                                                    furnace A/C (HVAC) System
                                                </td>
                                                <td></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                           
                                            <tr>
                                                <td style="font-weight: 600;">5. Servicing of septic system
                                                </td>
                                                <td></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                          
                                            

                                     
                                  
                                </table>
                            </td>
                            <td style="width: 50%; vertical-align: top;">
                                <table border="1" cellpadding="10" cellspacing="0"  style="font-size: 11px; width: 100%;">
                                    <tr>
                                        <th style="font-weight: 600;">
                                            Section E – Cleaning / Servicing 
                                            Conditions
                                        </th>
                                        <th style="font-weight: 600;">
                                            YEAR
                                        </th>
                                        <th style="font-weight: 600;">
                                            YES
                                        </th>
                                        <th style="font-weight: 600;">
                                         NO
                                        </th>
                                        <th style="font-weight: 600;">
                                            Do Not 
                                            Know
                                        </th>
                                        <th style="font-weight: 600;">
                                            None / 
                                             Not 
                                            Included
                                        </th>
                                     
                                    </tr>
                                       
                                            <tr>
                                                <td style="font-weight: 600;">6. Cleaning of wood-burning stove, including
                                                    chimney
                                                   
                                                </td>
                                                <td></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>
                                            <tr>
                                                <td style="font-weight: 600;">7. Treatment for wood-destroying insects or 
                                                    rodents 
                                                </td>
                                                <td></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr>   
                                            <tr>
                                                <td style="font-weight: 600;">8. Tested well water
                                                </td>
                                                <td></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr> 
                                            <tr>
                                                <td style="font-weight: 600;">9. Serviced / treated well water
                                                </td>
                                                <td></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                <td><input type="checkbox"></td>
                                                
                                            </tr> 
                                           
                                          
                                            

                                     
                                  
                                </table>
                            </td>
                        </tr>
                
                 
                        
                    </table>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="margin-top: 5px;">
                        <span style="font-weight: 600;"><span style="font-weight: 700;">Seller’s Initials</span><input type="text"
                        style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">/<input type="text"
                        style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;"> Property Address <input type="text"
                        style="  outline: none; border: none; border-bottom:1px solid black; width: 450px;">Buyer’s Initials<input type="text"
                        style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">/<input type="text"
                        style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;"></span>
                    </div>
                </td>
            </tr>
            
        </table>
    </section>
    <section>
        <table style="width: 1100px; margin:0 auto; background: #fff; padding:10px 15px;">
            <tr>
                <td>
                    <div>
                        <span>
                            <span style="font-weight: 600;">PART III –</span>
                            Comments. Please reference comments on items responded to above in PART I or II, with Section letter and item number.
Note: Use additional pages if necessary.
                        </span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <span><input type="text"
                        style="outline: none; border: none; border-bottom:1px solid black; width: 100%;"></span>
                </td>
            </tr>
            <tr>
                <td>
                    <div>
                        <span>If checked here <input type="checkbox">_ PART III is continued on a separate page(s)
                        </span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="margin-top: 20px; text-align: center">
                        <span style="font-size: 20px; font-weight: 600; ;">SELLER’S CERTIFICATION</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="margin-top: 20px;">
                        <span>Seller hereby certifies that this disclosure statement, which consists of<input type="text"
                            style="  outline: none; border: none; border-bottom:1px solid black; width: 50px;">pages (including additional comment pages), has been completed by Seller; 
                            that Seller has completed this disclosure statement to the best of Seller’s belief and knowledge as the date hereof, which is the date this disclosure 
                            statement is completed and signed by the Seller.</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div>
                        <span>Seller’s Signature<input type="text"
                            style="  outline: none; border: none; border-bottom:1px solid black; width: 700px;"> Date <input type="text"
                            style="  outline: none; border: none; border-bottom:1px solid black; width: 200px;"></span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div>
                        <span>Seller’s Signature<input type="text"
                            style="  outline: none; border: none; border-bottom:1px solid black; width: 700px;"> Date <input type="text"
                            style="  outline: none; border: none; border-bottom:1px solid black; width: 200px;"></span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="border: 1px solid black; padding: 10px;text-align: center;margin-top: 20px; ">
                        <span style="font-weight: 600;">ACKNOWLEDGEMENT OF RECEIPT OF DISCLOSURE STATEMENT, UNDERSTANDING AND CERTIFICATION</span>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="border: 1px solid black; padding: 10px;text-align: justify; ;">
                        <span style="font-weight: 500;">I/We acknowledge receipt of a photocopy of the above Seller Property Condition Disclosure Statement; understand that such disclosure statement is 
                            NOT a warranty of any kind by the seller or any agent representing any principal in the transaction; understand that such disclosure statement should 
                            not be accepted as a substitute for any inspection or warranty that I/we may wish to obtain; understand the information provided in this disclosure 
                            statement is the representation of the seller and not the representation of any agent, and is not intended to be part of any contract between the seller 
                            and purchaser; and certify that disclosure statement was delivered to me/us or my/our agent on or before the effective date of any contract entered 
                            into by me/us relating to the real property described in such disclosure statement.<br> Purchaser’s Signature<input type="text"
                            style="  outline: none; border: none; border-bottom:1px solid black; width: 650px;"> Date <input type="text"
                            style="  outline: none; border: none; border-bottom:1px solid black; width: 200px;"><br> Purchaser’s Signature<input type="text"
                            style="  outline: none; border: none; border-bottom:1px solid black; width: 650px;"> Date <input type="text"
                            style="  outline: none; border: none; border-bottom:1px solid black; width: 200px;"></span></span>
                    </div>
                </td>
            </tr>
        </table>
    </section>
</body>

</html>`


export const LeadBasePaint1 = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap"
        rel="stylesheet">

    <style>
        * {
            font-family: "Poppins", sans-serif;
        }
    </style>

</head>
<body style="background: #ddd; ">
    <section>
        <table style="width: 1100px; margin:0 auto; background: #fff; padding: 15px;">
            <tr>
                <td>
                    <table style="width: 100%;">
                        <tr>
                            <td style="width: 90%;"><img style="width: 250px;"
                                    src="https://storagemilforddev.blob.core.windows.net/images/logo.svg" /></td>
                                
                            <td  ><img style="width: 30px;"
                                    src="https://storagemilforddev.blob.core.windows.net/images/realtor.svg" alt="" >
                                    <img style="width: 50px;"
                                    src="https://storagemilforddev.blob.core.windows.net/images/eh.svg" alt="">
                            </td>
                           
                        </tr>
                    </table>
                </td>
            </tr>
            <tr >
                <td style="padding: 10px 0px;">
                    <span style="font-weight: 700; font-size: 25px;">Disclosure of Information on Lead-Based Paint &/or Lead-Based Paint Hazards</span>
                </td>
            </tr>
            <tr>
                <td style="padding: 10px 0px;">
                  
                    <span >  <span style="font-weight: 600; color: #3b0083;">ADDRESS:</span><input type="text" id="address"
                        style="  outline: none; border: none; border-bottom:1px solid black; width:950px;"></span>
                </td>
            </tr>
            <tr >
                <td style="padding: 10px 0px;">
                    <span style="font-weight: 600; color: #3b0083; font-size: 20px; ;">LEAD WARNING STATEMENT</span><br/>
                    <span>Every purchaser of any interest in residential real property on which a residential dwelling was built prior to 1978 is notified that such property may present exposure to lead from lead-based 
                        paint that may place young children at risk of developing lead poisoning. Lead poisoning in young children may produce permanent neurological damage, including learning disabilities, reduced 
                        intelligence quotient, behavioral problems, and impaired memory. Lead poisoning also poses a particular risk to pregnant women. The seller of any interest in residential real property is required to 
                        provide the buyer with any information on lead-based paint hazards from risk assessments or inspections in the seller’s possession and notify the buyer of any known lead-based paint hazards. A risk 
                        assessment or inspection for possible lead-based paint hazards is recommended prior to purchase.</span>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 5px;">
                    <span style="font-weight: 600; color: #3b0083; font-size: 20px; ;">Seller’s Disclosure</span>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 5px;">
                    <span>( a ) Presence of lead-based paint and/or lead-based paint hazards (check (i) or (ii) below):</span>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 5px;">
                    <span style="padding-left: 80px;">( i ) <input type="checkbox"  id="seller_disclosure_chk1">Known lead-based paint and/or lead-based paint hazards are present in the housing (explain).
                    
                       
                    </span>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 5px;">
                    <span style="padding-left: 80px;">   <input type="text"
                        style="  outline: none; border: none; border-bottom:1px solid black; width:1000px;"></span>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 5px;">
                    <span style="padding-left: 80px;">( ii ) <input type="checkbox" id="seller_disclosure_chk2">Seller has no knowledge of lead-based paint and/or lead-based paint hazards in the housing.
                    
                       
                    </span>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 5px;">
                    <span>( b )  Records and reports available to the seller (check (i) or (ii) below):</span>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 5px;">
                    <span style="padding-left: 80px;">( i ) <input type="checkbox" id="seller_disclosure_chk3">Seller has provided the purchaser with all available records and reports pertaining to lead-based paint and/or 
                        lead-based paint hazards in the housing (list documents below).
                    
                       
                    </span>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 5px;">
                    <span style="padding-left: 80px;">   <input type="text"
                        style="  outline: none; border: none; border-bottom:1px solid black; width:1000px;"></span>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 5px;">
                    <span style="padding-left: 80px;">( ii ) <input type="checkbox" id="seller_disclosure_chk4">Seller has no reports or records pertaining to lead-based paint and/or lead-based paint hazards in the housing.
                    
                       
                    </span>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 5px;">
                    <span style="font-weight: 600; color: #3b0083; ">Purchaser’s Acknowledgment</span>
                    <span>(initial)</span>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 5px;">
                    <span>( c ) <input type="text" id="purchaseer_ack_value1"
                        style="  outline: none; border: none; border-bottom:1px solid black; width:100px;">Purchaser has received copies of all information listed above.</span>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 5px;">
                    <span>(  d ) <input type="text" id="purchaseer_ack_value2"
                        style="  outline: none; border: none; border-bottom:1px solid black; width:100px;">Purchaser has received the pamphlet Protect Your Family from Lead in Your Home.</span>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 5px;">
                    <span>( e ) Purchaser has (check (i) or (ii) below):</span>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 5px;">
                    <span style="padding-left: 80px;">( i ) <input type="checkbox" id="purchaser_ack_chk1">Known lead-based paint and/or lead-based paint hazards are present in the housing (explain).
                    
                       
                    </span>
                </td>
            </tr>
          
            <tr>
                <td style="padding-top: 5px;">
                    <span style="padding-left: 80px;">( ii ) <input type="checkbox" id="purchaser_ack_chk2">Seller has no knowledge of lead-based paint and/or lead-based paint hazards in the housing.
                    
                       
                    </span>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 5px 0px;">
                    <span style="font-weight: 600; color: #3b0083; ">Agent Acknowledgment</span>
                    <span>(initial)</span>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 5px;">
                    <span>( f ) <input type="text" id="agent_ackhnowledge"
                        style="  outline: none; border: none; border-bottom:1px solid black; width:100px;">Agent has informed the seller of the seller’s obligations under 42 U.S.C. 4852(d) and is aware of his/her responsibility to ensure compliance.</span>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 5px;">
                    <span style="font-weight: 600; color: #3b0083; ">Certification of Accuracy</span><br/>
                    <span>The following parties have reviewed the information above and certify, to the best of their knowledge, that the information they have provided is true and accurate. </span>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 5px;">
                    <table>
                        <tr>
                            <td style="text-align: center;display:flex;align-items:center">
                                <span><img  id="seller1"
                                    style="  outline: none; border: none; border-bottom:2px solid #3b0083; width: 200px;"></span>
                                    <span style="padding-right: 250px;"> Seller</span> <input type="date" id="seller1_date" style="outline: none; border: none; border-bottom:2px solid #3b0083; width: 200px;">Date
                            </td>
                            <td style="text-align: center;">
                                <span><input type="date" 
                                    style="  outline: none; border: none; border-bottom:2px solid #3b0083; width: 200px;"></span>
                                    <span style="padding-right: 250px;"> Purchaser </span>Date
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 5px;">
                    <table>
                        <tr>
                            <td style="text-align: center;">
                                <span><input id="seller2"
                                    style="  outline: none; border: none; border-bottom:2px solid #3b0083; width: 200px;"></span>
                                    <span style="padding-right: 250px;"> Seller</span>Date
                            </td>
                            <td style="text-align: center;">
                                <span><input type="text"
                                    style="  outline: none; border: none; border-bottom:2px solid #3b0083; width: 500px;"></span>
                                    <span style="padding-right: 250px;"> Purchaser </span>Date
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 5px;">
                    <table>
                        <tr>
                            <td style="text-align: center;">
                                <span><input type="text"
                                    style="  outline: none; border: none; border-bottom:2px solid #3b0083; width: 500px;"></span>
                                    <span style="padding-right: 250px;"> Seller</span>Date
                            </td>
                            <td style="text-align: center;">
                                <span><input type="text"
                                    style="  outline: none; border: none; border-bottom:2px solid #3b0083; width: 500px;"></span>
                                    <span style="padding-right: 250px;"> Purchaser </span>Date
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 10px;">
                    <span>Other provisions: Earnest deposit to be delivered within (5) business days</span>
                </td>
            </tr>
        </table>
    </section>
</body>
</html>
`



export const LeadinSoilAddendum = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap"
        rel="stylesheet">

    <style>
        * {
            font-family: "Poppins", sans-serif;
        }
    </style>

</head>
<body style="background: #ddd; ">
    <table style="width: 1100px; margin:0 auto; background: #fff; padding: 30px;">
        <tr>
            <td>
                <table style="width: 100%;">
                    <tr>
                        <td style="width: 90%;"><img style="width: 250px;"
                                src="https://storagemilforddev.blob.core.windows.net/images/logo.svg" /></td>
                            
                        <td  ><img style="width: 30px;"
                                src="https://storagemilforddev.blob.core.windows.net/images/realtor.svg" alt="" >
                                <img style="width: 50px;"
                                src="https://storagemilforddev.blob.core.windows.net/images/eh.svg" alt="">
                        </td>
                       
                    </tr>
                   
                </table>
            </td>
        </tr>
        <tr>
            <td style="padding: 15px 0px;">
                <span style="font-size: 20px; font-weight: 700;">Lead in Soil Addendum</span>
            </td>
        </tr>
        <tr>
            <td>
                <span>This addendum is in addition to and becomes a permanent part of the Purchase Agreement dated<input type="text"
                    style="  outline: none; border: none; border-bottom:1px solid black; width:228px;">on the property known as <input type="text"
                    style="  outline: none; border: none; border-bottom:1px solid black; width:78%;"></span>
            </td>
        </tr>


        <tr>
            <td style="padding: 30px 0px;">
                <span>Purchase Agreement to be amended to include the following disclosure:</span>
            </td>
        </tr>
        <tr>
            <td style="padding: 15px 0px;">
                <span style="font-weight: 700; color: #3b0083;">ENVIRONMENTAL CONDITIONS<br/>
                    Lead in Soil</span>
            </td>
        </tr>
        <tr>
            <td>
                <span>The housing is located in an area of Omaha Nebraska (generally Harrison Street to the South, Read Street to the North, 56th Street to 
                    the West and East to the Missouri River) which EPA tests have shown may contain concentrations of lead in the soil that may pose an 
                    unacceptable risk to human health and the environment (the “Omaha Nebraska Lead Site”). The Omaha Lead Site has been included on 
                    EPA’s Superfund National Priorities List. However, the housing will only become part of the Omaha Lead Site if soils in its yard are tested 
                    and contain lead concentrations in excess of applicable EPA action levels.</span>
            </td>
        </tr>
        <tr>
            <td style="padding: 15PX 0px;">
                <table style="width: 100%;">
                    <tr>
                        <td style="width: 50%;">
                            <span><input type="checkbox">No reports are available</span>
                        </td>
                        <td>
                            <span><input type="checkbox">Reports have been given to the Buyer’s agent.</span>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td style="padding: 15px 0px;">
                <table>
                    <tr>
                        <td style="text-align: center;">
                            <span><input type="text"
                                style="  outline: none; border: none; border-bottom:2px solid #3b0083; width: 500px;"></span>
                                <span style="padding-right: 250px;"> Seller</span>Date
                        </td>
                        <td style="text-align: center;">
                            <span><input type="text"
                                style="  outline: none; border: none; border-bottom:2px solid #3b0083; width: 500px;"></span>
                                <span style="padding-right: 250px;"> Buyer </span>Date
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td style="padding: 15px 0px;">
                <table>
                    <tr>
                        <td style="text-align: center;">
                            <span><input type="text"
                                style="  outline: none; border: none; border-bottom:2px solid #3b0083; width: 500px;"></span>
                                <span style="padding-right: 250px;"> Seller</span>Date
                        </td>
                        <td style="text-align: center;">
                            <span><input type="text"
                                style="  outline: none; border: none; border-bottom:2px solid #3b0083; width: 500px;"></span>
                                <span style="padding-right: 250px;"> Buyer </span>Date
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td style="padding: 15px 0px;">
                <table>
                    <tr>
                        <td style="text-align: center;">
                            <span><input type="text"
                                style="  outline: none; border: none; border-bottom:2px solid #3b0083; width: 500px;"></span>
                                <span style="padding-right: 250px;">Witness </span>Date
                        </td>
                        <td style="text-align: center;">
                            <span><input type="text"
                                style="  outline: none; border: none; border-bottom:2px solid #3b0083; width: 500px;"></span>
                                <span style="padding-right: 250px;">Witness </span>Date
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td style="padding: 15px 0px;">
                <span style="font-weight: 700; color: #3b0083;">For information updated by the City of Omaha please visit http://www.omahalead.org</span>
            </td>
        </tr>
        <tr>
            <td style="padding: 15px 0px;">
                <span><img src="image/Screenshot (17).png" alt=""></span>
            </td>
        </tr>
        <tr>
            <td style="padding: 15px 0px;">
                <span>Other provisions: Earnest deposit to be delivered within (5) business days</span>
            </td>
        </tr>

    </table>
    <table style="width: 1100px; margin:0 auto; background: #fff; padding: 30px;">
        <tr>
            <td>
                <table >
                    <tr>
                      <td align="center" valign="middle" style="padding: 10px; border-radius: 5px; background-color: #007bff;">
                        <a href="#" style="color: #ffffff; text-decoration: none; font-size: 16px; font-weight: bold;">Click Here</a>
                      </td>
                    </tr>
                  </table>
            </td>
        </tr>
        
    </table>
 
</body>
</html>
`