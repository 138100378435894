import { IdxUser, User, UserDetails, changePassworddata, favroutes } from "../types/user"
import { currentuser } from "../util/currentuser"
import { Savesearch } from "../views/IDX/Components/Filter"
import { Contactus } from "../views/IDX/ContactUs"
import { Filters } from "../views/IDX/ProductDetails"
import { getRequests, postRequests } from "./_common"

/**
 * To register IDX user
 * @returns message user registered sucecsfully
 */
export const CreateIdxUser = async (users: IdxUser) => {
    let user = {
      "first_name": users.first_name,
      "last_name": users.last_name,
      "email": users.email,
      "password": users.password,
      "phone": users.phone,
      "department": users.department,
      "relationshipStatus": users.relationshipStatus,
      "dateOfBirth": users.dateOfBirth,
      "tShirtSize": users.tShirtSize,
      "address1": users.address1,
      "address2": users.address2,
      "country": users.country,
      "city": users.city,
      "state": users.state,
      "zip": users.zip,
      "leadRole": users.leadRole,
      "agreeTermAndCondition": users.agreeTermAndCondition,
      "faceBook": "",
      "instaGram": "",
      "linkdin": "",
      "twitter": ""
    }
    let response = { message: "", statusCode: "", data: false }
    await postRequests(`User/CreateUser`, user, {
      successful: (v: any) => {
        response = {
          message: v.data?.message || "",
          statusCode: v.data?.status || "",
          data: v.data?.data || false
        }
      },
      unprocessable: (v: any) => {
        response = {
          message: v?.message || "",
          statusCode: v?.status || "",
          data: false
        }
      }
    })
    return response
  }
  
  /**
   * To login IDX user
   * @returns null
   */
  export const LoggedinIdxUser = async (users: UserDetails) => {
    let response;
    await postRequests(`User/LogIn`, users, {
      successful: (v: any) => {
        response = v
      },
      unprocessable: (v: any) => {
        response = v
      }
    })
    // console.log(response);

    return response
  }
  
  /**
   * To send user email for forgot password
   * @returns null
   */
  export const forgotPassword = async (email: any) => {
    let data = {
      email: email
    }
    let response = "";
    await postRequests(`User/ForgetPassword`, data, {
      successful: (v: any) => {
        response = v.status;
      }
    })
    return response
  }
  
  /**
   * To verify otp of registered user 
   * @returns null
   */
  export const verifyOtp = async (email: any) => {
    let data = {
      email: "karie@yopmail.com",
      otp: "9228"
    }
    await postRequests(`User/ForgetPassword`, data, {
      successful: (v: any) => {
        // response = {
        //   message: v.data?.message || "",
        //   statusCode: v.data?.status || "",
        //   data: v.data?.data || false
        // }
      }
    })
  }
  
  /**
   * To verify otp of registered user 
   * @returns null
   */
  export const changePassword = async (data:changePassworddata) => {
    let response = { message: "", statusCode: "", data: false }
    await postRequests(`User/ResetPassword`, data,{
      successful:(v:any) => {
        response = {
          message: v.data?.message || "",
          statusCode: v.data?.status || "",
          data: v.data?.data || false
        }
      },
      unprocessable: (v:any) => {
        response = {
          message: v?.message || "",
          statusCode: v?.status || "",
          data: false
        }
      },
    })
    return response
  }
  
  /**
   * To get Property listing 
   * @returns list
   */

  
  export const propertyListing = async (data:Filters) => {
    let response = "";
    await postRequests(`Property/GetPropertyList`, data, {
      successful: (v: any) => {
        response = v.data;
      }
    })
    return response
  }
  
  /**
   * To get Property listing 
   * @returns list
   */
  export const saveFavroutes = async (data:favroutes , type:string) => {
    let response = "";
    if(type === "add") {
      await postRequests(`Property/SavePropertyWithUser`, data, {
        successful: (v: any) => {
          response = v.data;
        }
      })
      return response
    }else {
      await postRequests(`Property/RemovePropertyWithUser`, data, {
        successful: (v: any) => {
          response = v.data;
        }
      })
      return response
    }
    
  } 
  
  /**
   * To get details of loggedin user 
   * @returns object of required information
   */
  export const fetchCurrentUserdata = async (user:User) => {
    let temp = await getRequests<any>(`User/UserDetailsById?userid=${user.id}`);
    return temp.data
  }
  
  /**
   * To update details of loggedin user 
   * @returns message
   */
  export const updateCurrentUserdata = async (data:any) => {
  let response = "";
  await postRequests(`User/UpdateUserProfile`,data, {
    successful: (v: any) => {
      response = v.data.message;
    }
  })
  return response
  }
  
  /**
   * To get property details
   * @returns data if success
   */
  export const getPropertyDetailsbyId = async (data:any) => {
    let user_id = currentuser();
    let current_user = user_id ? user_id.id : "00000000-0000-0000-0000-000000000000"; 
    let temp = await getRequests<any>(`Property/GetPropertyById?PropertyId=${data}&userid=${current_user}`);
    return temp?.data?.value
  }

  /**
   * To save user activity for logged in user 
   * @returns null if success
   */
  export const getSavePropertyActivity = async (data:any) => {
      await postRequests(`Property/SavePropertyActivity`, data)
  }
  
  /**
   * To get list of favorite and dislike 
   * @returns array if success
   */
  export const getFavroutesList = async (data:favroutes) => {
    let response = "";
      await postRequests(`Property/GetSavePropertyWithUser`, data, {
        successful: (v: any) => {
          response = v.data;
        }
      })
      return response
  }
  
  /**
   * To get details of filters List 
   * @returns message
   */
  export const getFilterListData = async () => {
    let temp = await getRequests<any>(`Filter/GetSearchList`);
    return temp.data
  }
  
  /**
   * To save data of request tour form
   * @returns success message
   */
  export const requestTour = async (temp_data:any) => {
    let response = "";
    await postRequests(`Property/RequestTour`, temp_data, {
      successful: (v: any) => {
        response = v.data;
      }
    })
    return response
  }
  
  /**
   * To update social media details
   * @returns message if success
   */
  export const UpdateSocialMediaHandle = async (data:any) => {
    let response = "";
    let required_data = {
      "id": data.id,
      "faceBook": data.faceBook,
      "instaGram": data.instaGram,
      "linkdin": data.linkdin,
      "twitter": data.twitter
    }
      await postRequests(`User/UpdateSocialMedia`, required_data, {
        successful: (v: any) => {
          response = v.data.status;
        }
      })
      return response
  }
  
  /**
   * To save custom filter
   * @returns message if success
   */
  export const handleSaveSearchdata = async (data:Savesearch) => {
    let response = "";
      await postRequests(`Filter/SaveAndUpdateSearchFilter`, data, {
        successful: (v: any) => {
          response = v?.data?.message;
        },
        unprocessable: (v:any) => {
          response = v.message;
        },
      })
      return response
  }
  
  /**
   * To get saved filter list of user
   * @returns data if success
   */
  export const GetFilterListByuserId = async (id:string) => {
    let temp = await getRequests<any>(`Filter/GetFilterListByuserId?userid=${id}`);
    return temp.data
  }
  
  /**
   * To get saved filter details
   * @returns data if success
   */
  export const GetFilterListByIdfilter = async (userid:string,sid:string) => {
    let temp = await getRequests<any>(`Filter/GetFilterListById?userid=${userid}&sid=${sid}`);
    return temp
  }
  
  /**
   * To save contact us form's data
   * @returns data if success
   */
  export const ContactApi = async (data:Contactus) => {
    let response = "";
    let required_data = {
      "id": 0,
      "propertiesid": null,
      "firstname": data.first_name,
      "lastname": data.last_name,
      "email": data.email,
      "phonenumber": data.phone,
      "message": data.message
    }
    await postRequests(`Property/Contact`, required_data, {
      successful: (v: any) => {
        response = v.data;
      }
    })
    return response
  }
  
  /**
   * To get user's saved list
   * @returns array with listname in it
   */
  export const getUserList = async (id:string) => {
    let temp = await getRequests<any>(`Property/GetUserList?userid=${id}`);
    return temp.data
  }
  
  /**
   * To get details of particular user's saved list 
   * @returns array with listname in it
   */
  export const getUserListData = async (id:string) => {
    let temp = await getRequests<any>(`Property/GetUserListData?usersavedlistid=${id}`);
    return temp.data
  }
  
  /**
   * To create user saved list 
   * @returns message
   */
  export const createUserSavedList = async (data:any) => {
    let current_user = currentuser();
    let response = "";
    let required_data = {
      "id": 0,
      "userid": current_user?.id,
      "listname": data,
      "isactive": true,
      "isdeleted": false
    }
    await postRequests(`Property/CreateUserSavedList`, required_data, {
      successful: (v: any) => {
        response = v.data;
      }
    })
    return response
  }
  
  /**
   * To save property in user's saved list 
   * @returns Your list has been added successfully.
   */
  export const savedPropertyToUserList = async (id:number,propertyid:string) => {
    let response = "";
    let required_data = {
      "id": 0,
      "usersavedlistid": id,
      "propertiesid": propertyid
    }
    await postRequests(`Property/SavedPropertyToUserList`, required_data, {
      successful: (v: any) => {
        response = v.data;
      }
    })
    return response
  }
// to get profile like and dislike icons
  export const profilelikedislikelist = async (listingid:string,userid:string) => {
    let temp = await getRequests<any>(`Property/GetUserPropertyDetails?UserId=${userid}&ListingId=${listingid}`);
    return temp?.data
  }