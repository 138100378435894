import React, { useState, useEffect } from "react";

import Logo from "../../assets/icons/Logo.svg";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { login_success, navigate_to } from "../../state/actions";
import Store, { State } from "../../store";
import NavbarMobile from "./Util/NavbarMobile";
import { connect } from "react-redux";


const Navbar = (props: any) => {
  const [stickyClass, setStickyClass] = useState("");
  const [loggedin, setLoggedIn] = useState(false);
  const [toggle, setToggle] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [toggleNav, settoggleNav] = useState(false);
  const [dispatch1, setdispatch1] = useState(null)

  // useEffect(() => {
  //   debugger
  //   if (JSON.stringify(props) !== '{}') {
  //     setdispatch1(props);
  //   }
  // }, [props]);
  useEffect(() => {
    let dis: any = localStorage.getItem('dispatch')
    let dia1 = JSON.parse(dis)
  }, [])

  const gotoleads = () => {

    let data: any = localStorage.getItem('logindata')
    let res = JSON.parse(data)
 
    let user = JSON.parse(atob(res?.jwtToken.split('.')[1]))
    // let JWTtoken = atob(data?.jwtToken.split('.')[1])
    // const user = JSON.parse(JWTtoken);
    props.dispatch(login_success({
      token: res?.jwtToken,
      user: {
        ...user,
        permissions: user.permissions.split(",")

      },
    }))
    // navigate_to('/leads');
  }

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 150 ? setStickyClass("sticky-nav") : setStickyClass("");
    }
  };

  useEffect(() => {
    async function fetchCurrentUser() {
      let user: any = localStorage.getItem("user");
      let user_id = JSON.parse(user);
      if (user_id) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    }
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    async function fetchCurrentUser() {
      let user: any = localStorage.getItem("user");
      let user_id = JSON.parse(user);
      if (user_id) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    }
    fetchCurrentUser();
  }, []);

  const handlelogin = (e: any) => {
    e.preventDefault();
    history.push("/login");
  };
  const handlePopup = () => {
    settoggleNav(!toggleNav);
  };
  const handleClickOutside = (event: any) => {
    if (event.target.closest(".profile-dropdown") === null) {
      settoggleNav(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    
    localStorage.clear();
    history.push("/login");
  };



  return (
    <>
      <div className={`HomeNamv ${stickyClass}`}>
        <nav className="navbar navbar-expand-lg navbar-light ">
          <div className="container-fluid custom-container">
            <Link to="/homepage" className="navbar-brand" href="#">
              <img src={Logo}></img>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse navHome"
              id="navbarSupportedContent"
            >
              <a className="navbar-brand" href="#">
                {/* <img src={Logo}></img> */}
              </a>

              <ul className="mini-nav">
                <li className={location.pathname == "/homepage" ? "active" : ""}>
                  <Link
                    className="nav-link"
                    to="/homepage"
                    tabIndex={-1}
                    aria-disabled="true"
                  >
                    HOME
                  </Link>
                </li>
                <li className={location.pathname == "/property-list" ? "active" : ""}>
                  <Link
                    className="nav-link"
                    to="/property-list"
                    tabIndex={-1}
                    aria-disabled="true"
                  >
                    BUY
                  </Link>
                </li>
                {/* <li>
          <Link
            className="nav-link"
            to="###"
            tabIndex={-1}
            aria-disabled="true"
          >
            SELL
          </Link>
        </li> */}
                {/* <li>
          <Link className="nav-link" to="###" aria-disabled="true">
            BUY & SELL
          </Link>
        </li> */}
                <li>
                  <a className="nav-link" href="https://milfordmagazine.com/milford-market/" aria-disabled="true" target="_blank" >
                    MILFORD MARKET
                  </a>
                </li>
                <li>

                  <a className="nav-link" href="https://milfordmagazine.com/" aria-disabled="true" target="_blank" >
                    MAGAZINE
                  </a>

                </li>
                <li className={location.pathname == "/contactus" ? "active" : ""}>
                  <Link
                    className="nav-link"
                    to="/contactus"
                    tabIndex={-1}
                    aria-disabled="true"
                  >
                    CONTACT
                  </Link>
                </li>
              </ul>
              {loggedin === false ? (

                location.pathname !== "/login" ? <button onClick={(e) => handlelogin(e)} className="btn btn-login signupbtn loginbtn-top desktopview">
                  <Link to="/login" >
                    Login
                  </Link>
                </button> : <span></span>

              ) : (
                <div className="nav-heart-user">
                  <div className="nav-heart">
                    <Link to="/property-list/favourites">
                      <i className="fa-solid fa-heart link-dark"></i>
                    </Link>
                  </div>

                  <div className="profile-dropdown">
                    <div
                      className="nav-user profile-dropdown"
                      onClick={() => handlePopup()}
                    >
                      <i className="fa-solid fa-user"></i>
                    </div>
                    {toggleNav === true ? (
                      <ul>
                        <li>
                          <Link to="/property-list/clientprofile">Profile</Link>
                        </li>
                        <li >

                          <Link to={{
                            pathname: "/leads",
                            state: { prevPath: location.pathname },
                          }} onClick={gotoleads}> Dashboard</Link>
                        </li>
                        <li>
                          <Link to="/property-list/messages">Messages</Link>
                        </li>
                        <li>
                          <Link to="/property-list/savedlists">Save Searches</Link>
                        </li>
                        <li>
                          <Link to="/property-list/dislike">Dislikes</Link>
                        </li>
                        <li>
                          <Link to="/property-list/recentlyviewed">
                            Recently Viewed
                          </Link>
                        </li>
                        <li>
                          <Link to="/property-list/yourteam">Your Team</Link>
                        </li>
                        <li>
                          <Link to="/property-list/account">Account Settings</Link>
                        </li>
                        <hr />
                        <li onClick={handleLogout}>
                          <Link className="btn sign-out-btn" to="/login">
                            SIGN OUT
                          </Link>
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                // <div className="profile flex desktopview">
                //   <span className="profileCircle loginLogout">
                //     <div className="half">
                //       <label htmlFor="profile2" className="profile-dropdown">
                //         <img src="https://cdn0.iconfinder.com/data/icons/avatars-3/512/avatar_hipster_guy-512.png" />

                //         {toggle === true ? (
                //           <ul>
                //             <li>
                //               <NavLink
                //                 to={{
                //                   pathname: "/property-list/clientprofile",
                //                   state: { prevPath: location.pathname },
                //                 }}
                //               >
                //                 Profilee
                //               </NavLink>
                //             </li>
                //             <li >
                //             <NavLink        to={{
                //                   pathname: "/leads",
                //                   state: { prevPath: location.pathname },
                //                 }}  onClick={gotoleads}> Dashboard</NavLink>


                //             </li>
                //             <li>
                //               <NavLink
                //                 to={{
                //                   pathname: "/property-list/messages",
                //                   state: { prevPath: location.pathname },
                //                 }}
                //               >
                //                 Messages
                //               </NavLink>
                //             </li>
                //             <li>
                //               <NavLink
                //                 to={{
                //                   pathname: "/property-list/favourites",
                //                   state: { prevPath: location.pathname },
                //                 }}
                //               >
                //                 Favorites
                //               </NavLink>
                //             </li>
                //             <li>
                //               <NavLink
                //                 to={{
                //                   pathname: "/property-list/savedlists",
                //                   state: { prevPath: location.pathname },
                //                 }}
                //               >
                //                 Save Searches
                //               </NavLink>
                //             </li>
                //             <li>
                //               <NavLink
                //                 to={{
                //                   pathname: "/property-list/dislike",
                //                   state: { prevPath: location.pathname },
                //                 }}
                //               >
                //                 Dislikes
                //               </NavLink>
                //             </li>
                //             <li>
                //               <NavLink
                //                 to={{
                //                   pathname: "/property-list/recentlyviewed",
                //                   state: { prevPath: location.pathname },
                //                 }}
                //               >
                //                 Recently Viewed
                //               </NavLink>
                //             </li>
                //             <li>
                //               <NavLink
                //                 to={{
                //                   pathname: "/property-list/yourteam",
                //                   state: { prevPath: location.pathname },
                //                 }}
                //               >
                //                 Your Team
                //               </NavLink>
                //             </li>
                //             <li>
                //               <NavLink
                //                 to={{
                //                   pathname: "/property-list/account",
                //                   state: { prevPath: location.pathname },
                //                 }}
                //               >
                //                 Account Settings323
                //               </NavLink>
                //             </li>
                //             <hr></hr>
                //             <li>
                //               <NavLink
                //                 to="/login"
                //                 className="btn sign-out-btn"
                //                 onClick={() => handleLogout()}
                //               >
                //                 SIGN OUT
                //               </NavLink>
                //             </li>
                //           </ul>
                //         ) : (
                //           ""
                //         )}
                //       </label>
                //     </div>
                //   </span>
                // </div>
              )}
            </div>
          </div>
        </nav>
        <NavbarMobile />
      </div>
    </>
  );
};

export default connect((_: State) => ({}))(Navbar);



