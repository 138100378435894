import { ActionType } from './actions'
import { User } from '../types'
import { _reducer } from './_common'
import { v4 } from 'uuid'

export type errorMessage = "unknownUser"|"unknownError"|"cancelled"|""

export interface StateAuth
{
  user:User|null
  token:string
  websocket_connected:boolean
}

export const reducerAuth = _reducer<StateAuth>({
  user:null,
  token:"",
  websocket_connected:false,
},[
  [ActionType.LOGIN,(state,v) => ({
      ...state,
      token:v.token,
      user:v.user,
    })
  ],
  [ActionType.LOGOUT,(state,v) => {
    return {
      ...state,
      token:'',
      user:null,
    }
  }],
  [ActionType.WEBSOCKET_CONNECT,(state) => ({
    ...state,
    websocket_connected:true,
  })],
  [ActionType.WEBSOCKET_DISCONNECT,(state) => ({
    ...state,
    websocket_connected:false,
  })],
])