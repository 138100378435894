// @ts-ignore-file
import React, { useContext, useEffect, useRef, useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker, Autocomplete, DirectionsRenderer } from '@react-google-maps/api';
import { CButton, CForm, CSpinner } from '@coreui/react';
import { CFormInput, CFormLabel } from '@coreui/react-pro';
import { posix } from 'path';
import Navbar from '../Navbar';
// import { translate } from 'pdf-lib';
import { connect } from "react-redux";

const containerStyle = {
  width: '100%',
  height: '90vh'
};

const center = {
  lat: -3.745,
  lng: -38.523
};

const Maps = (props: any) => {


  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDMM3u6IqGRtUL4qJLvNh3IvPywYlm4xu4",
    libraries: ['places'],
  })
  const geocoder = new google.maps.Geocoder();
  const [directionres, setDirctionres] = useState(null)
  const [distance, setdistance] = useState('')
  const [duration, setduration] = useState('')
  const [loading, setloading] = useState(false)
  const [where, setwhere] = useState("")
  const [map, setMap] = React.useState(null)

  const originref: any = useRef()
  const searchloc: any = useRef()
  const destinationref: any = useRef()

  async function calculateroute() {
    setloading(true)
    if (originref?.current?.value === '' || destinationref?.current?.value === '') {
      return
    }
    const directionservice = new google.maps.DirectionsService()
    const result: any = await directionservice.route({
      origin: originref.current.value,
      destination: destinationref.current.value,
      travelMode: google.maps.TravelMode.DRIVING
    })

    setDirctionres(result)
    setdistance(result.routes[0].legs[0].distance.text)
    setduration(result.routes[0].legs[0].duration.text)
    setloading(false)
  }

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
     

        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        handleReverseGeocode(latitude, longitude)

    
      }, (error) => {
        console.error('Error getting location:', error);
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }

  const handleReverseGeocode = (lat: any, lng: any) => {
    const geocoder = new window.google.maps.Geocoder();

    const latlng = new window.google.maps.LatLng(lat, lng);
    geocoder.geocode({ location: latlng }, (results: any, status: any) => {

      if (status === 'OK') {
        if (results && results[0] && originref && originref.current) {
          originref.current.value = results[0]?.formatted_address
        }
      }
    });
  };

  useEffect(() => {
    getCurrentLocation()
    let locate = props.location.locationdata


    setwhere(locate)
    originref.current.value = locate
    destinationref.current.value = locate
  }, [])
  const [marker, setMarker]: any = useState(null);
  const [searchTerm, setSelectedPlace] = useState('');

  const getloc = () => {
    const searchLocation = searchloc?.current?.value; // Replace with the searched location

    geocoder.geocode({ address: searchLocation }, (results: any, status) => {

      if (status === 'OK') {
        const result = results[0];
        const latitude = result.geometry.location.lat();
        const longitude = result.geometry.location.lng();
  
        const location = new google.maps.LatLng(latitude, longitude);

        // map?.setCenter(location);

        // @ts-ignore
        // map?.setCenter(location);

        const marker = new google.maps.Marker({
          position: location,
          map: map,
          title: 'Marker',
        });
        // @ts-ignore
        setMarker(marker);
      } else {
        console.error('Geocoding failed:', status);
      }
    });
  }
  // let directionres: DirectionsResult | undefined;


  return (
    <>
      <Navbar />
      <div className='milford-google-form'>

        {where === "maps" ? '' : <CForm className='googleform'>
          <div className="ml-3">
            <CFormLabel htmlFor="exampleInputEmail1">Origin</CFormLabel>
            <Autocomplete>

              <CFormInput type="text" id="exampleInputEmail1" aria-describedby="emailHelp" ref={originref} disabled />
            </Autocomplete>
          </div>

          <div className="ml-3">
            <CFormLabel htmlFor="exampleInputEmail1">Destination</CFormLabel>
            <Autocomplete>

              <CFormInput type="text" id="exampleInputEmail1" aria-describedby="emailHelp" ref={destinationref} />
            </Autocomplete>
          </div>

          <CButton className='ml-3' onClick={calculateroute} color="primary">
            Submit
            {loading ? <CSpinner color="light" size="sm" className={'ml-1'} /> : null}
          </CButton>

        </CForm>}

        {where === "maps" ? "" : <div className='distance-box'>
          <div className="ml-3">
            <CFormLabel htmlFor="exampleInputEmail1">Distance:</CFormLabel>
            <CFormLabel>{distance}</CFormLabel>
          </div>
          <div className="ml-3">
            <CFormLabel htmlFor="exampleInputEmail1">Duration: </CFormLabel>

            <CFormLabel>{duration}</CFormLabel>
          </div>
        </div>}

        {where != "maps" ? " " : <div className="ml-3">
          <CFormLabel htmlFor="exampleInputEmail1" style={{ display: "block" }} >Search location</CFormLabel>
          <div className="d-flex">
            <Autocomplete >
              <CFormInput type="text" id="exampleInputEmail1" aria-describedby="emailHelp" ref={searchloc} />
            </Autocomplete>
            <CButton className='ml-3' onClick={getloc} color="primary">
              search
              {loading ? <CSpinner color="light" size="sm" className={'ml-1'} /> : null}
            </CButton>
          </div>

        </div>}

      </div>
      {/* <Autocomplete>

    <input type="text" name="" id="" ref={originref}/>
    </Autocomplete>
    <Autocomplete>

    <input type="text" name="" id="" ref={destinationref}/>
    </Autocomplete> */}

      {
        isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >

            { /* Child components, such as markers, info windows, etc. */}
            <Marker position={center} />
// @ts-ignore
            {marker && <Marker position={marker.getPosition()} />}
// @ts-ignore
            {directionres && <DirectionsRenderer directions={directionres} />}
          </GoogleMap>
        ) : <></>
      }

    </>

  )
}

export const mapStateToProps = (state: any) => {
  return {
    location: state.location.locationdata,
  };
};

export default connect(mapStateToProps)(Maps);

