import { Dispatch } from 'redux'
import { v4 as uuidv4 } from 'uuid'

import { getRequest, getRequests, postRequest, postRequests, ResponseHandlers } from './_common'
import { State } from '../store'
import { User, UserMeta, AgentMeta, UserList, SortedUserListData,Paginator, PaginatedData, SortedPaginator, SortedPaginatorData } from '../types'
import { fetchUser, fetchUserSuccess, fetchUserError } from '../state/actions'
import { parseInstant } from '../util/datetime'
import { changePassworddata, favroutes, IdxUser, UserDetails, UserListMailList } from '../types/user'
import { Filters } from '../views/IDX/ProductDetails'
import { Contactus } from '../views/IDX/ContactUs'
import { currentuser } from '../util/currentuser'
import { Savesearch } from '../views/IDX/Components/Filter'
// import {  } from '../types/pagination'

export const getUsers = async (paginator: Paginator) => {
  let users: PaginatedData<UserList> = { data: [], hasMore: false, totalRecords: 0 }
 
  await postRequest<PaginatedData<UserList>, any>('users', { paginator: paginator }, {
    successful: (v) => { users = { ...v.data, data: v.data.data.map((v: any) => ({ ...v, firstName: v.firstname, lastName: v.lastname })) } }
  })

  return users
}

export const getConcierge = async () => (
  await getRequest<UserMeta[]>('users/concierge')
).data

export const getAgents = async () => (
  await getRequest<AgentMeta[]>('users/agents')
).data

export const getUser = async (id: string | undefined) => {
  let temp = await (
    await getRequest<any>(`users/${id}`)
  ).data
  return ({
    ...temp,
    role: temp.role.fields[0],
    created: parseInstant(temp.created),
  } as User)
}

export const getcitylist = async () => {
  const res = await getRequests<any>(`AdminUserApp/GetCityList`);
  return res
}

export const getAssignableConcierge = async () => (
  await getRequest<UserMeta[]>('users/assignable-concierge')
).data

export const getAssignAbleAgents = async () => {
  let temp = await getRequest<AgentMeta[]>(`users/assignable-agents`)
  return temp.data
}

export const getAssignAbleAgentslist = async () => {
  let temp:any = await getRequests(`AdminUserApp/GetAssignableAgents`)
  return temp?.data?.data
}

export const fetchUserMiddleware = (id: string) => async (dispatch: Dispatch<any>, _: () => State) => {
  dispatch(fetchUser())
  try {
    let user = await getUser(id)
    dispatch(fetchUserSuccess(user))
  }
  catch (e) {
    dispatch(fetchUserError())
  }
}

export const saveUser = async (user: User, handlers: ResponseHandlers<any, any>) => {

  let id = user.id ? user.id : uuidv4();
  if(user?.id){
    await postRequest( `users/${user.id}/update`, { ...user, id: id }, {
      ...handlers,
      successful: () => handlers.successful(id)
    })
    return id
  }

  else{
    await postRequests('AdminUserApp/CreateUser', { ...user, id: id }, {
      ...handlers,
      successful: () => handlers.successful(id)
    })
    return id 
  }
}

/**
 * To delete user
 * @returns null
 */
export const deleteusersaveUser = async (id: string) => {
  const res = await getRequest<any>(`users/deleteuser/${id}`);
  return res
}
export const uploadprofilepic = async (data:any) => {
  const res = await postRequests(`users/UpdateUserProfile`,data);
  return res
}


/**
 * To get list of the users when searching with emailid or without get all users
 * @returns list of users
 */
export const getUsersEmailList = async (paginator: Paginator, filters: { search: string | null }) => {
  let users: PaginatedData<UserListMailList> = { data: [], hasMore: false, totalRecords: 0 }
  await postRequest<PaginatedData<UserListMailList>, any>(`leads/get-emails-of-contacts`, { filter: filters, paginator: paginator }, {
    successful: (v) => { users = { ...v.data, data: v.data.data.map((v: any) => ({ ...v, firstName: v.firstName, lastName: v.lastName, email: v.email, phone: v.phone })) } }
  })
  return users
}

export const userRoles = async () => {
  let temp = await getRequest<AgentMeta[]>(`users/getrolepermission`)
  return temp.data
}

/**
 * To get list of all users
 * @returns list of roles in objects of array 
 */
export const GetRoles = async () => {
  let temp = await getRequest<any>(`users/getroles`)
  return temp.data
}

/**
 * To get list of all roles based on role
 * @returns list of roles of selected user 
 */
export const GetUserPermissionsBasedonRoles = async (v: string) => {
  let temp = await getRequest<any>(`users/getpermissionbyrole/${v}`)
  return temp.data
}

/**
 * To get list of all Permissions based on role
 * @returns list of permissions 
 */
export const GetAllPermissions = async () => {
  let temp = await getRequest<any>(`users/getpermission`)
  return temp.data
}

/**
 * To submit all permissions based on role selected by user
 * @returns null
 */
export const AssignAllPermissions = async (permission: any, handlers: ResponseHandlers<any, any>) => {
  await postRequest(`users/assign-permissions`, permission, {
    ...handlers,
    successful: () => handlers.successful(null)
  })
  return null
}

/**
 * To get list of all department based on role
 * @returns list of permissions 
 */
export const GetAllDepartment = async () => {
  let temp = await getRequest<any>(`leads/leaddepartments`)
  return temp.data
}

/** To check entered email exists  */
export const IsEmailValid = async (email: any) => {
  let permission = { email: email }
  let users = { message: "", statusCode: "", data: false }
  await postRequest(`users/lsEmailValid`, permission, {
    successful: (v: any) => {
      users = {
        message: v.data?.message || "", // Make sure to handle null or undefined values
        statusCode: v.data?.statusCode || "",
        data: v.data?.data || false, // Set to appropriate default value or handle null/undefined values
      }
    }
  })
  return users
}

/** To get list of contacts based on lead id */
export const GetContactsList = async (id:any) => {
  let temp = await getRequests<any>(`AdminLead/GetLeadList?leadid=${id}`);
  return temp.data
}

/** To get list of leads based on conceirge and agentid */
export const getLeadsList = async (ids: string[]|null) => {
  let permission = {"agentIds": ids}
  let users = { message: "", statusCode: "", data: [] }
  await postRequests(`AdminUserApp/GetleadsAgents`, permission, {
    successful: (v: any) => {
      users = {
        message: v.data?.data?.message || "", // Make sure to handle null or undefined values
        statusCode: v.data?.data?.status || "",
        data: v.data?.data || [], // Set to appropriate default value or handle null/undefined values
      }
    },
    unprocessable: (v: any) => {
      users = {
        message: v?.message || "", // Make sure to handle null or undefined values
        statusCode: v?.status || "",
        data: v?.data || [], // Set to appropriate default value or handle null/undefined values
      }
    },
  })
  return users
}

export const conceirgeAgents = async (id:string) => {
  let temp = await getRequests<any>(`AdminUserApp/GetConciergeAgentIds?ConciergeId=${id}`);
  return temp.data
}

/** To assign lead to another agent */
export const assignLeadOtherAgent = async (leadIdssd:any) => {
  let users = {
    status: "",
    message: "",
    data: null,
    success: false
  }
  await postRequests(`AdminUserApp/AssignLeadToAnotherAgent`, leadIdssd, {
    successful: (v: any) => {
      users = {
        message: v.data?.message || "", // Make sure to handle null or undefined values
        status: v.data?.status || "",
        data: v.data?.data || [], // Set to appropriate default value or handle null/undefined values
        success: v.data?.success
      }
    },
  })
  return users
}

export const removeLeadfromAgent = async (id:string) => {
  let temp = await getRequests<any>(`AdminUserApp/RemovedLeadfromAgent?AgentId=${id}`)
  return temp?.data?.status 
}

/** To delete user */
export const deleteUser = async (id:string) => {
  let temp = await getRequests<any>(`AdminUserApp/DeleteUser?Id=${id}`);
  return temp?.data?.status
}

/** To transfer conceirge agents to another conceirge */
export const transferConceirgeToAnotherConceirge = async (leadIdssd:any) => {
  let users = {
    status: "",
    message: "",
    data: null,
    success: false
  }
  await postRequests(`AdminUserApp/TransferAgentToAnotherConcierge`, leadIdssd, {
    successful: (v: any) => {
      users = {
        message: v.data?.message || "", // Make sure to handle null or undefined values
        status: v?.data?.status || "",
        data: v.data?.data || [], // Set to appropriate default value or handle null/undefined values
        success: v.data?.success
      }
    },
  })
  return users
}

/** To remove agents from conceirge */
export const removeAgentsFromConceirge = async (id:string) => {
  let temp = await getRequests<any>(`AdminUserApp/RemoveAgentFromConcierge?ConciergeId=${id}`);
  return temp?.data?.status 
}

/** To get sorted , filtered and search data in user management */
export const getUpdatedUsersData = async ( paginator: SortedPaginator, filters: { search: string | null, orderby: string|null, role: string|null }) => {
  let users: SortedPaginatorData<UserList> = { userdata:[] , hasMore:false , totalRecords:0 };
  await postRequests('AdminUserApp/GetUserList',{filter: filters, paginator: paginator } , {
    successful: (v:any) => { users = {...v?.data, userdata: v?.data?.userdata?.map((v: any) => ({ ...v, firstName: v.firstname, lastName: v.lastname })) } }
  })
  return users
}

