import React, { Component } from 'react';
import { connect, DispatchProp, Provider } from 'react-redux'
import { BrowserRouter, Switch } from 'react-router-dom';
import { GuardProvider, GuardedRoute } from 'react-router-guards'
import Navigatable from './components/Navigatable'

import Login from './views/Login';
import store, {  State } from './store'
import { loginNeeded } from './state/actions'
import SingleProduct from './views/IDX/Components/SingleProduct';
import Resetpassword from './views/IDX/Resetpassword';
import Register from './views/IDX/Register';
import ContactUs from './views/IDX/ContactUs';
import Createnewpassword from './views/IDX/Createnewpassword';
import 'react-loading-skeleton/dist/skeleton.css'
import Maps from './views/IDX/Components/Maps';
import 'react-loading-skeleton/dist/skeleton.css'
import { HomePage } from './views/IDX/HomePage';
import '@coreui/coreui/dist/css/coreui.min.css';
import { PrivacyPolicy } from './views/IDX/Pages/PrivacyPolicy';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";
import './assets/font/Roslindale_Web/fonts.css';
import './assets/font/Secular/fonts.css';
import './scss/idx.scss'
import './scss/style.scss';
import Layoutidx from './views/IDX/Routing/Layoutidx';
import LeadGeneration from './views/IDX/Pages/LeadGeneration';
import Sharedoc from './views/Transaction/Components/Sharedoc';
import { LeadBasePaint } from './views/Transaction/Components/externalDocuments/LeadBasePaint';
import LeadinSoilAddendum from './views/Transaction/Components/externalDocuments/LeadinsoilAddendum';
// import SellerPdf from './views/Transaction/Components/externalDocuments/SellerPdf';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
// const Layoutidx = React.lazy(() => import('./views/IDX/Routing/Layoutidx'));

type Props = {
  user:State['auth']['user']
} & DispatchProp

class App extends Component<Props> {

  requireUser = (to:any, _from:any, next:any) =>
  {
    if(to.meta.auth)
    {
      if(this.props.user)
      {
        next()
      } else {
        this.props.dispatch(loginNeeded(window.location.pathname))
        next.redirect('/homepage')
      }

    }
    else
    {
      next()
    }
  }

  requirePublic = (_to:any, _from:any, next:any) =>
  {
    if(!this.props.user)
    {
      next()
    } else {
      next.redirect('/leads')
    }
  }

  requireIdxUser = (_to:any, _from:any, next:any) => {
    let user = localStorage.getItem("user");
    let user_id = user ? JSON.parse(user) : null ;
    if(user_id?.id){
      next()
    }else {
      next.redirect("/login");
    }
  }



  render() {
    return (
      // <Provider store={Datastore}>
      
      <BrowserRouter>
        <Navigatable>
          <React.Suspense fallback={ loading }>
          <GuardProvider>
            <Switch>
          
              <GuardedRoute path="/register" component={ Register } />
              <GuardedRoute path="/contactus" component={ ContactUs } />
              <GuardedRoute path="/signdoc" component={ Sharedoc } />
              <GuardedRoute path="/document=LeadBasedPaint/:id" component={ LeadBasePaint } />
              <GuardedRoute path="/document=LeadinSoilAddendum/:id" component={ LeadinSoilAddendum } />
              {/* <GuardedRoute path="/document=sellerpdf/:id" component={ SellerPdf } /> */}
              <GuardedRoute path="/singleproduct/:id" component={ SingleProduct }/>
              <GuardedRoute path="/forgotpassword" component={ Resetpassword } />
              <GuardedRoute path="/buyer/find-the-home-that-speaks-to-you" component={ LeadGeneration } />
              <GuardedRoute path="/resetpassword" component={ Createnewpassword } />
              <GuardedRoute path="/login" component={ Login } guards={[ this.requirePublic ]}/>
              <GuardedRoute path="/property-list" component={ Layoutidx } meta={{ auth:true }}  />
              <GuardedRoute path="/maps" component={ Maps } meta={{ auth:true }}  />
              <GuardedRoute path="/homepage" component={ HomePage } meta={{ auth:true }}  />
       
              <GuardedRoute path="/" component={ TheLayout } meta={{ auth:true }} guards={[ this.requireUser ]}/>
           
            </Switch>
          </GuardProvider>
        </React.Suspense>
        </Navigatable>
      </BrowserRouter>
      /* */

    );
  }

}
export default connect((state:State) => ({
  user:state.auth.user,
}))(App)
