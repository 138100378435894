import { ActionType } from './actions'
import { User } from '../types'
import { _reducer } from './_common'
import { v4 } from 'uuid'

export type errorMessage = "unknownUser"|"unknownError"|"cancelled"|""

export interface StateAuth
{
locationdata:string
}
export interface Offeridum
{
id:number
}
export interface StateAuth1
{
  add_save:string
}

const defaultState : StateAuth =
{
    locationdata:""
}
const Offeriddet : Offeridum =
{
    id:0
}
const defaultState1 : StateAuth1 =
{
  add_save:""
}
const searchbar =
{
  searchbardata:""
}



export const datastore = _reducer<StateAuth>(defaultState,[
  [ActionType.UPDATE_LOCATION,(state,v) => ({
      ...state,
      locationdata:v,
    })
  ],
])
export const Offerid = _reducer<Offeridum>(Offeriddet,[
  [ActionType.UPDATE_OFFERID,(state,v) => ({
      ...state,
      id:v,
    })
  ],
])
export const addresssearch = _reducer<StateAuth1>(defaultState1,[
  [ActionType.UPATE_ADDRESS,(state,v) => ({
      ...state,
      add_save:v,
    })
  ],
])
export const searchbarstate = _reducer(searchbar,[
  [ActionType.SEARCH_BAR,(state,v) => ({
      ...state,
      searchbardata:v,
    })
  ],
])



