// @ts-nocheck
import { getRequest, postRequest, deleteRequest, isAxiosError, ResponseHandlers, postRequests, getRequests } from './_common'
import { Dispatch } from 'redux'
import { v4 as uuidv4 } from 'uuid'

import { State } from '../store'
import {
  Lead,
  LeadContact,
  LeadMeta,
  InputCreateLead,
  InputLeadLastContactedInfo,
  InputLeadNotes,
  InputLeadStage,
  PaginatedData,
  Paginator,
  DEFAULT_RELATIONSHIP,
  Email,
} from '../types'
import { InputLeadEngagementLevel, InputLeadContactStatusLevel, InputLeadResponseLevel, LenderStatus, Homebot, preapproved, Homesearch, lastconnected, lastworked, assignlender, lastconnectedlender } from "../types/lead";
import { EmailUserContact, Sms } from "../types/email";
import { fetchLead, fetchLeadSuccess, fetchLeadError, leadClaimed, not_found } from '../state/actions'
import { parseDate, parseInstant } from '../util/datetime'
import Store from '../store'
import { InputLeadContact, InputLeadDetails, InputLeadRole, InputLeadSlideIn } from '../types/lead'
import moment from 'moment'
import { contactdetailsobj } from '../views/leads/components/UserProfileDetailedSection'
import { getUser } from './users'


export const getLeadRoles = async () => (
  await getRequest<string[]>('leads/roles')
).data

/**
 * @returns get listing of Departments
 */
export const leaddepartments = async () => (
  await getRequest<string[]>('leads/leaddepartments')
).data

const mapRelationshipIdToDisplay = (id: string): string => {
  var relationship = Store.store.getState().leads.relationships.find((v) => v.id == id);
  return (relationship) ? relationship.display : DEFAULT_RELATIONSHIP;
}

const mapTShirtSizesToDisplay = (id: string | null): string | null => {
  if (id === null)
    return null;
  var tShirtSize = Store.store.getState().leads.tShirtSizes.find((v) => v.id == id);
  return (tShirtSize) ? tShirtSize.display : null;
}

const mapResponseLevelsToDisplay = (id: string | null): string | null => {
  if (id === null)
    return null;
  var responseLevel = Store.store.getState().leads.responseLevels.find((v) => v.id == id);
  return (responseLevel) ? responseLevel.display : null;
}

const mapClosingBagOptionsToDisplay = (id: string | null): string | null => {
  if (id === null)
    return null;
  var closingBagOption = Store.store.getState().leads.closingBagOptions.find((v) => v.id == id);
  return (closingBagOption) ? closingBagOption.display : null;
}

const mapLeadStatusToDisplay = (id: string): string | null => {
  let storedaat = Store.store.getState().leads.leadStatuses;
  var leadStatus
  if(storedaat.length !== 0){
     leadStatus = storedaat?.find((v) => v.id == id);
     return (leadStatus) ? leadStatus.display : null;
  }else{
    let data = localStorage.getItem("leadStatuses")
    let temp_data = JSON.parse(data ? data : "");
    leadStatus = temp_data?.find((v:any) => v.id == id);
    return (leadStatus) ? leadStatus.display : null;
  }
}

const mapLeadEngagementToDisplay = (id: string): string | null => {
  var leadEngagementLevel = Store.store.getState().leads.engagementLevel.find((v) => v.id == id);
  return (leadEngagementLevel) ? leadEngagementLevel.display : null;
}

const verifyLeadStatus = async () => {
  if (!Store.store.getState().leads.leadStatuses.length) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        await verifyLeadStatus()
        resolve(null)
      }, 100)
    })
  }
}

const verifyResponseLevels = async () => {
  if (!Store.store.getState().leads.responseLevels.length) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        await verifyResponseLevels()
        resolve(null)
      }, 100)
    })
  }
}

const verifyClosingBagOptions = async () => {
  if (!Store.store.getState().leads.closingBagOptions.length) {
    return new Promise((resolve) => {
      setTimeout(async () => {
        await verifyClosingBagOptions()
        resolve(null)
      }, 100)
    })
  }
}

const formatDate = (value: any) => {
  const originalTime = new Date(value);
  const isoTime = originalTime.toISOString(); // Output: 2023-05-07T18:30:00.000Z
  return isoTime
};

export const getLeads = async (paginator: Paginator, filters: { status: string | null, search: string, role: string, agent: string, stage: string }, getleadsortby: { lastWorkedAgentSortBy: string | null, lastConnectedAgentSortBy: string | null, lastWorkedLenderSortBy: string | null, lastConnectedLenderSortBy: string | null, dateCreatedSortBy: string | null }) => {
  let leads: PaginatedData<LeadMeta> = { data: [], hasMore: false, totalRecords: 0, statuscode: "400" }
  await postRequests<PaginatedData<LeadMeta>, any>('AdminLead/Getleads', { filter: filters, paginator: paginator, getleadsortby: getleadsortby }, {
    successful: async (v) => {
      await verifyLeadStatus()
      leads = {
        ...v.data,
        data: v.data.data.map((x: any) => {
          let temp_data = x.lastContacted ? formatDate(x.lastContacted) : null
          return {
            ...x,
            stage: (filters?.stage) ? mapLeadStatusToDisplay(filters.stage) : mapLeadStatusToDisplay(x.stage),
            lastContacted: temp_data ? parseInstant(temp_data) : null,
            lastConnectedLender: x.lastConnectedLender ? parseInstant(x.lastConnectedLender) : null,
            relationship: mapRelationshipIdToDisplay(x.relationship),
            secondaryRelationship: (x.secondaryRelationship) ? mapRelationshipIdToDisplay(x.secondaryRelationship) : null,
            lastWorked: x.lastWorked ? parseDate(x.lastWorked) : null,
            lastWorkedLender: x.lastWorkedLender ? parseDate(x.lastWorkedLender) : null
          } as LeadMeta
        })
      }
    }
  })
  return leads
}

export const getLead = async (id: string) => {
  verifyResponseLevels();
  verifyClosingBagOptions();
  let temp_data: any = (await getRequests<Lead>(`AdminLead/GetleadContactDetails?leadid=${id}`)).data
  let temp: any = temp_data[0];
  return {
    ...temp,
    stage: mapLeadStatusToDisplay(temp.stage),
    // stage: temp.stage ? mapLeadStatusToDisplay(temp.stage) : "",
    responselevel: mapLeadEngagementToDisplay(temp.responseLevel),
    lastContacted: temp.lastContacted ? (temp.lastContacted) : null,
    lastConnectedLender: temp.lastConnectedLender ? (temp.lastConnectedLender) : null,
    responseLevel: mapResponseLevelsToDisplay(temp.responseLevel),
    lastWorked: (temp.lastWorked) ? (temp.lastWorked) : null,
    lastWorkedLender: (temp.lastWorkedLender) ? (temp.lastWorkedLender) : null,
    closingBagOption: mapClosingBagOptionsToDisplay(temp.closingBagOption),
    contacts: {
      id: temp?.contacts?.id,
      firstName: temp?.contacts?.firstName,
      lastName: temp?.contacts?.lastName,
      streetOne: temp?.contacts?.streetOne,
      streetTwo: temp?.contacts?.streetTwo,
      city: temp?.contacts?.city,
      state: temp?.contacts?.state,
      zip: temp?.contacts?.zip,
      country: temp?.contacts?.country,
      email: temp?.contacts?.email,
      phone: temp?.contacts?.phone,
      relationship: mapRelationshipIdToDisplay(temp?.contacts?.relationship),
      dateOfBirth: (temp?.contacts.dateOfBirth) ? parseDate(temp?.contacts.dateOfBirth) : null,
      tShirtSize: mapTShirtSizesToDisplay(temp?.contacts?.tShirtSize)
    } as LeadContact,
    secondorycontact: {
      id: temp?.secondaryLeadContact[0]?.contacts?.id,
      firstName: temp?.secondaryLeadContact[0]?.contacts?.firstName,
      lastName: temp?.secondaryLeadContact[0]?.contacts?.lastName,
      streetOne: temp?.secondaryLeadContact[0]?.contacts?.streetOne,
      streetTwo: temp?.secondaryLeadContact[0]?.contacts?.streetTwo,
      city: temp?.secondaryLeadContact[0]?.contacts?.city,
      state: temp?.secondaryLeadContact[0]?.contacts?.state,
      zip: temp?.secondaryLeadContact[0]?.contacts?.zip,
      country: temp?.secondaryLeadContact[0]?.contacts?.email,
      email: temp?.secondaryLeadContact[0]?.contacts?.email,
      phone: temp?.secondaryLeadContact[0]?.contacts?.phone,
      relationship: mapRelationshipIdToDisplay(temp?.secondaryLeadContact[0]?.contacts?.relationship),
      dateOfBirth: (temp?.secondaryLeadContact[0]?.contacts.dateOfBirth) ? parseDate(temp?.secondaryLeadContact[0]?.contacts.dateOfBirth) : null,
      tShirtSize: mapTShirtSizesToDisplay(temp?.secondaryLeadContact[0]?.contacts?.tShirtSize)
    } as LeadContact,
    // requests: {created: parseInstant(temp?.requests)},
  } as Lead
}

export const fetchLeadMiddleware = (id: string) => async (dispatch: Dispatch<any>, _: () => State) => {
  dispatch(fetchLead())
  try {
    let lead = await getLead(id)
    dispatch(fetchLeadSuccess(lead))
  }
  catch (e) {
    if (isAxiosError(e)) {
      if (e.response && e.response.status == 404) {
        dispatch(not_found())
      }
    }
    dispatch(fetchLeadError())
  }
}

const claimLead = async (id: string) => {
  await postRequest(`leads/${id}/claim`, {})
}

export const claimLeadMiddleware = (id: string) => async (dispatch: Dispatch<any>, _: () => State) => {
  await claimLead(id)
  dispatch(leadClaimed(id))
}

export const saveLead = async (currentcontactdetials: contactdetailsobj, handlers: ResponseHandlers<any, any>) => {
  let id = currentcontactdetials?.primaryContact.id ? currentcontactdetials?.primaryContact?.id : "";
  
  let mappedStage = Store.store.getState().leads.leadStatuses.find((v) => v.display == currentcontactdetials.stage);
  let mappedEngagementLevel = Store.store.getState().leads.engagementLevel.find((v) => v.display == currentcontactdetials.engagementLevel);
  let mappedResponseLevel = Store.store.getState().leads.responseLevel.find((v) => v.display == currentcontactdetials.responseLevel);
  let mappedstatusLevel = Store.store.getState().leads.leadStatus.find((v) => v.display == currentcontactdetials.leadstatus);
  const currenttime = moment().utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';

  let mapRelationshipDisplayToId = (display: string | null): string | null => {
    var relationship = Store.store.getState().leads.relationships.find((v) => v.display == display);
    return (relationship) ? relationship.id : null;
  }

  let mapTShirtSizeToId = (display: string | null): string | null => {
    if (display === null)
      return null;
    var tShirtSize = Store.store.getState().leads.tShirtSizes.find((v) => v.display == display);
    return (tShirtSize) ? tShirtSize.id : null;
  }

  await postRequests('AdminLead/CreateLead', {
    ...currentcontactdetials,
    id: id,
    primaryContact: {
      ...currentcontactdetials.primaryContact,
      relationship: mapRelationshipDisplayToId(currentcontactdetials.primaryContact ? currentcontactdetials.primaryContact.relationship : ""),
      tShirtSize: mapTShirtSizeToId(currentcontactdetials.primaryContact ? currentcontactdetials.primaryContact.tShirtSize : "")
    },
    stage: mappedStage ? mappedStage.id : currentcontactdetials.stage,
    engagementLevel: mappedEngagementLevel ? mappedEngagementLevel.id : currentcontactdetials.engagementLevel,
    responseLevel: mappedResponseLevel ? mappedResponseLevel.id : currentcontactdetials.responseLevel,
    contactStatusLevel: mappedstatusLevel ? mappedstatusLevel.id : currentcontactdetials.leadstatus,
    subSource: currentcontactdetials.subSource ? currentcontactdetials.subSource : null,
    createdOn: currenttime ? currenttime : null,
    subSourceAgent: currentcontactdetials.subSourceAgent ? currentcontactdetials.subSourceAgent : null,
    concierge: currentcontactdetials.concierge ? { ...currentcontactdetials.concierge, photo: undefined } : currentcontactdetials.concierge,
  }, {
    ...handlers,
    successful: () => handlers.successful(id)
  })
  return id
}

/**
 * To add secondory contact
 * @returns success message
 */
export const saveLeadContact = async (leadId: string, contact: InputLeadContact, selectedcontact: any, handlers: ResponseHandlers<any, any>) => {
  let id = contact.id ? contact.id : uuidv4();

  let mapRelationshipDisplayToId = (display: string | null): string | null => {
    var relationship = Store.store.getState().leads.relationships.find((v) => v.display == display);
    return (relationship) ? relationship.id : null;
  }

  let mapTShirtSizeToId = (display: string | null): string | null => {
    if (display === null)
      return null;
    var tShirtSize = Store.store.getState().leads.tShirtSizes.find((v) => v.display == display);
    return (tShirtSize) ? tShirtSize.id : null;
  }

  let contactbody = {
    leadid: leadId,
    secondaryContactLeadId: selectedcontact ? selectedcontact.leadId : "00000000-0000-0000-0000-000000000000",
    relationshipStatus: mapRelationshipDisplayToId(contact?.relationship),
    firstName: contact?.firstName,
    lastName: contact?.lastName,
    email: contact?.email,
    phone: contact?.phone,
    dateOfBirth: contact?.dateOfBirth,
    tShirtSize: mapTShirtSizeToId(contact?.tShirtSize),
    address1: contact?.streetOne,
    address2: contact?.streetTwo,
    country: contact?.country,
    city: contact?.city,
    state: contact?.state,
    zip: contact?.zip
  }
  await postRequests("AdminLead/AddSecondaryContact", contactbody, { ...handlers, successful: () => handlers.successful(id) })
  return id
}

export const deleteLeadContact = async (leadId: string, id: string, handlers: ResponseHandlers<any, any>) => {
  await deleteRequest(`leads/${leadId}/contacts/${id}/delete`, {
    ...handlers,
    successful: () => handlers.successful(id)
  })
  return id
}

export const makeLeadContactPrimary = async (leadId: string, id: string, handlers: ResponseHandlers<any, any>) => {
  await postRequest(`leads/${leadId}/contacts/${id}/makePrimary`, {}, {
    ...handlers,
    successful: () => handlers.successful(id)
  })
  return id
}

export const saveLeadLastContactedInfo = async (leadLastContactedInfo: InputLeadLastContactedInfo, handlers: ResponseHandlers<any, any>) => {
  await postRequest(`leads/${leadLastContactedInfo.id}/updatelastcontactedinfo`, leadLastContactedInfo, {
    ...handlers,
    successful: () => handlers.successful(leadLastContactedInfo.id)
  })
  return leadLastContactedInfo.id
}

export const saveLeadNotes = async (leadNotes: InputLeadNotes, handlers: ResponseHandlers<any, any>) => {
  await postRequest(`leads/${leadNotes.id}/updatenotes`, leadNotes, {
    ...handlers,
    successful: () => handlers.successful(leadNotes.id)
  })
  return leadNotes.id
}

export const saveLenderNotes = async (leadNotes: InputLeadNotes, handlers: ResponseHandlers<any, any>) => {
  await postRequest(`leads/${leadNotes.id}/updatelendernotes`, leadNotes, {
    ...handlers,
    successful: () => handlers.successful(leadNotes.id)
  })
  return leadNotes.id
}

export const saveLeadStage = async (leadStage: InputLeadStage, handlers: ResponseHandlers<any, any>) => {
  let mappedStage = Store.store.getState().leads.leadStatuses.find((v) => v.display == leadStage.stage);
  await postRequest(`leads/${leadStage.id}/updatestage`, {
    ...leadStage,
    stage: mappedStage ? mappedStage.id : leadStage.stage
  }, {
    ...handlers,
    successful: () => handlers.successful(leadStage.id)
  })
  return leadStage.id
}

/**
 * to update response level
 * @returns null if success
 */
export const UpdateResponseLevel = async (response: InputLeadEngagementLevel, handlers: ResponseHandlers<any, any>) => {
  let mappedStage = Store.store.getState().leads.engagementLevel.find((v) => v.display == response.engagement);

  await postRequest(`leads/${response.id}/updateLeadEngagementLevel`,
    {
      ...response,
      engagementLevel: mappedStage ? mappedStage.id : response.engagement
    }, {
    ...handlers,
    successful: () => handlers.successful(response.id)
  })
  return response.id
}

/**
 * to update response level
 * @returns null if success
 */
export const UpdateLeadContactStatus = async (response: InputLeadContactStatusLevel, handlers: ResponseHandlers<any, any>) => {
  let mappedStage = Store.store.getState().leads.leadStatus.find((v) => v.display == response.leadContactStatus);

  await postRequest(`leads/${response.id}/updateLeadStatusLevel`,
    {
      ...response,
      leadContactStatus: mappedStage ? mappedStage.id : response.leadContactStatus
    }, {
    ...handlers,
    successful: () => handlers.successful(response.id)
  })
  return response.id
}

/**
 * to update response level
 * @returns null if success
 */
export const UpdateLeadResponseStatusLevel = async (response: InputLeadResponseLevel, handlers: ResponseHandlers<any, any>) => {
  let leadResponseLevel = Store.store.getState().leads.responseLevel.find((v) => v.display == response.leadResponseLevel);

  await postRequest(`leads/${response.id}/updateLeadResponseLevel`,
    {
      ...response,
      leadContactStatus: leadResponseLevel ? leadResponseLevel.id : response.leadResponseLevel
    }, {
    ...handlers,
    successful: () => handlers.successful(response.id)
  })
  return response.id
}

export const saveLeadRole = async (leadRole: InputLeadRole, handlers: ResponseHandlers<any, any>) => {
  await postRequest(`leads/${leadRole.id}/updaterole`, leadRole, {
    ...handlers,
    successful: () => handlers.successful(leadRole.id)
  })
  return leadRole.id
}

export const saveLeadDetails = async (leadDetails: InputLeadDetails, handlers: ResponseHandlers<any, any>) => {
  let mappedResponseLevel = Store.store.getState().leads.responseLevels.find((v) => v.display == leadDetails.responseLevel);
  let mappedClosingBagOption = Store.store.getState().leads.closingBagOptions.find((v) => v.display == leadDetails.closingBagOption);
  await postRequest(`leads/${leadDetails.id}/updatedetails`, {
    ...leadDetails,
    responseLevel: mappedResponseLevel ? mappedResponseLevel.id : null,
    closingBagOption: mappedClosingBagOption ? mappedClosingBagOption.id : null
  }, {
    ...handlers,
    successful: () => handlers.successful(leadDetails.id)
  })
  return leadDetails.id
}

export const saveLeadSlideIn = async (leadSlideIn: InputLeadSlideIn, handlers: ResponseHandlers<any, any>) => {
  let mappedStage = Store.store.getState().leads.leadStatuses.find((v) => v.display == leadSlideIn.stage);

  let leadSlideInClone = {
    ...leadSlideIn,
    conciergeId: leadSlideIn.concierge?.id,
    agentId: leadSlideIn.agent?.id,
    stage: mappedStage ? mappedStage.id : null,
  }
  delete leadSlideInClone.concierge
  delete leadSlideInClone.agent
  await postRequest(`leads/${leadSlideIn.id}/updateslidein`, leadSlideInClone, {
    ...handlers,
    successful: () => handlers.successful(leadSlideIn.id)
  })
  return leadSlideIn.id
}

export const sendEmail = async (value: Email, handlers: ResponseHandlers<any, any>) => {
  await postRequest(`leads/send-email`, { ...value, emailFor: value.to }, handlers)
}

export const sendGroupEmail = async (value: Email, search: State['leads']['leadSearchCriteria'] & { status: string | null }, handlers: ResponseHandlers<any, any>) => {
  await postRequest(`leads/newsletter`, { email: value, criteria: search }, handlers)
}

/**
 * To send groupmail or selected users
 * @returns null if success
 */
export const sendemail = async (value: EmailUserContact, handlers: ResponseHandlers<any, any>) => {
  await postRequest(`leads/send-email`, value, handlers)
}

/**
 * To send sms to the user
 * @returns null if success
 */
export const sendSms = async (value: Sms, handlers: ResponseHandlers<any, any>) => {
  await postRequest(`leads/SendSMStoUser`, value, handlers)
}

/**
 * To get lender status
 * @returns null if success
 */
export const lenderstatus = async () => (
  await getRequest<string[]>('leads/getlenderstatus')
).data

/**
 * To get lender status
 * @returns null if success
 */
export const savelenderstatus = async (lenderstatus: LenderStatus, handlers: ResponseHandlers<any, any>) => {

  await postRequest(`leads/${lenderstatus.id}/updatelenderstatus`, lenderstatus, {
    ...handlers,
    successful: () => handlers.successful(lenderstatus.id)
  })
  return lenderstatus.id
}

/**
 * To Updating Homebot API
 * @returns null if success
 */
export const UpdateHomeBotsearch = async (response: Homebot, handlers: ResponseHandlers<any, any>) => {
  await postRequest(`leads/${response.id}/updateleadhomebot`, { ...response, }, { ...handlers, successful: () => handlers.successful(response.id) })
  return response.id
}

/**
 * To Update Pre-approved loan API
 * @returns null if success
 */
export const UpdatePreApproved = async (response: preapproved, handlers: ResponseHandlers<any, any>) => {
  await postRequest(`leads/${response.id}/updateleadpreapproved`, { ...response, }, { ...handlers, successful: () => handlers.successful(response.id) })
  return response.id
}

/**
 * To Updating Home Search API
 * @returns null if success
 */
export const UpdateHomeSearchSetup = async (response: Homesearch, handlers: ResponseHandlers<any, any>) => {
  await postRequest(`leads/${response.id}/updatehomesearchsetup`,
    {
      ...response,
    }, {
    ...handlers,
    successful: () => handlers.successful(response.id)
  })
  return response.id
}

/**
 * To Update last connected Agent
 * @returns null if success
 */
export const updateLastConnectedAgent = async (response: lastconnected, handlers: ResponseHandlers<any, any>) => {
  await postRequest(`leads/updatelastconnectedAgent`,
    {
      ...response,
    }, {
    ...handlers,
    successful: () => handlers.successful(response.id)
  })
  return response.id
}

/**
 * To Update last connected lender
 * @returns null if success
 */
export const updateLastConnectedLender = async (response: lastconnectedlender, handlers: ResponseHandlers<any, any>) => {
  await postRequest(`leads/updatelastconnectedlender`,
    {
      ...response,
    }, {
    ...handlers,
    successful: () => handlers.successful(response.id)
  })
  return response.id
}

/**
 * To Update last worked Agent
 * @returns null if success
 */
export const updateLastWorked = async (response: lastworked, handlers: ResponseHandlers<any, any>) => {
  await postRequest(`leads/updatelastworkedAgent`,
    {
      ...response,
    }, {
    ...handlers,
    successful: () => handlers.successful(response.id)
  })
  return response.id
}

/**
 * To update last worked lender
 * @returns null if success
 */
export const updateLastWorkedLender = async (response: lastworked, handlers: ResponseHandlers<any, any>) => {
  await postRequest(`leads/updatelastworkedlender`,
    {
      ...response,
    }, {
    ...handlers,
    successful: () => handlers.successful(response.id)
  })
  return response.id

}

/**
 * To assign lender
 * @returns null if success
 */
export const lenderAssign = async (response: assignlender, handlers: ResponseHandlers<any, any>) => {
  await postRequest(`leads/${response.id}/assignLender`,
    {
      "id": response.id,
      "lenderId": response.lenderId
    }, {
    ...handlers,
    successful: () => handlers.successful(response.id)
  })
  return response.id
}

/**
 * To assign agent
 * @returns null if success
 */
export const assignAgent = async (lead: string, user: { conciergeId: string | null, agentId: string | null }, handlers: ResponseHandlers<any, any>) => {
  await postRequest(`leads/${lead}/assign`, user, handlers)
}

/**
 * To assign multiple agents to multiple leads
 * @returns string "message": " updated successfully",
 */
export const assignMultipleLeads = async (user: any, ids: any[], handlers: ResponseHandlers<any, any>) => {
  let assigned_multipleagents = {
    "assignmultipleagents": ids.map(id => ({
      "lead_id": id,
      "assign_to": user?.assign_to
    }))
  };
  await postRequests(`AdminLead/AssignMultipleLead`, assigned_multipleagents, handlers);
};

/**
 * To assign multiple agents to multiple leads
 * @returns string "message": " updated successfully",
 */
export const getwalkscore = async (data: any) => { 
  await postRequests(`Property/GetWalkscore`, data, {
    successful: (v: any) => {
        data = {
          walData: v.data || ""
        }
    },
  }); 
  return data
};


/**
 * To assign multiple agents to multiple leads
 * @returns string "message": " updated successfully",
 */
export const savequizdetails = async (data: any) => { 
  await postRequests(`Property/SaveQuizDetails`, data, {
    successful: (v: any) => {
        data = {
          data: v.data || ""
        }
    },
  }); 
  return data
};

/**
 * To assign multiple agents to multiple leads
 * @returns string "message": " updated successfully",
 */
export const getnoiselevel = async (address: any) => { 
  const res = await getRequests(`Property/GetNoiseLevel?address=${address}`);
  return res
};

/**
 * To assign multiple agents to multiple leads
 * @returns string "message": " updated successfully",
 */
export const isquizzsubmitted = async (email: any) => { 
  const res = await getRequests(`Property/IsQuizSubmitted?Email=${email}`);
  return res
};

/**
 * To assign multiple agents to multiple leads
 * @returns string "message": " updated successfully",
 */
export const getOpenHouseListing = async (pageNumber: number) => { 
  const res = await getRequests(`Property/GetOpenHouseListing?pageNumber=${pageNumber}&pageSize=10`);
  return res
};

/**
 * To Updating Homebot API
 * @returns null if success
 */
export const getAssingedAgents = async (id: string) => (
  await getRequest<string[]>(`leads/agentdetails/${id}`)
).data

/**
 * To get property activity
 * @returns list of user activity
 */
export const getuserPropertyActivity = async (id: string,) => (
  await getRequests<string[]>(`Property/GetPropertyActivity?userid=${id}`)
).data

/**
 * To get favourite property of contact
 * @returns list of favorite property
 */
export const getFavoritePropertyList = async (id: string,) => (
  await getRequests<string[]>(`Property/GetFavoritePropertyList?userid=${id}`)
).data

export const savesLead = async (currentcontactdetials: InputCreateLead, handlers: ResponseHandlers<any, any>) => {
  let id = currentcontactdetials?.primaryContact.id ? currentcontactdetials?.primaryContact?.id : "";
  let mappedStage = Store.store.getState().leads.leadStatuses.find((v) => v.display == currentcontactdetials.stage);
  let mappedEngagementLevel = Store.store.getState().leads.engagementLevel.find((v) => v.display == currentcontactdetials.engagementLevel);
  let mappedResponseLevel = Store.store.getState().leads.responseLevel.find((v) => v.display == currentcontactdetials.responseLevel);
  let mappedstatusLevel = Store.store.getState().leads.leadStatus.find((v) => v.display == currentcontactdetials.leadStatus);
  let user = Store?.store?.getState().auth.user;
  let current_user = getUser(user?.id);
  let currentuser_role = {
    role : "",
    id : ""
  }
  let data = await current_user;
  currentuser_role = { role : data?.role?.role ? data?.role?.role : "" , id: data?.id};
  const currenttime = moment().utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';

  let mapRelationshipDisplayToId = (display: string | null): string | null => {
    var relationship = Store.store.getState().leads.relationships.find((v) => v.display == display);
    return (relationship) ? relationship.id : null;
  }

  let mapTShirtSizeToId = (display: string | null): string | null => {
    if (display === null)
      return null;
    var tShirtSize = Store.store.getState().leads.tShirtSizes.find((v) => v.display == display);
    return (tShirtSize) ? tShirtSize.id : null;
  }

  await postRequests('AdminLead/CreateLead', {
    ...currentcontactdetials,
    id: id,
    primaryContact: {
      ...currentcontactdetials.primaryContact,
      relationship: mapRelationshipDisplayToId(currentcontactdetials.primaryContact ? currentcontactdetials.primaryContact.relationship : ""),
      tShirtSize: mapTShirtSizeToId(currentcontactdetials.primaryContact ? currentcontactdetials.primaryContact.tShirtSize : "")
    },
    stage: mappedStage ? mappedStage.id : currentcontactdetials.stage,
    engagementLevel: mappedEngagementLevel ? mappedEngagementLevel.id : currentcontactdetials.engagementLevel,
    responseLevel: mappedResponseLevel ? mappedResponseLevel.id : currentcontactdetials.responseLevel,
    contactStatusLevel: mappedstatusLevel ? mappedstatusLevel.id : currentcontactdetials.leadStatus,
    subSource: currentcontactdetials.subSource ? currentcontactdetials.subSource : null,
    createdOn: currenttime ? currenttime : null,
    subSourceAgent: currentcontactdetials.subSourceAgent ? currentcontactdetials.subSourceAgent : null,
    concierge: currentcontactdetials.concierge ? { ...currentcontactdetials.concierge, photo: undefined } : currentcontactdetials.concierge,
    userid : currentuser_role?.role === 'agent' ? currentuser_role?.id : "" 
  }, {
    ...handlers,
    successful: () => handlers.successful(id)
  })
  return id
}