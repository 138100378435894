import React, { useState } from "react";
import { connect } from "react-redux";
import Navbar from "./Navbar";
import { ContactApi } from "../../api/idx";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./Footer";
import { successMessage } from "./Util/Toaster";
import { NewFooter } from "./Pages/NewFooter";
import NewContactUs from "./Pages/NewContactUs";

export interface Contactus {
  first_name: string | null;
  last_name: string | null;
  phone: string | null;
  email: string | null;
  message: string | null;
}

const ContactUs = () => {

  const notify = () => toast("Your  Contact successfully!");

  const [contact, setContact] = useState<Contactus>({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    message: "",
  });

  const [validation, setValidation] = useState<Contactus>({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    message: "",
  });

  const checkValidation = () => {
    let errors = JSON.parse(JSON.stringify(validation));

    if (contact.first_name === null) {
      errors.first_name = "First Name is required";
    } else {
      switch (true) {
        case !contact.first_name.trim():
          errors.first_name = "First Name is required";
          break;
        case !/^[a-zA-Z]+$/.test(contact.first_name):
          errors.first_name = "First Name can only contain letters";
          break;
        case contact.first_name.length < 2:
          errors.first_name = "First Name must be at least 2 characters";
          break;
        case contact.first_name.length > 25:
          errors.first_name = "First Name cannot exceed 25 characters";
          break;
        default:
          errors.first_name = "";
      }
    }

    // Last Name validation
    if (contact.last_name === null) {
      errors.last_name = "Last Name is required";
    } else {
      switch (true) {
        case !contact.last_name.trim():
          errors.last_name = "Last Name is required";
          break;
        case !/^[a-zA-Z]+$/.test(contact.last_name):
          errors.last_name = "Last Name can only contain letters";
          break;
        case contact.last_name.length < 2:
          errors.last_name = "Last Name must be at least 2 characters";
          break;
        case contact.last_name.length > 25:
          errors.last_name = "Last Name cannot exceed 25 characters";
          break;
        default:
          errors.last_name = "";
      }
    }

    // Email validation
    if (contact.email === null) {
      errors.email = "Email is required";
    } else {
      const emailCond = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      switch (true) {
        case !contact.email.trim():
          errors.email = "Email is required";
          break;
        case !emailCond.test(contact.email):
          errors.email = "Please enter a valid email address";
          break;
        default:
          errors.email = "";
      }
    }
    // phone number validation

    if (contact.phone === null) {
      errors.email = "Phone number is required";
    } else {
      switch (true) {
        case !contact.phone.trim():
          errors.phone = "Contact Number is required";
          break;
        case !/^\d+$/.test(contact.phone):
          errors.phone = "Contact number can only contain numbers";
          break;
        case contact.phone.length !== 10:
          errors.phone = "Contact number must be 10 digits";
          break;
        default:
          errors.phone = "";
      }
    }

    return errors;
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  const handleSubmit = async () => {
    let validations = await checkValidation();
    if (Object.values(validations).every((value) => value === "")) {
      let res = await ContactApi(contact);
      notify()
      switch (res) {
        case "Your  Contact successfully":
          successMessage("Your request has been submitted succesfully")
          notify()
          setValidation(() => ({
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            message: "",
          }));
          setContact({
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            message: "",
          });
          break;
      }
    } else {
      setValidation((prevState) => ({
        ...prevState,
        first_name: validations.first_name,
        last_name: validations.last_name,
        email: validations.email,
        phone: validations.phone,
        message: validations.message,
      }));
    }
  };

  return (
    <div>
      <Navbar />

      <div className="login-bg signup-bg pt-5">
          <NewContactUs />
        {/* <div className="container">
          <div className="row">
            <div className="col-md-12 pl-md-0 pl-lg-5">
              <div className="col-md-12">
                <h1 className="signup-heading">Get in Touch</h1>
              </div>
              <div className="card overflow-hidden">
                <div className="card-body py-0">
                  <div className="row">
                    <div className="col py-md-5 pb-5 login-btn">
                      <section className="signup">
                        <div className="row">
                          <div className="col-lg-12 col-xl-12">
                            <div className="text-black">
                              <form>
                                <div className="row mb-4">
                                  <div className="col-md-6 pl-3 mb-4">
                                    <div>
                                      <div className="form-outline flex-fill mb-0">
                                        <i className="fas fa-user fa-lg me-3 fa-fw"></i>
                                        <label
                                          className="form-label"
                                          htmlFor="form3Example1c"
                                        >
                                          First Name
                                        </label>
                                        <input
                                          type="text"
                                          name="first_name"
                                          className="form-control"
                                          placeholder="Enter Your First Name"
                                          onChange={(e: any) => handleChange(e)}
                                          value={
                                            contact.first_name
                                              ? contact.first_name
                                              : ""
                                          }
                                        />
                                      </div>
                                      <Validation state={validation.first_name} />
                                    </div>
                                  </div>
                                  <div className="col-md-6 mb-4">
                                    <div>
                                      <div className="form-outline flex-fill mb-0">
                                        <i className="fas fa-user fa-lg me-3 fa-fw"></i>
                                        <label
                                          className="form-label"
                                          htmlFor="form3Example1c"
                                        >
                                          Last Name
                                        </label>
                                        <input
                                          type="text"
                                          name="last_name"
                                          //   id="last_name"
                                          className="form-control"
                                          placeholder="Enter Your Last Name"
                                          onChange={(e) => handleChange(e)}
                                          value={
                                            contact.last_name
                                              ? contact.last_name
                                              : ""
                                          }
                                        />
                                      </div>
                                      <Validation state={validation.last_name} />
                                    </div>
                                  </div>
                                  <div className="col-md-6 mb-2 mb-md-4">
                                    <div className="d-flex flex-row align-items-center mb-4">
                                      <div className="form-outline flex-fill mb-0">
                                        <i className="fa-solid fa-phone fa-lg me-3 fa-fw"></i>
                                         <label
                                          className="form-label"
                                          htmlFor="form3Example3c"
                                        >
                                          Phone
                                        </label>
                                        <input
                                          type="Number"
                                          name="phone"
                                          className="form-control"
                                          placeholder="Enter Your Phone Number"
                                          onChange={(e) => handleChange(e)}
                                          value={
                                            contact.phone ? contact.phone : ""
                                          }
                                        />
                                      </div>
                                    </div>
                                    <Validation state={validation.phone} />
                                  </div>
                                  <div className="col-md-6 mb-2 mb-md-4">
                                    <div className="d-flex flex-row align-items-center mb-4">
                                      <div className="form-outline flex-fill mb-0">
                                        <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                                        <label
                                          className="form-label"
                                          htmlFor="form3Example3c"
                                        >
                                          Email
                                        </label>
                                        <input
                                          type="text"
                                          name="email"
                                          className="form-control"
                                          placeholder="Enter Your Email Address"
                                          onChange={(e) => handleChange(e)}
                                          value={
                                            contact.email ? contact.email : ""
                                          }
                                        />
                                      </div>
                                    </div>
                                    <Validation state={validation.email} />
                                  </div>
                                  <div className="col-md-12 mb-md-4">
                                    <div className="d-flex flex-row align-items-center mb-4">
                                      <div className="form-outline flex-fill mb-0">
                                        <i className="fas fa-envelope fa-lg me-3 fa-fw mt-2"></i>
                                        <label
                                          className="form-label"
                                          htmlFor="form3Example3c"
                                        >
                                          Message
                                        </label>
                                        <textarea
                                          rows={4}
                                          name="message"
                                          className="form-control"
                                          placeholder="Enter Your Message"
                                          onChange={(e) => handleChange(e)}
                                          value={
                                            contact.message
                                              ? contact.message
                                              : ""
                                          }
                                        ></textarea>
                                      </div>
                                    </div>
                                    <Validation state={validation.message} />
                                  </div>
                                </div>

                                <div className="d-flex justify-content-end mx-auto mb-4 mb-md-2 col-md-2">
                                  <Link
                                    to="#"
                                    type="submit"
                                    className="btn btn-lg signupBtn submitbtn"
                                    id="submit-button"
                                    onClick={() => handleSubmit()}
                                  >
                                    Submit
                                  </Link>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <NewFooter/>
    </div>
  );
};

export default connect(() => ({}))(ContactUs);

export const Validation = ({ state }: { state: any }) => {
  return (
    state && (
      <p style={{ color: "red" }}>
        {state}
      </p>
    )
  )
}
