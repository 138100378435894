import React, { useState, useEffect } from "react";
import accountingcalc from "../../../assets/icons/Accounting Calculator.svg";
import infoCircleIcon from "../../../assets/headerpage-icon/Information Circle.svg";
// import chart from "../../../assets/icons/chart.svg";  
import { Link } from "react-router-dom";
import Chart from "react-apexcharts"; 

export interface mortagetypes {
  homeprice: number;
  downpayment: number;
  interestrate: number;
  loantype: number;
}

export interface mortagestages {
  principalinterest: number;
  propertytax: number;
  homeinsurance: number;
  hoafees: number;
}
export interface mortagestagesEdit {
  principalinterest: boolean,
  propertytax: boolean,
  homeinsurance:boolean,
  hoafees:boolean, 
}

const MortgageCalculator = (props:any) => {
  const [mortagestages, setMortageStages] = useState<mortagestages>({
    principalinterest: 2944,
    propertytax: 860,
    homeinsurance: 241,
    hoafees: 10,
  });
  const [mortagetypes, setMortageTypes] = useState<mortagetypes>({
    homeprice: props.salePrice,
    downpayment: 10,
    interestrate: 10,
    loantype: 2,
  });
  const [inputEdit, setInputEdit] = useState({
    homePrice: false,
    downPayment: false,
    interestRate:false,
    loanType:false, 
  })
  
  const [mortagestagesEdit, setMortagestagesEdit] = useState<mortagestagesEdit>({
    principalinterest: false,
    propertytax: false,
    homeinsurance:false,
    hoafees:false, 
  })
  // const [monthlypayment, setmonthlyPayment] = useState<number>();
  const [monthlypayment, setmonthlyPayment] = useState<string>();
  const [chartWidth, setchartWidth] = useState<string>('500');
  const calculateMortgagestages = () => {
    if (mortagetypes.homeprice && mortagetypes.downpayment && mortagetypes.interestrate && mortagetypes.loantype) {
      const downPayment = (mortagetypes.homeprice * mortagetypes.downpayment) / 100;
      const loanAmountNeeded = mortagetypes.homeprice - downPayment;
      const monthlyInterestRate =  mortagetypes.interestrate / 100 / 12;
      const numberOfPayments = mortagetypes.loantype * 12;
      const propertyTaxPerYear = Number((mortagestages.propertytax / 12).toFixed(2));
      const annualMortgageInsuranceCost = mortagestages.homeinsurance / 12;
      const annualHoaFeesCost = mortagestages.hoafees / 12;
      const totalAnnualCosts = propertyTaxPerYear + annualMortgageInsuranceCost + annualHoaFeesCost;
      const principleAndInterest = loanAmountNeeded * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments) / (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);
    
 
      mortagestages.principalinterest = principleAndInterest
      setmonthlyPayment((principleAndInterest + totalAnnualCosts).toFixed(2)); 
    }
  }
  // useEffect(() => {
  //   async function handleCalculate() {
  //     let amountBorrowed = mortagetypes?.homeprice - mortagetypes?.downpayment;
  //     let final_data = calculateMortgage(
  //       amountBorrowed,
  //       mortagetypes?.interestrate,
  //       mortagetypes?.loantype
  //     );
  //     setmonthlyPayment(final_data);
  //   }
  //   handleCalculate();
  // }, [mortagetypes]);

  useEffect(() => {
    calculateMortgagestages();

  }, [mortagestages, mortagetypes]);

  const handleChangeMortageTypes = (e: any) => {
    const { name, value } = e.target;
    if(isNaN(value)){
      return 
    }
    if(name==="downpayment" || name==="interestrate"){
      if(Number(value)>100){
        return 
      }
    }
    setMortageTypes({ ...mortagetypes, [name]: Number(value)  });
  };
  const handleChangeMortageStatges = (e: any) => {
    const { name, value } = e.target;
    if(isNaN(value)){
      return 
    }
    setMortageStages({ ...mortagestages, [name]: Number(value) });

    // const regex = /^[0-9]+$/; 
    // if (regex.test(value)) {
    
    //   console.log("Input is a valid number.");
    // } else {
    //   console.log("Input is not a valid number.");
    // }
   
   

  
   
  };

  // const calculateMortgage = (p: any, r: any, n: any) => {
  //   r = percentToDecimal(r);
  //   n = yearsToMonths(n);
  //   const pmt = (r * p) / (1 - Math.pow(1 + r, -n));
  //   return parseFloat(pmt.toFixed(2));
  // };

  // const percentToDecimal = (percent: any) => {
  //   return percent / 12 / 100;
  // };

  // const yearsToMonths = (years: any) => {
  //   return years * 12;
  // };
 
    useEffect(() => {
      if(window.innerWidth <= 1366) {
        setchartWidth('400')
      }
      if(window.innerWidth <= 1024) {
        setchartWidth('380')
      }
      if(window.innerWidth <= 992) {
        setchartWidth('400')
      }
      if (window.innerWidth <= 576) {
        setchartWidth('320')
      }
      if (window.innerWidth <= 460) {
        setchartWidth('300')
      }
    }, [])

  const state = {
    options: {
      colors: ['#AB94E0', '#3B0082', '#A49EB2', '#000000'],
      labels: ['Principal Interest', 'Property Tax', 'Home Insurance', 'HOA Fees']
    },
    series: [mortagestages.principalinterest, mortagestages.propertytax ,mortagestages.homeinsurance ,mortagestages.hoafees],
     
  };  

  return (
    <>
     
      <div className="paymentgraph">
        <div className="row">
          <div className="siderow mt-5">
            <div className="col-md-12">
              <h1 className="property-overview-heading mb-2">
                Payment
              </h1>
              <span className="d-flex mb-5">
                <img src={accountingcalc} /> 
                <span className="pricehead">${monthlypayment} per month</span>
              </span> 
              {/* <img src={chart} className="chartimg" /> */} 
            <div className="d-flex justify-content-center">
            <Chart options={state.options} series={state.series} type="donut" width={chartWidth} />
            </div>
              <div className="chartcalc mt-5">
                <ul>
                  <li>
                    <span>Principal & Interest</span>
                    <span className="payment-price">
                      <strong>${mortagestages.principalinterest?.toFixed(2)}</strong>
                    </span> 
                  </li>
                  <li>
                    <span>Property Tax</span>
                    {mortagestagesEdit?.propertytax ?
                    <input
                      type="text"
                      className='form-control text-black'
                      value={mortagestages?.propertytax}
                      name="propertytax"
                      onChange={(e) => handleChangeMortageStatges(e)}
                    />
                    :
                    <span className="payment-price">
                      <strong>${mortagestages.propertytax}</strong>
                    </span>
                    } 
                   
                      <a onClick={() => setMortagestagesEdit({...mortagestagesEdit, propertytax:!mortagestagesEdit.propertytax})}>
                      {mortagestagesEdit?.propertytax ? 

 
<i className="fas fa-save"></i>
:

<i className="fa-solid fa-pencil"></i>
                      }  
                      </a> 
                  </li>
                  <li>
                    <span>Home Insurance</span>
                    {mortagestagesEdit?.homeinsurance ?
                    <input
                     type="text"
                      className='form-control text-black'
                      value={
                        mortagestages.homeinsurance
                      }
                      name="homeinsurance"
                      onChange={(e) => handleChangeMortageStatges(e)}
                    />
                    :
                    <span className="payment-price">
                      <strong>${mortagestages.homeinsurance}</strong>
                    </span>
                    } 
                   
                      <a onClick={() => setMortagestagesEdit({...mortagestagesEdit, homeinsurance:!mortagestagesEdit.homeinsurance})}>
                      {mortagestagesEdit?.homeinsurance ? 

 
<i className="fas fa-save"></i>
:

<i className="fa-solid fa-pencil"></i>
                      }  
                      </a> 
                  </li>
                  <li>
                    <span>HOA Fees</span>
                    {mortagestagesEdit?.hoafees ?
                    <input
                     type="text"
                      className='form-control text-black'
                      value={
                        mortagestages.hoafees
                      }
                      name="hoafees"
                      onChange={(e) => handleChangeMortageStatges(e)}
                    />
                    :
                    <span className="payment-price">
                      <strong>${mortagestages.hoafees}</strong>
                    </span>
                    } 
                   
                      <a onClick={() => setMortagestagesEdit({...mortagestagesEdit, hoafees:!mortagestagesEdit.hoafees})}>
                      {mortagestagesEdit?.hoafees ? 

 
<i className="fas fa-save"></i>
:

<i className="fa-solid fa-pencil"></i>
                      }  
                      </a> 
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="siderow pricesection">
          <div className="row">
            <div className="col-md-6">
              <div className="pricediv">
                <div className="mydiv">
                  <span>Home Price</span> 
                  {inputEdit?.homePrice ?
                    <input
                      type="text"
                      className='form-control text-black'
                      value={
                        mortagetypes.homeprice
                      }
                      name="homeprice"
                      onChange={(e) => handleChangeMortageTypes(e)}
                    />
                    :
                    <span>  ${mortagetypes.homeprice ? mortagetypes.homeprice : ""}</span>
                    } 
                  <span className="mortEdit" onClick={() => setInputEdit({...inputEdit, homePrice:!inputEdit.homePrice})}>
                  {inputEdit?.homePrice ? 
                  <a href="#." onClick={calculateMortgagestages}>SAVE</a> 
                    :
                   <>
                    <a href="#.">EDIT</a>
                    <i className="fa-solid fa-pencil"></i>
                   </>
                  }
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="pricediv">
                <div className="mydiv">
                  <span>Down Payment</span> 
                  {inputEdit?.downPayment ?
                  <input
                       type="text"
                      className='form-control text-black'
                      value={
                        mortagetypes.downpayment 
                      }
                      name="downpayment"
                      onChange={(e) => handleChangeMortageTypes(e)}
                    />
                    :
                    <span>  {mortagetypes.downpayment ? mortagetypes.downpayment : ""}%</span>
                      }
                  <span className="mortEdit" onClick={() => setInputEdit({...inputEdit, downPayment:!inputEdit.downPayment})}>
                  {inputEdit?.downPayment ? 
                  <a href="#.">SAVE</a> 
                    :
                   <>
                    <a href="#.">EDIT</a>
                    <i className="fa-solid fa-pencil"></i>
                   </>
                  }
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="pricediv">
                <div className="mydiv">
                  <span>Interest Rate</span> 
                  {inputEdit?.interestRate ?
                  <input
                      type="text"
                      className='form-control text-black'
                      value={
                        mortagetypes.interestrate
                      }
                      name="interestrate"
                      onChange={(e) => handleChangeMortageTypes(e)}
                    />
                    :
                    <span>  {mortagetypes.interestrate ? mortagetypes.interestrate : ""}%</span>
                      }
                  <span className="mortEdit" onClick={() => setInputEdit({...inputEdit, interestRate:!inputEdit.interestRate})}>
                  {inputEdit?.interestRate ? 
                  <a href="#.">SAVE</a> 
                    :
                   <>
                    <a href="#.">EDIT</a>
                    <i className="fa-solid fa-pencil"></i>
                   </>
                  }
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="pricediv">
                <div className="mydiv">
                  <span>Loan Type</span>
                  {inputEdit?.loanType ?
                  <input
                      type="text"
                      className='form-control text-black'
                      value={mortagetypes.loantype}
                      name="loantype"
                      onChange={(e) => handleChangeMortageTypes(e)}
                    />
                    :
                    <span>  {mortagetypes.loantype ? mortagetypes.loantype : ""}-Yr Fixed</span>
                      } 
                  <span className="mortEdit" onClick={() => setInputEdit({...inputEdit, loanType:!inputEdit.loanType})}>
                  {inputEdit?.loanType ? 
                  <a href="#.">SAVE</a> 
                    :
                   <>
                    <a href="#.">EDIT</a>
                    <i className="fa-solid fa-pencil"></i>
                   </>
                  }
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="duesection">
            <span>Total Due</span>
            <span>$134,000 due at close</span>
            <span>20% down, 5% closing costs</span>
            <span>
              Apply veterans benefits
              <img src={infoCircleIcon} className="ml-3" alt="" />
              {/* <i className="fa-solid fa-circle-info ml-3"></i> */}
            </span>
          </div>
          <div className="pb-5">
          <Link to="/contactus">
            {" "}
            <button className="btn btnSchedule mt-5">Get pre-approved</button>
          </Link> 
          </div>
        </div>
      </div>
    </>
  );
};

export default MortgageCalculator;


