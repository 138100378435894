import React from 'react'

import { ICON_KIND_LOOKUP } from './components/Icon'

import { User } from './types'
import { Permission } from './types/permissions'
import Useraccess from './views/Useraccess/Useraccess'
import { userPermissionRole } from './util/UserPermissionsRole'
import HomeWarranty from './views/Transaction/HomeWarranty'
// import IncompleteOffer from './views/Transaction/Components/IncompleteOffer'
import Maps from './views/IDX/Components/Maps'

const Leads = React.lazy(() => import('./views/leads/Leads'))
const UserManagement = React.lazy(() => import('./views/Users/UserTable'))
const UserDetail = React.lazy(() => import('./views/Users/UserManagement'))
const Components = React.lazy(() => import('./views/Components'))
const NewLeads = React.lazy(() => import('./views/NewLeads'))
const Dashboard = React.lazy(() => import('./views/Dashboard'))
const ChangeLog = React.lazy(() => import('./views/ChangeLog'))
const Forbidden = React.lazy(() => import('./views/errors/Forbidden'))
const NotFound = React.lazy(() => import('./views/errors/NotFound'))
const ClaimLead = React.lazy(() => import('./views/leads/Claim'))
const LeadsTable = React.lazy(() => import('./views/leads/LeadsTable'))
const Transaction = React.lazy(() => import('./views/Transaction/Transaction'))
const TransactionDetails = React.lazy(() => import('./views/Transaction/TransactionDetails'))
const Createnewpassword = React.lazy(() => import('./views/IDX/Createnewpassword'))
const VerifyIdentity = React.lazy(() => import('./views/IDX/Components/VerifyIdentity'))
const VerifyPhone = React.lazy(() => import('./views/IDX/Components/VerifyPhone'))
const SingleProduct = React.lazy(() => import('./views/IDX/Components/SingleProduct'))
const Favorites = React.lazy(() => import('./views/IDX/Components/Favourites'))
const Dislikes = React.lazy(() => import('./views/IDX/Components/DislikesList'))
const RecentlyViewed = React.lazy(() => import('./views/IDX/Components/RecentlyViewed'))
const Accounts = React.lazy(() => import('./views/IDX/Components/Account'))
const AccountSettings = React.lazy(() => import('./views/IDX/Components/AccountSetting'))
const Team = React.lazy(() => import('./views/IDX/Components/Team'))
const Profile = React.lazy(() => import('./views/IDX/Components/Profile'))
const SavedList = React.lazy(() => import('./views/IDX/Components/SavedLists'))
const Home = React.lazy(() => import('./views/IDX/Home'))
const savedlistitems = React.lazy(() => import('./views/IDX/Components/SavedListItems'))
const Refferal = React.lazy(() => import('./views/IDX/Components/Reffral'))
const SubmitReferals = React.lazy(() => import('./views/IDX/Components/SubmitReferals'))
const Teamprofile = React.lazy(() => import('./views/IDX/Components/Teamprofile'))
const MyReffral = React.lazy(() => import('./views/IDX/Components/MyReffral'))
const Messages = React.lazy(() => import('./views/IDX/Components/Messages'))
const ComposeNewMessages = React.lazy(() => import('./views/IDX/Components/ComposeNewMessages'))
const ChatView = React.lazy(() => import('./views/IDX/Components/ChatView'))
const DashboardMain = React.lazy(() => import('./views/Transaction/DashboardMain'))
const Mission = React.lazy(() => import('./views/Mission'))
const NewTransaction = React.lazy(() => import('./views/Transaction/NewTransaction'))
const EsignDocuments = React.lazy(() => import('./views/Transaction/EsignDocuments'))
const ClaimTransaction = React.lazy(() => import('./views/Transaction/ClaimTransaction'))

const routing = () => {
  const userpermissionsrole = userPermissionRole();

// console.log(userpermissionsrole,"rgbwg");
  const route = [
    { permissions: [], path: '/', exact: true, name: 'Home', navItem: null },
    { permissions: [], path: '/forbidden', exact: true, name: 'Forbidden', navItem: null, component: Forbidden },
    { permissions: [], path: '/not-found', exact: true, name: 'NotFound', navItem: null, component: NotFound },
    {
      permissions: [],
      path: '/leads',
      name: 'Contacts',
      component: LeadsTable,
      navItem: {
        _tag: 'SidebarItem',
        name: 'Contacts',
        icon: 'cil-address-book',
      }
    },
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "unspecified") === true) ? [] : ['Unspecified'],
      path: '/leads/unspecified',
      name: 'Unspecified',
      component: LeadsTable,
      navItem: {
        addLinkClass: 'sub-nav-item',
        _tag: 'SidebarItem',
        name: 'Unspecified',

      }
    },
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "suspects") === true) ? [] : ['Suspects'],
      path: '/leads/suspect',
      name: 'Suspects',
      component: LeadsTable,
      navItem: {
        addLinkClass: 'sub-nav-item',
        _tag: 'SidebarItem',
        name: 'Suspects',

      }
    },
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "prospects") === true) ? [] : ['Prospects'],
      path: '/leads/prospect',
      name: 'Prospects',
      component: LeadsTable,
      navItem: {
        addLinkClass: 'sub-nav-item',
        _tag: 'SidebarItem',
        name: 'Prospects',

      }
    },
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "leads") === true) ? [] : ['Leads'],
      path: '/leads/lead',
      name: 'Leads',
      component: LeadsTable,
      navItem: {
        addLinkClass: 'sub-nav-item',
        _tag: 'SidebarItem',
        name: 'Leads',
      }
    },
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "future") === true) ? [] : ['Future'],
      path: '/leads/future',
      name: 'Future',
      component: LeadsTable,
      navItem: {
        addLinkClass: 'sub-nav-item',
        _tag: 'SidebarItem',
        name: 'Future',
      }
    },
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "clients") === true) ? [] : ['Clients'],
      path: '/leads/client',
      name: 'Clients',
      component: LeadsTable,
      navItem: {
        addLinkClass: 'sub-nav-item',
        _tag: 'SidebarItem',
        name: 'Clients',
      }
    },
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "clientdonotcontact") === true) ? [] : ["Client - Do Not Contact"],
      path: '/leads/client-donotcontact',
      name: 'Client - Do Not Contact ',
      component: LeadsTable,
      navItem: {
        addLinkClass: 'sub-nav-item',
        _tag: 'SidebarItem',
        name: 'Client - Do Not Contact',
      }
    },
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "pastclients") === true) ? [] : ["Past Clients"],
      path: '/leads/past-client',
      name: 'Past Clients',
      component: LeadsTable,
      navItem: {
        addLinkClass: 'sub-nav-item',
        _tag: 'SidebarItem',
        name: 'Past Clients',
      }
    },
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "oldleadsupdated") === true) ? [] : ["Past Client - Updated"],
      path: '/leads/past-client-updated',
      name: 'Past Client - Updated',
      component: LeadsTable,
      navItem: {
        addLinkClass: 'sub-nav-item',
        _tag: 'SidebarItem',
        name: 'Past Client - Updated',
      }
    },
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "pastclientsupdated") === true) ? [] : ["Past Clients - Updated"],
      path: '/leads/past-client-updated',
      name: 'Past Clients - Updated',
      component: LeadsTable,
      navItem: {
        addLinkClass: 'sub-nav-item',
        _tag: 'SidebarItem',
        name: 'Past Clients - Updated',
      }
    },
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "lostclients") === true) ? [] : ["Lost Clients"],
      path: '/leads/lost',
      name: 'Lost Clients',
      component: LeadsTable,
      navItem: {
        addLinkClass: 'sub-nav-item',
        _tag: 'SidebarItem',
        name: 'Lost Clients',
      }
    },
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "lostleads") === true) ? [] : ["Lost Leads"],
      path: '/leads/lost-leads',
      name: 'Lost Leads',
      component: LeadsTable,
      navItem: {
        addLinkClass: 'sub-nav-item',
        _tag: 'SidebarItem',
        name: 'Lost Leads',
      }
    },
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "trashleads") === true) ? [] : ["Trash Leads"],
      path: '/leads/trash-lead',
      name: 'Trash Leads',
      component: LeadsTable,
      navItem: {
        addLinkClass: 'sub-nav-item',
        _tag: 'SidebarItem',
        name: 'Trash Leads',
      }
    },
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "oldleads") === true) ? [] : ["Old Lead"],
      path: '/leads/old-lead',
      name: 'Old Lead',
      component: LeadsTable,
      navItem: {
        addLinkClass: 'sub-nav-item',
        _tag: 'SidebarItem',
        name: 'Old Lead',
      }
    },
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "claimleads") === true) ? [] : ["Claim Leads"],
      path: '/leads/claim-lead',
      name: 'Claim Leads',
      component: LeadsTable,
      navItem: {
        addLinkClass: 'sub-nav-item',
        _tag: 'SidebarItem',
        name: 'Claim Leads',
      }
    },
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "creditrepair") === true) ? [] : ["Credit Repair"],
      path: '/leads/credit-repair',
      name: 'Credit Repair',
      component: LeadsTable,
      navItem: {
        addLinkClass: 'sub-nav-item',
        _tag: 'SidebarItem',
        name: 'Credit Repair',
      }
    },
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "investor") === true) ? [] : ["Investor"],
      path: '/leads/investor',
      name: 'Investor',
      component: LeadsTable,
      navItem: {
        addLinkClass: 'sub-nav-item',
        _tag: 'SidebarItem',
        name: 'Investor',
      }
    },
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "nonresponsive") === true) ? [] : ["Non Responsive"],
      path: '/leads/non-responsive',
      name: 'Non Responsive ',
      component: LeadsTable,
      navItem: {
        addLinkClass: 'sub-nav-item',
        _tag: 'SidebarItem',
        name: 'Non Responsive',
      }
    },
    { permissions: [], path: '/leads/:id', name: 'Lead', component: Leads, navItem: null },
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "usermanagement") === true) ? [] : ["usermanagement"],
      path: '/usermanagement',
      name: 'UserManagement',
      component: UserManagement,
      navItem: {
        _tag: 'SidebarItem',
        name: 'User Management',
        icon: 'cil-group',
      }
    },
    { permissions: [], path: '/users/:id', name: 'User', component: UserDetail, navItem: null },
    { permissions: [], path: '/components', name: 'Components', component: Components, navItem: null },
    { permissions: [], path: '/newleads', name: 'NewLeads', component: NewLeads, navItem: null },
    { permissions: [], path: '/dashboard', name: 'Dashboard', component: Dashboard, navItem: null },
    { permissions: [], path: '/change-log', name: 'ChangeLog', component: ChangeLog, navItem: null },

    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "e-sign") === true) ? [] : ['e-sign'],
       path: '/esign', name: 'ChangeLog', component: EsignDocuments, navItem: {
      _tag: 'SidebarItem',
      name: 'E-Sign',
      icon: { content: ICON_KIND_LOOKUP.Permission},
    } },

    {
      permissions:   (userpermissionsrole?.some((permission) => permission.id === "userpermissions") === true) ? [] : ["userpermissions"],
      path: '/useraccess',
      name: 'User Permissions',
      component: Useraccess,
      navItem: {
        _tag: 'SidebarItem',
        name: 'User Permissions',
        icon: { content: ICON_KIND_LOOKUP.Permission},
      }
    },
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "claimcontact") === true) ? [] : ["claimcontact"],
      path: '/claim-leads',
      name: 'ClaimLeads',
      component: ClaimLead,
      navItem: {
        _tag: 'SidebarItem',
        name: 'Claim Contact',
        icon: { content: ICON_KIND_LOOKUP.Handshake },
      }
    },
    {
      permissions: [],
      path: '/transaction-details',
      name: 'TransactionDetails',
      component: TransactionDetails,
      //   navItem: {
      //     _tag:'SidebarItem',
      //     name:'Transaction Details',
      //     icon:{ content:ICON_KIND_LOOKUP.Handshake },
      //   }
    },
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "transaction") === true) ? [] : ["transaction"],
      path: '/dashboardmain',
      name: 'Transaction',
      component: DashboardMain,
        navItem: {
          _tag:'SidebarItem',
          name:'Transaction',
          icon:{ content:ICON_KIND_LOOKUP.Dashboard },
        }
    },

    {
      permissions: [],
      path: '/transaction/:id',
      name: 'New Transaction',
      component: NewTransaction,
    },

  

    // {
    //   permissions: (userpermissionsrole?.some((permission) => permission.id === "investor") === true) ? [] : ["Investor"],
    //   path: '/newtransaction/homewarranty',
    //   name: 'Home Waranty',
    //   component: HomeWarranty,
    //   navItem: {
    //     addLinkClass: 'sub-nav-item',
    //     _tag: 'SidebarItem',
    //     name: 'Home Waranty',
        
    //   }
    // },
    //    {
    //   permissions: (userpermissionsrole?.some((permission) => permission.id === "investor") === true) ? [] : ["Investor"],
    //   path: '/newtransaction/inspectioncompany',
    //   name: 'Inspection Company',
    //   component: HomeWarranty,
    //   navItem: {
    //     addLinkClass: 'sub-nav-item',
    //     _tag: 'SidebarItem',
    //     name: 'Inspection Company',
    //   }
    // },

    //       {
    //   permissions: (userpermissionsrole?.some((permission) => permission.id === "investor") === true) ? [] : ["Investor"],
    //   path: '/newtransaction/termitecompany',
    //   name: 'Termite Company',
    //   component: HomeWarranty,
    //   navItem: {
    //     addLinkClass: 'sub-nav-item',
    //     _tag: 'SidebarItem',
    //     name: 'Termite Company',
    //   }
    // },
    //       {
    //   permissions: (userpermissionsrole?.some((permission) => permission.id === "investor") === true) ? [] : ["Investor"],
    //   path: '/newtransaction/escrowagent',
    //   name: 'Escrow Agent',
    //   component: HomeWarranty,
    //   navItem: {
    //     addLinkClass: 'sub-nav-item',
    //     _tag: 'SidebarItem',
    //     name: 'Escrow Agent',
    //   }
    // },
    //       {
    //   permissions: (userpermissionsrole?.some((permission) => permission.id === "investor") === true) ? [] : ["inprogress"],
    //   path: '/newtransaction/incompleteOffer',
    //   name: 'Incomplete Offer',
    //   component: IncompleteOffer,
    //   navItem: {
    //     addLinkClass: 'sub-nav-item',
    //     _tag: 'SidebarItem',
    //     name: 'Incomplete Offer',
    //   }
    // },

    {
      permissions:  (userpermissionsrole?.some((permission) => permission?.id === "mission") === true) ? [] : ["mission"],
      path: '/mission',
      name: 'Mission',
      component: Mission,
        navItem: {
          _tag:'SidebarItem',
          name:'Mission',
          icon:{ content:ICON_KIND_LOOKUP.Skyliner },
        }
    },

    //   {
    //   permissions: [],
    //   path: '/newtransaction',
    //   name: 'New Transaction',
    //   component: NewTransaction,
    //     navItem: {
    //       _tag:'SidebarItem',
    //       name:'New Transaction',
    //       icon:{ content:ICON_KIND_LOOKUP.Skyliner },
    //     }
    // },
    
    {
      permissions: (userpermissionsrole?.some((permission) => permission.id === "claimtransaction") === true) ? [] : ["claimtransaction"],
      path: '/claim-transaction',
      name: 'Claim Transaction',
      component: ClaimTransaction,
        navItem: {
          _tag:'SidebarItem',
          name:'Claim Transaction',
          icon:{ content:ICON_KIND_LOOKUP.Skyliner },
        }
    },
    { permissions: [],path: '/createnewpassword',name: 'Createnewpassword',component: Createnewpassword,navItem: null },
    { permissions: [], path: '/verifyidentity',name: 'VerifyIdentity',component: VerifyIdentity,navItem: null},
    { permissions: [],path: '/singleproduct',name: 'SingleProduct',component: SingleProduct,navItem: null},
    { permissions: [],path: '/Maps',name: 'commute',component: Maps,navItem: null},

    { permissions: [],path: '/property-list',name: 'Profile',component: Home,navItem: null},
    { permissions: [],path: '/property-list/clientprofile',name: 'Profile',component: Profile,navItem: null},
    { permissions: [],path: '/property-list/favourites',name: 'Favourites',component: Favorites,navItem: null},
    { permissions: [],path: '/property-list/dislike',name: 'dislke',component: Dislikes,navItem: null},
    { permissions: [],path: '/property-list/recentlyviewed',name: 'recentlyviewed',component: RecentlyViewed,navItem: null},
    { permissions: [],path: '/property-list/account',name: 'account',component: Accounts,navItem: null},
    { permissions: [],path: '/property-list/accountsetting',name: 'accountsettings',component: AccountSettings,navItem: null},
    { permissions: [],path: '/property-list/yourteam',name: 'your team',component: Team,navItem: null},
    { permissions: [],path: '/property-list/savedlists',name: 'saved list',component: SavedList,navItem: null},
    { permissions: [],path: '/property-list/savedlists/items',name: 'saved list items',component: savedlistitems,navItem: null},
    { permissions: [],path: '/property-list/refferal',name: 'Profile',component: Refferal,navItem: null},
    { permissions: [],path: '/property-list/submitreferal',name: 'Profile',component: SubmitReferals,navItem: null},
    { permissions: [],path: '/property-list/teamprofile',name: 'Profile',component: Teamprofile,navItem: null},
    { permissions: [],path: '/property-list/myreferal',name: 'Profile',component: MyReffral,navItem: null},
    { permissions: [],path: '/property-list/messages',name: 'Profile',component: Messages,navItem: null},
    { permissions: [],path: '/property-list/composenewmessages',name: 'Profile',component: ComposeNewMessages,navItem: null},
    { permissions: [],path: '/property-list/chatview',name: 'Profile',component: ChatView,navItem: null},
  ]
  return route
}

export const getNavigation = () => {

  let userpermissionsroledata = userPermissionRole();
  let routedata = routing();

  return routedata.filter((v) => v.navItem)
    .filter((v) => v.permissions?.length === 0 || v.permissions?.find((x) => userpermissionsroledata && userpermissionsroledata?.find((v) => v?.permission === x)))
    .map((v) => ({ ...v.navItem, to: v.path }))
}

let routes = routing();

export default routes    
