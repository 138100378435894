import React, { useEffect, useState } from "react";
import { propertyListing } from "../../../api/idx";
import Carousel from "react-multi-carousel";
import noimage from "../../../assets/icons/noimage.svg";
import { useHistory } from "react-router-dom";

export interface Filters {
  city: string | null;
  country: string | null;
  zip: string | null;
  number_of_beds: string | null;
  number_of_baths: string | null;
  propertytype: string | null;
  pricemin: string | null;
  pricemax: string | null;
  pageNo: number;
  pageSize: number;
}

type Props = {
  propertydetails: any;
};

const SimilarProperties: React.FC<Props> = (props) => {
  const [propertylisting, setPropertyListing] = useState<any>([]);
  const history = useHistory();
  const [handlefavoritelike, setHandleFavoriteLike] = useState<boolean>();
  const responsiveproduct = {
    desktop: {
      breakpoint: { max: 3000, min: 1366 },
      items: 1.5,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1366, min: 576 },
      items: 1.5,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  useEffect(() => {
    async function anonymous() {
      let custom_filter = {
        city: props?.propertydetails?.city,
        country: props?.propertydetails?.country,
        zip: props?.propertydetails?.zip,
        number_of_beds: props?.propertydetails?.number_of_beds,
        number_of_baths: props?.propertydetails?.number_of_baths,
        propertytype: props?.propertydetails?.propertytype,
        pricemin: props?.propertydetails?.pricemin,
        pricemax: props?.propertydetails?.pricemax,
        pageNo: 1,
        pageSize: 20,
      };
      // @ts-ignore
      let listing: any = await propertyListing(custom_filter);
      let filtered_array = listing?.value?.filter( (v:any) => v?.listingid !== props?.propertydetails?.listingid)
      setPropertyListing(filtered_array);
    }
    anonymous();
  }, []);

  const handleNavigation = (v:any) => {
    let path = v?.listingid;
    history.push(`/singleproduct/${path}`);
  };


  return (
    <>
      <h1 className="property-overview-heading">Similar Properties</h1>
      <Carousel
               swipeable={true}
               draggable={true}
               showDots={false}
               autoPlay={false}
               responsive={responsiveproduct}
               ssr={true} // means to render carousel on server-side.
               infinite={true}
               autoPlaySpeed={3000}
               keyBoardControl={true}
               customTransition="all .5"
               transitionDuration={500}
               containerClass="carousel-container"
               removeArrowOnDeviceType={["mobile"]}
               dotListClass="custom-dot-list-style"
               itemClass="carousel-item-padding-40-px"
               arrows={true}
              >

                
        { propertylisting?.map((v: any , index:any) => {
            return ( 
              <>
              <div>
              <div className="card productcard border-0 rounded-0 shadow">
                {/* <span className="wishlist">
                  <i className="fa-regular fa-heart wishlist-regular-icon"></i>
                </span> */}
              {/* {handlefavoritelike === true ? (
          <div onClick={(e) => handleFavorites(e)}>
            <span className="wishlist">
              <i
                className={
                  toggle === true
                    ? "fa-solid fa-heart wishlist-icon"
                    : "fa-regular fa-heart wishlist-regular-icon"
                }
              ></i>
            </span>
          </div>
        ) : null} */}
                  {v?.mediaList !== null ? (v?.mediaList ? (<img onClick={() => handleNavigation(v)} src={v?.mediaList[0]?.mediaurl} className="featured-idx-img" />) : ("")) : (<img src={noimage} onClick={() => handleNavigation(v)} className="featured-idx-img" />)}
                  <div className="align-items-center g-0">
                    <div className="card-body mt-3 mb-3">
                      <div className="content-row">
                        <div className="content-col-left">
                          {/* <div
                            className="card-address truncate-line"
                            data-testid="card-address"
                          >
                            <div
                              className="truncate-line"
                              data-testid="card-address-1"
                            >
                              <span className="cardaddress1">
                               {v?.city}
                              </span>
                            </div>
                            <div
                              className="truncate-line"
                              data-testid="card-address-2"
                            >
                              {v?.city}, &nbsp;
                              {v?.state} &nbsp;
                              {v?.zip}
                            </div>
                          </div> */}
                          {v?.street_address && (
                      <>
                        <span className="street-name">
                          {v?.street_address.split(",")[0]}
                        </span>

                        <div
                          className="truncate-line"
                          data-testid="card-address-2"
                        >
                          {v?.city}, &nbsp;
                          {v?.state} &nbsp;
                          {v?.zip}
                        </div>
                      </>
                    )}
                        </div>
                        <div className="content-col-right">
                          <h1 className="card-price">{new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(v?.price)}</h1>
                        </div>
                      </div>

                      <div className="cHVLag">
                        <ul>
                          <li>
                            <span>{v?.number_of_beds ? v?.number_of_beds : 0 }</span>bed
                          </li>
                          <li>
                            <span>{v?.number_of_baths ? v?.number_of_baths : 0}</span>bath
                          </li>
                          <li>
                            <span>{v?.buildingareatotal ? new Intl.NumberFormat("en-IN", {
                              maximumSignificantDigits: 3,
                               }).format(v?.buildingareatotal) : 0}</span>sqft
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
               </>
             );
            })}
            </Carousel>                
    </>
    
  );
};

export default SimilarProperties;
