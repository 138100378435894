import useToggle from '../../../../hooks/toggle';
import '../externaldoc.scss'
import React, { useEffect, useRef, useState } from "react";
import { selecteddocumentesign } from '../ReviewDocs';
import Esign from '../Esign';
import { useSelector } from 'react-redux';
import { sendleadinSoilAmmendum } from '../../../../api/transaction';

const LeadinSoilAddendum = ()=>{
const [leadsoil,setLeadSoil] = useState({
    offer_id:130,
    purchase_agreement_date :null,
    property_knownas :'',
    no_report :false,
    report_have_been :false,
    seller1_value :"",
    seller2_value :"",
    seller1_date :null,
    seller2_date :null,
    buyer1_value :"",
    buyer2_value :"",
    buyer1_date :null,
    buyer2_date :null,
    witness1_value :"",
    witness2_value :"",
    witness1_date :null,
    witness2_date :null
})

const [canvasmodal, setCanvasModal] = useToggle(false);
const [htmlContent, setHtmlContent] = useState("");
const [selecteddocumentesign, setselectedDocumentEsign] = useState<selecteddocumentesign>({
    offerid : "",
  document_name: "",
  image_base64: "",
  sign_role: 1
  })
// @ts-ignore
  const val = useSelector((state)=>state?.new?.signset)
//   const seller_date = ()=>{const d =  new Date();setLeadBasePaint({...leadbasepaint,seller1_date: new Date()})  }
  

               const ref = useRef(null)
  const printf =async () => {
    const printWindow = window
    const printWindowDoc = printWindow.document;
    printWindowDoc.open();
    printWindowDoc.write(ref.current.innerHTML);
    printWindowDoc.close();
    printWindow.print();
      
        }
    
    
        const send = async()=>{
  
             const run =async ()=>{return setLeadSoil({...leadsoil,seller1_value:val})}
            await run()
            if(leadsoil?.seller1_value){
             console.log(leadsoil,"njervrj");
             const res = await sendleadinSoilAmmendum(leadsoil)
             console.log(res,"rhgegbrtgb");
            }
     
     }   
 

return (
    <>
    <section className='leadbasepaint' ref={ref}>
    <table  className="soil_addendum_table_main">
        <tr>
            <td>
                <table style={{width:"100%"}}>
                    <tr>
                        <td style={{width:"90%"}}><img style={{width:'250px'}}
                                src="https://storagemilforddev.blob.core.windows.net/images/logo.svg" /></td>
                            
                        <td  ><img style={{width:"30px"}}
                                src="https://storagemilforddev.blob.core.windows.net/images/realtor.svg" alt="" />
                                <img style={{width:"30px"}}
                                src="https://storagemilforddev.blob.core.windows.net/images/eh.svg" alt=""/>
                        </td>
                       
                    </tr>
                   
                </table>
            </td>
        </tr>
        <tr>
            <td style={{padding:"15px 0px"}}>
                <span style={{fontSize:"20px",fontWeight:700}}>Lead in Soil Addendum</span>
            </td>
        </tr>
        <tr>    
            <td> 
                <span>This addendum is in addition to and becomes a permanent part of the Purchase Agreement dated<input type="date" value={leadsoil?.purchase_agreement_date}
onChange={(e)=>{setLeadSoil({...leadsoil,purchase_agreement_date:e?.target.value})}}
style={{outline: 'none', borderBottom:"1px solid black",width:'228px'}}/>on the property known as <input type="text" value={leadsoil?.property_knownas}
onChange={(e)=>{setLeadSoil({...leadsoil,property_knownas:e?.target.value})}}
                    style={{outline: 'none', borderBottom:"1px solid black",width:'78%'}} /></span>
            </td>
        </tr>


        <tr>
            <td style={{padding:"30px 0px"}}>
                <span>Purchase Agreement to be amended to include the following disclosure:</span>
            </td>
        </tr>
        <tr>
            <td style={{padding:"15px 0px"}}>
                <span style={{color:"#3b0083",fontWeight:700}}>ENVIRONMENTAL CONDITIONS<br/>
                    Lead in Soil</span>
            </td>
        </tr>
        <tr>
            <td>
                <span>The housing is located in an area of Omaha Nebraska (generally Harrison Street to the South, Read Street to the North, 56th Street to 
                    the West and East to the Missouri River) which EPA tests have shown may contain concentrations of lead in the soil that may pose an 
                    unacceptable risk to human health and the environment (the “Omaha Nebraska Lead Site”). The Omaha Lead Site has been included on 
                    EPA’s Superfund National Priorities List. However, the housing will only become part of the Omaha Lead Site if soils in its yard are tested 
                    and contain lead concentrations in excess of applicable EPA action levels.</span>
            </td>
        </tr>
        <tr>
            <td style={{padding:"15px 0px"}}>
                <table style={{width:"100%"}}>
                    <tr>
                        <td style={{width:"50%"}}>
                            <span><input type="checkbox" checked={leadsoil?.no_report}  onChange={(e)=>{setLeadSoil({...leadsoil,no_report:!leadsoil?.no_report})}}/>No reports are available</span>
                        </td>
                        <td>
                            <span><input type="checkbox" checked={leadsoil?.report_have_been} onChange={(e)=>{setLeadSoil({...leadsoil,report_have_been:!leadsoil?.report_have_been})}}/>Reports have been given to the Buyer’s agent.</span>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td style={{padding:'15px 0px'}}>
                <table>
                    <tr>
                        <td style={{textAlign:'center'}}>
                            <span><img  id="seller1_value" src={val}
                                 className='text_input_soil_add'/></span>
                                <span style={{paddingRight:"250px"}}> Seller</span>Date
                        </td>
                        <td style={{textAlign:'center'}}>
                            <span><input type="text"
                                 className='text_input_soil_add' /></span>
                                <span style={{paddingRight:"250px"}}> Buyer </span>Date
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td style={{padding:'15px 0px'}}>
                <table>
                    <tr>
                        <td style={{textAlign:'center'}}>
                            <span><input type="text"
                                className='text_input_soil_add' /></span>
                                <span style={{paddingRight:"250px"}}> Seller</span>Date
                        </td>
                        <td style={{textAlign:'center'}}>
                            <span><input type="text"
className='text_input_soil_add' /></span>
                                <span style={{paddingRight:"250px"}}> Buyer </span>Date
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td style={{padding:'15px 0px'}}>
                <table>
                    <tr>
                        <td style={{textAlign:'center'}}>
                            <span><input type="date"
                               className='text_input_soil_add'  value={leadsoil?.witness1_date}
                               onChange={(e)=>{setLeadSoil({...leadsoil,witness1_date:e?.target.value})}}/></span>
                                <span style={{paddingRight:"250px"}}>Witness </span>Date
                        </td>
                        <td style={{textAlign:'center'}}>
                            <span><input type="text"
                                className='text_input_soil_add'/></span>
                                <span style={{paddingRight:"250px"}}>Witness </span>Date
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td style={{padding:'15px 0px'}}>
                <span style={{fontWeight:700, color:'#3b0083'}}>For information updated by the City of Omaha please visit http://www.omahalead.org</span>
            </td>
        </tr>
        <tr>
            <td style={{padding:'15px 0px'}}>
                <span><img src="image/Screenshot (17).png" alt="" /></span>
            </td>
        </tr>
        <tr>
            <td style={{padding:'15px 0px'}}>
                <span>Other provisions: Earnest deposit to be delivered within (5) business days</span>
            </td>
        </tr>

    </table>
 
    </section>
    <button onClick={send}>Submit</button>
<button onClick={printf}>print</button>
<React.Fragment>
            {/* <button
              className="btn btn-primary modal-createoffer-btn"
              onClick={() =>{ handleSaveDocumentEsign(); setReviewdocs(!reviewdocs)}}
            >
            Save
            </button> */}
            <button className="signaturebtn btn" onClick={() => setCanvasModal()}>Signature</button> 

          </React.Fragment>
<Esign
        canvasmodal={canvasmodal}
        setCanvasModal={setCanvasModal}
        htmlContent={htmlContent}
        setHtmlContent={setHtmlContent}
        selecteddocumentesign={selecteddocumentesign}
        setselectedDocumentEsign={setselectedDocumentEsign}
        type={"seller"}
      ></Esign>
 
    </>
)

}

export default LeadinSoilAddendum