import axios from 'axios'
import { Dispatch } from 'redux'
import { State } from '../store'
import { login_success } from '../state/actions'

export const login = (token: string,alias:string,logintype:string) => async (dispatch:Dispatch<any>,_:()=>State) => {
  if(logintype === "custom") {
    let user = {}
    dispatch(login_success({
      token:token,
      user: {
        ...user,
        permissions:["abletoassignlender","address","agentnotes","app","appdownloaded","appmessages","bath","bed","buyersellerstatus","claimcontact","claimleads","clientbirthday","clientcommunicationlog","clientcreateddate","clientdonotcontact","clientemail","clienthomeaddress","clientname","clientphone","clients","clienttshirtsize","commissions","create-leads","create-users","creditrepair","datecreated","edit-leads","edit-users","esign","exportclients","future","homebot","homedescription","homesearch","internalnotes","investor","lastconnectedagent","lastconnectedlender","lastworkedagent","lastworkedlender","lead-assign-all","lead-assign-own","lead-claim","lead-edit-source","leads","leadsource","leadstatus","leadsubsource","lead-view-sub-source","lendernotes","lenderstatus","listingtype","lostclients","lostleads","mastermissions","missions","nonresponsive","oldleads","oldleadsupdated","openhousedatetime","pairedcontact","paperwork","paperworkfinalpurchaseagreement","paperworkfinalpurchaseagreementsellside","pastclients","price","prospects","referredclient","referredclients","referredclientsname","reviews","role-mgt","sq.ft.","stage","submittingsupporttickets","suspects","team","transactioncontacts","transactions","trashleads","unspecified","usermanagement","userpermissions"]
      },
    }))
  }else {
    try
  {
    const response = (await axios.post('/auth/google/login',{
        token:token,
        alias:alias,
      })).data
      // console.log(response,"wiragr");
      let userdet = JSON.stringify(response.rolename)
      
      localStorage.setItem('logindata',userdet)
    let user = JSON.parse(atob(response.token.split('.')[1])) 
    // console.log(user,"permissons");   
    dispatch(login_success({
      token:response.token,
      user: {
        ...user,
        permissions:user.permissions.split(",")
      },
      rolename:{
        userdet:response?.rolename
      }
    }))
  }
  catch (e) {
    throw e
  }
  }
}
