import React from 'react'
import Navbar from '../Navbar'
import Content from './Content'
import Footer from '../Footer'
import { NewNavbar } from '../Pages/NewNavbar'
import { NewFooter } from '../Pages/NewFooter'
import ChatBubble from '../Components/ChatBubble'

const Layoutidx = () => {
  return (
    <>
     <NewNavbar />  
      <Content />
      <ChatBubble/>
      <NewFooter />
    </>
  )
}

export default Layoutidx