import React, { useEffect, useRef, useState } from "react"
import { template5 } from "./StaticHtmlTemplate";
import { CSpinner } from "@coreui/react";
import { DispatchProp, connect, useDispatch } from "react-redux";
import { State } from "../../../store";
import Esign from "./Esign";
import useToggle from "../../../hooks/toggle";
import { selecteddocumentesign } from "./ReviewDocs";
import { LeadBasePaint1  } from "./SharedocHTML";
import html2pdf from 'html2pdf.js';

const Sharedoc = ()=>{

    const [htmlContent, setHtmlContent] = useState("");
    const [loading, setloading] = useState(false);
    const [selecteddocumentesign, setselectedDocumentEsign] = useState<selecteddocumentesign>({
        offerid : "",
      document_name: "",
      image_base64: "",
      sign_role: 1
      })
    const [canvasmodal, setCanvasModal] = useToggle(false);




    useEffect(()=>{
        setloading(true)
        setHtmlContent(LeadBasePaint1)
        setloading(false)
    },[])

    const parser = new DOMParser();
    const doc = parser.parseFromString(LeadBasePaint1, "text/html");
    const inputElement = doc.getElementById("address");
    // inputElement?.addEventListener("change", (e) => {
    //   // @ts-ignore
    //   console.log(e.target.value);
    // })
 
    
    const handlesubmitdoc =async ()=>{
      const parser = new DOMParser();
        const doc = parser.parseFromString(LeadBasePaint1, "text/html");
        const inputElement = doc.getElementById("address");
    console.log(inputElement,"rjbr");
    }
const mainhtml = useRef()
    const printf = () => {
        // console.log(window,"fnerjr");
        // window.print()
        const printWindow = window.open('', '', 'height=600,width=800');
        // printWindow.document.open();
        printWindow.document.write(LeadBasePaint1)
        printWindow.print();
        printWindow.document.close();
        // const content = mainhtml.current.innerHTML;
        // setloading(true)
        // // Create a container for the HTML content
        // const element = document.createElement('div');
        // element.innerHTML = htmlContent;
        // console.log(htmlContent, "rbhrbvrbb");
        // // Append to body (or you can use any other container)
        // document.body.appendChild(element);
    
        // // Define PDF options
        // const options = {
        //   margin: [10, 10, 10, 10],
        //   filename: 'document.pdf',
        //   image: { type: 'jpeg', quality: 0.98 },
        //   html2canvas: { scale: 2 }, // Improve quality
        //   jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        // };
    
        // // Convert HTML to PDF
        // html2pdf().from(element).set(options).save().then(() => {
        //   // Cleanup: Remove the temporary element
        //   document.body.removeChild(element);
        // });
        // setloading(false)
      };

    return (
        <>
        {loading && <CSpinner color="dark" size="sm" className={ 'ml-1' } />}
             {loading === true ?  <CSpinner color="dark" size="sm" className={ 'ml-1' } />  : 
            <>
              <button onClick={() => printf()}>Print</button>
            <div className="document_set" ref={mainhtml}>
         
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} ></div>

			</div>
            <button className="signaturebtn btn" onClick={() => setCanvasModal()}>Signature</button> 

            <button onClick={handlesubmitdoc} className="btn btn-primary">Submit</button>
             </>
      }
        <Esign
        canvasmodal={canvasmodal}
        setCanvasModal={setCanvasModal}
        htmlContent={htmlContent}
        setHtmlContent={setHtmlContent}
        selecteddocumentesign={selecteddocumentesign}
        setselectedDocumentEsign={setselectedDocumentEsign}
        type={"buyer"}
      ></Esign>

        </>
    )
}

// export default Sharedoc
export default connect((_: State) => ({}))(Sharedoc);