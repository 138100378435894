
import React, { useState, useEffect } from "react";
import Housefilter from "../../../assets/icons/Housefilter.svg";
import filters from "../../../assets/icons/filter.svg";

import { Link, useHistory } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AllFilters from "../Util/AllFilters";
import AddressForm from "../Util/AddressForm";
import Skeleton from "react-loading-skeleton";
import { useSearchParams } from "../../../hooks/useSearchParams";
import { queryString } from "../../../hooks/queryString";
// import { useSearchParams } from "../../../hooks/useSearchParams";


export interface Filters {
  city: string | null;
  country: string | null;
  stateOrProvince: string | null;
  zip: string | null;
  number_of_beds: string | number | null;
  number_of_baths: string | number | null;
  yearbuilt: number | null;
  highschool: string | null;
  elementaryschool: string | null;
  garageyn: boolean | null;
  horseyn: boolean | null;
  garagespaces: number | null;
  subdivisionname: string | null;
  highschooldistrict: string | null;
  // waterfrontfeatures: string[] | null;
  searchdata: string | null;
  yearbuiltmin: string | number | null;
  yearbuiltmax: string | number | null;
  garagespacesmin: string | number | null;
  garagespacesmax: string | number | null;
  propertytype: string | null;
  propertysubtype: string | null;
  newconstructionyn: boolean | null;
  fireplacemin: string | number | null;
  fireplacemax: string | number | null;
  userid: string | null;
}

export interface Filterlist {
  city: string[];
  country: string[];
  highschool: string[];
  highschooldistrict: string[];
  subdivisionname: string[];
  zip: string[];
  fireplaces: string[];
  garagespace: string[];
  propertysubtype: string[];
  yearbuilt: string[];
  cityandstate: string[];
  searchdata: string[];
  yearbuiltmin: string[];
  yearbuiltmax: string[];
  lotFeatures: string[];
}

export interface Currentfiltertype {
  type: string;
  value: string;
}

export interface Dropdownshowhide {
  searchdata: boolean;
  propertysubtype: boolean;
  highschooldistrict: boolean;
  highschool: boolean;
  subdivisionname: boolean;
  zip: boolean;
  city: boolean;
  country: boolean;
  garagespace: boolean;
  yearbuilt: boolean;
  fireplaces: boolean;
  lotFeatures: boolean;
}

export interface Savesearch {
  sid: string | number | null;
  userid: string | null;
  filter_name: string | null;
  country: string | null;
  zip: string | null;
  number_of_beds: string | number | null;
  number_of_baths: string | number | null;
  highschool: string | null;
  elementaryschool: string | null;
  subdivisionname: string | null;
  waterfrontfeatures: string[] | null;
  highschooldistrict: string | null;
  propertytype: string | null;
  propertysubtype: string | null;
  newconstructionyn: boolean | null;
  offmarketdate: string | null;
  onmarketdate: string | null;
  city: string | null;
  yearbuiltmin: number | null;
  yearbuiltmax: number | null;
  pricemin: number | null;
  pricemax: number | null;
  squarefeetmin: number | null;
  squarefeetmax: number | null;
  fireplacemin: number | null;
  fireplacemax: number | null;
  garagespacesmin: number | null;
  garagespacesmax: number | null;
}

type Props = {
  filters?: any;
  filterlist?: any;
  propertylisting?: any;
  toggle?: any;
  setToggle?: any;
  filter?: any;
  setFilter?: any;
  loading?: boolean;
  propertyTypeVal?: any;
};

const Filter: React.FC<Props> = (props) => {
  const {
    filterlist,
    toggle,
    setToggle,
    filter,
    setFilter,
    loading,
    propertyTypeVal
  } = props;
  const [suggestions, setSuggestions] = useState<Filterlist>({
    city: [],
    country: [],
    highschool: [],
    highschooldistrict: [],
    subdivisionname: [],
    zip: [],
    yearbuilt: [],
    fireplaces: [],
    garagespace: [],
    propertysubtype: [],
    cityandstate: [],
    searchdata: [],
    yearbuiltmin: [],
    yearbuiltmax: [],
    lotFeatures: []
  });
  const [currrentfilter, setCurrentFilter] = useState<Currentfiltertype>({
    type: "",
    value: ""
  });
  const [showSuggestions, setShowSuggestions] = useState<Dropdownshowhide>({
    searchdata: false,
    propertysubtype: false,
    highschooldistrict: false,
    highschool: false,
    subdivisionname: false,
    zip: false,
    city: false,
    country: false,
    garagespace: false,
    yearbuilt: false,
    fireplaces: false,
    lotFeatures: false
  });
  const [savesearch, setsaveSearch] = useState<Savesearch>({
    sid: null,
    userid: null,
    filter_name: null,
    country: null,
    zip: null,
    number_of_beds: null,
    number_of_baths: null,
    highschool: null,
    elementaryschool: null,
    subdivisionname: null,
    waterfrontfeatures: null,
    highschooldistrict: null,
    propertytype: null,
    propertysubtype: null,
    newconstructionyn: null,
    offmarketdate: null,
    onmarketdate: null,
    city: null,
    yearbuiltmin: null,
    yearbuiltmax: null,
    pricemin: null,
    pricemax: null,
    squarefeetmin: null,
    squarefeetmax: null,
    fireplacemin: null,
    fireplacemax: null,
    garagespacesmin: null,
    garagespacesmax: null
  });
  const [handlefilterpopup, sethandleFilterPopup] = useState<boolean>(false);
  const [filtersearching, setfilterSearching] = useState<any>({
    propertytype: false,
    price: false,
    bed: false,
    bath: false
  });
  const [PropertyType, setPropertyType] = useState<any>("any");
  // const [address, setAddress] = useState("");
  const [serachParams, setSerachParams]: any = useSearchParams()
  const address = serachParams?.address || ""

  const setAddress = (str: string) => {
    setSerachParams({ address: str })
  }





  useEffect(() => {
    async function filteringdata() {
      let filtered_data = filterData(currrentfilter.value, currrentfilter.type);

      setSuggestions({ ...suggestions, [currrentfilter.type]: filtered_data });
    }

    filteringdata();
  }, [filter]);

  const filterData = (input: any, type: string) => {
    switch (type) {
      case "city":
        return filterlist?.city?.filter((item: any) =>
          item.toLowerCase().includes(input.toLowerCase())
        );
      case "zip":
        return filterlist?.zip?.filter((item: any) =>
          item.toLowerCase().includes(input.toLowerCase())
        );
      case "country":
        return filterlist?.country?.filter((item: any) =>
          item.toLowerCase().includes(input.toLowerCase())
        );
      case "highschool":
        return filterlist?.highschool?.filter((item: any) =>
          item.toLowerCase().includes(input.toLowerCase())
        );
      case "highschooldistrict":
        return filterlist?.highschooldistrict?.filter((item: any) =>
          item.toLowerCase().includes(input.toLowerCase())
        );
      case "subdivisionname":
        return filterlist?.subdivisionname?.filter((item: any) =>
          item.toLowerCase().includes(input.toLowerCase())
        );
      case "searchdata":
        return filterlist?.cityandstate?.filter((item: any) =>
          item.toLowerCase().includes(input.toLowerCase())
        );
      case "propertysubtype":
        return filterlist?.propertysubtype?.filter((item: any) =>
          item.toLowerCase().includes(input.toLowerCase())
        );
      case "lotFeatures":
        return filterlist?.lotrange?.filter((item: any) =>
          item.toLowerCase().includes(input.toLowerCase())
        );
      default:
        return [];
    }
  };

  const handleSearchFilteredData = () => {
    setToggle(!toggle);
    setShowSuggestions({ ...showSuggestions, searchdata: false });




  };

  const handleClearall = () => {
    setFilter({
    city: null,
    country: null,
    zip: null,
    stateOrProvince: null,
    number_of_beds: null,
    number_of_baths: null,
    yearbuilt: null,
    highschool: null,
    elementaryschool: null,
    garageyn: null,
    horseyn: null,
    garagespaces: null,
    subdivisionname: null,
    highschooldistrict: null,
    waterfront: null,
    riverfront: null,
    lakefront: null,
    pool_In_Ground: null,
    pool_Above_Ground: null,
    pool_Access: null,
    horse_Permitted: null,
    out_Building: null,
    golf_Course: null,
    searchdata: null,
    yearbuiltmin: null,
    yearbuiltmax: null,
    garagespacesmin: null,
    garagespacesmax: null,
    propertytype: null,
    propertysubtype: null,
    newconstructionyn: null,
    fireplacemin: null,
    fireplacemax: null,
    squarefeetmin: null,
    squarefeetmax: null,
    pricemin: null,
    pricemax: null,
    lotFeatures: null,
    specialfeature: null,
    userid: null,
    pageSize: 20,
    pageNo: 1,
    luxury: null,
    streetNumber: null,
    waterfrontfeatures: [],
    });
    setsaveSearch({ ...savesearch, filter_name: null });
    setPropertyType(null);
    setAddress("");
    setToggle(!toggle);
  };

  {
    /* PropertySubType=Townhouse,Condominium,Villa/Patio Home,Multi Family,Farm

PropertyType=Land

Waterfront=true,

Pool_Access=true

Horse_Permitted=true

Golf_Course=true */
  }
  const handlePropertyData = (data: string) => {

    if (data === "Land") {
      setFilter({
        ...filter,
        propertytype: data,
        waterfront: null,
        pool_Access: null,
        horse_Permitted: null,
        golf_Course: null,
        propertysubtype: null
      });
    } else if (data === null) {
      setFilter({
        ...filter,
        propertytype: null,
        waterfront: null,
        pool_Access: null,
        horse_Permitted: null,
        golf_Course: null,
        propertysubtype: null
      });
    } else if (data === "Waterfront") {
      setFilter({
        ...filter,
        propertytype: null,
        waterfront: true,
        pool_Access: null,
        horse_Permitted: null,
        golf_Course: null,
        propertysubtype: null
      });
    } else if (data === "Pool_Access") {
      setFilter({
        ...filter,
        propertytype: null,
        waterfront: null,
        pool_Access: true,
        horse_Permitted: null,
        golf_Course: null,
        propertysubtype: null
      });
    } else if (data === "Horse_Permitted") {
      setFilter({
        ...filter,
        propertytype: null,
        waterfront: null,
        pool_Access: null,
        horse_Permitted: true,
        golf_Course: null,
        propertysubtype: null
      });
    } else if (data === "Golf_Course") {
      setFilter({
        ...filter,
        propertytype: null,
        waterfront: null,
        pool_Access: null,
        horse_Permitted: null,
        golf_Course: true,
        propertysubtype: null
      });
    } else {
      setFilter({
        ...filter,
        propertytype: null,
        waterfront: null,
        pool_Access: null,
        horse_Permitted: null,
        golf_Course: null,
        propertysubtype: data
      });
    }
    setfilterSearching({
      propertytype: null,
      price: null,
      bed: null,
      bath: null
    });
    setToggle(!toggle);
  };

  useEffect(() => {
    handlePropertyData(propertyTypeVal);
  }, [propertyTypeVal]);

  // console.log("data filter", filter)

  useEffect(() => {

    if (address && filter.city && filter.country) {
      handleSearchFilteredData();
    }
  }, [filter])
  const history = useHistory();
  return (
    <>
      <>
        <div className="parent-filter-flex ">
          <form className="d-flex searchform">
            <div>
              <button className=" searchfilter">
                <img src={Housefilter} />
              </button>
            </div>
            <span className="iconsearch"   >
              <i className="fa-solid fa-location-dot"></i>
            </span>
            <div className="parent-search form-control ">
              {address.length > 0 ? <div className="close-icon">
                <i
                  className="fa-solid fa-xmark"
                  onClick={() =>{ handleClearall();
                    localStorage.setItem("searchbaradd","none") 
                    localStorage.removeItem("filtervalue")
                    localStorage.removeItem("onaddressbar")
                    
                                      }}
                ></i>
              </div> : ""}
              <div className="searchBtn">
                {address.length > 0 ?
                  <Link
                    to={`/property-list?${queryString.stringify(serachParams)}`}
                    // to={{
                    //   pathname: '/property-list',
                    //   state: {data:address}
                    // }}
                    onClick={(event) => {
                      event.preventDefault();
                      handleSearchFilteredData();
                      history.push('/property-list');

                    }}
                    // state={{ fromHome: { data:address } }}
                    className="btn"
                  >
                    <i className="fa-solid fa-magnifying-glass icon"></i>
                  </Link>
                  : <button className="btn" disabled> <i className="fa-solid fa-magnifying-glass icon"></i></button>
                }
              </div>
              <AddressForm
                filter={filter}
                setFilter={setFilter}
                address={address}
                setAddress={setAddress}
                handleSearchFilteredData={handleSearchFilteredData}
              />
            </div>
          </form>
          <div className="filter-flex">

            <button
              // className=" btn filters-product filterdropdown"
              className={location.pathname == "/property-list" ? 'btn filters-product filterdropdown purchase-page' : 'btn filters-product filterdropdown'}
              onClick={() => sethandleFilterPopup(true)}
            >
              <span>
                <img src={filters} className="mr-2" />
              </span>
              <span>Filters</span>
            </button>
          </div>
        </div>
        <AllFilters
          handlefilterpopup={handlefilterpopup}
          sethandleFilterPopup={sethandleFilterPopup}
          PropertyType={PropertyType}
          setPropertyType={setPropertyType}
          filter={filter}
          setFilter={setFilter}
          savesearch={savesearch}
          setsaveSearch={setsaveSearch}
          toggle={toggle}
          setToggle={setToggle}
          filterlist={filterlist}
          currrentfilter={currrentfilter}
          setCurrentFilter={setCurrentFilter}
          showSuggestions={showSuggestions}
          setShowSuggestions={setShowSuggestions}
          suggestions={suggestions}
          setSuggestions={setSuggestions}

        />

        <ToastContainer rtl={false} draggable pauseOnFocusLoss closeOnClick />
      </>
    </>
  );
};

export default Filter;