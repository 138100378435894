import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "../../../assets/icons/Logo.svg";
import any from "../../../assets/headerpage-icon/villa.svg";
import house from "../../../assets/headerpage-icon/villa.svg";
import Townhouse from "../../../assets/headerpage-icon/townhouse.svg";
import Condo from "../../../assets/headerpage-icon/condo.svg";
import Villa from "../../../assets/headerpage-icon/villa.svg";
import Luxury from "../../../assets/headerpage-icon/villa.svg";
import MultiFamily from "../../../assets/headerpage-icon/multi-family.svg";
import Land from "../../../assets/headerpage-icon/land.svg";

import Waterfront from "../../../assets/headerpage-icon/waterfront.svg";
import Pool from "../../../assets/headerpage-icon/pool.svg";
import Equestrian from "../../../assets/headerpage-icon/equestrian.svg";
import Golfcourse from "../../../assets/headerpage-icon/Golf Hole Ball.svg";
import forms from "../../../assets/headerpage-icon/forms.svg";
import debounce from 'lodash.debounce';
import { currentuser } from "../../../util/currentuser";
import { Filterlist, Filters } from "../ProductDetails";
import { getFilterListData, propertyListing } from "../../../api/idx";
import { handdleAscending, handdleDescending } from "../../../util/sorting";
import Filter from ".././Components/Filter";
import { NewProductDetails } from "./NewProductDetails";
import { useDispatch, useSelector } from "react-redux";
import { login_success, logout, setsearchbaraddress } from "../../../state/actions";
import { CListGroup } from "@coreui/react";
import { isMobile } from 'react-native-web';
import { useSearchParams } from "../../../hooks/useSearchParams";
import { debug } from "console";

type Props = {
  filter?: any;
};
export const NewNavbar: React.FC<Props> = (props) => {
  const [loggedin, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [oddata, setoddata] = useState<any>();
  const history = useHistory();
  const location = useLocation();
  const [scrollPosition, setScrollPosition] = useState(false);
  const [toggleNav, settoggleNav] = useState(false);



  useEffect(() => {
    const updatePosition = () => {
      if (window.pageYOffset > 100) {
        setScrollPosition(true);
      } else {
        setScrollPosition(false);
      }
    };
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);
  useEffect(() => {
    async function fetchCurrentUser() {
      let user: any = localStorage.getItem("user");
      let user_id = JSON.parse(user);
      if (user_id) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
    }
    fetchCurrentUser();
  }, []);
  const handlelogin = (e: any) => {
    e.preventDefault();
    history.push("/login");
  };
  const handlePopup = () => {
    settoggleNav(!toggleNav);
  };
  const handleClickOutside = (event: any) => {
    if (event.target.closest(".profile-dropdown") === null) {
      settoggleNav(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    localStorage.clear()
    dispatch(logout());
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  let user_id = currentuser();
  const [propertylisting, setPropertyListing] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [toggle, setToggle] = useState<Boolean>(false);
  const [propertyTypeVal, setPropertyTypeVal] = useState<any>(null);
  const [filter, setFilter] = useState<Filters>({
    city: null,
    country: null,
    zip: null,
    stateOrProvince: null,
    number_of_beds: null,
    number_of_baths: null,
    yearbuilt: null,
    highschool: null,
    elementaryschool: null,
    garageyn: null,
    horseyn: null,
    garagespaces: null,
    subdivisionname: null,
    highschooldistrict: null,
    waterfrontfeatures: [],
    waterfront: null,
    riverfront: null,
    lakefront: null,
    pool_In_Ground: null,
    pool_Above_Ground: null,
    pool_Access: null,
    horse_Permitted: null,
    out_Building: null,
    golf_Course: null,
    searchdata: null,
    lotFeatures: null,
    yearbuiltmin: null,
    yearbuiltmax: null,
    garagespacesmin: null,
    garagespacesmax: null,
    propertytype: null,
    propertysubtype: null,
    newconstructionyn: null,
    fireplacemin: null,
    fireplacemax: null,
    squarefeetmin: null,
    squarefeetmax: null,
    pricemin: null,
    pricemax: null,
    userid: user_id ? user_id?.id : null,
    pageSize: 20,
    pageNo: currentPage,
    luxury: false,
    streetNumber: null,
    lotrange : null
  });
  const [filterlist, setFilterList] = useState<Filterlist>({
    city: [],
    country: [],
    highschool: [],
    highschooldistrict: [],
    subdivisionname: [],
    zip: [],
    yearbuilt: [],
    fireplaces: [],
    garagespace: [],
    propertysubtype: [],
    cityandstate: [],
    searchdata: [],
    squarefeet: [],
    price: [],
    lotrange: []
  });
  const [count, setCount] = useState<any>();
  const [pagescountstartend, setPagesCountStartend] = useState({
    start: 1,
    end: 20
  });



  useEffect(() => {
    async function anonymous() {
      let data = await getFilterListData();
      const sortedcity = data?.city.sort();
      const sortedcountry = data?.country.sort();
      const sortedfireplaces = data?.fireplaces.sort((a: any, b: any) => a - b);
      const sortedgaragespace = data?.garagespace.sort(
        (a: any, b: any) => a - b
      );
      const sortedprice = data?.price.sort((a: any, b: any) => a - b);
      const sortedsquarefeet = data?.squarefeet.sort((a: any, b: any) => a - b);
      const sortedzip = data?.zip.sort((a: any, b: any) => a - b);
      const sortedyearbuilt = data?.yearbuilt.sort();
      const sortedhighschool = data?.highschool.sort();
      const sortedhighschooldistrict = data?.highschooldistrict.sort();
      const sortedsubdivisionname = data?.subdivisionname.sort();
      const sortedpropertysubtype = data?.propertysubtype.sort();
      const sortedcityandstate = data?.cityandstate.sort();
      const lotrange = data?.lotrange.sort(); 
      setFilterList({
        city: sortedcity,
        country: sortedcountry,
        highschool: sortedhighschool,
        highschooldistrict: sortedhighschooldistrict,
        subdivisionname: sortedsubdivisionname,
        zip: sortedzip,
        yearbuilt: sortedyearbuilt,
        fireplaces: sortedfireplaces,
        garagespace: sortedgaragespace,
        propertysubtype: sortedpropertysubtype,
        cityandstate: sortedcityandstate,
        searchdata: data.searchdata,
        squarefeet: sortedsquarefeet,
        price: sortedprice,
        lotrange: lotrange
      });
    }
    anonymous();
  }, []);

  let typesofproperty = {
    city: null,
    country: null,
    zip: null,
    stateOrProvince: null,
    number_of_beds: null,
    number_of_baths: null,
    yearbuilt: null,
    highschool: null,
    elementaryschool: null,
    garageyn: null,
    horseyn: null,
    garagespaces: null,
    subdivisionname: null,
    highschooldistrict: null,
    waterfront: null,
    riverfront: null,
    lakefront: null,
    pool_In_Ground: null,
    pool_Above_Ground: null,
    pool_Access: null,
    horse_Permitted: null,
    out_Building: null,
    golf_Course: null,
    searchdata: null,
    yearbuiltmin: null,
    yearbuiltmax: null,
    garagespacesmin: null,
    garagespacesmax: null,
    propertytype: null,
    propertysubtype: null,
    newconstructionyn: null,
    fireplacemin: null,
    fireplacemax: null,
    squarefeetmin: null,
    squarefeetmax: null,
    pricemin: null,
    pricemax: null,
    lotFeatures: null,
    specialfeature: null,
    userid: null,
    pageSize: 20,
    pageNo: currentPage,
    luxury: null,
    streetNumber: null,
    waterfrontfeatures: [],
    lotrange :null
  }



  const [newtype, setNewtype] = useState("")
  const [check, setcheck] = useState({})
  // const check = useRef(null)

  const hasRun = useRef(false);
  const [time, settimes] = useState(false)

  useEffect(() => {
    if (JSON.stringify(filter) == JSON.stringify(typesofproperty)) {
      (async function () {
        setLoading(true)
        let listing: any = await propertyListing(typesofproperty);

        let value = listing?.value;
        let count = listing?.odatacount;
        setoddata(count);
        setPropertyListing(value);
        setLoading(false);
        setcheck(filter)
      })()

    }
    (async function () {
      const res = () => { return localStorage.getItem('searchbaradd') }
      const result = await res()
      const res1 = () => { return localStorage.getItem('filtervalue') }
      const result1 = await res1()
      const filter1 = JSON.parse(result1)
 
      if (filter1 && result === "searchbaradd") {
        setLoading(true)
      
        filter1.pageNo = currentPage

        let listing: any = await propertyListing(filter1);

        let value = listing?.value;
        let count = listing?.odatacount;
        setoddata(count);
        setPropertyListing(value);
        setLoading(false);
        setcheck(filter)

      }
      else if (result === "searchbaradd") {

        let customfilter = typesofproperty
        customfilter.city = filter?.city ? filter?.city : null
        customfilter.stateOrProvince = filter?.stateOrProvince ? filter?.stateOrProvince : null
        customfilter.zip = filter?.zip ? filter?.zip : null
        customfilter.streetNumber = filter?.streetNumber ? filter?.streetNumber : null
        customfilter.propertytype = filter?.propertytype ? filter?.propertytype : null
        customfilter.pageNo = currentPage
        setLoading(true)
        let listing: any = await propertyListing(customfilter);

        let value = listing?.value;
        let count = listing?.odatacount;

        setoddata(count);
        setPropertyListing(value);
        setLoading(false);
        setcheck(filter)

      }



    })()


  }, [filter, currentPage])


  useEffect(() => {

    (async function () {

      const res = () => { return localStorage.getItem('searchbaradd') }
      const result = await res()
      const res1 = () => { return localStorage.getItem('filtervalue') }
      const result1 = await res1()
      const filter1 = JSON.parse(result1)


      dispatch(setsearchbaraddress("none"))

      if (result === "none") {
        let customfilter = typesofproperty
        setLoading(true)
        customfilter.pageNo = currentPage
        let listing: any = await propertyListing(customfilter);

        let value = listing?.value;
        let count = listing?.odatacount;
        setoddata(count);
        setPropertyListing(value);
        setLoading(false);
        setcheck(filter)
        localStorage.removeItem('filtervalue')
      }



      else {
        if ('filtervalue' in localStorage) {
          return;
        } else {
          let customfilter = typesofproperty
          setLoading(true)

          let listing: any = await propertyListing(customfilter);

          let value = listing?.value;
          let count = listing?.odatacount;
          setoddata(count);
          setPropertyListing(value);
          setLoading(false);
          setcheck(filter)
        }


      }
    })()
    settimes(true)
    // localStorage.setItem('searchbaradd',"none")


  }, [])


  const handlePropertyTypeChange = async (type: string) => {
    localStorage.setItem('searchbaradd', "none")
    setPropertyTypeVal(type)
    const res1 = () => { return localStorage.getItem('filtervalue') }
    const result1 = await res1()
    let filter1 = JSON.parse(result1)
    const res = () => { return localStorage.getItem('searchbaradd') }
    const result = await res()
    if (filter1) {
      filter1.golf_Course = null
      filter1.propertysubtype = null
        filter1.horse_Permitted = null
        filter1.pool_Access = null
          filter1.waterfront = null
          filter1.luxury = null
      filter1.propertytype = null
      setCurrentPage(1);
      if (type === "House") {
        // filter1.golf_Course = null
        // filter1.propertysubtype = null
        //   filter1.horse_Permitted = null
        //   filter1.pool_Access = null
        //     filter1.waterfront = null
        //     filter1.luxury = null
        filter1.propertytype = "Residential"
      }
      if (type === "Townhouse") {
        // filter1.golf_Course = null
        // filter1.propertytype = null
        //   filter1.horse_Permitted = null
        //    filter1.pool_Access = null
        //      filter1.waterfront = null
        //      filter1.luxury = null
        filter1.propertysubtype = "Townhouse"
      }
      if (type === "Condominium") {
        // filter1.golf_Course = null
        // filter1.propertytype = null
        //   filter1.horse_Permitted = null
        //    filter1.pool_Access = null
        //      filter1.waterfront = null
        //      filter1.luxury = null
        filter1.propertysubtype = "Condominium"
      }
      if (type === "Villa/Patio Home") {
        filter1.golf_Course = null
        // filter1.propertytype = null
        // filter1.propertysubtype = null
        //   filter1.horse_Permitted = null
        //    filter1.pool_Access = null
        //      filter1.waterfront = null
        //      filter1.luxury = null
        filter1.propertysubtype = "Villa/Patio Home"
      }
      if (type === "Luxury") {
        // filter1.golf_Course = null
        // filter1.propertysubtype = null
        // filter1.propertytype = null
        //   filter1.horse_Permitted = null
        //    filter1.pool_Access = null
        //      filter1.waterfront = null
        filter1.luxury = true
      }
      if (type === "Multi Family") {
        // filter1.golf_Course = null
        // filter1.propertysubtype = null
        // filter1.propertytype = null
        //   filter1.horse_Permitted = null
        //    filter1.pool_Access = null
        //      filter1.waterfront = null
        //      filter1.luxury = null
        filter1.propertysubtype = "Multi Family"
      }
      if (type === "Land") {
        // filter1.golf_Course = null
        // filter1.propertysubtype = null
        // filter1.propertytype = null
        //   filter1.horse_Permitted = null
        //    filter1.pool_Access = null
        //      filter1.waterfront = null
        //      filter1.luxury = null
        filter1.propertytype = "Land"
      }
      if (type === "Waterfront") {
        // filter1.golf_Course = null
        // filter1.propertysubtype = null
        // filter1.propertytype = null
        // filter1.luxury = null
        //   filter1.horse_Permitted = null
        //    filter1.pool_Access = null
        filter1.waterfront = true
      }
      if (type === "Pool_Access") {
        // filter1.golf_Course = null
        // filter1.propertysubtype = null
        // filter1.propertytype = null
        //   filter1.horse_Permitted = null
        //     filter1.waterfront = null
        //     filter1.luxury = null
        filter1.pool_Access = true
      }
      if (type === "Equestrian") {
      //   filter1.golf_Course = null
      //   filter1.propertysubtype = null
      //   filter1.propertytype = null
      //   filter1.horse_Permitted = null
      //     filter1.horse_Permitted = null
      //        filter1.pool_Access = null
      //          filter1.waterfront = null
      //          filter1.luxury = null
        filter1.horse_Permitted = true
      }
      if (type === "Golf_Course") {
        // filter1.propertysubtype = null
        // filter1.horse_Permitted = null
        // filter1.propertytype = null
        //    filter1.pool_Access = null
        //      filter1.waterfront = null
        //      filter1.luxury = null
        filter1.golf_Course = true

      }
      if (type === "Farm") {
        // filter1.golf_Course = null
        // filter1.waterfront = null
        // filter1.propertysubtype = null
        // filter1.propertytype = null
        // filter1.horse_Permitted = null
        // filter1.luxury = null
        // filter1.pool_Access = null
        filter1.propertysubtype = "Farm"
      }
      setPagesCountStartend({
        start: 1,
        end: 20
      })
      setLoading(true)
      filter1.pageNo = 1
      localStorage.setItem('filtervalue', JSON.stringify(filter1))
      let listing: any = await propertyListing(filter1);

      let value = listing?.value;
      let count = listing?.odatacount;
      setoddata(count);
      setPropertyListing(value);
      setLoading(false);
    }
    else {
      
      let customfilter = typesofproperty
      customfilter.golf_Course = null
      customfilter.propertysubtype = null
      customfilter.horse_Permitted = null
      customfilter.pool_Access = null
      customfilter.waterfront = null
      customfilter.luxury = null
      customfilter.propertytype = null
      if (type === "House") {
        customfilter.propertytype = "Residential"
      }
      if (type === "Townhouse") {
        customfilter.propertysubtype = "Townhouse"
      }
      if (type === "Condominium") {
        customfilter.propertysubtype = "Condominium"
      }
      if (type === "Villa/Patio Home") {
        customfilter.propertysubtype = "Villa/Patio Home"
      }
      if (type === "Luxury") {
        customfilter.luxury = true
      }
      if (type === "Multi Family") {
        customfilter.propertysubtype = "Multi Family"
      }
      if (type === "Land") {
        customfilter.propertytype = "Land"
      }
      if (type === "Waterfront") {
        customfilter.waterfront = true
      }
      if (type === "Pool_Access") {
        customfilter.pool_Access = true
      }
      if (type === "Equestrian") {
        customfilter.horse_Permitted = true
      }
      if (type === "Golf_Course") {
        customfilter.golf_Course = true
      }
      if (type === "Farm") {
        customfilter.propertysubtype = "Farm"
      }
      setLoading(true)
      setPagesCountStartend({
        start: 1,
        end: 20
      })
      customfilter.pageNo = 1
      localStorage.setItem('filtervalue', JSON.stringify(customfilter))
      let listing: any = await propertyListing(customfilter);

      let value = listing?.value;
      let count = listing?.odatacount;
      setoddata(count);
      setPropertyListing(value);
      setLoading(false);

    }

  }



  useEffect(() => {
localStorage.removeItem('onaddressbar')

    if (currentPage) {

      (async function () {
        const res1 = () => { return localStorage.getItem('filtervalue') }
        const result1 = await res1()
        const filter1 = JSON.parse(result1)
        let customfilter = typesofproperty
        customfilter.city = filter?.city ? filter?.city : null
        customfilter.stateOrProvince = filter?.stateOrProvince ? filter?.stateOrProvince : null
        customfilter.zip = filter?.zip ? filter?.zip : null
        customfilter.streetNumber = filter?.streetNumber ? filter?.streetNumber : null
        customfilter.propertytype = filter?.propertytype ? filter?.propertytype : null
        customfilter.pageNo = currentPage
        setLoading(true)
        if (filter1) {
          filter1.pageNo = currentPage
          let listing: any = await propertyListing(filter1);
          let value = listing?.value;
          let count = listing?.odatacount;

          setoddata(count);
          setPropertyListing(value);
          setLoading(false);
          setcheck(filter)
        }
        else {
          let customfilter = typesofproperty
          customfilter.city = filter?.city ? filter?.city : null
          customfilter.stateOrProvince = filter?.stateOrProvince ? filter?.stateOrProvince : null
          customfilter.zip = filter?.zip ? filter?.zip : null
          customfilter.streetNumber = filter?.streetNumber ? filter?.streetNumber : null
          customfilter.propertytype = filter?.propertytype ? filter?.propertytype : null
          let listing: any = await propertyListing(customfilter);
          let value = listing?.value;
          let count = listing?.odatacount;

          setoddata(count);
          setPropertyListing(value);
          setLoading(false);
          setcheck(filter)
        }


      })()



    }

  }, [currentPage])
// @ts-ignore

  const val = useSelector((state)=>state?.new?.signset)
  
  
    useEffect(()=>{
  (async function(){
  const res = await val
  
  try {
    setLoading(true);
    let listing: any = await propertyListing(res);
    let value = listing?.value;
    let count = listing?.odatacount;

    setoddata(count);
    setPropertyListing(value);
    setLoading(false);
    setcheck(filter)
    localStorage.setItem('filtervalue',JSON.stringify(res))
  } catch (error) {
    console.log(error)
  }
  
  })()
  
    },[val])

  
  useEffect(() => {
    async function listingproperty() {
      if (history?.location?.state) {
        let temp_filter = props.filter;
        let customsavedfilter = {
          city: temp_filter?.city,
          country: temp_filter?.filter?.country,
          zip: temp_filter?.filter?.zip,
          stateOrProvince: temp_filter?.filter?.stateOrProvince,
          number_of_beds: temp_filter?.filter?.number_of_beds,
          number_of_baths: temp_filter?.filter?.number_of_baths,
          yearbuilt: temp_filter?.filter?.yearbuilt,
          highschool: temp_filter?.filter?.highschool,
          elementaryschool: temp_filter?.filter?.elementaryschool,
          garageyn: temp_filter?.filter?.garageyn,
          horseyn: temp_filter?.filter?.horseyn,
          garagespaces: temp_filter?.filter?.garagespaces,
          subdivisionname: temp_filter?.filter?.subdivisionname,
          highschooldistrict: temp_filter?.filter?.highschooldistrict,
          waterfrontfeatures: temp_filter?.filter?.waterfrontfeatures,
          searchdata: null,
          yearbuiltmin: temp_filter?.filter?.yearbuiltmin,
          yearbuiltmax: temp_filter?.filter?.yearbuiltmax,
          garagespacesmin: temp_filter?.filter?.garagespacesmin,
          garagespacesmax: temp_filter?.filter?.garagespacesmax,
          propertytype: temp_filter?.filter?.propertytype,
          propertysubtype: temp_filter?.filter?.propertysubtype,
          newconstructionyn: temp_filter?.filter?.newconstructionyn,
          fireplacemin: temp_filter?.filter?.fireplacemin,
          fireplacemax: temp_filter?.filter?.fireplacemax,
          squarefeetmin: temp_filter?.filter?.squarefeetmin,
          squarefeetmax: temp_filter?.filter?.squarefeetmax,
          pricemin: temp_filter?.filter?.pricemin,
          pricemax: temp_filter?.filter?.pricemax,
          specialfeature: temp_filter?.filter?.specialfeature,
          userid: temp_filter?.filter?.userid,
          pageSize: 20,
          pageNo: 1,
          luxury: filter?.luxury
        };

        history.replace({ ...history.location, state: null });
       
        // @ts-ignore
        let listing: any = await propertyListing(customsavedfilter);
        let values = listing?.value;
        let count = listing?.showingresult;
        setPropertyListing(values);
        setLoading(false);
        setCount(count);
      }

    }
    listingproperty();
  }, [filter, toggle, currentPage]);

  const handleSorting = (type: string) => {
    if (type === "ascending") {
      let sorted_data = handdleDescending(propertylisting);
      setPropertyListing(sorted_data);
    }
    if (type === "descending") {
      let sorted_data = handdleAscending(propertylisting);
      setPropertyListing(sorted_data);
    }
  };

  const nextPage = () => {
    
    setCurrentPage((prevPage) => prevPage + 1);
    setPagesCountStartend({
      start: pagescountstartend.start + 20,
      end: pagescountstartend.end + 20
    });
    window.scrollTo({
      top: 0,

      behavior: "smooth"
    });
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
    setPagesCountStartend({
      start: pagescountstartend.start - 20,
      end: pagescountstartend.end - 20
    });
    window.scrollTo({
      top: 0,

      behavior: "smooth"
    });
  };
  const dispatch = useDispatch()
  const gotoleads = () => {

    let data: any = localStorage.getItem('logindata')
    let res = JSON.parse(data)
    // console.log(dia1, "newdata");
    let user = JSON.parse(atob(res?.jwtToken.split('.')[1]))
    // let JWTtoken = atob(data?.jwtToken.split('.')[1])
    // const user = JSON.parse(JWTtoken);
    dispatch(login_success({
      token: res?.jwtToken,
      user: {
        ...user,
        permissions: user.permissions.split(",")

      },
    }))
    // navigate_to('/leads');
  }

  const handlePageDonw = () => {
    if (location.pathname !== "/homepage") {
      // window.location.href = "/homepage"
    }
  }

  //26/07/2024s
  // const handlePropertyTypeChange = (type: string) => {
  // setNewtype(type)
  // getfilter(type)

  // };
  const [number, setNumber] = useState(4028800202);
  const textUsBtn = () => {
    const userAgent = navigator.userAgent;
    if (/(android|ipad|iphone|ipod|ipod touch|blackberry|playbook|silk|kindle|webos|tizen|sony|symbian)/i.test(userAgent)) {
      const url = `sms:${number}`;
      window.open(url, '_self');
    }
    else {
      const emailUrl = `mailto:karie@milford.com`;
    }
  };

  return (
    <>
      <div className={scrollPosition ? "header-bar headerFixed" : "header-bar"}>
        <div className="top-header">
          <button className="head-btn">

            <a href="tel:402-880-0202"> <i className="fa fa-phone mr-2"></i> <span>Call Us</span></a>
          </button>
          {/* <Link to="/contactus">
            {" "}
            <button className="req-btn">REQUEST CONSULTATION</button>
          </Link> */}
          <a href="/homepage#contactus" onClick={handlePageDonw}><button className="req-btn">REQUEST CONSULTATION</button></a>
          <button className="head-btn ">
            {window.innerWidth <= 576.5 ?
              <a onClick={textUsBtn}> <span>Text Us</span><i className="fa fa-comment-dots ml-2"></i></a>
              :
              <a href="mailto:karie@milford.com"> <span>Text Us</span><i className="fa fa-comment-dots ml-2"></i></a>
            }
          </button>
        </div>
      </div>
      <div className={location.pathname == "/property-list" ? 'nav-wrapper-sticky nav-wrapper' : 'nav-wrapper'} >
        <div className="nav-container">
          <div className="navbarLogo">
            <Link to="/homepage">
              <img src={Logo} alt="Logo" />
            </Link>
          </div>
          {/* <div className="col-md-6">
            <form className="d-flex searchform">
              <div>
                <button className=" searchfilter">
                  <img src="/static/media/Housefilter.599c0c10.svg" />
                </button>
              </div>
              <span className="iconsearch">
                <i className="fa-solid fa-location-dot" />
              </span>
              <div className="parent-search form-control ">
                <div className="searchBtn">
                  <a className="btn" href="/property-list">
                    <i className="fa-solid fa-magnifying-glass icon" />
                  </a>
                </div>
                <div>
                  <div className="search-input">
                    <input
                      type="search"
                      autoComplete="off"
                      role="combobox"
                      aria-autocomplete="list"
                      aria-expanded="false"
                      placeholder="Search properties by city, zip, neighborhood"
                      className="form-control search-form"
                      defaultValue=""
                    />
                    <div className="close-icon">
                      <i className="fa-solid fa-xmark"></i>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <button className=" btn filters-product filterdropdown">
            <span>
              <img src="/static/media/filter.34616648.svg" className="mr-2" />
            </span>
            <span>Filters</span>
          </button> */}
          <Filter
            filters={props?.filter}
            filterlist={filterlist}
            propertylisting={propertylisting}
            toggle={toggle}
            setToggle={setToggle}
            filter={filter}
            setFilter={setFilter}
            loading={loading}
            propertyTypeVal={propertyTypeVal}
          />

          {loggedin === false ? (
            <button onClick={handlelogin} className="btn btn-login signupbtn loginbtn-top desktopview ml-3">
              <Link to="/login">
                Login
              </Link>
            </button>
          ) : (
            <div className="nav-heart-user ">
              <div className="nav-heart">
                <Link to="/property-list/favourites">
                  <i className="fa-solid fa-heart link-dark"></i>
                </Link>
              </div>

              <div className="profile-dropdown">
                <div
                  className="nav-user profile-dropdown"
                  onClick={() => handlePopup()}
                >
                  <i className="fa-solid fa-user"></i>
                </div>
                {toggleNav === true ? (
                  <ul>
                    <li>
                      <Link to="/property-list/clientprofile">Profile</Link>
                    </li>
                    <li >

                      <Link to={{
                        pathname: "/leads",
                        state: { prevPath: location.pathname },
                      }} onClick={gotoleads}> Dashboard</Link>
                    </li>
                    <li>
                      <Link to="/property-list/messages">Messages</Link>
                    </li>
                    <li>
                      <Link to="/property-list/savedlists">Save Searches</Link>
                    </li>
                    <li>
                      <Link to="/property-list/favourites">Favourites</Link>
                    </li>
                    <li>
                      <Link to="/property-list/dislike">Dislikes</Link>
                    </li>
                    <li>
                      <Link to="/property-list/recentlyviewed">
                        Recently Viewed
                      </Link>
                    </li>
                    <li>
                      <Link to="/property-list/yourteam">Your Team</Link>
                    </li>
                    <li>
                      <Link to="/property-list/account">Account Settings</Link>
                    </li>
                    <hr />
                    <li onClick={handleLogout}>
                      <Link className="btn sign-out-btn" to="/login">
                        SIGN OUT
                      </Link>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      {location.pathname == "/property-list" ? (
        <ul className={location.pathname == "/property-list" ? 'search-quick-filter-sticky search-quick-filter' : 'search-quick-filter'}>

          <li className={propertyTypeVal === null ? "nevfilteractive" : ""} onClick={() => handlePropertyTypeChange(null)}>
            <a href='#.' >
              Any
            </a>
            <img src={any} />
          </li>
          <li className={propertyTypeVal === 'House' ? "nevfilteractive" : ""} onClick={() => handlePropertyTypeChange("House")}>
            <a href='#.' >
              House
            </a>
            <img src={house} />
          </li>
          <li className={propertyTypeVal === 'Townhouse' ? "nevfilteractive" : ""} onClick={() => handlePropertyTypeChange("Townhouse")}>
            {" "}
            <a href='#.' >
              Townhouse
            </a>
            <img src={Townhouse} />
          </li>
          <li className={propertyTypeVal === 'Condominium' ? "nevfilteractive" : ""} onClick={() => handlePropertyTypeChange("Condominium")}>
            {" "}
            <a href='#.' >
              Condo
            </a>
            <img src={Condo} />
          </li>
          <li className={propertyTypeVal === 'Villa/Patio Home' ? "nevfilteractive" : ""} onClick={() => handlePropertyTypeChange("Villa/Patio Home")}>
            {" "}
            <a href='#.' >
              Villa
            </a>
            <img src={Villa} />
          </li>
          <li className={propertyTypeVal === 'Luxury' ? "nevfilteractive" : ""} onClick={() => handlePropertyTypeChange("Luxury")}>
            {" "}
            <a href='#.' >
              Luxury
            </a>
            <img src={Luxury} />
          </li>
          <li className={propertyTypeVal === 'Multi Family' ? "nevfilteractive" : ""} onClick={() => handlePropertyTypeChange("Multi Family")} >
            {" "}
            <a href='#.' >
              Multi-Family
            </a>
            <img src={MultiFamily} />
          </li>
          <li className={propertyTypeVal === 'Land' ? "nevfilteractive" : ""} onClick={() => handlePropertyTypeChange("Land")}>
            {" "}
            <a href='#.' >
              Land
            </a>
            <img src={Land} />
          </li>
          <li className={propertyTypeVal === 'Waterfront' ? "nevfilteractive" : ""} onClick={() => handlePropertyTypeChange("Waterfront")}>
            {" "}
            <a href='#.' >
              Waterfront
            </a>
            <img src={Waterfront} />
          </li>
          <li className={propertyTypeVal === 'Pool_Access' ? "nevfilteractive" : ""} onClick={() => handlePropertyTypeChange("Pool_Access")}>
            {" "}
            <a href='#.' >
              Pool
            </a>
            <img src={Pool} />
          </li>
          <li className={propertyTypeVal === 'Equestrian' ? "nevfilteractive" : ""} onClick={() => handlePropertyTypeChange("Equestrian")}>
            {" "}
            <a href='#.' >
              Equestrian
            </a>
            <img src={Equestrian} />
          </li>
          <li className={propertyTypeVal === 'Golf_Course' ? "nevfilteractive" : ""} onClick={() => handlePropertyTypeChange("Golf_Course")}>
            {" "}
            <a href='#.' >
              Golfcourse
            </a>
            <img src={Golfcourse} />
          </li>
          <li className={propertyTypeVal === 'Farm' ? "nevfilteractive" : ""} onClick={() => handlePropertyTypeChange("Farm")}>
            {" "}
            <a href='#.' >
              Farms
            </a>
            <img src={forms} />
          </li>
        </ul>

      ) : (
        ""
      )}
      <ul className="mini-nav homepage-nav">
        <li className={location.pathname == "/homepage" ? "active" : ""}>
          <Link
            className="nav-link"
            to="/homepage"
            tabIndex={-1}
            aria-disabled="true"
          >
            HOME
          </Link>
        </li>
        <li className={location.pathname == "/property-list" ? "active" : ""}>
          <Link
            className="nav-link"
            to="/property-list"
            tabIndex={-1}
            aria-disabled="true"
          >
            BUY
          </Link>
        </li>
        {/* <li>
          <Link
            className="nav-link"
            to="/property-list"
            tabIndex={-1}
            aria-disabled="true"
          >
            SELL
          </Link>
        </li>
        <li>
          <Link className="nav-link" to="/property-list" aria-disabled="true">
            BUY & SELL
          </Link>
        </li> */}
        <li>
          <a className="nav-link" href="https://milfordmagazine.com/milford-market/" aria-disabled="true" target="_blank" >
            MILFORD MARKET
          </a>
        </li>
        <li>
          <a className="nav-link" href="https://milfordmagazine.com/" aria-disabled="true" target="_blank" >
            MAGAZINE
          </a>
        </li>
        <li className={location.pathname == "/contactus" ? "active" : ""}>
          <Link
            className="nav-link"
            to="/contactus"
            tabIndex={-1}
            aria-disabled="true"
          >
            CONTACT
          </Link>
        </li>
      </ul>

      {location.pathname == "/property-list" ? (
        <>
          <NewProductDetails
            oddata={oddata}
            count={count}
            propertylisting={propertylisting}
            handleSorting={handleSorting}
            loading={loading}
            currentPage={currentPage}
            prevPage={prevPage}
            nextPage={nextPage}
            pagescountstartend={pagescountstartend}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};


