import React, { RefObject, useEffect, useRef, useState } from "react";
import { DispatchProp, connect } from "react-redux";
import { login } from "../api/auth";
import { State } from "../store";
import GoogleLogin from "../components/GoogleLogin";
import { LoggedinIdxUser } from "../api/idx";
import { fetchUserSuccess, fetchUsersSuccessful, login_success, navigate_to } from "../state/actions";
import { NavLink } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Navbar from "./IDX/Navbar";
import Google from "../assets/icons/google.png";
import apple from "../assets/icons/apple.svg";
import facebook from "../assets/icons/Facebook.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CSpinner } from "@coreui/react";
// import { createStore } from 'redux'
// import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'

export interface UserDetails {
  email: string;
  password: string;
}
type InputRefType = RefObject<HTMLInputElement>;
type Props = DispatchProp<any>;
const Login: React.FC<Props> = (props) => {
  const [error, setError] = useState("");
  const [alias, setAlias] = useState("");
  const [logindata, setLoginData] = useState({})
  const [showPassword, setshowPassword] = useState(false);
  const [userdetails, setUserDetails] = useState<UserDetails>({
    email: "",
    password: "",
  });
  const [validation, setValidation] = useState<any>({
    email: "",
    password: "",
  });



  const handleCallback = async (response: any) => {
    // console.log(response, "response123");
    if (response.error) {
      switch (response.error) {
        case "popup_closed_by_user":
          setError("No account selected. Please try again.");
          break;
        default:
          setError("Something unexpected happened.");
      }
    } else {
      try {
        await props.dispatch(login(response.credential, alias, "old"));
        setError("");
      } catch (e) {
        switch (e.response.status) {
          case 404:
            setError("Unknown User");
            break;
          default:
            setError("Something unexpected happened.");
        }
      }
    }
  };

  /* It is used to set states of email and password of the form */
  const handleInput = (e: any) => {
    let { name, value } = e.target;
    setUserDetails({ ...userdetails, [name]: value });
  };

  /* It is used to check the validation of the form */
  const checkValidation = () => {
    let errors = JSON.parse(JSON.stringify(validation));

    // Email validation
    const emailCond = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    switch (true) {
      case !userdetails.email.trim():
        errors.email = "Email is required";
        break;
      case userdetails.email ? !emailCond.test(userdetails.email) : "":
        errors.email = "Please enter a valid email address";
        break;
      default:
        errors.email = "";
    }

    // Password validation
    const passwordCond = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,20}$/;
    const password = userdetails?.password;
    switch (true) {
      case !password:
        errors.password = "Password is required";
        break;
      case password ? password.length < 6 : "":
        errors.password = "Password must be longer than 6 characters";
        break;
      case password ? password.length >= 20 : "":
        errors.password = "Password must be shorter than 20 characters";
        break;
      case password ? !passwordCond.test(password) : "":
        errors.password = "Password must contain at least one lowercase, one uppercase, and one number";
        break;
      default:
        errors.password = "";
    }
    return errors;
  };
  const [loading, setLoading] = useState(false)
    /* This function runs when enter on the login button */
    const handleKeyPress = (e) => {
      if (e.key === "Enter") {
        inputRef.current.click(); 
      }
    };
  /* This function runs when click on the login button */
  const handleLoggedin = async (e: any) => {
    e.preventDefault();
    setLoading(true)
    let validations = await checkValidation();
    if (Object.values(validations).every((value) => value === "")) {
      let required_user_details = {
        email: userdetails.email,
        password: userdetails.password,
      };
      let api_data: any = await LoggedinIdxUser(required_user_details);

      let result = api_data?.data
      localStorage.setItem('logindata', JSON.stringify(result))
      localStorage.setItem('disptach', JSON.stringify(props))
      setLoginData(result)
      let result1 = api_data?.message
      // console.log(result1);
      switch (result1) {
        case "No user found.":
          setValidation({ email: "Please enter registered user email", password: "Please enter registered user password" });
          toast.error("Please Enter valid credentials")
          setLoading(false)
          break;
        case "Password Is Incorrect !.":
          setValidation({ password: "Please enter correct password", });
          setLoading(false)
          break;
        default:
          toast.success("User logged in successfully!")
          const decodedToken: any = jwt_decode(result?.jwtToken);
          localStorage.setItem("user", JSON.stringify(decodedToken));
          // localStorage.setItem("persist:auth",JSON.stringify(decodedToken));
          localStorage.setItem("token", result?.jwtToken);
          props.dispatch(fetchUsersSuccessful(decodedToken))
          props.dispatch(fetchUserSuccess(decodedToken))
          let user = JSON.parse(atob(result?.jwtToken.split('.')[1]))


          if (result?.rolename == "super-admin") {
            // let user = {}

            props.dispatch(login_success({
              token: result?.jwtToken,
              user: {
                ...user,
                permissions: user.permissions.split(",")

              },
            }))
            props.dispatch(navigate_to('/leads'));
            setLoading(false)
          }
          else if (result?.rolename == "client") {
            props.dispatch(navigate_to('/homepage'));
            setLoading(false)
          }
          else {
            props.dispatch(login_success({
              token: result?.jwtToken,
              user: {
                ...user,
                permissions: user.permissions.split(",")
        
              },
            }))
            // props.dispatch(navigate_to('/leads'));
            // setLoading(false)
          }
          break;
      }
    } else {
      setValidation({ email: validations.email, password: validations.password, });
      setLoading(false)
    }
  };

  // console.log(props, "newprops");
  const inputRef: InputRefType = useRef(null);
  //   const keyDownHandler = (e:any) => {
  // // debugger
  //     if (e.code = "Enter") {
  //       // debugger

  //       console.log('hi');
  //       handleLoggedin(e)
  //     }
  // };

  // useEffect(()=>{
  //   const handle = document.addEventListener('keydown', keyDownHandler);

  //   return () => {
  //       document.removeEventListener('keydown', handle);
  //   };
  // },[keyDownHandler])
  return (
    <>
      <Navbar logindata={logindata} />
      <div className="login-bg">
        <svg
          className="tringle-bg"
          xmlns="http://www.w3.org/2000/svg"
          width="1011"
          height="985"
          viewBox="0 0 1011 985"
          fill="none"
        >
          <path d="M94 1210L1011 0H-125V1193L94 1210Z" fill="white" />
        </svg>
        <section className="login" id="login">
          <div className="row justify-content-center">
            <div className="col-md">
              <div className="container">
                <h1 className="signin-heading">Sign in</h1>
                <div className="card overflow-hidden">
                  <div className="card-body py-0">
                    <div className="row">
                      <div className="col py-5 d-flex flex-column login-btn">
                        <h1 className="login-title">Welcome back!</h1>
                        <div className="d-flex align-items-center justify-content-center">
                        </div>
                        <section className="signup">
                          <div className="row">
                            <div className="col-lg-12 col-xl-12">
                              <div className="text-black">
                                <form className="mx-1 mx-md-4">
                                  <div className="row gy-4">
                                    <div className="col-md-12">
                                      <div className="form-outline flex-fill mb-0">
                                        <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                                        <label
                                          className="form-label"
                                          htmlFor="form3Example3c"
                                        >
                                          Email
                                        </label>
                                        <input
                                          type="email"
                                          className="form-control"
                                          placeholder="Enter Your Email Address"
                                          name="email"
                                          value={
                                            userdetails.email
                                              ? userdetails.email.toLocaleLowerCase()
                                              : ""
                                          }
                                          onChange={(e) => handleInput(e)}
                                          onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                      {validation.email && (
                                        <p className="regiter-errorMsg">
                                          {validation.email}
                                        </p>
                                      )}
                                    </div>
                                    <div className="col-md-12">
                                      <div className="form-outline flex-fill mb-0">
                                        <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                                        <label
                                          className="form-label"
                                          htmlFor="form3Example4c"
                                        >
                                          Password
                                        </label>
                                        <input
                                          type={showPassword ? "text" : "password"}
                                          className="form-control"
                                          placeholder="Enter Your Password"
                                          name="password"
                                          value={
                                            userdetails.password
                                              ? userdetails.password
                                              : ""
                                          }
                                          onChange={(e) => handleInput(e)}
                                          onKeyDown={handleKeyPress}
                                        />
                                        <i
                                          className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"
                                            } password-toggle`}
                                          onClick={() =>
                                            setshowPassword(!showPassword)
                                          }
                                        ></i>

                                      </div>
                                      {validation.password && (
                                        <p className="regiter-errorMsg">
                                          {validation.password}
                                        </p>
                                      )}
                                    </div>
                                  </div>

                                  
                                  <div className="form-check mb-3">
                                    <NavLink to="/forgotpassword" className="d-flex justify-content-end loginSignup mt-2">
                                      Forgot Password ?
                                    </NavLink>
                                  </div>
                                  <div className="mb-2 mb-lg-4">
                                    <button
                                      type="button"
                                      className="btn btn-lg loginBtn"
                                       // @ts-ignore
                                      ref={inputRef}
                                      onClick={(e: any) => handleLoggedin(e)}

                                    >
                                      Sign in
                                      {loading ? <CSpinner color="light" size="sm" className={'ml-1'} /> : null}
                                    </button>
                                  </div>
                                  <div className="login-options">
                                    <span className="mt-2">
                                      Or Log In with
                                    </span>
                                    <div className="flex justify-content-start mt-3">
                                      <span className="social">
                                        <GoogleLogin
                                          googleId={
                                            process.env.REACT_APP_google_id || ""
                                          }
                                          handleCallback={handleCallback}
                                        >
                                          <img
                                            src={Google}
                                            alt=""
                                            className="mr-2 img-fluid google-logo"
                                          />

                                          {/* Login with Google */}
                                        </GoogleLogin>
                                        {error ? (
                                          <p
                                            className="invalid-feedback text-center"
                                            style={{ display: "block" }}
                                          >
                                            {error}
                                          </p>
                                        ) : null}
                                      </span>
                                      <span className="social">
                                        <img src={apple} width={36} />

                                      </span>
                                      <span className="social">
                                        <img src={facebook} width={28} />
                                      </span>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </section>
                        <span className="mt-3 not-regidtered ">
                          <span className="not-registared d-block">Not Registered?</span>
                          <NavLink to="/register" className="loginSignup ml-1">
                            <button className="btn loginSignup signupbtn ml-1" >Sign up here</button>
                          </NavLink>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default connect((_: State) => ({}))(Login);
