import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import bed from "../../../assets/icons/bed.svg";
import arrow from "../../../assets/icons/Arrow Down.svg";
import bath from "../../../assets/icons/Bathroom Tub Towel.svg";
import dimention from "../../../assets/icons/Real Estate Dimensions Block.svg";
import garage from "../../../assets/icons/Car Garage 2.svg";
import wrench from "../../../assets/icons/Screwdriver Wrench.svg";
import housefilter from "../../../assets/icons/Housefilter.svg";
import car from "../../../assets/icons/Car Truck.svg";
import House4 from "../../../assets/icons/House 4.svg";
import Hamer from "../../../assets/icons/Hammer 1.svg";
import Ruler from "../../../assets/icons/Measure Ruler Corner.svg";
import calander from "../../../assets/icons/Calendar 3.svg";
import LownMower from "../../../assets/icons/Gardening Lawn Mower 1.svg";
import BuildingNature from "../../../assets/icons/Building Nature.svg";
import BuildingModern from "../../../assets/icons/Buildings Modern.svg";
import WindCock from "../../../assets/icons/Farming Wind Cock.svg";
import DoorLeftHandClose from "../../../assets/icons/Door Left Hand Closed.svg";
import TreeValey from "../../../assets/icons/Outdoors Tree Valley.svg";
import IndoorPlant from "../../../assets/icons/Indoor Plant 15.svg";
import CarGarage from "../../../assets/icons/Car Garage 4.svg";
import TileRoof from "../../../assets/icons/Material Tile Roof 1.svg";
import Heater from "../../../assets/icons/Heater.svg";
import DuplicateFile from "../../../assets/icons/Duplicate File.svg";
import fastfood from "../../../assets/icons/Fast Food Double Burger.svg";
import playground from "../../../assets/icons/Playground 3.svg";
import karie from "../../../assets/image/attractive-beautiful-beauty-584314 2.png";
import washingmachine from "../../../assets/icons/Washing Machine.svg";
import singlebed from "../../../assets/icons/Hotel Single Bed 1.svg";
import bathtab from "../../../assets/icons/Bathroom Tub Towel (1).svg";
import architect from "../../../assets/icons/Architecture Fence.svg";
import flower from "../../../assets/icons/Flower 2.svg";
import hoa from "../../../assets/icons/Gardening Lawn Mower 1.svg";
import school from "../../../assets/icons/School Building.svg";
import downloads from "../../../assets/icons/f2.svg";
import closeIcon from "../../../assets/headerpage-icon/close-icon.svg";
import uploadIcon from "../../../assets/headerpage-icon/upload.svg";
import closeCircleIcon from "../../../assets/headerpage-icon/close-circle.png";
import expandCircleIcon from "../../../assets/headerpage-icon/expand icon.png";
import AlertCircleIcon from "../../../assets/headerpage-icon/Alert Circle.svg";
import floodRiskIcon from "../../../assets/headerpage-icon/Natural Disaster Flood House.svg";
import plusIcon from "../../../assets/headerpage-icon/plus.svg";
import checkIcon from "../../../assets/headerpage-icon/check.svg";
import utilityInfoIcon from "../../../assets/headerpage-icon/utilityInfo.svg";
import inpersonIcon from "../../../assets/headerpage-icon/inpersonTour.svg";
import phonetourIcon from "../../../assets/headerpage-icon/phonetour.svg";
import noiseLvlIcon from "../../../assets/headerpage-icon/Noise Pollution.svg";
import walkingIcon from "../../../assets/headerpage-icon/Walking.svg";
import bikeScoreIcon from "../../../assets/headerpage-icon//Bicycle Sports.svg";
import noimage from "../../../assets/icons/noimage.svg";
import {
   
  getPropertyDetailsbyId,
  getSavePropertyActivity,
  profilelikedislikelist,
  requestTour,
  saveFavroutes,
} from "../../../api/idx";
import Modal from "../../../components/Modal";
import frame from "../../../assets/icons/Frame 1171276368.svg";
import { currentuser } from "../../../util/currentuser";
import Navbar from "../Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "../Footer";
import MortgageCalculator from "./MortgageCalculator";
import SimilarProperties from "../Util/SimilarProperties";
import Skeleton from "react-loading-skeleton";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { setNewLocation } from "../../../state/actions";
import axios from "axios";
import { getOpenHouseListing, getnoiselevel, getwalkscore } from "../../../api/leads";
import moment from "moment";
import { errorMessage } from "../Util/Toaster";
import { NullLogger } from "@microsoft/signalr";
import { NewFooter } from "../Pages/NewFooter";
import { formatUSPhoneNumber } from "../../../util/stringformat";
import ChatBubble from "./ChatBubble";
import { CSpinner } from "@coreui/react";

export interface likedislike {
  "favorites": boolean,
  "dislikes": boolean,
}

const SingleProduct = (props: any) => { 
  
  const [accordionState, setAccordionState] = useState({
    keyInfo: false,
    homeInfo: false,
    parkingInfo:false,
    exteriorInfo:false,
    utilitiesInfo:false,
    locationInfo:false,
    otherInfo:false,
  })

  const [propertylisting, setPropertyListing] = useState<any>([]);
  const [noiseLevel, setNoiseLevel] = useState<string>('');
  const [homeList, setHomeList] = useState<any>([]);
  const [homeListAllData, setHomeListAllData] = useState<any>([]);
  const [scheduleTimes, setScheduleTimes] = useState<any>([]);
  const [scheduleTimeValue, setScheduleTimeValue] = useState<string>('');
  const [scheduleErrorMsg, setScheduleErrorMsg] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [homeListLoader, setHomeListLoader] = useState<boolean>(true);
  const [userLoggedIn, setUserLoggedIn] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(false);
  const [fvrtToggle, setFvrtToggle] = useState<boolean>();
  const saleTopSection = useRef();
  const [isAtTop, setIsAtTop] = useState(false);
  const location = useLocation<{ prevPath?: string }>();
  const history = useHistory();
  const [scheduleInPerson, setscheduleInPerson] = useState({});
  const [walkScoreState, setwalkScoreState] = useState<any>({
    walkScoreDescription:'',
    walkScoreNumber:'',
    bikeScoreDescription:'',
    bikeScoreNumber:'',
  });
  const [inPersonTour, setInPersonTour] = useState(1);
  const [inPersonData, setInPersonData] = useState({
    firstName: "",
    email:"",
    phone:"",
    message:""

  })
  const [inPersonTourError, setInPersonDataError] = useState({
    firstName: "",
    email:"",
    phone:"",
    message:""

  })
const [tourDate, setTourDate]=useState("")

  const [toggle, setToggle] = useState(false);
  const [tour, setTour] = useState({
    inpersontour: true,
    videochattour: false,
  });
  const [likedislike, setlikeDislike] = useState<likedislike>({
    favorites: false,
    dislikes: false,
  })
  const [loader, setLoader] = useState(true);
  let path_name = window.location.pathname;
  const listing_id = path_name?.split("/")[2];



  const responsiveproduct = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  const responsivegalleryimg = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  useEffect(() => {
    async function anonymous() {
      let current_user = currentuser();
      let temp = await profilelikedislikelist(listing_id, current_user?.id);
      setlikeDislike({ favorites: temp?.favorites, dislikes: temp?.dislikes})
    }
    anonymous()
  }, [fvrtToggle]) 
   
  const handleFvrt = async (val:boolean) => {
    let current_user = currentuser(); 
    let user: any = localStorage.getItem("user");
    let user_id = JSON.parse(user);
    if (user_id === null) {
      errorMessage("Please login");
    } else {
      if(val == true){ 
        let data = {
          userid: current_user?.id,
          propertiesid: listing_id,
          favorites: false,
          dislikes: false,
          recently_viewed: false,
        };
        let response = await saveFavroutes(data, "remove");
        switch (response) {
          case "Your Property Remove successfully": 
          setlikeDislike({ favorites: false, dislikes: true });
           setFvrtToggle((prev: any) => !prev)
            toast.success("Property has been removed from favourites list");
            break;
        } 
      }
      else {
        let data = {
          userid: current_user?.id,
          propertiesid: listing_id,
          favorites: true,
          dislikes: false,
          recently_viewed: false,
        };
        let response = await saveFavroutes(data, "add");
        switch (response) {
          case "Your Property Save successfully":
            setlikeDislike({ favorites: true, dislikes: true })
             setFvrtToggle((prev: any) => !prev)
            toast.success("Property has been added to favourites");
            break;
        }
      }
    }
  } 
  
  const handleDislike = async (val:boolean) => {
    let current_user = currentuser(); 
    let user: any = localStorage.getItem("user");
    let user_id = JSON.parse(user);
    if (user_id === null) {
      errorMessage("Please login");
    } else {
    if(val == true){ 
      let data = {
        userid: current_user?.id,
        propertiesid: listing_id,
        favorites: false,
        dislikes: false,
        recently_viewed: false,
      };
      let response = await saveFavroutes(data, "remove");
      switch (response) {
        case "Your Property Remove successfully":
          setlikeDislike({ favorites: false, dislikes: false })
          setFvrtToggle((prev: any) => !prev)
          toast.success("Property has been removed from dislike list");
          break;
      }
    }
    else {
      let data = {
        userid: current_user?.id,
        propertiesid: listing_id,
       favorites: false,
        dislikes: true,
        recently_viewed: false,
      };
      let response = await saveFavroutes(data, "add");
      switch (response) {
        case "Your Property Save successfully":
          setlikeDislike({ favorites: false, dislikes: true })
          setFvrtToggle((prev: any) => !prev)
          toast.success("Property has been added to dislike list");
          break;
      }
    }
  }
  }



  useEffect(() => {
    async function listingproperty() {
      let current_user = currentuser();
      let response = await getPropertyDetailsbyId(listing_id);
      setPropertyListing(response); // Set the filtered result as the property listing
      setLoader(false);
      let temp_data = {
        listingid: response[0]?.listingid,
        userid: current_user?.id
          ? current_user?.id
          : "00000000-0000-0000-0000-000000000000",
        address:
          response[0]?.street_address +
          response[0]?.city +
          response[0]?.state +
          response[0]?.zip,
        propertysubtype: response[0]?.propertysubtype,
        listingprice: response[0]?.price,
      };
      await getSavePropertyActivity(temp_data);
    }
    listingproperty();
    let user: any = localStorage.getItem("user");
    let user_id = JSON.parse(user);
    if(user_id != null) {
      setUserLoggedIn(true)
    }
  

  }, []);

  const handleInPersonChange = (e: any) => {
    const {name, value} = e.target
    setInPersonData((prev: any) => ({
        ...prev,
        [name]: value, // Assign the value here
    }));
    console.log(name,"ergrgj");
    if(name == "phone"){
      setInPersonDataError({...inPersonTourError,phone:""})
    }
};

   const postInPersonData = async(scheduleInPerson:any)=>{
    try {
      const res = await postInPersonData(scheduleInPerson)
    
    } catch (error) {
      console.log(error)
    }
   }



  const handleTourData = (type: string) => {
    if (type === "persontour") {
      setTour({ inpersontour: true, videochattour: false });
    }
    if (type === "videotour") {
      setTour({ inpersontour: false, videochattour: true });
    }
  };




  const handleScheduleTour = async () => {  
    debugger
    if(inPersonData.firstName?.trim()?.length === 0){
      // debugger
      setInPersonDataError((prev)=> ({...prev, firstName: "Name is required"}));
    
    }
    else{
      setInPersonDataError((prev)=> ({...prev, firstName: ""}));
    }
 
    if(inPersonData.email == ""){
      setInPersonDataError((prev)=> ({...prev, email: "Email is required"}));
    }else if(inPersonData.email){
      let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  
      if (!regex.test(inPersonData.email) || inPersonData.email?.trim()?.length === 0) { 
        inPersonTourError.email = "Email is not valid"
        setInPersonDataError((prev)=> ({...prev, email: "Email is not valid"}));
      }
      else{
        setInPersonDataError((prev)=> ({...prev, email: ""}));
      }
    }
    if(inPersonData.phone?.trim()?.length === 0){
      setInPersonDataError((prev)=> ({...prev, phone: "Phone no. is required"}));
    }
   else if(inPersonData.phone?.trim()?.length < 10 || inPersonData.phone?.trim()?.length > 14 ){
      setInPersonDataError((prev)=> ({...prev, phone: "Phone no. should be of 10 digit"}));
    }

    else{
      setInPersonDataError((prev)=> ({...prev, phone: ""}));
    }
    if(inPersonData.message?.trim()?.length === 0){
      setInPersonDataError((prev)=> ({...prev, message: "Message is required"}));
    } else{
      setInPersonDataError((prev)=> ({...prev, message: ""}));
    }

    
    let isValid = Object.values(inPersonData).every((each)=>each !=="")
   

    if(isValid && inPersonTourError.email == "" && inPersonTourError.phone == ""){
      setloading(true)

      const formattedDate = moment(tourDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"); 
    let user: any = localStorage.getItem("user");
    let user_id = JSON.parse(user);
  
    let data = {
      propertyId: listing_id, 
      userId: user_id?.id,  
      firstName:inPersonData?.firstName,  
      email: inPersonData?.email,
      phone: inPersonData?.phone,
      date_Time: formattedDate,//tourDate
      comments: inPersonData?.message,
      inPersonTour: tour?.inpersontour,
      videoChatTour: tour?.videochattour,
      time:scheduleTimes,
      address:propertylisting[0]?.UnparsedAddress
    };

    let res = await requestTour(data);
    if(res){
      toast.success("Tour scheduled successfully.");
      // setInPersonTour(0)
    }
    setloading(false)
    // if(res?.status === 200){
    //   toast.success("Tour scheduled successfully.");
    // } 
  }
  };
  const scheduleTime = () => { 
      if(scheduleTimeValue !== null && scheduleTimeValue && scheduleTimeValue?.trim() !== '') {
        setScheduleErrorMsg('')
        if(scheduleTimes.length < 3) {
          setScheduleErrorMsg('')
          if (!scheduleTimes.includes(scheduleTimeValue)) {
            setScheduleErrorMsg('')
            setScheduleTimes([...scheduleTimes, String(scheduleTimeValue)])
          }
          else {
            setScheduleErrorMsg('Already Selected')
          }
        }
        else {
          setScheduleErrorMsg('Select up to 3 times only')
        }
      } 
      else {
        setScheduleErrorMsg('Enter Time')
      }
  } 
  
  
  const updatelocaton = (data:string)=>{
    if(data==="commute"){
   
      props.dispatch(setNewLocation(currentlocation))
    }
    if(data==="restaurant"){
   
      props.dispatch(setNewLocation(currentlocation))
    }
    else if(data==="Maps"){
     props.dispatch(setNewLocation("maps"))
    }
     }
   
  const [currentlocation, setCurrentLocation] = useState("")

  useEffect(() => {
    if (propertylisting.length > 0) {

      setCurrentLocation(`${propertylisting[0]?.UnparsedAddress.split(",")[0]},${propertylisting[0]?.City},${propertylisting[0]?.StateOrProvince},${propertylisting[0]?.PostalCode}`)
    }   
    noiseLevelFun();
    getWalkScoreData();
   
  }, [propertylisting])

  useEffect(() => {
    getOpenHouseListing(currentPage).then((res: any) => {
      if(res?.data?.status == "success"){
        setHomeList(res?.data?.data?.items) 
        setHomeListAllData((prev:any)=>[...prev,...res?.data?.data?.items]) 
        setHomeListLoader(false)
      }
    }).catch((e) => console.log(e));
  }, [currentPage]);

   
    const getWalkScoreData = () => {
      if(propertylisting[0] != undefined) { 
        let walkScorePayload = {
          propertyAddress: propertylisting[0]?.UnparsedAddress,
          latitude: propertylisting[0]?.Latitude,
          longitude: propertylisting[0]?.Longitude
        };   
      let res = getwalkscore(walkScorePayload).then((data) => {  
          if(data?.walData?.status == 1) { 
            setwalkScoreState({
              walkScoreDescription: data?.walData?.description,  
              walkScoreNumber: data?.walData?.walkscore, 
              bikeScoreDescription: data?.walData?.bike?.description, 
              bikeScoreNumber: data?.walData?.bike?.score
            })
          }
          else {
            setwalkScoreState({
              walkScoreDescription: 'N/A',
              walkScoreNumber: 'N/A',
              bikeScoreDescription: 'N/A',
              bikeScoreNumber: 'N/A'
            })
          }
        })
        .catch(error => console.error(error));  
      }

      
    } 
 
    const noiseLevelFun = ()=> {
      if(propertylisting[0] != undefined) { 
        let res= getnoiselevel(propertylisting[0]?.UnparsedAddress).then((data) => {
     
          // if(data?.data?.status == 'OK') {
          //   setNoiseLevel(data?.data?.result[0].scoretext)
          // }
          if ((data as any).data?.status === 'OK' && (data as any).data.result[0]) {
            setNoiseLevel((data as any).data.result[0].scoretext);
          }
          else {
            setNoiseLevel('N/A')
          }
        }).catch(error => console.error(error)); 
      } 
    }

    const CustomRightArrow =({onClick}: any)=> {
      function handleClick() {
        // do whatever you want on the right button click
        setCurrentPage((prev)=> prev + 1)
        // ... and don't forget to call onClick to slide
        onClick();
      }
    
      return (
        <button
          onClick={handleClick}
          aria-label="Go to next slide"
          className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
        />
      );
    }
    
    
  // const dd = useSelector((state) => state.location)
  // console.log(dd,"mmmm");


  const [dates, setDates] = useState([]);

  useEffect(() => {
    const loopDates = () => {
      const start = new Date();
      const end = new Date(start.getFullYear(), 11, 31);
  

      const datesArray:any = [];
      for (let i = start; i <= end; i.setDate(i.getDate() + 1)) {
        datesArray.push({
          date: i.toLocaleDateString(),
          day: i.toLocaleString('en-US', { weekday: 'long' }),
          month: i.toLocaleString('en-US', { month: 'long' }),
          year: i.getFullYear(),
          daydate: i.getDate()
        });
      }
      setDates(datesArray);
    };
    loopDates();
  }, []);



  const handlePreviousPage = () => {
    let prepath = location?.state?.prevPath;
    history.push(prepath ? prepath : "");
  };

  const checkPosition = () => {
    if (saleTopSection.current) {
      //  @ts-ignore
      const rect = saleTopSection.current.getBoundingClientRect();
      setIsAtTop(rect.top <= 0); 
    }
  };

  useEffect(() => {
    checkPosition(); // Initial check
    window.addEventListener('scroll', checkPosition);

    return () => {
      window.removeEventListener('scroll', checkPosition);
    };
  }, []);

  
  return (
    <>
      <Navbar />

      {loader === false ? (
        
        propertylisting?.map((v: any) => {
         
          return (
            <>
              <section className="singleproduct">
                <div className="container-fluid custom-container">
                  {v?.Media !== null ? (
                    v?.Media?.length > 0 ? (
                      <>
                        <div className="gallar">
                          <div className="left-img">
                            <img
                              src={v?.Media[0]?.MediaURL}
                              className="img-fluid gallary-img big-img"
                            />
                            
                            <button
                              className="viewallphoto"
                              onClick={() => {
                                setToggle(true);
                              }}
                            >
                              <img src={frame} className="mr-2" />
                              View all photos
                            </button>
                          </div>
                          <div className="right img">
                            {v?.Media[1]?.MediaURL && (
                              <img
                                src={v.Media[1].MediaURL}
                                className="gallary-img left-gallary"
                              />
                            )}
                            {!v?.Media[1]?.MediaURL && (
                              <img src={noimage} className="featured-idx-img" />
                            )}
                            {v?.Media[2]?.MediaURL && (
                              <img
                                src={v.Media[2].MediaURL}
                                className="gallary-img left-gallary"
                              />
                            )}
                            {!v?.Media[1]?.MediaURL && (
                              <img src={noimage} className="featured-idx-img" />
                            )}
                            {v?.Media[3]?.MediaURL && (
                              <img
                                src={v.Media[3].MediaURL}
                                className="gallary-img left-gallary"
                              />
                            )}
                            {!v?.Media[1]?.MediaURL && (
                              <img src={noimage} className="featured-idx-img" />
                            )}
                            {v?.Media[4]?.MediaURL && (
                              <img
                                src={v.Media[4].MediaURL}
                                className="gallary-img left-gallary"
                              />
                            )}
                            {!v?.Media[1]?.MediaURL && (
                              <img src={noimage} className="featured-idx-img" />
                            )}
                          </div>
                        </div>
                        <div>
                          <div className="gallery-modal">
                            <Modal
                              show={toggle}
                              size={"xl"}
                              onClose={() => setToggle(false)}
                              header={"To see all photos"}
                              className={"gallery-modal modal-dialog-centered "}
                              body={
                                <div className="gallery-carousel">
                                  <Carousel
                                    swipeable={true}
                                    draggable={true}
                                    showDots={true}
                                    responsive={responsivegalleryimg}
                                    ssr={true} // means to render carousel on server-side.
                                    infinite={true}
                                    autoPlaySpeed={1000}
                                    keyBoardControl={true}
                                    customTransition="all .5"
                                    transitionDuration={500}
                                    containerClass="carousel-container"
                                    // removeArrowOnDeviceType={[
                                    //   "tablet",
                                    //   "mobile",
                                    // ]}
                                    dotListClass="custom-dot-list-style"
                                    itemClass="carousel-item-padding-40-px"
                                    arrows={true}
                                  >
                                    {v?.Media.length > 0 ? (
                                      v?.Media.map((x: any) => {
                                        return (
                                          <img
                                            src={x?.MediaURL}
                                            className="featured-idx-img"
                                          />
                                        );
                                      })
                                    ) : (
                                      <img
                                        src={noimage}
                                        className="featured-idx-img"
                                      />
                                    )}
                                  </Carousel>
                                </div>
                              }
                            ></Modal>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="no-image">
                        <img src={noimage} />
                      </div>
                    )
                  ) : (
                    <div className="no-image">
                      <img src={noimage} />
                    </div>
                  )}

                </div>
                  <section className={isAtTop ? "sale is-sticky" : "sale"}  id="sale" ref={saleTopSection}> 
                    <span className="sale-text">for sale</span>
                    <button className="btn propBackbtn" onClick={(e: any) => handlePreviousPage()}>
                     <img src={arrow} />
                    </button>
                    <div className="row">
                      <div className="col-md-4 mt-3">
                        <div className="price-details">
                          <h1>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                            }).format(v?.ListPrice)}
                          </h1>
                        </div>
                      </div>
                      <div className="col-md-4 text-center">
                        <div className="address">
                          <span>{v.UnparsedAddress.split(",")[0]}</span>
                          <div
                            className="sub-address-inner"
                            data-testid="card-address-2"
                          >
                            {v?.City}, &nbsp;
                            {v?.StateOrProvince} &nbsp;
                            {v?.PostalCode}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 text-end">
                        <div className="action">
                        {likedislike?.favorites === false ?
                         likedislike?.dislikes === true ? 
                            <span className="spancircle disliked" onClick={() => handleDislike(true)}>
                              <img src={closeIcon} />
                            </span>
                            :
                            <span className="spancircle" onClick={() => handleDislike(false)}>
                              <img src={closeIcon} />
                            </span> 
                            :
                            ''
                          } 
                          {/* <span className="spancircle">
                            <img src={closeIcon} />
                          </span> */}
                          {/* <span className="spancircle">
                            <img src={uploadIcon} />
                          </span> */}
                            {likedislike?.dislikes === false ?
                             likedislike?.favorites === true ? 
                              <span className="spancircle addwishlist" onClick={() => handleFvrt(true)}>
                              <i
                                className='fa-solid fa-heart wishlist-icon'
                              ></i>
                            </span>
                            :
                            <span className="spancircle addwishlist" onClick={() => handleFvrt(false)}>
                              <i className="fa-regular fa-heart wishlist-regular-icon"></i>
                            </span>
                             :
                            ''
                          } 

           
                        </div>
                      </div>
                    </div>
                  </section>
                <section className="productdetail">
                  <div className="container-fluid custom-container">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="property-fecility">
                          <div className="bed">
                            <span>
                              <img src={bed} />
                            </span>
                            <span>
                              <span className="facility-similar-style">
                                {v?.BedroomsTotal ? v?.BedroomsTotal : 0}
                              </span>{" "}
                              Bed
                            </span>
                          </div>
                          <div className="bath">
                            <span>
                              <img src={bath} />
                            </span>
                            <span>
                              <span className="facility-similar-style">
                                {v?.BathroomsTotalInteger ? v?.BathroomsTotalInteger : 0}
                              </span>{" "}
                              Bath
                            </span>
                          </div>
                          <div className="dimention">
                            <span>
                              <img src={dimention} />
                            </span>
                            <span>
                              <span className="facility-similar-style">
                                {v?.BuildingAreaTotal ? v?.BuildingAreaTotal : 0}
                              </span>{" "}
                              Sq Ft
                            </span>
                          </div>
                          <div className="garage">
                            <span>
                              <img src={garage} />
                            </span>
                            <span>
                              <span className="facility-similar-style">
                                {v?.GarageSpaces ? v?.GarageSpaces : 0}
                              </span>{" "}
                              Garage
                            </span>
                          </div>
                          <div className="wrench">
                            <span>
                              <img src={wrench} />
                            </span>
                            <span>
                              <span className="facility-similar-style">
                                {v?.YearBuilt ? v?.YearBuilt : 0}
                              </span>{" "}
                              Built
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="container-fluid custom-container">
                  <section className="property-overview property-overview-updated" id="property-overview">
                    <div className="row">
                      <div className="col-lg-7 col-md-6 left-property">
                        <div className="accordion-main" id="accordion-main">
                          <div className="accordion" id="accordionExample">
                          <div className="accordion-item border-bottom-0">
                              <h2 className="accordion-heading">
                              <img  alt="..."
                                             src={House4}
                                              className="image-sm mr-3"
                                            /> 
                                  Property Overview
                              </h2>
                              <div className="accordion-collapse collapse show">
                                <div className="accordion-body property-overview-list">
                                  
                             <p>
                             {v?.PublicRemarks
                                ? v?.PublicRemarks
                                : "NA"}
                             </p>
                                  
                                  
                                </div>
                              </div>
                              
                               
                            </div>
                            <div className="accordion-item key-info">
                              <h2 className="accordion-heading">
                                 
                                  <img src={AlertCircleIcon} className="mr-2" />
                                  Key Information 
                              </h2>
                              <div
                                id="collapseOne"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <div className="table-responsive">
                                    <table className="table">
                                      <tbody>
                                        <tr>
                                          <td
                                            scope="row"
                                            data-label="Job Title"
                                          >
                                            <img
                                              alt="..."
                                              src={House4}
                                              className="image-sm mr-3"
                                            />
                                            <a className="text-heading">
                                              Property Type
                                            </a>
                                          </td>
                                          <td>
                                            <strong className="text-heading">
                                              {v?.PropertyType
                                                ? v?.PropertyType
                                                : "NA"}
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td data-label="Job Title">
                                            <img
                                              alt="..."
                                              src={Hamer}
                                              className="image-sm mr-3"
                                            />
                                            <a className="text-heading">
                                              Year built
                                            </a>
                                          </td>

                                          <td>
                                            <strong>
                                              {v?.YearBuilt
                                                ? v?.YearBuilt
                                                : "NA"}
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td data-label="Job Title">
                                            <img
                                              alt="..."
                                              src={Ruler}
                                              className="image-sm mr-3"
                                            />
                                            <a className="text-heading">
                                              Price per sqft
                                            </a>
                                          </td>

                                          <td>
                                            <strong>
                                              {v?.BK9_L_PricePerSQFT
                                                ? v?.BK9_L_PricePerSQFT
                                                : 0}
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td data-label="Job Title">
                                            <img
                                              alt="..."
                                              src={calander}
                                              className="image-sm mr-3"
                                            />
                                            <a className="text-heading">
                                              Time on market
                                            </a>
                                          </td>
                                          {/* OnMarketDate should be minus to todays date days should shown */}
                                          <td>
                                            <strong>
                                              {v?.OffMarketDate && v?.OnMarketDate ? v?.OffMarketDate - v?.OnMarketDate + ' days' : 'NA'}
                                              </strong>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td data-label="Job Title">
                                            <img
                                              alt="..."
                                              src={LownMower}
                                              className="image-sm mr-3"
                                            />
                                            <a className="text-heading">
                                              HOA Dues
                                            </a>
                                          </td>
                                          <td>
                                            <strong>
                                              {v?.AssociationFee
                                                ? v?.AssociationFee
                                                : "NA"}
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td data-label="Job Title">
                                            <img
                                              alt="..."
                                              src={BuildingNature}
                                              className="image-sm mr-3"
                                            />
                                            <a className="text-heading">
                                              Stories
                                            </a>
                                          </td>
                                          <td>
                                            <strong>
                                              {v?.Levels ? v?.Levels?.map((v: any) => v) : "NA"}
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td data-label="Job Title">
                                            <img
                                              alt="..."
                                              src={BuildingModern}
                                              className="image-sm mr-3"
                                            />
                                            <a className="text-heading">
                                              Community
                                            </a>
                                          </td>
                                          <td>

                                            <strong> {v?.SubdivisionName ? v?.SubdivisionName : "NA"}</strong>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td data-label="Job Title">
                                            <img
                                              alt="..."
                                              src={WindCock}
                                              className="image-sm mr-3"
                                            />
                                            <a className="text-heading">
                                              County
                                            </a>
                                          </td>
                                          <td>
                                            <strong>
                                              {v?.Country ? v?.Country : "NA"}
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td data-label="Job Title">
                                            <img
                                              alt="..."
                                              src={DoorLeftHandClose}
                                              className="image-sm mr-3"
                                            />
                                            <a className="text-heading">MLS#</a>
                                          </td>
                                          <td>
                                            <strong>
                                              {v?.ListingId ? v?.ListingId : "NA"}
                                            </strong>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td data-label="Job Title">
                                            <img
                                              alt="..."
                                              src={TreeValey}
                                              className="image-sm mr-3"
                                            />
                                            <a className="text-heading">
                                              Lot Size
                                            </a>
                                          </td>
                                          <td>
                                            <strong>
                                              {v?.LotSizeAcres
                                                ? v?.LotSizeAcres
                                                : "NA"}
                                            </strong>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              
                              <h2 className="accordion-header" id="headingOne">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne"
                                  aria-expanded="true"
                                  aria-controls="collapseOne"
                                  onClick={() => setAccordionState({...accordionState, keyInfo:!accordionState.keyInfo})}
                                >
                                   {accordionState?.keyInfo ? <img src={expandCircleIcon} className="mr-2" /> : <img src={closeCircleIcon} className="mr-2" />}
                                  
                                  {
                                    accordionState?.keyInfo ? 'Show ' : 'Hide '
                                  }
                                   Key Information
                                </button>
                              </h2>
                            </div>
                            <div className="accordion-item">
                            <h2 className="accordion-heading"> <img src={IndoorPlant} className="mr-2" />
                            Home Details</h2> 
                              <div
                                id="collapseTwo"
                                className="accordion-collapse collapse show"
                                aria-labelledby="collapseTwo"

                              >
                                <div className="accordion-body">
                                  <h3>
                                    <img src={singlebed} className="mr-2" />
                                    {v?.BedroomsTotal
                                      ? v?.BedroomsTotal
                                      : "NA"}{" "}
                                    Bedrooms
                                  </h3>
                                  <div className="key-information-table border-bottom">
                                    <div className="table-responsive mb-5">
                                      <table className="table table-striped">
                                        <thead>
                                          <tr>
                                            <th scope="col"></th>
                                            <th scope="col">LEVEL</th>
                                            <th scope="col">SIZE</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>Bed # 1</td>
                                            <td>
                                              {v?.RoomBedroom1Level
                                                ? v?.RoomBedroom1Level
                                                : "NA"}
                                            </td>
                                            <td>
                                              {" "}
                                              {v?.RoomBedroom1Length} x{" "}
                                              {v?.RoomBedroom1Width}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Bed # 2</td>
                                            <td>{v?.RoomBedroom2Level}</td>
                                            <td>
                                              {" "}
                                              {v?.RoomBedroom2Length} x{" "}
                                              {v?.RoomBedroom2Width}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Bed # 3</td>
                                            <td>{v?.RoomBedroom3Level}</td>
                                            <td>
                                              {" "}
                                              {v?.RoomBedroom3Length} x{" "}
                                              {v?.RoomBedroom3Width}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Bed # 4</td>
                                            <td>{v?.RoomBedroom4Level}</td>
                                            <td>
                                              {v?.RoomBedroom4Length} x{" "}
                                              {v?.RoomBedroom4Width}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Bed # 5</td>
                                            <td>{v?.RoomBedroom5Level}</td>
                                            <td>
                                              {v?.RoomBedroom5Length} x{" "}
                                              {v?.RoomBedroom5Width}
                                           </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>

                                  <h3 className="mt-5">
                                    <img src={bathtab} className="mr-2" />
                                    {v?.BathroomsTotalInteger
                                      ? v?.BathroomsTotalInteger
                                      : "NA"}{" "}
                                    Bathrooms
                                  </h3>
                                  <div className="table-responsive key-information-table mb-5 border-bottom pb-5">
                                    <table className="table table-striped">
                                      <thead>
                                        <tr>
                                          <th scope="col"></th>
                                          <th scope="col">LEVEL</th>
                                          <th scope="col">SIZE</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>Bath # 1</td>
                                          <td>
                                            {v?.levels ? v?.levels : "N/A"}
                                          </td>
                                          <td>Full</td>
                                        </tr>
                                        <tr>
                                          <td>Bath # 2</td>
                                          <td>Main Floor</td>
                                          <td>1/2</td>
                                        </tr>
                                        <tr>
                                          <td>Bath # 3</td>
                                          <td>Second Floor</td>
                                          <td>Full</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  {/* <h2 className="mb-5">Full details:-</h2> */}
                                  <h3>
                                    <img
                                      src={IndoorPlant}
                                      className="image-sm mr-2"
                                    />
                                    Room Information
                                  </h3>
                                  <ul className="mb-4">
                                    <li>Basement: Egress</li>
                                    <li>
                                      <span>Basement Area :</span>
                                      {v?.RoomBasementArea
                                        ? v?.RoomBasementArea
                                        : "NA"}{" "}
                                      sqft
                                    </li>
                                    <li>
                                      Master Bedroom Level :{" "}
                                      {v?.RoomMasterBedroomLevel
                                        ? v?.RoomMasterBedroomLevel
                                        : "NA"}
                                    </li>
                                    <li>
                                      Master Bedroom Features :{" "}
                                      {v?.RoomMasterBedroomFeatures
                                        ? v?.RoomMasterBedroomFeatures?.join(", ")
                                        : "NA"}
                                    </li>

                                    <li>
                                      Master Bathroom Features :{" "}
                                      {v?.RoomMasterBathroomFeatures
                                        ? v?.RoomMasterBathroomFeatures?.join(", ")
                                        : "NA"}
                                    </li>
                                    <li>
                                      Living Room Level :{" "}
                                      {v?.RoomLivingRoomLevel}
                                    </li>
                                    <li>
                                      Living Room Features :{" "}
                                      {v?.RoomLivingRoomFeatures
                                        ? v?.RoomLivingRoomFeatures?.join(", ")
                                        : "NA"}
                                    </li>
                                    <li>
                                      <span>Kitchen Level : </span>
                                      {v?.RoomKitchenLevel
                                        ? v?.RoomKitchenLevel
                                        : "NA"}
                                    </li>
                                    <li>
                                      <span className="mr-1">
                                        Kitchen Features :
                                      </span>
                                      {v?.RoomKitchenFeatures
                                        ? v?.RoomKitchenFeatures?.join(", ")
                                        : "NA"}
                                    </li>
                                    <li>
                                      Dining Room Level :{" "}
                                      {v?.RoomDiningRoomLevel
                                        ? v?.RoomDiningRoomLevel
                                        : "NA"}
                                    </li>
                                    <li>
                                      Dining Room Features :{" "}
                                      {v?.RoomDiningRoomFeatures
                                        ? v?.RoomDiningRoomFeatures?.join(", ")
                                        : "NA"}
                                    </li>
                                    <li>
                                      Bedroom 1 Features :{" "}
                                      {v?.RoomBedroom1Features
                                        ? v?.RoomBedroom1Features?.join(", ")
                                        : "NA"}
                                    </li>
                                    <li>
                                      Bedroom 2 Features :{" "}
                                      {v?.RoomBedroom2Features
                                        ? v?.RoomBedroom2Features?.join(", ")
                                        : "NA"}
                                    </li>
                                    <li>
                                      Bedroom 3 Features :{" "}
                                      {v?.RoomBedroom3Features
                                        ? v?.RoomBedroom3Features?.join(", ")
                                        : "NA"}
                                    </li>
                                    <li>
                                      Bedroom 4 Features :{" "}
                                      {v?.RoomBedroom4Features
                                        ? v?.RoomBedroom4Features?.join(", ")
                                        : "NA"}
                                    </li>
                                    <li>
                                      Bedroom 5 Features :{" "}
                                      {v?.RoomBedroom5Features
                                        ? v?.RoomBedroom5Features?.join(", ")
                                        : "NA"}
                                    </li>
                                  </ul>

                                  <h3>
                                    <img
                                      src={washingmachine}
                                      className="image-sm mr-2"
                                    />
                                    Interior Features
                                  </h3>
                                  <ul className="mb-4">
                                    <li>
                                      Has Fireplace :{" "}
                                      {v?.FireplaceYN === true ? "Yes" : "No"}
                                    </li>
                                    <li>
                                      # of Fireplaces :{" "}
                                      {v?.FireplacesTotal
                                        ? v?.FireplacesTotal
                                        : "NA"}
                                    </li>
                                    <li>
                                      Fireplace Features :{" "}
                                      {v?.FireplaceFeatures ? v?.FireplaceFeatures?.map((v: any) => v) : "NA"}
                                    </li>
                                    <li>
                                      {v?.appliances ? v?.appliances : "NA"}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <h2 className="accordion-header" id="headingTwo">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseTwo"
                                  aria-expanded="true"
                                  aria-controls="collapseTwo"
                                  onClick={() => setAccordionState({...accordionState, homeInfo:!accordionState.homeInfo})}
                                >
                              {accordionState?.homeInfo ? <img src={expandCircleIcon} className="mr-2" /> : <img src={closeCircleIcon} className="mr-2" />}
                                  {
                                    accordionState?.homeInfo ? 'Show ' : 'Hide '
                                  }
                                   Home Details
                                </button>
                              </h2>
                            </div>
                            <div className="accordion-item">
                            <h2 className="accordion-heading">  <img src={CarGarage} className=" mr-2" />
                            Parking</h2> 
                              
                              <div
                                id="collapseThree"
                                className="accordion-collapse collapse show"
                                aria-labelledby="collapseThree"

                              >
                                <div className="accordion-body">
                                  <h3>
                                    <img
                                      src={CarGarage}
                                      className="image-sm mr-2"
                                    />
                                    Garage Features
                                  </h3>
                                  <ul className="mb-4">
                                    <li>
                                      <span className="mr-1">Has Garage :</span>
                                      {v?.GarageYN === true ? "Yes" : "NO"}
                                    </li>
                                    <li>
                                      <span>Garage Spaces :</span>{" "}
                                      {/* {v?.GarageSpaces === true ? "Yes" : "No"} */}
                                      {v?.GarageSpaces ? v?.GarageSpaces : "NA"}
                                    </li>
                                    <li>
                                      <span className="mr-1">
                                        Has Attached Garage :
                                      </span>
                                      {v?.AttachedGarageYN === true
                                        ? "Yes"
                                        : "No"}
                                    </li>
                                  </ul>

                                  <h3>
                                    <img
                                      src={CarGarage}
                                      className="image-sm mr-2"
                                    />
                                    Parking Features
                                  </h3>
                                  <ul className="mb-4">
                                    <li>
                                      <span className="mr-1">
                                        Parking Total :
                                      </span>
                                      {v?.ParkingTotal ? v?.ParkingTotal : "NA"}
                                    </li>
                                    <li>
                                      <span className="mr-1">
                                        Parking Features :
                                      </span>
                                      {v?.ParkingFeatures ? v?.ParkingFeatures?.map((v: any, index: number) => index === 0 ? v : `, ${v}`) : "NA"}
                                    </li>
                                    <li>
                                      <span className="mr-1">
                                        Covered Spaces :
                                      </span>
                                      {v?.CoveredSpaces
                                        ? v?.CoveredSpaces
                                        : "NA"}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <h2
                                className="accordion-header"
                                id="headingThree"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseThree"
                                  aria-expanded="true"
                                  aria-controls="collapseThree"
                                  onClick={() => setAccordionState({...accordionState, parkingInfo:!accordionState.parkingInfo})}
                                >
                               {accordionState?.parkingInfo ? <img src={expandCircleIcon} className="mr-2" /> : <img src={closeCircleIcon} className="mr-2" />}
                                  {
                                    accordionState?.parkingInfo ? 'Show ' : 'Hide '
                                  }
                                   Parking
                                </button>
                              </h2>
                            </div>
                            <div className="accordion-item">
                            <h2 className="accordion-heading">  <img src={TileRoof} className="mr-2" />
                            Exterior</h2> 
                            
                              <div
                                id="collapseFour"
                                className="accordion-collapse collapse show"
                                aria-labelledby="collapseFour"

                              >
                                <div className="accordion-body">
                                  <h3>
                                    <img
                                      src={architect}
                                      className="image-sm mr-2"
                                    />
                                    Building Information
                                  </h3>
                                  <ul className="mb-4">
                                    <li>
                                      <span>Levels:</span>
                                      {v?.Levels ? v?.Levels?.join(", ") : "NA"}
                                    </li>
                                    <li>
                                      <span className="mr-1">Year Built:</span>
                                      {v?.YearBuilt ? v?.YearBuilt : "NA"}
                                    </li>
                                    <li>
                                      <span className="mr-1">
                                        Construction Materials :
                                      </span>
                                      {v?.ConstructionMaterials ? v?.ConstructionMaterials?.join(", ") : "NA"}
                                    </li>
                                    <li>
                                      <span>Model:</span>{" "}
                                      {v?.Model ? v?.Model : "NA"}
                                    </li>
                                    <li>
                                      <span className="mr-1">
                                        Is New Construction :
                                      </span>
                                      {v?.NewConstructionYN ? v?.NewConstructionYN === true ? "Yes" : "No" : "NA"}
                                    </li>
                                    <li>
                                      <span className="mr-1">
                                        Building Area Total :
                                      </span>
                                      {v?.BuildingAreaTotal
                                        ? v?.BuildingAreaTotal
                                        : "NA"}
                                    </li>
                                    <li>
                                      <span className="mr-1">
                                        Building Area Units :
                                      </span>
                                      {v?.BuildingAreaUnits
                                        ? v?.BuildingAreaUnits
                                        : "NA"}
                                    </li>
                                    <li>
                                      <span className="mr-1">
                                        Builder Name :
                                      </span>
                                      {v?.BuilderName ? v?.BuilderName : "NA"}
                                    </li>
                                  </ul>

                                  <h3>
                                    <img
                                      src={TileRoof}
                                      className="image-sm mr-2"
                                    />
                                    Exterior Features
                                  </h3>
                                  <ul className="mb-4">
                                    <li>
                                      Patio and Front Porch Features :{" "}
                                      {v?.PatioAndPorchFeatures ? v?.PatioAndPorchFeatures?.join(", ") : "NA"}
                                    </li>
                                    <li>
                                      Sprinkler System :{" "}{v?.ExteriorFeatures ? v?.ExteriorFeatures?.join(", ") : "NA"}
                                    </li>
                                    <li> Roof : {v?.Roof ? v?.Roof?.join(", ") : "NA"} </li>
                                  </ul>

                                  <h3>
                                    <img
                                      src={House4}
                                      className="image-sm mr-2"
                                    />
                                    Property Information
                                  </h3>
                                  <ul className="mb-4">
                                    <li>
                                      Property Type :{" "}
                                      {v?.PropertyType ? v?.PropertyType : "NA"}
                                    </li>
                                    <li>
                                      Property Sub Type :{" "}
                                      {v?.PropertySubType
                                        ? v?.PropertySubType
                                        : "NA"}
                                    </li>
                                    <li>
                                      Property Condition : {v?.PropertyCondition ? v?.PropertyCondition.join(", ") : "NA"}
                                    </li>
                                    <li>
                                      Living Area :{" "}
                                      {v?.LivingArea ? v?.LivingArea : "NA"}
                                    </li>
                                    <li>
                                      Living Area Units :{" "}
                                      {v?.LivingAreaUnits
                                        ? v?.LivingAreaUnits
                                        : "NA"}
                                    </li>
                                    <li>
                                      Below Grade Finished Area :{" "}
                                      {v?.BelowGradeFinishedArea == null
                                        ? "NA"
                                        : v?.BelowGradeFinishedArea}
                                    </li>
                                    <li>
                                      Below Grade Finished Area Units :{" "}
                                      {v?.BelowGradeFinishedAreaUnits
                                        ? v?.BelowGradeFinishedAreaUnits
                                        : "NA"}
                                    </li>
                                    <li>
                                      Above Grade Finished Area :{" "}
                                      {v?.AboveGradeFinishedArea
                                        ? v?.AboveGradeFinishedArea
                                        : "NA"}
                                    </li>
                                    <li>
                                      Above Grade Finished Area :{" "}
                                      {v?.AboveGradeFinishedAreaUnits
                                        ? v?.AboveGradeFinishedAreaUnits
                                       : "NA"}
                                    </li>
                                  </ul>
                                  <h3>
                                    <img
                                      src={TreeValey}
                                      className="image-sm mr-2"
                                    />
                                    Land Information
                                  </h3>
                                  <ul className="mb-4">
                                    <li>
                                      Foundation Details :{" "}
                                      {v?.FoundationDetails
                                        ? v?.FoundationDetails?.join(", ")
                                        : "NA"}
                                    </li>
                                  </ul>
                                  <h3>
                                    <img
                                      src={flower}
                                      className="image-sm mr-2"
                                    />
                                    Lot Information
                                  </h3>
                                  <ul className="mb-4">
                                    <li>
                                      Lot Features :{" "}
                                      {v?.LotFeatures ? v?.LotFeatures?.join(", ") : "NA"}
                                    </li>
                                    <li>
                                      Lot Size Dimensions :{" "}
                                      {v?.LotSizeDimensions
                                        ? v?.LotSizeDimensions
                                        : "NA"}
                                    </li>
                                    <li>
                                      Lot Size Acres :{" "}
                                      {v?.LotSizeAcres ? v?.LotSizeAcres : "NA"}
                                    </li>
                                    <li>Lot Included in Price: </li>
                                  </ul>
                                </div>
                              </div>
                              <h2 className="accordion-header" id="headingFour">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseFour"
                                  aria-expanded="true"
                                  aria-controls="collapseFour"
                                  onClick={() => setAccordionState({...accordionState, exteriorInfo:!accordionState.exteriorInfo})}
                                >
                                 {accordionState?.exteriorInfo ? <img src={expandCircleIcon} className="mr-2" /> : <img src={closeCircleIcon} className="mr-2" />}
                                  {
                                    accordionState?.exteriorInfo ? 'Show ' : 'Hide '
                                  }
                                   Exterior
                                </button>
                              </h2>
                            </div>
                            <div className="accordion-item">
                            <h2 className="accordion-heading">   <img src={Heater} className="mr-2" />
                            Utilities</h2> 
                              
                              <div
                                id="collapseFive"
                                className="accordion-collapse collapse show"
                                aria-labelledby="collapseFive"

                              >
                                <div className="accordion-body">
                                  <h3>
                                    <img
                                      src={Heater}
                                      className="image-sm mr-2"
                                    />
                                    Heating & Cooling
                                  </h3>
                                  <ul className="mb-4">
                                    <li>
                                      <span>Has Heating : </span>
                                      {v?.HeatingYN === true ? "Yes" : "No"}
                                    </li>
                                    <li>
                                      <span>Heating :</span>{" "}
                                      {v?.HeatingYN ? v?.Heating?.join(", ") : "NA"}
                                    </li>
                                    <li>
                                      <span className="mr-1">Has Cooling :</span>
                                      {v?.CoolingYN === true ? "Yes" : "No"}
                                    </li>
                                    <li>
                                      <span className="mr-1">Cooling :</span>{" "}
                                      {v?.CoolingYN ? v?.Cooling?.join(", ") : "NA"}
                                    </li>
                                  </ul>

                                  <h3>
                                    <img
                                      src={utilityInfoIcon}
                                      className="image-sm mr-2"
                                    />
                                    Utility Information
                                  </h3>
                                  <ul className="mb-4">
                                    <li>
                                      {v?.utilities ? v?.utilities?.join(", ") : "NA"}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <h2 className="accordion-header" id="headingFive">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseFive"
                                  aria-expanded="true"
                                  aria-controls="collapseFive"
                                  onClick={() => setAccordionState({...accordionState, utilitiesInfo:!accordionState.utilitiesInfo})}
                                >
                                 {accordionState?.utilitiesInfo ? <img src={expandCircleIcon} className="mr-2" /> : <img src={closeCircleIcon} className="mr-2" />}
                                  {
                                    accordionState?.utilitiesInfo ? 'Show ' : 'Hide '
                                  }
                               Utilities
                                </button>
                              </h2>
                            </div>
                            <div className="accordion-item">
                            <h2 className="accordion-heading">
                            <img src={WindCock} className="mr-2" />
                            Location
                              </h2> 
                              
                              <div
                                id="collapseSix"
                                className="accordion-collapse collapse show"
                                aria-labelledby="collapseSix"

                              >
                                <div className="accordion-body">
                                  <h3>
                                    <img src={hoa} className="image-sm mr-2" />
                                    HOA Information
                                  </h3>
                                  <ul className="mb-4">
                                    <li>
                                      <span className="mr-1">
                                        Has Association :
                                      </span>
                                      {v?.AssociationYN === true ? "Yes" : "No"}
                                    </li>
                                    <li>
                                      <span className="mr-1">
                                        Association Name :
                                      </span>
                                      {v?.AssociationName
                                        ? v?.AssociationName
                                        : "NA"}
                                    </li>
                                  </ul>

                                  <h3>
                                    <img
                                      src={school}
                                      className="image-sm mr-2"
                                    />
                                    School Information
                                  </h3>
                                  <ul className="mb-4">
                                    <li>
                                      <span className="mr-1">
                                        Elementary School District :
                                      </span>
                                      {v?.ElementarySchoolDistrict
                                        ? v?.ElementarySchoolDistrict
                                        : "NA"}
                                    </li>
                                    <li>
                                      <span className="mr-1">
                                        Elementary School :
                                      </span>
                                      {v?.ElementarySchool
                                        ? v?.ElementarySchool
                                        : "NA"}
                                    </li>
                                    <li>
                                      <span className="mr-1">
                                        Middle or Junior School District :
                                      </span>
                                      {v?.MiddleOrJuniorSchoolDistrict
                                        ? v?.MiddleOrJuniorSchoolDistrict
                                        : "NA"}
                                    </li>
                                    <li>
                                      <span className="mr-1">
                                        Middle or Junior School :
                                      </span>
                                      {v?.MiddleOrJuniorSchool
                                        ? v?.MiddleOrJuniorSchool
                                        : "NA"}
                                    </li>
                                    <li>
                                      <span className="mr-1">
                                        High School District :
                                      </span>
                                      {v?.HighSchoolDistrict
                                        ? v?.HighSchoolDistrict
                                        : "NA"}
                                    </li>
                                    <li>
                                      <span className="mr-1">High School :</span>
                                      {v?.HighSchool ? v?.HighSchool : "NA"}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <h2 className="accordion-header" id="headingSix">
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseSix"
                                  aria-expanded="true"
                                  aria-controls="collapseSix"
                                  onClick={() => setAccordionState({...accordionState, locationInfo:!accordionState.locationInfo})}
                                >
                               {accordionState?.locationInfo ? <img src={expandCircleIcon} className="mr-2" /> : <img src={closeCircleIcon} className="mr-2" />}
                                  {
                                    accordionState?.locationInfo ? 'Show ' : 'Hide '
                                  }
                                  Location
                                </button>
                              </h2>
                            </div>
                            <div className="accordion-item">
                            <h2 className="accordion-heading">
                            <img src={DuplicateFile} className="mr-2" />
                                  Other
                              </h2> 
                              
                              <div
                                id="collapseSeven"
                                className="accordion-collapse collapse show"
                                aria-labelledby="collapseSeven"

                              >
                                <div className="accordion-body">
                                  <h3>
                                    <img
                                      src={DuplicateFile}
                                      className="image-sm mr-2"
                                    />
                                    Documents & Disclosures
                                  </h3>
                                  <ul className="mb-4">
                                    <li>
                                      <span className="mr-1">
                                        Documents Count :
                                      </span>
                                      {v?.DocumentsCount
                                        ? v?.DocumentsCount
                                        : "NA"}
                                    </li>
                                  </ul>

                                  {/* <h3>
                                    <img
                                      src={House4}
                                      className="image-sm mr-2"
                                    />
                                    Listing Information
                                  </h3>
                                  <ul className="mb-4">
                                    <li>
                                      <span className="mr-1">
                                        Buyer Agency Compensation :
                                      </span>
                                      {v?.BuyerAgencyCompensation
                                        ? v?.BuyerAgencyCompensation
                                        : "NA"}
                                    </li>
                                    <li>
                                      <span className="mr-1">
                                        Buyer Agency Compensation Type :
                                      </span>
                                      {v?.BuyerAgencyCompensationType
                                        ? v?.BuyerAgencyCompensationType
                                        : "NA"}
                                    </li>
                                  </ul> */}
                                </div>
                              </div>
                                <h2
                                className="accordion-header"
                                id="headingSeven"
                              >
                                <button
                                  className="accordion-button collapsed"
                                  type="button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseSeven"
                                  aria-expanded="true"
                                  aria-controls="collapseSeven"
                                  onClick={() => setAccordionState({...accordionState, otherInfo:!accordionState.otherInfo})}
                                >
                                {accordionState?.otherInfo ? <img src={expandCircleIcon} className="mr-2" /> : <img src={closeCircleIcon} className="mr-2" />}
                                  {
                                    accordionState?.otherInfo ? 'Show ' : 'Hide '
                                  }
                                  Other
                                </button>
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-5 col-md-6 fixedheight"
                        id="property-overview"
                      >
                        <div className="siderow">
                          <h1 className="property-overview-heading">
                            Location Information
                          </h1>
                          <div className="row mb-5">
                            <div className="col-md-6">
                              <div className="square">
                                <span>
                                  <span className="flex mb-3">
                                    <img src={housefilter} />
                                  </span>
                                  <span>
                                  <NavLink to="/maps" target="_blank" onClick={()=>updatelocaton("Maps")}>View Maps</NavLink>
                                
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="square">
                                <span>
                                  <span className="flex mb-3">
                                    <img src={car} />
                                  </span>
                                  <span>
                                    <NavLink to="/maps"  onClick={(e)=>updatelocaton("commute")}>Commute</NavLink>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="siderow">
                          <h1 className="property-overview-heading">
                            Neighborhood
                          </h1>
                          <div className="row mb-4">
                            <div className="col-md-6">
                              <div className="square">
                                <span>
                                  <span className="flex mb-3">
                                    <img src={floodRiskIcon} />
                                  </span>
                                  <span>
                                    <h6>Flood Risk</h6>
                                  </span>
                                  <span className="d-flex mt-4 mb-2">
                                    Risk Factor: 1/10
                                  </span>
                                  <span>
                                    <a href="#">Learn More</a>
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="square">
                                <span>
                                  <span className="flex mb-3">
                                    <img src={noiseLvlIcon} />
                                  </span>
                                  <span>
                                    <h6>Noise Level</h6>
                                  </span>
                                  
                                  <h6 className="mt-4">{noiseLevel == '' ?  <Skeleton height={20} /> : noiseLevel}</h6>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="siderow">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="square">
                                <span>
                                  <span className="flex mb-3">
                                    <img src={walkingIcon} />
                                  </span>
                                  <span>
                                    <h6>Walk Score</h6>
                                  </span>
                                  {walkScoreState.walkScoreDescription == '' ?  <Skeleton height={20} /> : <>
                                  <h6 className="d-flex mt-4 mb-2">{walkScoreState.walkScoreNumber}/100</h6>
                                  <span className="d-flex mb-2">
                                    {walkScoreState.walkScoreDescription}
                                  </span>
                                  </> 
                                  }
                                 
                                  <span>
                                    <a href="#">Learn More</a>
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="square">
                                <span>
                                  <span className="flex mb-3">
                                    <img src={bikeScoreIcon} />
                                  </span>
                                  <span>
                                    <h6>Bike Score</h6>
                                  </span>
                                  {walkScoreState.bikeScoreDescription == '' ?  <Skeleton height={20} /> : <>
                                  <h6 className="d-flex mt-4 mb-2">{walkScoreState.bikeScoreNumber}/100</h6>
                                  <span className="d-flex mb-2">
                                    {walkScoreState.bikeScoreDescription}
                                  </span>
                                  </> 
                                  }
                                  <span>
                                    <a href="#">Learn More</a>
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="scheduled-square">
                          <div className="siderow scheduleTour mt-5 ">
                            <h1 className="property-overview-heading mb-3">
                              Schedule Tour
                            </h1>
                            <div className="row">
                              <div className="col-md-6">
                                <div className={tour.inpersontour ==  true ? "scheduled-square active" : 'scheduled-square'}>
                                  <section>
                                    <div className="tile">
                                      <label htmlFor="sport1">
                                        <img src={inpersonIcon} className="mb-2" />
                                        <h6>In Person Tour</h6>
                                      
                                        <input
                                          type="radio"
                                          name="sports"
                                          id="sport1"
                                           checked
                                          onClick={() =>
                                            handleTourData("persontour")
                                          }
                                        />
                                          <div className="customCheckbox">
                                              <img src={checkIcon} alt="" />
                                          </div>
                                        <h4>Select</h4>
                                      </label>
                                    </div>
                                  </section>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className={tour.videochattour ==  true ? "scheduled-square active" : 'scheduled-square'}>
                                  <section>
                                    <div className="tile">
                                      <label htmlFor="sport2">
                                        <img src={phonetourIcon} className="mb-2" />
                                        <h6>In Video Tour</h6>
                                        <input
                                          type="radio"
                                          name="sports"
                                          id="sport2"
                                          onClick={() =>
                                            handleTourData("videotour")
                                          }
                                        />
                                        <div className="customCheckbox">
                                              <img src={checkIcon} alt="" />
                                          </div>
                                        <h4>Select</h4>
                                      </label>
                                    </div>
                                  </section>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <Carousel
                                  centerMode={true}
                                  responsive={responsive}
                                  swipeable={false}
                                  draggable={false} 
                                  arrows={true}
                                  customRightArrow={<CustomRightArrow />}  
                                >

                                  {dates.map((date, index : any) => {
                                    return(
                                  <div key={index}>
                                    <div className="invisible-checkboxes mt-3">
                                      <input
                                        type="checkbox"
                                        name="rGroup"
                                        value={date?.date}
                                        id={date?.date}
                                        onChange={()=>setTourDate(date?.date)}

                                      /> 
                                   
                                      <label
                                        className="checkbox-alias"
                                        htmlFor={date?.date}
                                      >
                                        <div className="calande-week" key={index}>
                                          <span>{date.day}</span>
                                          <span>{date.daydate}</span>
                                          <span>{date.month} {date.year}</span>
                                        </div>
                                      </label>

                                    </div>
                                  </div>
                                    )
                                  })
                                }
                                </Carousel>
                                {tourDate &&  <button
                                  className="btn btnSchedule"
                                  data-bs-toggle="modal" data-bs-target="#scheduleTour"
                                  // onClick={() => handleScheduleTour()}
                                  // disabled={tourDate && !(tour.inpersontour || tour.videochattour)}
                                >
                                  Schedule Tour
                                </button>}
                               
                               
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* @ts-ignore */}
                        <MortgageCalculator  salePrice={v?.ListPrice}/>
                        <div className="siderow scheduleTour">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="estimatedvalue">
                                <h1 className="property-overview-heading">
                                  Estimated Home Value
                                </h1>
                                <h3>August 2023</h3>
                                <div className="chartcalc">
                                  <ul>
                                    <li>
                                      <span>CoreLogic</span>
                                      <span className="payment-price">
                                        <strong>$2,944</strong>
                                      </span>
                                    </li>
                                    <li>
                                      <span>Current List Price</span>
                                      <span className="payment-price">
                                        <strong>$2,944</strong>
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="siderow mb-4">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="prophist">
                                <h1 className="property-overview-heading">
                                  Property History
                                </h1>
                                <span>
                                  <strong>Last sold</strong> October 27, 2019
                                  for $625,000
                                </span>
                                <span>
                                  <strong>Taxes</strong> in 2022 were $4,208
                                </span>
                                <span>
                                  <strong>Assessment</strong> in 2021 was
                                  $700,000
                                </span>
                                <span>Full details:</span>
                                <span>Property Price</span>
                                <div className="table-responsive">
                                  <table className="table table-striped">
                                    <thead>
                                      <tr>
                                        <th scope="col">DATE</th>
                                        <th scope="col">EVENT</th>
                                        <th scope="col">PRICE</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>05/21/23</td>
                                        <td>Listed</td>
                                        <td>$750,000</td>
                                      </tr>
                                      <tr>
                                        <td>03/30/23</td>
                                        <td>Listed</td>
                                        <td>$745,000</td>
                                      </tr>
                                      <tr>
                                        <td>11/08/19</td>
                                        <td>Relisted</td>
                                        <td>$735,000</td>
                                      </tr>
                                      <tr>
                                        <td>10/27/18</td>
                                        <td>Sold</td>
                                        <td>$735,000</td>
                                      </tr>
                                      <tr>
                                        <td>05/10/18</td>
                                        <td>Sold</td>
                                        <td>$725,000</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="mt-4">
                                  <h3>Property Tax</h3>
                                  <div className="table-responsive">
                                    <table className="table table-striped">
                                      <thead>
                                        <tr>
                                          <th scope="col">YEAR</th>
                                          <th scope="col">TAXES</th>
                                          <th scope="col">ASSESSMENT</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>2022</td>
                                          <td>$12,987</td>
                                          <td>$750,000</td>
                                        </tr>
                                        <tr>
                                          <td>2021</td>
                                          <td>$11,248</td>
                                          <td>$745,000</td>
                                        </tr>
                                        <tr>
                                          <td>2020</td>
                                          <td>$7,000</td>
                                          <td>$735,000</td>
                                        </tr>
                                        <tr>
                                          <td>2019</td>
                                          <td>$6,000</td>
                                          <td>$735,000</td>
                                        </tr>
                                        <tr>
                                          <td>2018</td>
                                          <td>$5,000</td>
                                          <td>$725,000</td>
                                        </tr>
                                        <tr>
                                          <td>2017</td>
                                          <td>$6,000</td>
                                          <td>$735,000</td>
                                        </tr>
                                        <tr>
                                          <td>2016</td>
                                          <td>$5,000</td>
                                          <td>$725,000</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="siderow scheduleTour">
                          <h1 className="property-overview-heading mb-4">
                            Attractions Nearby
                          </h1>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="attractiondiv">
                                <div>
                                  <span>
                                    <img src={fastfood} />
                                 </span>
                                  <span>Restaurants</span>
                                  <span>
                                  <NavLink to="/maps" target="_blank"  onClick={(e)=>updatelocaton("restaurant")}>View Map</NavLink>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="attractiondiv">
                                <div>
                                  <span>
                                    <img src={playground} />
                                  </span>
                                  <span>Parks</span>
                                  <span>
                                    <a href="#">View Map</a>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="siderow upcomings">
                          <div className="row">
                            <div className="col-md-12 pb-5">
                              <h1 className="property-overview-heading mt-4">
                                Upcoming Open Houses
                              </h1>
                            {
                             homeListLoader ?
                             <Skeleton height={50}/>
                             :
                             homeList?.length > 0 ? 
                             <Carousel 
                               centerMode={true}
                               responsive={responsive}  
                               swipeable={false}
                               draggable={false} 
                               arrows={true}
                               customRightArrow={<CustomRightArrow />}  
                             >
                              {homeListAllData?.map((list: any) => {
                                  return (
                               <div id={list.openHouseKey}>
                                 <div className="invisible-checkboxes mt-3">
                                  <label className="checkbox-alias">
                                     <div className="calande-week"> 
                                       <span>{moment(list.openHouseStartTime).format('dddd')}</span>
                                       <span>{moment(list.openHouseStartTime).date()}</span>
                                       <span>{moment(list.openHouseStartTime).format('MMM')} {moment(list.openHouseStartTime).format('yyyy')}</span> 
                                     </div>
                                   </label>
                                 </div>
                               </div> 
                                  )
                              })
                             }
                                     
                             </Carousel>
                           
                            :

                            <span className="upcomingcalender">
                              <i className="fa-solid fa-calendar-days mr-2"></i>
                              <span>No upcoming open houses</span>
                            </span>  
                            }
        </div>
                          </div>
                        </div>
                        {userLoggedIn &&
                        <div className="siderow scheduleTour">
                        <div className="row align-items-center">
                          <div className="col-md-12">
                            <h1 className="property-overview-heading">
                              Your Agent
                            </h1>
                          </div>
                          <div className="col-md-4">
                            <img src={karie} className="karie" />
                          </div>
                          <div className="col-md-8">
                            <h3 className="agentname">Karie Milford</h3>
                            <span>Realtor®</span>
                            
                          </div>
                          <div className="col-md-12">
                          <div className="mt-3 agent-btn-section">
                          <button className="btn btnSchedule contactbtn mr-2">  
                                Contact
                              </button>
                          <button className="btn btnSchedule contactbtn">  
                                Profile
                              </button>
                              {/* <button className="btn btnSchedule contactbtn">
                                <i className="fa-solid fa-phone mr-3"></i>{" "}
                                Call Me
                              </button>
                              <button className="btn btnSchedule contactbtn">
                                <i className="fa-solid fa-message mr-3"></i>
                                Text Me
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                        }
                        <div className="siderow similarproduct mt-5">
                          <SimilarProperties propertydetails={v} />
                          <div className="similar-viewALL text-center">
<a target="_blank">View All</a>
</div>

                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </section>
      {/* modal popup */} 
      <div className="modal fade schedule-tour-modal" id="scheduleTour" aria-labelledby="scheduleTourLabel" aria-hidden="true">
          <div className="modal-dialog">
            {inPersonTour == 1 && <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="scheduleTourLabel">Request a tour </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>setInPersonTour(1)}></button>
              </div>
              <div className="modal-body">
                 <div className="schedule-details">
                 {
                 v?.Media !== null ?  <img
                 src={v?.Media[0]?.MediaURL}
                 className="img-fluid"
               /> :
               <img src={noimage} />
                }
                    <img src="" alt="" />
                    <div className="address-fields">
                        <p>
                        <span>{v.UnparsedAddress.split(",")[0]}</span>
                          <span>

                            {v?.City}, &nbsp;
                            {v?.StateOrProvince} &nbsp;
                            {v?.PostalCode} 
                          </span>   
                          </p> 
                        <div className="room-details">
                          <div>
                            <strong> {v?.BedroomsTotal ? v?.BedroomsTotal : 0}</strong>
                            <span>bd</span>
                          </div>
                          <div>
                            <strong> {v?.BathroomsTotalInteger ? v?.BathroomsTotalInteger : 0}</strong>
                            <span>ba</span>
                          </div>
                          <div>
                            <strong> {v?.BuildingAreaTotal ? v?.BuildingAreaTotal : "NA"}</strong>
                            <span>sqft</span>
                          </div>
                        </div>
                    </div>
                 </div>
                 <div className="schedule-time">
                  <h4>Select up to 3 times</h4>
                  <input type="time" className="form-control"  onChange={(e) => setScheduleTimeValue(e.target.value)} /> 
                  <div className="added-time">
                    { 
                      scheduleTimes?.map((time: any) => {
                        return ( <span>{time}</span>)
                       })
                    }
                  </div>
                  <p className="text-danger my-2">{scheduleErrorMsg}</p>
                  {scheduleTimes.length < 3 && 
                  <div className="add-time" onClick={scheduleTime}>
                      <span><img src={plusIcon} alt="plus icon"/></span>
                      <p>Add a time</p>
                  </div>
                  }
                 </div>
              </div>
              <div className="modal-footer justify-content-center">
              <button className="cont-us" disabled={scheduleTimes.length < 1} onClick={()=>{setInPersonTour(2)}}>Next</button> 
              </div>
            </div>}
            
            {inPersonTour == 2 && <div className="modal-content tour-confirmation">
              <div className="modal-header">
                <h5 className="modal-title" id="scheduleTourLabel">Confirm your tour</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>setInPersonTour(1)}></button>
              </div>
              <div className="modal-body">
                  <div className="tour-form">
                  <h3 >How can we reach out to confirm your tour?</h3>
                    <div className="form-group">
                      <label htmlFor="">Name*</label>
                      <input type="text" placeholder="Enter your name" className="form-control" name="firstName" value={inPersonData?.firstName} onChange={(e)=>handleInPersonChange(e)}/>
                      <span className="text-danger">{inPersonData?.firstName?.trim()?.length === 0 ? inPersonTourError.firstName : ""}</span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Email*</label>
                      <input type="text" placeholder="Enter your mailing address" className="form-control" name="email" value={inPersonData?.email} onChange={(e)=>handleInPersonChange(e)}/>
                      <span className="text-danger">{(inPersonData?.email?.trim()?.length === 0  || inPersonTourError.email === "Email is not valid")? inPersonTourError.email : ""}</span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Phone*</label>
                      <input type="phone" placeholder="Enter your phone number" className="form-control" name="phone" maxLength={14} minLength={14}  value={inPersonData?.phone ? formatUSPhoneNumber(inPersonData?.phone) : ""} onChange={(e)=>handleInPersonChange(e)}/>
                      <span className="text-danger">{(inPersonData?.phone?.trim()?.length < 10 || inPersonData?.phone?.trim()?.length > 14) ?  inPersonTourError.phone : ""}</span>
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Message*</label>
                      <input type="text" placeholder="Enter your message" className="form-control" name="message" value={inPersonData?.message} onChange={(e)=>handleInPersonChange(e)}/>
                      <span className="text-danger">{inPersonData?.message?.trim()?.length === 0 ? inPersonTourError.message : ""}</span>
                    </div>
                      <p className="tour-small">
                      By pressing Request Tour, you agree that Milford Real Estate and its real estate professionals may call/text you about your inquiry. You don’t need to consent as a condition of buying any property, goods, or services. Message/data rates may apply. You also agree to our Terms of Use.
                      </p>
                  </div>
              </div>
              <div className="modal-footer justify-content-center">
              <button className="cont-us" onClick={()=>handleScheduleTour()}>Request Tour  {loading && <CSpinner color="dark" size="sm" className={ 'ml-1' } />}</button> 
              </div>
            </div>}
          </div>
        </div>

            </>
          );
        })
      ) : (
        <section className="singleproduct">
          <div className="container-fluid custom-container">
            <div className="gallar">
              <div className="left-img">
                <Skeleton height={530} />
              </div>
              <div className="right img">
                <Skeleton height={255} />
                <Skeleton height={255} />
                <Skeleton height={255} />
                <Skeleton height={255} />
              </div>
            </div>

            <section className="sale" id="sale">
              <span>
                {" "}
                <Skeleton width={50} />
              </span>
              <div className="row">
                <div className="col-md-4 mt-3">
                  <div className="price-details">
                    <h1>
                      <Skeleton />
                    </h1>
                  </div>
                </div>
                <div className="col-md-4 text-center">
                  <div className="address">
                    <span>
                      {" "}
                      <Skeleton />
                    </span>
                    <div
                      className="sub-address-inner"
                      data-testid="card-address-2"
                    >
                      <Skeleton />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </section>
      )}
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* <Footer /> */}
      <ChatBubble/>
      <NewFooter />

     
    </>
  );
};

export const mapStateToProps = (state: any) => {
  return {
    location: state.location.locationdata,
  };
};



export default connect(mapStateToProps)(SingleProduct);
