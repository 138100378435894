import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { connect } from 'react-redux'

import { State } from '../store'
import { redirect } from '../state/actions'

const Navigatable = (props:any) => {
  let history = useHistory()

  useEffect(() => {
    if(props.url)
    {
      props.dispatch(redirect())
      history.push(props.url)
    }
  })

  return (
    <div>
      { props.children }
    </div>
  )
}

export default connect((state:State) => ({
  url:state.navigate.url,
}))(Navigatable)