import React, { useEffect, useState } from 'react'
import message from "../../../assets/icons/Group 1171276216.svg";
import { Link } from 'react-router-dom';



const ChatBubble = () => { 
    const [bubble, setBubble]  = useState(false)
    useEffect(() => {
        let user: any = localStorage.getItem("user");
        let user_id = JSON.parse(user);
        if (user_id !== null) {
            setBubble(true)
        }  
      }, [])

    return (
        <>
         
            
                <div className="message-bubble">
                    <Link to='/contactus' className='btn'> 
                        <img src={message} alt="" /> 
                    </Link>
                </div>
            
        </>
    )
}

export default ChatBubble