import React, { useState } from "react";
import quizfrst from "../../../assets/headerpage-icon/quiz1.png";
import thankLogo from "../../../assets/headerpage-icon/thank-logo.png";
import quiz2 from "../../../assets/headerpage-icon/quiz2.png";
import quiz3 from "../../../assets/headerpage-icon/quiz3.png";
import quiz4 from "../../../assets/headerpage-icon/quiz4.png";
import quiz5 from "../../../assets/headerpage-icon/quiz5.png";
import quiz6 from "../../../assets/headerpage-icon/quiz6.png";
import quiz7 from "../../../assets/headerpage-icon/quiz7.png";
import quiz8 from "../../../assets/headerpage-icon/quiz8.png";
import quiz9 from "../../../assets/headerpage-icon/quiz9.png";
import quiz10 from "../../../assets/headerpage-icon/quiz10.png";
import { savequizdetails } from "../../../api/leads";
import { errorMessage, successMessage } from "../Util/Toaster";
import { CSpinner } from "@coreui/react";

export interface QuestionAnswer {
  selected: any; 
  // other properties...
}
const QuizStepper = (props : any) => {
  const {email, phone, user_name} = props.data 

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [quiz, setQuiz] = useState([
    {
      question: "Would you say you're more introverted or extroverted?",
      options: ["Introverted", "Extroverted", "Depeneds on the day"],
      selected: null,
    },
    {
      question: "How would you describe your perfect home your perfect home?",
      options: [
        "Well-kept and realxing",
        "Somewhere cozy for my family to hang out",
        "A place where I can have a good time",
      ],
      selected: null,
    },

    {
      question: "Which movie title best describes your life?",
      options: ["Analyze This", "Field of Dreams", "American Hustle"],
      selected: null,
    },
    {
      question: "What's your decision-making style?",
      options: [
        "I often put off making decisions",
        "I spend a lot of time writing pros and cons before making a decision",
        "I trust myself to make decisions quickly and easily",
      ],
      selected: null,
    },
    {
      question: "If you could live anywhere, where would you go?",
      options: [
        "New York: fast-paced, place for me to shine and competitive environment",
        "Spain: siestas, churros, and a sense of community",
        "Los Angeles: creative, enlightening, inspirational",
      ],
      selected: null,
    },

    {
      question: "Which best describes your style?",
      options: ["Bold", "Casual", "Chic"],
      selected: null,
    },

    {
      question: "Which store would you be most likely to shop at?",
      options: ["Apple", "Dior", "Chic"],
      selected: null,
    },
    {
      question: "On the weekends you can find me:",
      options: [
        "Relaxing with my family on the couch",
        "Spending time outdoors",
        "Crafting new diy projects in my garage",
      ],
      selected: null,
    },

    {
      question: "What is your ideal agent personality?",
      options: ["Outgoing", "Direct", "Easy-going"],
      selected: null,
    },

    {
      question: "Do you prefer a male or female agent?",
      options: ["Male", "Female", "No preference"],
      selected: null,
    },
  ]);

  const handleSelectOption = (index: any) => {
    const copy = [...quiz];
    copy[currentQuestion].selected = index;
    setQuiz(copy);
    setSelectedOption(index);
  };

  const checkSelection = () => {
    return quiz[currentQuestion].selected;
  };

  const nextQuestion = () => {
    if (checkSelection()) {
      if (currentQuestion < quiz.length - 1) {
        setScore(score + 1);
        setCurrentQuestion(currentQuestion + 1);
        storeSelectedOption();
      } else {
        storeSelectedOption();
        setSpinLoading(true);
        // alert("Quiz completed! Your answers have been saved.");
        const submittedForm = JSON.parse(localStorage.getItem("storedOptions") || '');
        // const qustionAnser = submittedForm.map((obj: any) => ({ ...obj, answer: obj.selected }));
        // const qustionAnswer = submittedForm.map(({ selected: answer, ...rest }) => ({  answer, ...rest}));
        const qustionAnswer = submittedForm.map(({ selected, ...rest }: QuestionAnswer) => ({ answer: selected, ...rest }));
        if(email != undefined || phone != undefined || user_name != undefined ) {
            let saveQuizPayload = {
                user_info : {
                    name: user_name,
                    email: email,
                    phone: phone
                },
                quizz_Question_info: qustionAnswer
              };   

              let res = savequizdetails(saveQuizPayload).then((data) => { 
          
                if(data?.data?.status == "success") {
                    successMessage(data?.data?.message);
                    setSpinLoading(false);
                    setQuizSubmitted(true);
                }
                else {
                    errorMessage(data?.data?.message);
                    setSpinLoading(false);
                }
              })
              .catch(error => console.error(error));    
        }
       
      }
    } else {
        errorMessage('Please select an option before moving on to the next question.');
    //   alert("Please select an option before moving on to the next question.");
    }
  };

  const storeSelectedOption = () => {
    localStorage.setItem(
      "storedOptions",
      JSON.stringify(quiz.map(({ options, ...rest }) => ({ ...rest })))
    );
  };

  const previousQuestion = () => {
    if (currentQuestion > 0) {
      setScore(score - 1);
      setCurrentQuestion(currentQuestion - 1);
    }
  };

  const optionLetters = ["A", "B", "C"];
  const renderOptions = () => {
    return quiz[currentQuestion].options.map((option, index) => (
      <div
        // className={`quiz-box ${option == selectedOption ? "active" : ""}`}
        className={`quiz-box ${quiz[currentQuestion].selected == option ? "active" : ""}`}
        key={index}
        onClick={() => handleSelectOption(option)}
      >
        <span className="inner">
          {optionLetters[index % optionLetters.length]}
        </span>{" "}
        <p>{option}</p>
      </div>
    ));
  };

  return (
    <>
      {!quizSubmitted ? (
        <div className="modal-form quiz-inner-body">
          <div className="row no-gutters">
            <div className="col-md-6">
              <div className="quiz-left h-100">
                <p className="text-center">Get Matched with an Agent</p>
                <div className="card-border">
                  <div className="card-border">
                    <img
                      src={quizfrst}
                      className={`img-fluid w-100 ${
                        score == 0 ? "showFields" : "hideField"
                      }`}
                      alt=""
                    />
                    <img
                      src={quiz2}
                      className={`img-fluid w-100 ${
                        score == 1 ? "showFields" : "hideField"
                      }`}
                      alt=""
                    />
                    <img
                      src={quiz3}
                      className={`img-fluid w-100 ${
                        score == 2 ? "showFields" : "hideField"
                      }`}
                      alt=""
                    />
                    <img
                      src={quiz4}
                      className={`img-fluid w-100 ${
                        score == 3 ? "showFields" : "hideField"
                      }`}
                      alt=""
                    />
                    <img
                      src={quiz5}
                      className={`img-fluid w-100 ${
                        score == 4 ? "showFields" : "hideField"
                      }`}
                      alt=""
                    />
                    <img
                      src={quiz6}
                      className={`img-fluid w-100 ${
                        score == 5 ? "showFields" : "hideField"
                      }`}
                      alt=""
                    />
                    <img
                      src={quiz7}
                      className={`img-fluid w-100 ${
                        score == 6 ? "showFields" : "hideField"
                      }`}
                      alt=""
                    />
                    <img
                      src={quiz8}
                      className={`img-fluid w-100 ${
                        score == 7 ? "showFields" : "hideField"
                      }`}
                      alt=""
                    />
                    <img
                      src={quiz9}
                      className={`img-fluid w-100 ${
                        score == 8 ? "showFields" : "hideField"
                      }`}
                      alt=""
                    />
                    <img
                      src={quiz10}
                      className={`img-fluid w-100 ${
                        score == 9 ? "showFields" : "hideField"
                      }`}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="quiz-right-section h-100">
                <div className="quiz-inner-body">
                  <p>Question {score + 1} of 10</p>
                  <div className="steps">
                    {Array.from({ length: 10 }, (_, i) => (
                      <div
                        key={i + 1}
                        className={score + 1 >= i + 1 ? "active" : ""}
                      ></div>
                    ))}
                  </div>
                  <div className="quiz-question">
                    <h3>{quiz[currentQuestion].question}</h3>
                    <div className="quiz-answer">{renderOptions()}</div>
                    <div className="quiz-footer-btn">
                      {score > 0 && (
                        !spinLoading && 
                        <button className="btn" onClick={previousQuestion}>
                          Previous
                        </button>
                      )}
                      {score == 9 ?  
                       spinLoading ? <button className="cont-us" disabled>Submit quiz<CSpinner color="light" size="sm" className={ 'ml-1' } /> </button> :  
                       <button className="cont-us"   onClick={nextQuestion}>Submit quiz</button> 
                    //   <button className="cont-us" onClick={nextQuestion}>Submit quiz</button>
                      :
                      <button className="cont-us" onClick={nextQuestion}>Next</button>
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="modal-form thankyou-section">
          <div className="text-center head-section">
            <h2>Thank you!</h2>
            <p>
              Your quiz has been submitted. <br />
              We’re working on assigning an agent to you.
            </p>
          </div>
          <button
            className="cont-us"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            Close
          </button>
          <img src={thankLogo} className="img-fluid" alt="" />
        </div>
      )}
    </>
  );
};

export default QuizStepper;
