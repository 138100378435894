import React from 'react'
import { CSpinner } from '@coreui/react'

import ActionButton from './buttons/Primary'


type Props = {
  googleId:string
  handleCallback:(t:any)=>Promise<void>
}

const SignIn = (props:React.PropsWithChildren<Props>) => {
  const [loading,setLoading] = React.useState(false)
  const handleClick = async () => {
    const google:any  = window['google' as any]
    
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_google_id,
      callback: async (v:any) => {
    
        setLoading(true)
        await props.handleCallback(v)
        setLoading(false)
      },
      auto_select:false,
      native_callback:(v:any) => console.log('123agent',v)
    })
    google.accounts.id.renderButton(
      document.getElementById("gbutton"),
      { theme: "filled_black", size: "large", text:'signin_with',logo_alignment:'left' }  // customization attributes
    )
    setTimeout(() => {
      (document.body.querySelector("#gbutton #button-label")! as HTMLElement).click()
    },10)
  }
  return (
    <div>
      <ActionButton
        onClick={ handleClick }
        size={'lg'}
      >
        { props.children }
        { loading ? <CSpinner color="light" size="sm" className={ 'ml-1' } /> : null }
      </ActionButton>
      <div id={ 'gbutton' } style={ { display:'none' } }></div>
    </div>
  )
}

export default SignIn
