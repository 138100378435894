import React, { useEffect, useState } from "react";
import NewContactUs from "./Pages/NewContactUs";

import homepage from "../../assets/headerpage-icon/HeroImage.svg";
import serving from "../../assets/headerpage-icon/serving.png";
import flower_img_icon from "../../assets/headerpage-icon/flower_img_icon.png";
import animate_banner1 from "../../assets/headerpage-icon/Rectangle_1.png";
import animate_banner2 from "../../assets/headerpage-icon/Rectangle_2.png";
import animate_banner3 from "../../assets/headerpage-icon/Rectangle_3.png";
import accordionAngle from "../../assets/headerpage-icon/accordionAngle.svg";
import layer_1 from "../../assets/headerpage-icon/Layer_1.png";
import layer_2 from "../../assets/headerpage-icon/Layer_2.png";
import layer_3 from "../../assets/headerpage-icon/Layer_3.png";
import servingImg from "../../assets/headerpage-icon/serving-img.jpg";
import iphone from "../../assets/headerpage-icon/Iphone.png";
import iphoneModal from "../../assets/headerpage-icon/IphoneModal.png";
import quizfrst from "../../assets/headerpage-icon/quiz1.png";
import quizLogo from "../../assets/headerpage-icon/quizLogo.png";
import comments from "../../assets/headerpage-icon/comments.png";
import market_logo from "../../assets/headerpage-icon/market-logo.png";
import gallary from "../../assets/image/gallary.png";

import milfordImg from "../../assets/headerpage-icon/milfordImg.png";
import OwlCarousel from "react-owl-carousel";
import Slider from "react-slick";
import Marquee from "react-fast-marquee";

import { NewFooter } from "./Pages/NewFooter";
import { NewNavbar } from "./Pages/NewNavbar";
import AOS from "aos";

import { Link, useHistory } from "react-router-dom";
import { AnimateAN } from "./Pages/AnimateAN";
import State from "ringcentral-client/build/paths/State";
import { connect } from "react-redux";
import axios from "axios";
import { getcitylist } from "../../api/users";
import QuizStepper from "./Components/QuizStepper";
import AppForm from "./Components/AppForm";
import { isquizzsubmitted } from "../../api/leads";
import { errorMonitor } from "stream";
import { errorMessage } from "./Util/Toaster";
import { CSpinner } from "@coreui/react";
import { currentuser } from "../../util/currentuser";
import ChatBubble from "./Components/ChatBubble";

AOS.init({
  delay: 500,
});

const options = {
  loop: true,
  margin: 10,
  items: 1,
  autoplay: true, 
};
const options1 = {
  loop: true,
  margin: 10,
  items: 5,
  dots: false,
  autoplay: true,
};
const options2 = {
  loop: true,
  items: 8,
  dots: false,
  autoplay: true,
  rtl: true,
};
const carouselSettings = {
  className: "serving-slider",
  dots: false,
  infinite: true,
  slidesToShow: 8,
  slidesToScroll: 3,
  margin: 10,
  autoplay: true,
  swipeToSlide: false,
  speed: 5000,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 1026,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        drag:false,
        swipeToSlide: true,
      }
    },
  ]
};
const carouselSettingsRTL = {
  className: "serving-slider",
  rtl: true,
  drag:false,
  dots: false,
  infinite: true,
  slidesToShow: 8,
  slidesToScroll: 3,
  margin: 10,
  autoplay: true,
  swipeToSlide: false,
  speed: 5000,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 1026,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        drag:false,
        swipeToSlide: true,
      }
    },
  ]
};
export interface AppNotifyForm {
  user_name: string | null;
  email: string | null;
  phone: string | null;
}
export const HomePage = (props: any) => {
  // console.log(props, "dispatch");
  const [notified, setNotified] = useState(false);

  const [quizStart, setQuizStart] = useState(false);
  const [lowaCity, setLowaCity] = useState<any>([]);
  const [userDetails, setUserDetails] = useState<any>({});
  const [nebraskaCity, setNebraskaCity] = useState<any>([]);
  const [spinLoading, setSpinLoading] = useState<boolean>(false);
  const history = useHistory();
  const [accordionState, setAccordionState] = useState({
    stateInfo: true,
    citiesInfo: true,
    countiesInfo:true,
    neighborhoodsInfo:true,
    zipInfo:true,
    propInfo:true, 
  })

  useEffect(() => {
    let res = getcitylist()
      .then((data) => {
        setLowaCity(data?.data?.lowa);
        setNebraskaCity(data?.data?.nebraska);
      })
      .catch((e) => console.log(e));
  }, []);


  const [notifyForm, setNotifyForm] = useState<AppNotifyForm>({
    user_name: "",
    email: "",
    phone: "",
  });

  const [validation, setValidation] = useState<AppNotifyForm>({
    user_name: "",
    email: "",
    phone: "",
  });

  useEffect(() => {
    let user: any = localStorage.getItem("user");
    let user_id = JSON.parse(user);
    if (user_id !== null) { 
      setNotifyForm({...notifyForm, user_name:`${user_id?.first_name} ${user_id?.last_name}`,email:user_id?.email})
    } 
     
  }, [])
  const checkValidation = () => {
    let errors = JSON.parse(JSON.stringify(validation));

    // user name validation
    if (notifyForm.user_name === null) {
      errors.user_name = "User Name is required";
    } else {
      switch (true) {
        case !notifyForm.user_name.trim():
          errors.user_name = "User Name is required";
          break;
        case !/^[a-z A-Z]+$/.test(notifyForm.user_name):
          errors.user_name = "User Name can only contain letters";
          break;
        case notifyForm.user_name.length < 2:
          errors.user_name = "User Name must be at least 2 characters";
          break;
        case notifyForm.user_name.length > 25:
          errors.user_name = "User Name cannot exceed 25 characters";
          break;
        default:
          errors.user_name = "";
          validation.user_name = "";
      }
    }
    // Email validation
    if (notifyForm.email === null) {
      errors.email = "Email is required";
    } else {
      const emailCond = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      switch (true) {
        case !notifyForm.email.trim():
          errors.email = "Email is required";
          break;
        case !emailCond.test(notifyForm.email):
          errors.email = "Please enter a valid email address";
          break;
        default:
          errors.email = "";
          validation.email = "";
      }
    }

    // phone number validation
    if (notifyForm.phone === null) {
      errors.phone = "Phone number is required";
    } else {
      switch (true) {
        case !notifyForm.phone.trim():
          errors.phone = "Phone Number is required";
          break;
        // case !/^\d+$/.test(notifyForm.phone):
        //   errors.phone = "Phone number can only contain numbers";
        //   break;
        case notifyForm.phone.length !== 14:
       
          errors.phone = "Phone number must be 10 digits";
          break;
        default:
          errors.phone = "";
          validation.phone = "";
      }
    }

    return errors;
  };

  const handleChange = async (e: any) => {
    const { name, value } = e.target; 
    setNotifyForm({ ...notifyForm, [name]: value });
    await checkValidation();
  };

  const handleSubmit = async (data: string) => {
    // e.preventDefault();
    let validations = await checkValidation();
 
    if (Object.values(validations).every((value) => value == "")) {
      setSpinLoading(true);
      if (data == "app") {
        setNotified(true);
        setSpinLoading(false);
   
      }
      if (data == "quiz") {
        setSpinLoading(false);
        setQuizStart(true);
        setUserDetails(notifyForm);
        // isquizzsubmitted(notifyForm?.email)
        //   .then((data: any) => {
        //     console.log("isquizsubmitted", data?.status); // this will log the JSON data
        //     const { status, message } = data.data;
        //     if (status == "failed") {
        //       errorMessage(data?.data?.message);
        //       setSpinLoading(false);
        //       setNotifyForm({
        //         user_name: "",
        //         email: "",
        //         phone: "",
        //       });
        //     } else {
        //       setQuizStart(true);
        //       setSpinLoading(false);
        //       setUserDetails(notifyForm);
        //     }
        //   })
        //   .catch((error) => console.error(error));
      }
      setValidation(() => ({
        user_name: "",
        email: "",
        phone: "",
      }));
    } else {
      setValidation((prevState) => ({
        ...prevState,
        user_name: validations.user_name,
        email: validations.email,
        phone: validations.phone,
      }));
    }
  };
  
  const servingBuyer = () => { 
    history.push('/property-list');
  }

  return (
    <>

      <div className="home-page-banner">
        <NewNavbar />
        <div className="home-page-banner-section">
          <div className="animation-box">
            {/* <h1>It all starts at home.</h1> */}
            <AnimateAN />
          </div>
          <div className="home-page-banner-img-section" data-aos="fade-up">
            <img src={homepage} alt="" width="100%" />
          </div>
        </div>
      </div>
      <div className="black-line"></div>
      <div className="proven-results-section">
        <div className="proven-results">
          <p className="bold-txt">PROVEN</p>
          <p className="creativity">
            We are known for our creativity, unrivaled customer service,
            innovative techniques, and dedication to ensuring our clients
            achieve their goals.
          </p>
          <p className="bold-txt-1">RESULTS</p>
          <a href="#contactus">
            {" "}
            <button className="cont-us">Contact Us</button>
          </a>
        </div>
      </div>
      <div className="serving-section">
        <div>
          <img src={serving} alt="" />
        </div>
        <div>
          <p className="serving-txt">
            At Milford Real Estate, our commitment to our clients is the very
            heartbeat of our agency. Every facet of our expertise is infused
            with the highest level of care and thoughtfulness. 
          </p>
        </div>
      </div>
      <div className="updated-serving">
        <h3>Popular cities we serve</h3>
        <div className="row gy-4">
        <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="updated-serving-card" onClick={()=> servingBuyer()} >
                    <img src={servingImg} className="img-fluid" alt="" />
                    <div className="serving-bottom text-center">
                      <h3>Omaha</h3>
                      <p>NEBRASKA</p>
                    </div>
                  </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="updated-serving-card" onClick={()=> servingBuyer()} >
                    <img src={servingImg} className="img-fluid" alt="" />
                    <div className="serving-bottom text-center">
                      <h3>Elkhorn</h3>
                      <p>NEBRASKA</p>
                    </div>
                  </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="updated-serving-card" onClick={()=> servingBuyer()} >
                    <img src={servingImg} className="img-fluid" alt="" />
                    <div className="serving-bottom text-center">
                      <h3>Gretna</h3>
                      <p>NEBRASKA</p>
                    </div>
                  </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="updated-serving-card" onClick={()=> servingBuyer()} >
                    <img src={servingImg} className="img-fluid" alt="" />
                    <div className="serving-bottom text-center">
                      <h3>Fort Calhoun</h3>
                      <p>NEBRASKA</p>
                    </div>
                  </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="updated-serving-card" onClick={()=> servingBuyer()} >
                    <img src={servingImg} className="img-fluid" alt="" />
                    <div className="serving-bottom text-center">
                      <h3>Lincoln</h3>
                      <p>NEBRASKA</p>
                    </div>
                  </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="updated-serving-card" onClick={()=> servingBuyer()} >
                    <img src={servingImg} className="img-fluid" alt="" />
                    <div className="serving-bottom text-center">
                      <h3>Papillion</h3>
                      <p>NEBRASKA</p>
                    </div>
                  </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="updated-serving-card" onClick={()=> servingBuyer()} >
                    <img src={servingImg} className="img-fluid" alt="" />
                    <div className="serving-bottom text-center">
                      <h3>Blair</h3>
                      <p>NEBRASKA</p>
                    </div>
                  </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="updated-serving-card" onClick={()=> servingBuyer()} >
                    <img src={servingImg} className="img-fluid" alt="" />
                    <div className="serving-bottom text-center">
                      <h3>Springfield</h3>
                      <p>NEBRASKA</p>
                    </div>
                  </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="updated-serving-card" onClick={()=> servingBuyer()} >
                    <img src={servingImg} className="img-fluid" alt="" />
                    <div className="serving-bottom text-center">
                      <h3>Ashland</h3>
                      <p>NEBRASKA</p>
                    </div>
                  </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="updated-serving-card" onClick={()=> servingBuyer()} >
                    <img src={servingImg} className="img-fluid" alt="" />
                    <div className="serving-bottom text-center">
                      <h3>Bellevue</h3>
                      <p>NEBRASKA</p>
                    </div>
                  </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="updated-serving-card" onClick={()=> servingBuyer()} >
                    <img src={servingImg} className="img-fluid" alt="" />
                    <div className="serving-bottom text-center">
                      <h3>Bennington</h3>
                      <p>NEBRASKA</p>
                    </div>
                  </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="updated-serving-card" onClick={()=> servingBuyer()} >
                    <img src={servingImg} className="img-fluid" alt="" />
                    <div className="serving-bottom text-center">
                      <h3>Valley</h3>
                      <p>NEBRASKA</p>
                    </div>
                  </div>
              </div>
            {/* {lowaCity?.map((city: any) => {
                return (
            <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="updated-serving-card" onClick={()=> servingBuyer()} >
                    <img src={servingImg} className="img-fluid" alt="" />
                    <div className="serving-bottom text-center">
                      <h3>{city}</h3>
                      <p>Iowa</p>
                    </div>
                  </div>
              </div>
                )
              })}
            {nebraskaCity?.map((city: any) => {
                return (
            <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="updated-serving-card" onClick={()=> servingBuyer()} >
                    <img src={servingImg} className="img-fluid" alt="" />
                    <div className="serving-bottom text-center">
                      <h3>{city}</h3>
                      <p>NEBRASKA</p>
                    </div>
                  </div>
              </div>
                )
          })}  */}
        </div>
        <div className="text-center mt-5">
          <button className="cont-us">Search for homes</button>
        </div>
      </div>
      {/* <div className="serving-main-section">
        <Slider {...carouselSettings}>
          {lowaCity.map((city: any) => {
            return <div className="sarving-card"> {city}</div>;
          })}
        </Slider>
        <Slider {...carouselSettingsRTL}>
          {nebraskaCity.map((city: any) => {
            return <div className="sarving-card"> {city}</div>;
          })}
        </Slider>
      </div> */}

      <div className="BackgroundImg position-relative">
        <div className="clip-path-img-section position-absolute">
          <img
            src={flower_img_icon}
            alt="flower_img_icon"
            
          />
        </div>
        <div className="clip-path-section"></div>
      </div>

      <section className="">
        <div className="design">
          <div className="animate-banner">
            <div className="decoration-img">
              <div className="row align-items-center justify-content-around">
                <div className="col-6 animate_banner1-img animate-img-parent" data-aos="fade-right">
                  <img src={animate_banner1} alt="" className="animate-img" />
                </div>
                <div className="col-6" data-aos="fade-left">
                  <div className="card-border card-width">
                    <div className="card-border">
                      <div className="card decoration-card  d-flex align-items-center justify-content-center px-2 py-5 m-0">
                        <img src={layer_1} alt="" />
                        <div className="card-body d-flex flex-column justify-content-center align-items-center ">
                          <div className="text-bg ">HOME BUYERS</div>
                          <div className="text mt-3">
                            Homeownership isn't just about having a place to
                            live—it's about investing in your future. You're
                            securing a foundation for financial freedom and
                            long-term wealth accumulation.
                          </div>
                          <Link to="/property-list">
                            <div className="card-btn mt-3">EXPLORE HOMES</div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center justify-content-around card-spacing">
                <div
                  className="col-6 animate_banner1-img d-flex justify-content-end"
                  data-aos="fade-right"
                >
                  <div className="card-border card-width">
                    <div className="card-border">
                      <div className="card decoration-card  d-flex align-items-center justify-content-center px-2 py-5 m-0">
                        <img src={layer_2} style={{ width: "200px" }} alt="" />
                        <div className="card-body d-flex flex-column justify-content-center align-items-center ">
                          <div className="text-bg ">HOME SELLERS</div>
                          <div className="text mt-3">
                          Every home has a story to tell – let us help you tell yours. Our innovative custom marketing strategies will showcase your home's unique features and attract qualified buyers who are ready to fall in love.
                          </div>

                          <a href="#contactus">
                            <div className="card-btn mt-3">CONTACT US</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 animate-img-parent" data-aos="fade-left">
                  <img src={animate_banner2} alt="" className="animate-img" />
                </div>
              </div>
              <div className="row align-items-center justify-content-around">
                <div className="col-6 animate_banner1-img animate-img-parent" data-aos="flip-down">
                  <img src={animate_banner3} alt="" className="animate-img" />
                </div>
                <div className="col-6 " data-aos="flip-up">
                  <div className="card-border card-width">
                    <div className="card-border">
                      <div className="card decoration-card  d-flex align-items-center justify-content-center px-2 py-5 m-0">
                        <img src={layer_3} alt="" />
                        <div className="card-body d-flex flex-column justify-content-center align-items-center ">
                          <div className="text-bg ">BUY & SELL</div>
                          <div className="text mt-3">
                          Our team of real estate professionals will handle every aspect of the process, from marketing to negotiations, with skill and expertise. Let us take care of the heavy lifting while you focus on your next adventure.
                          </div>

                          <a href="#contactus">
                            <div className="card-btn mt-3">CONTACT US</div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="d-flex align-items-center justify-content-between quiz-parent">
          <div className="col-6">
            <div className="d-flex  align-items-center justify-content-center flex-column gap-3 ">
              <h3 className="header-txt">Get Matched With an Agent</h3>
              <p className="get-txt">
                Take our Personalized Agent Quiz to be matched with the perfect
                REALTOR® for  your personality and lifestyle.
              </p>
              <button
                className="card-btn mt-3"
                data-bs-toggle="modal"
                data-bs-target="#quizModal"
              >
                TAKE QUIZ
              </button>
            </div>
          </div>
          <div className="col-6 p-0">
            <div className="img_ani"></div>
          </div>
        </div>
      </section>

      <section className="coming-soon">
        <div className="iphone">
          <h3 className="iphone-header">New App Coming Soon!</h3>
          <p className="iphone-para">
            Pick your filters, save your favorites, and keep track of listing
            updates all in one place.
          </p>
          <div className="iphone-bg">
            <img src={iphone} alt="" />
          </div>
          <h3 className="iphone-ancr">
            <i>Want the inside scoop</i> on when the app will launch?
          </h3>
          <button
            className="cont-us"
            data-bs-toggle="modal"
            data-bs-target="#appNotify"
          >
            Notify me!
          </button>
        </div>
      </section>

      <div className="testimonial-carousel">
       <div className="quotes">
              <img src={comments} alt="" />
            </div>
            <h3 className="comments-header">What Our Clients Say</h3>
      <OwlCarousel className="owl-theme whatourclientsay" {...options}>
        <div className="item">
          <div className="inner-quotes">
            
            <div className="comments-txt">
              <p>
                We have been totally amazed by Milford Real Estate. They are the
                most thoughtful, creative, artistic, responsive, and
                professional team you could ever ask for. They embody customer
                experience and go out of their way to make the home buying and
                selling experience effortless. We are thrilled to benefit from
                their expertise and we recommend them to anyone looking to buy
                or sell a home.
              </p>
            </div>
          </div>
        </div>

        <div className="item">
          <div className="inner-quotes">
            
            <div className="comments-txt">
              <p>
                “This is our second time using Milford for our house buying
                needs, and they also helped us sell a home. Every single time
                they have gone above and beyond. I recommend Milford to anyone
                looking to buy or sell. We will never use anyone else!”
              </p>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="inner-quotes">
            
            <div className="comments-txt">
              <p>
                “Karie Milford and team have elevated my view of how crucial a
                realtor is in the process of selling our home. They listened to
                what we valued and never lost sight of what mattered to us. We
                never felt pressured to lower our list price, even through
                winter months with slower buyer traffic. Karie shined in her
                creativity to market our home in a unique way to potential
                buyers.”
              </p>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="inner-quotes">
            
            <div className="comments-txt">
              <p>
                “Milford Real Estate gave us an easy, informed buying process.
                We were first time homeowners, so they were straight to the
                point with financial aspects, as well as giving insight into the
                various houses we looked at. We gave full trust into our
                realtor, and even put offers in on houses that only he looked
                at. We will definitely use Milford Real Estate again if we need
                to!”
              </p>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="inner-quotes">
            
            <div className="comments-txt">
              <p>
                “Our experience with Milford Real Estate was beyond
                expectations. They were extremely knowledgeable about the market
                and gave us the confidence we needed in selling our home. I
                highly recommend this professional and personable group of
                realtors to represent you in your housing needs.”
              </p>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="inner-quotes">
            
            <div className="comments-txt">
              <p>
                “The Milford team was wonderful throughout our home buying
                process. Very hands-on, no detail left behind and just wonderful
                staff and incredible owner. Definitely will be using Milford
                when we buy our next house!”
              </p>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="inner-quotes">
            
            <div className="comments-txt">
              <p>
                “Milford was wonderful in helping us find our home in Omaha. As
                we were at the time living in California, we trusted them as our
                boots on the ground in our home search. After months and months
                of looking, sending our wonderful realtor to many many houses,
                we finally found a house just in time for our proposed move.”
              </p>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="inner-quotes">
            
            <div className="comments-txt">
              <p>
                “We have used Milford Real Estate 4 times and every time they
                exceed all expectations of ours!! They are always on schedule
                during the buying and selling processes. They always make
                themselves available whether it’s answering questions or
                addressing any concerns or thoughts we may have. We will
                continue to use Milford Real Estate for years to come!”
              </p>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="inner-quotes">
            
            <div className="comments-txt">
              <p>
                “We have been totally amazed by Milford Real Estate. They are
                the most thoughtful, creative, artistic, responsive, and
                professional team you could ever ask for. They embody customer
                experience and go out of their way to make the home buying and
                selling experience effortless. We are thrilled to benefit from
                their expertise and we recommend them to anyone looking to buy
                or sell a home”
              </p>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="inner-quotes">
            
            <div className="comments-txt">
              <p>
                “Best real-estate experience ever! They sold our house and got
                us a new one in such a quick turn around. All we had to do was
                sign papers and look at houses! If you're looking to move there
                isn't a better option when it comes to a realtor team. Beware
                unusually fast results may happen as soon as you reach out! This
                is our 2nd time using them to buy and sell a house, and if we do
                ever move again it is nice knowing we have such a remarkable
                team we can call upon.”
              </p>
            </div>
          </div>
        </div>
      </OwlCarousel>
      </div>

      {/* <section>
        <div className="milford-market">
          <div className="market-logo text-center">
            <img src={market_logo} alt="" />
          </div>
          <div>
            <div className="market-h2">
              <h2>The Milford Market</h2>
            </div>
            <div className="market-para">
              <p>
                A curated online platform associated with Milford Magazine,
                showcasing listings of local businesses that actively support
                and sustain our community.
              </p>
            </div>
          </div>
          <div className="market-card-section p-4">
            <div className="container-fluid">
              <OwlCarousel className="owl-theme" {...options1}>
                <div className="col p-0  ">
                  <div className="item market-card card">
                    <div className="milford-mrkt-cardimg">
                      <img src={gallary} alt="" />
                    </div>
                    <div className="card-body ">
                      <h3>Parirer IN Bloom</h3>
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Adipisci qui et accusantium officiis quis est!
                      </p>
                      <hr />
                      <p className="fw-bold">402-511-7155</p>
                      <p className="fw-bold">chetu.com</p>
                      <button className="btn btn-dark">Discover More</button>
                    </div>
                  </div>
                </div>
                <div className="col p-0  ">
                  <div className="item market-card card">
                    <div className="milford-mrkt-cardimg">
                      <img src={gallary} alt="" />
                    </div>

                    <div className="card-body ">
                      <h3>Parirer IN Bloom</h3>
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Adipisci qui et accusantium officiis quis est!
                      </p>
                      <hr />
                      <p className="fw-bold">402-511-7155</p>
                      <p className="fw-bold">chetu.com</p>
                      <button className="btn btn-dark">Discover More</button>
                    </div>
                  </div>
                </div>
                <div className="col p-0  ">
                  <div className="item market-card card">
                    <div className="milford-mrkt-cardimg">
                      <img src={gallary} alt="" />
                    </div>

                    <div className="card-body ">
                      <h3>Parirer IN Bloom</h3>
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Adipisci qui et accusantium officiis quis est!
                      </p>
                      <hr />
                      <p className="fw-bold">402-511-7155</p>
                      <p className="fw-bold">chetu.com</p>
                      <button className="btn btn-dark">Discover More</button>
                    </div>
                  </div>
                </div>
                <div className="col p-0  ">
                  <div className="item market-card card">
                    <div className="milford-mrkt-cardimg">
                      <img src={gallary} alt="" />
                    </div>

                    <div className="card-body ">
                      <h3>Parirer IN Bloom</h3>
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Adipisci qui et accusantium officiis quis est!
                      </p>
                      <hr />
                      <p className="fw-bold">402-511-7155</p>
                      <p className="fw-bold">chetu.com</p>
                      <button className="btn btn-dark">Discover More</button>
                    </div>
                  </div>
                </div>
                <div className="col p-0  ">
                  <div className="item market-card card">
                    <div className="milford-mrkt-cardimg">
                      <img src={gallary} alt="" />
                    </div>

                    <div className="card-body ">
                      <h3>Parirer IN Bloom</h3>
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Adipisci qui et accusantium officiis quis est!
                      </p>
                      <hr />
                      <p className="fw-bold">402-511-7155</p>
                      <p className="fw-bold">chetu.com</p>
                      <button className="btn btn-dark">Discover More</button>
                    </div>
                  </div>
                </div>
                <div className="col p-0  ">
                  <div className="item market-card card">
                    <div className="milford-mrkt-cardimg">
                      <img src={gallary} alt="" />
                    </div>
                    <div className="card-body ">
                      <h3>Parirer IN Bloom</h3>
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Adipisci qui et accusantium officiis quis est!
                      </p>
                      <hr />
                      <p className="fw-bold">402-511-7155</p>
                      <p className="fw-bold">chetu.com</p>
                      <button className="btn btn-dark">Discover More</button>
                    </div>
                  </div>
                </div>
                <div className="col p-0  ">
                  <div className="item market-card card">
                    <div className="milford-mrkt-cardimg">
                      <img src={gallary} alt="" />
                    </div>

                    <div className="card-body ">
                      <h3>Parirer IN Bloom</h3>
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Adipisci qui et accusantium officiis quis est!
                      </p>
                      <hr />
                      <p className="fw-bold">402-511-7155</p>
                      <p className="fw-bold">chetu.com</p>
                      <button className="btn btn-dark">Discover More</button>
                    </div>
                  </div>
                </div>
                <div className="col p-0  ">
                  <div className="item market-card card">
                    <div className="milford-mrkt-cardimg">
                      <img src={gallary} alt="" />
                    </div>

                    <div className="card-body ">
                      <h3>Parirer IN Bloom</h3>
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Adipisci qui et accusantium officiis quis est!
                      </p>
                      <hr />
                      <p className="fw-bold">402-511-7155</p>
                      <p className="fw-bold">chetu.com</p>
                      <button className="btn btn-dark">Discover More</button>
                    </div>
                  </div>
                </div>
                <div className="col p-0  ">
                  <div className="item market-card card">
                    <div className="milford-mrkt-cardimg">
                      <img src={gallary} alt="" />
                    </div>

                    <div className="card-body ">
                      <h3>Parirer IN Bloom</h3>
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Adipisci qui et accusantium officiis quis est!
                      </p>
                      <hr />
                      <p className="fw-bold">402-511-7155</p>
                      <p className="fw-bold">chetu.com</p>
                      <button className="btn btn-dark">Discover More</button>
                    </div>
                  </div>
                </div>
                <div className="col p-0  ">
                  <div className="item market-card card">
                    <div className="milford-mrkt-cardimg">
                      <img src={gallary} alt="" />
                    </div>

                    <div className="card-body ">
                      <h3>Parirer IN Bloom</h3>
                      <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing
                        elit. Adipisci qui et accusantium officiis quis est!
                      </p>
                      <hr />
                      <p className="fw-bold">402-511-7155</p>
                      <p className="fw-bold">chetu.com</p>
                      <button className="btn btn-dark">Discover More</button>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
          <div className="market-h3">
            <h2>
              When you actively support these businesses—be it through
              purchases, engagement, or recommendation—you're not just making a
              transaction; you're nurturing a cycle of community-driven
              reciprocity. Your actions become the lifeline that fuels our
              community’s shared success.
            </h2>
          </div>
          <div className="market-btn text-center">
            
              <a
                href="https://milfordmagazine.com/milford-market/"
                target="_blank"
              >
              <button className="cont-us">  Go to Milford Market  </button>
              </a>
          
          </div>
        </div>
      </section> */}

      <div>
        <div className="milford-magazine justify-content-center align-items-center flex-column ">
          <div className="text-center milford-magazine-section">
            <h2>Milford Magazine</h2>
            <p>Focused on community, art, culture, and more.</p>
            <div className="d-flex milford-community-head">
              <div className="cols milford-community">
                <div className="milford-img-border1">
                  <div className="milford-img-border2">
                    <p>
                      <b>Community, art, culture,</b> and more!
                    </p>
                  </div>
                </div>
              </div>
              <div className="col">
                <img src={milfordImg} alt="" />
              </div>
            </div>
            <a
              href="https://milfordmagazine.com/milford-magazine-current-issue/"
              target="_blank"
            >
              <button className="cont-us">Read Issue </button>
            </a>
          </div>
          <div className="col subscribe-section d-flex justify-content-center align-items-center">
            <h3>
              Read about local hidden treasures, expand your worldview, and
              understand more than you did yesterday.
            </h3>
            <div className="triangle-btn position-relative">
              <a
                href="https://milfordmagazine.com/magazine-subscription/"
                target="_blank"
              >
                <button> SUBSCRIBE TO MAGAZINE</button>
              </a>

              <div className="triangle-right"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact">
        <div className="contact_us">
          <NewContactUs />
          <div className="early">
            <Marquee className="example-left">
              We’re here when you need us. We’re here when you need us. We’re
              here when you need us.
            </Marquee>
          </div>
        </div>
      </div>
      <div className="bottom-footers">
      <div className="bottom-filter">
          <div className="row">
              <div className="col-xl-2 col-lg-3 col-md-4">
                  <div className={`bottom-accordion statAc ${accordionState.stateInfo ? 'showAc' : ''}`}>
                    <div className="ac-header" 
                      onClick={() => setAccordionState({...accordionState, stateInfo:!accordionState.stateInfo})}>
                        <span>States</span>
                        <img src={accordionAngle} alt="" />
                    </div>
                    <div className="ac-body">
                        <ul>
                          <li>Nebraska</li>
                          <li>Iowa</li>
                        </ul> 
                    </div>
                  </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4">
              <div className={`bottom-accordion ${accordionState.citiesInfo ? 'showAc' : ''}`}>
                    <div className="ac-header"
                    onClick={() => setAccordionState({...accordionState, citiesInfo:!accordionState.citiesInfo})}>
                        <span>Cities</span>
                        <img src={accordionAngle} alt="" />
                    </div>
                    <div className="ac-body">
                        <ul>
                          <li>Omaha real estate</li>
                          <li>Omaha real estate</li>
                        </ul>
                        <p> <i className="fa-solid fa-plus"></i> Show more</p>
                    </div>
                  </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4">
                   <div className={`bottom-accordion ${accordionState.countiesInfo ? 'showAc' : ''}`}>
                    <div className="ac-header"
                    onClick={() => setAccordionState({...accordionState, countiesInfo:!accordionState.countiesInfo})}>
                        <span>Counties</span>
                        <img src={accordionAngle} alt="" />
                    </div>
                    <div className="ac-body">
                        <ul>
                          <li>Dougles</li>
                          <li>Sarpy</li>
                        </ul>
                        <p> <i className="fa-solid fa-plus"></i> Show more</p>
                    </div>
                  </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4">
                   <div className={`bottom-accordion ${accordionState.neighborhoodsInfo ? 'showAc' : ''}`}>
                    <div className="ac-header"
                    onClick={() => setAccordionState({...accordionState, neighborhoodsInfo:!accordionState.neighborhoodsInfo})}>
                        <span>Neighborhoods</span>
                        <img src={accordionAngle} alt="" />
                    </div>
                    <div className="ac-body">
                        <ul>
                          <li>Skyline Ranches</li>
                          <li>Country Club</li>
                        </ul>
                        <p> <i className="fa-solid fa-plus"></i> Show more</p>
                    </div>
                  </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4">
                   <div className={`bottom-accordion ${accordionState.zipInfo ? 'showAc' : ''}`}>
                    <div className="ac-header"
                    onClick={() => setAccordionState({...accordionState, zipInfo:!accordionState.zipInfo})}>
                        <span>Zip Codes</span>
                        <img src={accordionAngle} alt="" />
                    </div>
                    <div className="ac-body">
                        <ul>
                          <li>68101</li>
                          <li>68102</li>
                        </ul>
                        <p> <i className="fa-solid fa-plus"></i> Show more</p>
                    </div>
                  </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-4">
                   <div className={`bottom-accordion ${accordionState.propInfo ? 'showAc' : ''}`}>
                    <div className="ac-header"
                    onClick={() => setAccordionState({...accordionState, propInfo:!accordionState.propInfo})}>
                        <span>Property Types</span>
                        <img src={accordionAngle} alt="" />
                    </div>
                    <div className="ac-body">
                        <ul>
                          <li>House</li>
                          <li>Townhonse</li>
                        </ul>
                        <p> <i className="fa-solid fa-plus"></i> Show more</p>
                    </div>
                  </div>
              </div>
          </div>
      </div>
      </div>
      <ChatBubble/>
      <NewFooter />

      {/* App Notify Popup */}
      <div className="modal fade app-notify-modal" id="appNotify">
        <div className="modal-dialog">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>

            <div className="modal-form">
              <div className="row no-gutters">
                <div className="col-md-6">
                  {/*app-notification modal  */}
                  {!notified ? (
                    <div className="modal-left-section h-100">
                      <div className="text-center head-section">
                        <h2>The Milford App</h2>
                        <p>
                          Want the inside scoop on when the app will launch?
                          <strong className="d-block">
                            Complete the form below!
                          </strong>
                        </p>
                      </div>
                      <div className="modal-inner-form">
                        <AppForm
                          data={{ handleChange, notifyForm, validation }}
                        />
                        {/* <button
                          className="cont-us"
                          onClick={() => {
                            handleSubmit("app");
                          }}
                        >
                          Submit
                        </button> */}
                        {spinLoading ? (
                          <button className="cont-us" disabled>
                            Submit
                            <CSpinner
                              color="light"
                              size="sm"
                              className={"ml-1"}
                            />{" "}
                          </button>
                        ) : (
                          <button
                            className="cont-us"
                            onClick={() => {
                              handleSubmit("app");
                            }}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="modal-left-section thankyou-section">
                      <div className="text-center head-section">
                        <h2>Thank you!</h2>
                        <p>
                          You’re now on the list!
                          <strong className="d-block">
                            You’ll be the first to know once the app is
                            launched.
                          </strong>
                        </p>
                      </div>
                      <div className="modal-inner-form">
                        <button
                          className="cont-us"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="modal-right-section h-100">
                    <img src={iphoneModal} className="img-fluid iphoneImg" alt="iphone" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* quiz modal */}

      <div
        className="modal fade app-notify-modal quiz-modal"
        id="quizModal"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
            {quizStart ? (
              <QuizStepper data={userDetails} />
            ) : (
              <div className="modal-form">
                <div className="row no-gutters">
                  <div className="col-md-6">
                    <div className="modal-left-section h-100">
                      <div className="text-center head-section">
                        <h2>Get Matched with an Agent</h2>
                        <p>
                          Let’s find the best Milford REALTOR® for your
                          personality and lifestyle.
                          <strong className="d-block">
                            Get started below.
                          </strong>
                        </p>
                      </div>
                      <div className="modal-inner-form">
                        <AppForm
                          data={{ handleChange, notifyForm, validation }}
                        />
                        {/* <button
                          className="cont-us"
                          onClick={() => {
                            handleSubmit("quiz");
                          }}
                        >
                          Submit
                        </button> */}
                        {spinLoading ? (
                          <button className="cont-us" disabled>
                            Submit
                            <CSpinner
                              color="light"
                              size="sm"
                              className={"ml-1"}
                            />{" "}
                          </button>
                        ) : (
                          <button
                            className="cont-us"
                            onClick={() => {
                              handleSubmit("quiz");
                            }}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="modal-right-section h-100">
                      <img src={quizLogo} className="img-fluid" alt="iphone" />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      

    </>
  );
};
