import Store from "../store";

export interface permissiondata {
  id: string;
  permission: string;
  edit: string;
  view: string;
}

export const userPermissionRole = () => {
  // debugger
  let userdata = localStorage.getItem('user')
  let data = JSON.parse(userdata)
  // console.log(data,"fn3k4ef");
  // @ts-ignore
  let user = Store?.store?.getState().auth.user ;


  // console.log(user,data,"krgnnk");
  // console.log(user?.permissionsData,"permisionData");
  let dataString = user ? user.permissionsData : data?.permissionsData;

  // Step 1: Handle the case when permissionsData is an array
  if (Array.isArray(dataString)) {
    // Handle this scenario as per your application's requirements
    // For example, you might return an empty array or process the array data differently.
    return [];
  }

  // Step 2: Process the string representation of permissionsData

  // Split the dataString into individual object strings based on the semicolon ';'
  const objectStrings = dataString?.split(';');
// console.log(objectStrings,"brhjg");
  // Process each object string to convert it into an actual object
  const dataArray: permissiondata[] = objectStrings?.map((objectString: string) => {
    objectString = objectString.trim();
    const keyValuePairs = objectString?.match(/(\w+)\s*=\s*(\w+|"[^"]*")/g);
    const object: permissiondata = {
      id: '',
      permission: '',
      edit: '',
      view: '',
    };
    if (keyValuePairs) {
      for (const keyValuePair of keyValuePairs) {
        const [key, value] = keyValuePair?.split('=').map((str: string) => str.trim());
        object[key as keyof permissiondata] = value.replace(/"/g, '');
      }
    }
    return object;
  });

  return dataArray;
};
