// @ts-ignore-file
import moment from "moment";
import { NewOffer } from "../containers/TheSidebar";
import { newofferdetails } from "../views/Transaction/NewTransaction";
import { getofferformrequest, getRequest, getRequests, postRequests, putRequests } from "./_common"
import { selecteddocumentesign } from "../views/Transaction/Components/ReviewDocs";
import { v4 as uuidv4 } from 'uuid'
import axios from "axios";
import { transactionusers } from "../views/Transaction/HomeWarranty";

/**
 * To get list of agents
 * @returns list of co-agents
 */
export const getCoagents = async () => {
  let temp = await getRequests<any>(`User/GetCoAgents`);
  return temp?.data?.data
}

/**
 * To get list of external agents
 * @returns list of external agents
 */
export const getExternalAgents = async (type: string) => {
  let temp = await getRequests<any>(`User/GetExternalLenderList?type=${type}`);
  return temp?.data?.data
}

/**
 * To get list of idx addresses
 * @returns list of addresses
 */
export const getIdxAddress = async () => {
  let temp = await getRequests<any>(`property/GetPropertyAddress`);
  return temp?.data?.data
}

/**
 * To save details of new offer form
 * @returns success message
 */
export const saveNewOffer = async (newoffer: NewOffer) => {
  // console.log("saveNewOffercalled",newoffer);
  let datas = {
    statuscode: "",
    status: "",
    message: "",
    success: "",
    data: ""
  }
let data:any = localStorage.getItem('user')
let user = JSON.parse(data)
let userID: any = localStorage.getItem('logindata')
let userID2: any = JSON.parse(userID)?.id

  const temp_data = {
    propertytype: newoffer?.propertytype,
    state: newoffer?.state,
    propertyaddress: newoffer?.propertyaddress,
    offercode: newoffer?.offercode,
    clienttype: newoffer?.clienttype,
    leadid: newoffer?.clientid,
    agentid: newoffer?.agent?.id,
    coagentid: newoffer?.coagent?.id,
    external_agent_id: newoffer?.externalagent?.id,
    listing_id: newoffer?.listing_id,
    buyer_type: newoffer?.buyertype,
    user_id:user?.id ? user?.id : userID2
  }

  await postRequests(`Transaction/CreateNewOffer`, temp_data, {

    successful: (v: any) => {
      datas = { statuscode: v?.status, status: v?.data?.status, message: v?.data?.message, success: v?.data?.success, data: v?.data?.data }
    },
    unprocessable: (v: any) => {
      datas = { statuscode: "", status: "", message: "", success: "", data: "" }
    }
  })
  return datas
}

/**
 * To get savedoffer details
 * @returns savedoffer details
 */
export const getSavedOfferDetails = async (id: number | null) => {
  let temp = await getRequests<any>(`transaction/GetOfferDetailsById?id=${id}`);
  // let temp = await getRequests<any>(`transaction/GetOfferDetailsById?id=2`);
  return temp?.data?.data
}

/**
 * To save complete offer form details
 * @returns succes message
 */
export const saveOfferFormDetailsapi = async (data: any) => {

  let temp_data_response = {
    statuscode: "",
    status: "",
    message: "",
    success: "",
    data: ""
  }

  const temp_data = {
    "is_termite_inspection":data?.termite_inspection,
    "home_warranty": data?.home_warranty,
"is_home_inspection":data?.home_inspection,

    "id": typeof (data?.offer_id) === "string" ? Number(data?.offer_id) : data?.offer_id,
    "offercode": data?.offer_id ? data?.offer_id : "",
    "state": data?.state,
    "propertytype": data?.propertytype,
    "propertyaddress": data?.propertyAddress,
    "clienttype": data?.clientType,
    "buyer_type": data?.buyerType,
    "sub_type": data?.subtype,
    "leadid": data?.client?.leadId,
    "agentid": data?.agent?.agentId,
    "coagentid": data?.coagent?.coAgentId,
    "external_agent_id": data?.external_Agent_id,
    "offer_date": data?.offer_date,
    "offer_expire_date": data?.offer_expire_date ? moment(data?.offer_expire_date).toISOString() : "",
    "offer_expire_time": data?.offer_expire_time ? data?.offer_expire_time : "",
    "offer_time": data?.offer_time,
    "offer_price": typeof (data?.offer_price) === "string" ? Number(data?.offer_price) : data?.offer_price,
    "earnest_deposit": typeof (data?.earnest_deposit) === "string" ? Number(data?.earnest_deposit) : data?.earnest_deposit,
    "closing_date": data?.closing_date ? moment(data?.closing_date).toISOString() : "",
    "broker_admin_fees": typeof (data?.broker_admin_fees) === "string" ? Number(data?.broker_admin_fees) : data?.broker_admin_fees,
    "closing_costs": data?.closing_costs || "",
    "seller_agent_id":data?.seller_agent_id ? data?.seller_agent_id : "",
    "closing_amount": typeof (data?.closing_amount) === "string" ? Number(data?.closing_amount) : data?.closing_amount || 0,
    "delivered_agreement": data?.delivered_agreement,
    "deleivered_final_acceptance": data?.deleivered_final_acceptance,
    "delivered_later": data?.delivered_later,
    "delivered_by": data?.delivered_by ? data?.delivered_by : '',
    "received_by": data?.received_by ? data?.received_by : "",
    "delivered_date": data?.delivered_date ? data?.delivered_date : null,
    "received_date": data?.received_date ? data?.received_date : null,
    "buyer_finance_state": data?.buyer_finance_state,
    "buyer_finance_type": data?.buyer_finance_type,
    "lenderid": data?.lender?.id ? data?.lender?.id : 0,
    "all_cash": data?.all_cash,
    "conditional_financing": data?.conditional_financing,
    "terms_financing": data?.terms_financing,
    "interest_rate": data?.interest_rate,
    "legal_description": data?.legal_description,
    "county": data?.county,
    "escrow_agent_id": data?.escrow_Agents ? data?.escrow_Agents?.id : "",
    "title_company_name": data?.title_Companies?.companyname    ? data?.title_Companies?.companyname    : "" ,
    "title_company_id": typeof (data?.title_Companies?.id) === "string" ? Number(data?.title_Companies?.id) : data?.title_Companies?.id,
    "survey_id": typeof (data?.surveys?.id) === "string" ? Number(data?.surveys?.id) : data?.surveys?.id,
    "no_exception": data?.no_exception,
    "excepts": data?.excepts,
    "business_address": data?.propertyAddress ? data?.propertyAddress : "",
    "termite_date": data?.termite_date,
    "termite_time": data?.termite_time,
    "termite_location": data?.propertyAddress,
    "termite_company_name": data?.termite_Companies?.companyname ? data?.termite_Companies?.companyname : "",
    "termite_inspector_name": data?.termiteinspector ? data?.termiteinspector: "",
    "home_inspector": data?.home_inspection_inspector?.companyname ? data?.home_inspection_inspector?.companyname : "",
    "home_inspection": data?.home_inspection ?  [
      {
        "inspection_type_name": data?.inspection_Types?.companyname ? data?.inspection_Types?.companyname : "",
        "inspection_company_name":  data?.inspection_company?.companyname ? data?.inspection_company?.companyname : "",
        "inspector_name": data?.home_inspection_inspector?.firstName  ? data?.home_inspection_inspector?.firstName : ""
      }
    ] : null,
    "repairs": 545,
    "tax_date": data?.tax_date,
    "closing": data?.closing,
    "possession": data?.possession,
    "other": data?.other,
    "inspection_note": data?.home_inspection_note ? data?.home_inspection_note : "",
    "seller_discloser": data?.seller_discloser ? data?.seller_discloser : "",

    "home_payee": data?.home_payee || "Buyer Pays",
    "year_built": data?.year_built,
    "warranty_company_id": data?.warranty_Companies?.id ? data?.warranty_Companies?.id : 0,
    "warranty_company_name": data?.warranty_Companies?.companyname ? data?.warranty_Companies?.companyname : "",
    "warranty_type_name": data?.warranty_type ? data?.warranty_type : "Non-Evaluated",
    "warranty_cost": data?.warranty_cost ? data?.warranty_cost : 0,
    "sid": data?.sid ? data?.sid : false,
    "sid_info": data?.sid_info || "0",
    "buyer_sale_contingency_id": typeof (data?.buyer_Sale_Continencies?.id) === "string" ? Number(data?.buyer_Sale_Continencies?.id) : data?.buyer_Sale_Continencies?.id,
    "contingency_property_address": data?.contingency_property_address,
    "other_provision": data?.other_provision,
    "deed_name": data?.client?.leadName,
    "deed_status_id": data?.deed_Status?.id ? data?.deed_Status?.id : "",
    "offer_accepted": false,
    "filled_by": 0,
    "property_to_stay": data?.property_to_stay,
    "listing_id": data?.listing_id,
    "report_to_buyer_team":data?.report_to_buyer_team,
    "no_report":data?.no_report
    // "home_inspection": data?.home_inspection_selected ? data?.home_inspection_selected.map((item: any) => {
    //   return {
    //     inspection_company_id: item?.inspection_company_id?.id,
    //     inspection_type_id: item?.inspection_type_id?.id,
    //     inspector_id: item?.inspector_id?.id
    //   }
    // }) : []
  }


  await postRequests(`transaction/SaveOfferFormDetails`, temp_data, {

    successful: (v: any) => {
 
      temp_data_response = { statuscode: v?.status, status: v?.data?.status, message: v?.data?.message, success: v?.data?.success , data: v?.data?.data}
    },
    unprocessable: (v: any) => {
   
      temp_data_response = { statuscode: "", status: "", message: "", success: "", data:"" }
    }
  })
  return temp_data_response
}

/**
 * To get list of escrow agents
 * @returns list of escrow agents
 */
export const getEscrowAgents = async () => {
  let temp = await getRequests<any>(`Transaction/GetAllOfferDropdownList?role=1`);

  return temp?.data
}
export const getIncompleteOfferList = async (id:any) => {
  let temp = await getRequests<any>(`Transaction/GetOfferListByUserId?id=${id}`);

  return temp?.data
}
export const isOfferaccepted = async (id:any) => {
  let temp = await getRequests<any>(`Transaction/IsOfferAcceptedById?id=${id}`);
 
  return temp?.data
}
export const getdocumentStatus = async (id:any) => {
  let temp = await getRequests<any>(`Transaction/GetDocumentStatus?offerId=${id}`);
 
  return temp?.data
}

/**
 * To get list of escrow agents
 * @returns list of escrow agents
 */
export const getlistAgents = async () => {
  let temp = await getRequests<any>(`Transaction/GetAllOfferDropdownList`);

  return temp?.data
}
export const getagentcompanyname = async (id:number) => {
  let temp = await getRequests<any>(`Transaction/GetSellerAgentCompanyByAgentId?${id}`);

  return temp?.data
}
export const gettermiteinspector = async (v:string) => {
  let temp = await getofferformrequest<any>(`Transaction/GetTermiteInspectorByCompanName?companyName=${v}`);

  return temp?.data
}
export const gethomeinspector = async (v:string) => {
  let temp = await getofferformrequest<any>(`Transaction/GetHomeInspectorByCompanName?companyName=${v}`);

  return temp?.data
}

/**
 * To get list of assigned agents for offer
 * @returns list of assigned agents
 */
export const getlistofassignedAgents = async (offerid: string) => {
 
  let temp = await getRequests<any>(`Transaction/GetAgentsLists?offerid=${offerid}`);

  return temp?.data
}
export const getofferstatus = async (offerid: number) => {
 
  let temp = await getRequests<any>(`Transaction/IsOfferSubmitted?id=${offerid}`);

  return temp?.data
}
export const isundercontractaccepted = async (offerid: number) => {

  let temp = await getRequests<any>(`Transaction/IsOfferAcceptedUnderContract?id=${offerid}`);

  return temp?.data
}

/**
 * To get Legal Description and coutry based on listing id
 * @returns object containiing legal description and county
 */
export const getLegalDescriptionandCounty = async (listingid: string) => {

  let temp = await getRequests<any>(`Property/GetPropertyLegalandCounty?ListingId=${listingid}`);
 
  return temp?.data
}

/**
 * To save under contact form details agent side
 * @returns succes message
 */
export const underContactAgentForm = async (data:newofferdetails) => {

  let temp_data_response = {
    statuscode: "",
    status: "",
    message: "",
    success: "",
    data: ""
  }
  
  const temp_data = {
     // @ts-ignore
     
    "commissions_buyer_agent":data?.commissions_buyer_agent,
    "is_termite_inspection":data?.termite_inspection,
    "home_warranty": data?.home_warranty,
"is_home_inspection":data?.home_inspection,
    "title_company_name":data.title_Companies?.companyname ? data.title_Companies?.companyname  : "" ,
    "id": typeof (data?.offer_id) === "string" ? Number(data?.offer_id) : data?.offer_id,
    "offercode": data?.offer_id ? data?.offer_id : "",
    "state": data?.state,
    "county": data?.county || "string",
    "propertyaddress": data?.propertyAddress,
    "clienttype": data?.clientType,
    "buyer_type": data?.buyerType,
    "sub_type": data?.subtype || "",
    "leadid": data?.client?.leadId,
      // @ts-ignore
    "external_agent_id":data?.external_Agent_id,
    "agentid": data?.agent?.agentId,
    "coagentid": data?.coagent?.coAgentId,
    "seller_agent_id": data?.seller_agent_id ? data?.seller_agent_id :"",
    "offer_date": data?.offerDate || "",
    "offer_price": typeof (data?.offer_price) === "string" ? Number(data?.offer_price) : data?.offer_price,
    "earnest_deposit": typeof (data?.earnest_deposit) === "string" ? Number(data?.earnest_deposit) : data?.earnest_deposit,
    "closing_date": data?.closing_date ? moment(data?.closing_date).toISOString() : "",
    "closing_costs": data?.closing_costs,
    "closing_amount":  data?.closing_amount ? data?.closing_amount : 0,
    "termite_company_name":data?.termite_Companies?.companyname ? data?.termite_Companies?.companyname : "",
    "buyer_finance_state": data?.buyer_finance_state,
    "buyer_finance_type": data?.buyer_finance_type || "",
    "lenderid": data?.lender?.id ? data?.lender?.id : "",
    "all_cash": data?.all_cash,
    "home_inspection_time":data?.home_inspection_time ? data?.home_inspection_time : "",
    "home_inspection_date":data?.home_inspection_date ? data?.home_inspection_date : "",
    "conditional_financing": data?.conditional_financing,
    "terms_financing": data?.terms_financing,
    "interest_rate": data?.interest_rate,
    "termite_inspector_name":data?.termiteinspector ? data?.termiteinspector : "",
    "escrow_agent_id": data?.escrow_Agents ? data?.escrow_Agents?.id : null,
    "survey_id": typeof (data?.surveys?.id) === "string" ? Number(data?.surveys?.id) : data?.surveys?.id,
    "business_address": data?.propertyAddress ? data?.propertyAddress : "",
    "termite_date": data?.termite_date,
    "termite_time": data?.termite_time,
    "termite_location": data?.propertyAddress,
    "repairs": 154,
    "inspection_note": data?.home_inspection_note ? data?.home_inspection_note : "",
 
    "home_payee": data?.home_payee || "Buyer Pays",
    "warranty_company_name": data?.warranty_Companies?.companyname ? data?.warranty_Companies?.companyname : "",
    "warranty_type_name": data?.warranty_type ? data?.warranty_type : "Non-Evaluated",
    "warranty_cost": data?.warranty_cost ? data?.warranty_cost : 0,
    "other_provision":data?.other_provision || "",
    "buyer_sale_contingency_id": typeof (data?.buyer_Sale_Continencies?.id) === "string" ? Number(data?.buyer_Sale_Continencies?.id) : data?.buyer_Sale_Continencies?.id,
    "contingency_property_address": data?.contingency_property_address || "",
    "property_to_stay": data?.property_to_stay,
    "seller_agent":data?.seller_agent || 0,
  
    "home_inspection": data?.home_inspection === true ? [
{
  "inspection_type_name": data?.inspection_Types?.companyname ,
   // @ts-ignore
  "inspection_company_name": data?.inspection_company?.companyname,
   // @ts-ignore
  "inspector_name": data?.home_inspection_inspector?.firstName + " "  + data?.home_inspection_inspector?.lastName
},
...data?.home_inspection_selected
  
    ] : null,
   
   
    "closing_time": data?.closing_date_time ? data?.closing_date_time : "",
    "closing_location": data?.propertyAddress ? data?.propertyAddress : "",
    "finalwalkthrough_date": data?.final_walkthrough_date ? data?.final_walkthrough_date : "",
    "finalwalkthrough_time": data?.final_walkthrough_time ? data?.final_walkthrough_time : "",
  "finalwalkthrough_location": data?.propertyAddress ? data?.propertyAddress : "",
    "seller_agent_company": data?.seller_agent_company ? data?.seller_agent_company : "",
    "seller_business_address": data?.propertyAddress ? data?.propertyAddress : "",
    "closing_gift_option": data?.closing_gift_option ?data?.closing_gift_option : "",  
    "closing_gift_notes": data?.closing_gift_notes ? data?.closing_gift_notes : "",
    "buyer_agent_gross": data?.buyer_agent_gross ? data?.buyer_agent_gross : 0,
    
    "buyer_agent_gross_assigned": data?.buyer_agent_gross_assigned ? data?.buyer_agent_gross_assigned : "",
    "buyer_agent_assigned": data?.buyer_agent_assigned ? data?.buyer_agent_assigned : "",
    "co_buyer_agent": data?.co_buyer_agent ? data?.co_buyer_agent : 0,
    "co_buyer_agent_assigned": data?.co_buyer_agent_assigned ? data?.co_buyer_agent_assigned : "",
  
    "seller_agent_assigned": data?.seller_agent_assigned ?data?.seller_agent_assigned : "",
    "co_seller_agent": data?.co_seller_agent ? data?.co_seller_agent : 0,
    "co_seller_agent_assigned": data?.co_seller_agent_assigned ? data?.co_seller_agent_assigned : "",
    "milford_realestate": data?.milford_realestate ? data?.milford_realestate : 0,
    "milford_agent_assigned": data?.milford_agent_assigned ? data?.milford_agent_assigned : "",
    "showing_assistant": data?.showing_assistant ? data?.showing_assistant : 0,
    "showing_assistant_assigned": data?.showing_assistant_assigned ? data?.showing_assistant_assigned : "",
    "broker_admin_fee_payout": data?.broker_admin_fee_payout ? data?.broker_admin_fee_payout : 0,
    "agent_notes": data?.agent_notes ? data?.agent_notes : "",
    "buyer_broker_admin_fees": data?.broker_admin_fees ? data?.broker_admin_fees : 0,
    "commission_broker_admin_fees": data?.commission_broker_admin_fees ? data.commission_broker_admin_fees : 0,
    "buyer_percentage": data?.buyer_percentage ? data?.buyer_percentage : 0,
    "milford_marketing_fee":data?.milford_marketing_fee || 0,
    "outside_referrel_fee":data?.outside_referrel_fee || 0,
    // 'buyer_agent_gross':data?.buyer_agent_gross || 0,
    // "buyer_agent_gross_assigned": data?.buyer_agent_gross_assigned || "",
    // "commissions_buyer_agent":data?.buyer_agent || 0,
    // "buyer_agent_assigned": data?.buyer_agent_assigned || "",
    // "co_buyer_agent": data?.co_buyer_agent ||  0,
    // "co_buyer_agent_assigned": data?.co_buyer_agent_assigned || "",
    // "seller_agent": data?.seller_agent ||  0,
    // "seller_agent_assigned": data?.seller_agent_assigned || "",
    // "co_seller_agent":data?.co_seller_agent ||  0,
    // "co_seller_agent_assigned":data?.co_seller_agent_assigned ||  "",
    // "milford_realestate": data?.milford_realestate || 0,
    // "milford_agent_assigned":data?.milford_agent_assigned ||  "",
    // "showing_assistant": data?.showing_assistant || 0,
    // "showing_assistant_assigned": data?.showing_assistant_assigned || "",
    // "broker_admin_fee_payout": data?.broker_admin_fee_payout || 0,
  }



  await postRequests(`Transaction/UnderContractAgentFillOut`, temp_data, {

    successful: (v: any) => {

      temp_data_response = { statuscode: v?.status, status: v?.data?.status, message: v?.data?.message, success: v?.data?.success , data: v?.data?.data}
    },
    unprocessable: (v: any) => {
   
      temp_data_response = { statuscode: "", status: "", message: "", success: "", data:"" }
    }
  })
  return temp_data_response
}




export const HomeWarrantCompany = async (data:transactionusers,type:string) => {
  let temp_data_response = {
    status: "",
    message: "",
    success: "",
    data: ""
  }
  const temp_data = {
  "firstName": data?.fname,
  "lastName": data?.lname,
  "email":  data?.email,
  "phoneNo": data?.phone,
  "comapanyName": data?.cname

  }  

  await postRequests(type === "homewarranty" ? `transaction/HomeWarrantCompany` : type === "inspectioncompany" ?  `Transaction/SaveInspectionCompany` : type === "termitecompany" ? `Transaction/SaveTermiteCompany` : type === "escrowagent" ? `Transaction/SaveEscrowAgent` : "",  temp_data, {

    successful: (v: any) => {
   
      temp_data_response = { status: v?.data?.status, message: v?.data?.message, success: v?.data?.success , data: v?.data?.data}
 
    },
    unprocessable: (v: any) => {
     
      temp_data_response = { status: v?.data?.status, message: v?.data?.message, success: "", data:v?.data?.data }
      
    }
  })
  return temp_data_response
}


/**
 * To save Esign png with document name
 * @returns succes message
 */
export const saveEsign = async (selecteddocumentesign: selecteddocumentesign) => {
  let temp_data_response = {
    statuscode: "",
    status: "",
    message: "",
    success: "",
    data: ""
  }
  await postRequests(`Document/SaveESign`, selecteddocumentesign, {
    successful: (v: any) => {
      temp_data_response = { statuscode: v?.status, status: v?.data?.status, message: v?.data?.message, success: v?.data?.success, data: v?.data?.data }
  },
    unprocessable: (v: any) => {
      temp_data_response = { statuscode: "", status: "", message: "", success: "", data: "" }
    }
  })
  return temp_data_response
}
export const buyerteamsend = async (data: any) => {
  let temp_data_response = {
    statuscode: "",
    status: "",
    message: "",
    success: "",
    data: ""
  }

  // debugger
const payload = 
{
  "id": data?.id,
  "offer_price": data?.offer_price,
  "offer_date": data?.offerDate,
  "earnest_deposit": data?.earnest_deposit,
  "offercode": data?.offerCode,
  "clienttype": data?.clientType,
  "buyer_type": data?.buyerType,
  "sub_type": data?.sub_type,
  "leadid": data?.client?.leadId,
  "state": data?.state,
  "county": data?.county,
  "agentid": data?.agent?.agentId,
  "coagentid": data?.coagent?.coAgentId,
  "external_agent_id": data?.externalagent?.externalagentId,
  "propertyaddress": data?.propertyAddress,
  "closing_date": data?.closing_date,
  "closing_time": data?.closing_time,
  "closing_location": data?.closing_location,
  "finalwalkthrough_date": data?.finalwalkthrough_date,
  "finalwalkthrough_time": data?.finalwalkthrough_time,
  "finalwalkthrough_location": data?.finalwalkthrough_location,
  "buyer_finance_state": data?.buyer_finance_state,
  "all_cash": data?.all_cash,
  "conditional_financing": data?.conditional_financing,
  "terms_financing": data?.terms_financing,
  "buyer_finance_type": data?.buyer_finance_type,
  "lenderid": data?.lender?.id,
  "interest_rate": data?.interest_rate,
  "termite_date": data?.termite_date,
  "termite_time": data?.termite_time,
  "termite_location": data?.termite_location,
  "termite_company_name": data?.termite_company_name,
  "termite_inspector_name": data?.termite_inspector_name,
  // "repairs": 0,
  "home_inspection_date": data?.home_inspection_date,
  "home_inspection_time": data?.home_inspection_time,
  "home_inspection": [
    {
      "inspection_type_name": data?.insepction_type?.companyname,
      "inspection_company_name": data?.inspection_company?.companyname,
      "inspector_name": data?.insepection_inspector?.firstName + " " + data?.insepection_inspector?.lastName
    },
    ...data?.home_inspection
  ],
  "inspection_note": data?.home_inspection_note,
  "escrow_agent_id": data?.escrow_Agents_id,
  "title_company_name": data?.title_Company_name,
  "survey_id": 0,
  "business_address": data?.business_address,
  "property_to_stay": data?.property_to_stay,
  "home_warranty": data?.home_warranty,
  "is_home_inspection": data?.is_home_inspection,
  "is_termite_inspection": data?.is_termite_inspection,
  "home_payee": data?.home_payee,
  "warranty_company_name": data?.warranty_company_name,
  "warranty_type_name": data?.warranty_type_name || "Non-Evaluated",
  "warranty_cost": data?.warranty_cost,
  "buyer_percentage": data?.buyer_percentage,
  "buyer_broker_admin_fees": data?.buyer_broker_admin_fees,
  "buyer_sale_contingency_id": 0,
  "contingency_property_address": data?.contingency_property_address,
  "other_provision": data?.other_provision,
  "closing_costs": data?.closing_costs,
  "closing_amount": data?.closing_amount,
  "seller_agent_id": data?.seller_agent_id,
  "seller_agent_company": data?.seller_agent_company,
  "seller_business_address": data?.seller_business_address,
  "closing_gift_option": data?.closing_gift_option,
  "closing_gift_notes": data?.closing_gift_notes,
  // "confirm_lender_date": "string",
  // "confirm_title_date": "string",
  // "confirm_buyer_agent_date": "string",
  // "confirm_appraisal_ordered_date": "string",
  "agent_notes": data?.agent_notes
}



  await postRequests(`Transaction/UnderContractBuyerFillOut`, payload, {
    successful: (v: any) => {
      temp_data_response = { statuscode: v?.status, status: v?.data?.status, message: v?.data?.message, success: v?.data?.success, data: v?.data?.data }
    },
    unprocessable: (v: any) => {
      temp_data_response = { statuscode: "", status: "", message: "", success: "", data: "" }
    }
  })
  return temp_data_response
}

/**
 * To get saved sign based on document name
 * @returns saved sign png
 */
export const getSavedSignedDocument = async (documwentname: string, offerid: string) => {
 
  let temp = await getRequests<any>(`Document/GetESign?offerId=${offerid}&documentName=${documwentname}`);
 
  return temp?.data
}

export const getCommisionagentsList = async () => {
  const res = await getRequests<any>(`User/GetExternalLenderList?type=commision_agents`);
  return res
}

export const getundercontractagentfillout = async (id:string) => {

  let temp = await getRequests<any>(`Transaction/GetAgentFillOutById?id=${id}`);

  return temp?.data
}
export const isagentsubmitted = async (id:string) => {
  let temp = await getRequests<any>(`Transaction/IsAgentFilloutById?id=${id}`);
  return temp?.data
}

/**
 * To save offer is Accepted based on offerid
 * @returns succes message "Data updated successfully",
 */
export const offerAccepted = async (offerid: string, userid: string) => {
 
  let temp = await getRequests(`Transaction/OfferAcceptedById?id=${offerid}&userId=${userid}`);

  return temp?.data
}

export const sendemailworkflow = async (offerid: string,type:string) => {
 const data = {
  "offerId": offerid,
  "role": type
 }

  let temp = await postRequests(`Transaction/SendMissionEmailTempalate`,data);

  //  @ts-ignore
  return temp?.data
}
export const sendcommissiondata = async (data:any) => {
let temp_data_response
  let temp = await postRequests(`Transaction/UpdateCommissionDetails`,data,{
    successful: (v: any) => {
      temp_data_response = { v }
    },
    unprocessable: (v: any) => {
      temp_data_response = { v }
    }
  });

  //  @ts-ignore
  return temp_data_response?.v?.data
}


/**
 * To send selected documents list
 * @returns success message
 */
export const sendDocuments = async (selecteddocumentesign: any) => {
  let temp_data_response = {
    statuscode: "",
    status: "",
    message: "",
    success: "",
    data: ""
  }
  await postRequests(`Document/SaveBuyerDocument`, selecteddocumentesign, {
    successful: (v: any) => {
   
      temp_data_response = { statuscode: v?.status, status: v?.data?.status, message: v?.data?.message, success: v?.data?.success, data: v?.data?.data }
    },
    unprocessable: (v: any) => {
      temp_data_response = { statuscode: "", status: "", message: "", success: "", data: "" }
    }
  })
  return temp_data_response
}
export const getexternaldocuments = async (id:number,docType:string) => {
  let temp = await getRequests<any>(`Document/GetDocumentByOfferId?offer_id=${id}&document_name=${docType}`);
  return temp?.data
}


export const sendleadbasepaint = async (selecteddocumentesign: any) => {
  let temp_data_response = {
    statuscode: "",
    status: "",
    message: "",
    success: "",
    data: ""
  }
  await postRequests(`Document/SaveLeadBasedPaint`, selecteddocumentesign, {
    successful: (v: any) => {
   
      temp_data_response = { statuscode: v?.status, status: v?.data?.status, message: v?.data?.message, success: v?.data?.success, data: v?.data?.data }
    },
    unprocessable: (v: any) => {
      temp_data_response = { statuscode: "", status: "", message: "", success: "", data: "" }
    }
  })
  return temp_data_response
}
export const sendleadinSoilAmmendum= async (selecteddocumentesign: any) => {
  let temp_data_response = {
    statuscode: "",
    status: "",
    message: "",
    success: "",
    data: ""
  }
  await postRequests(`Document/SaveLeadSoilAddendum`, selecteddocumentesign, {
    successful: (v: any) => {
   
      temp_data_response = { statuscode: v?.status, status: v?.data?.status, message: v?.data?.message, success: v?.data?.success, data: v?.data?.data }
    },
    unprocessable: (v: any) => {
      temp_data_response = { statuscode: "", status: "", message: "", success: "", data: "" }
    }
  })
  return temp_data_response
}

/**
 * To get buyer offers based on leadid
 * @returns list of offers,
 */
export const leadOfferdetails = async (leadid: string) => {

  let temp = await getRequests<any>(`Document/GetClientByLeadId?leadId=${leadid}`);

  return temp?.data
}
  export const GetclientID = async () => {
  
    let temp = await getRequests<any>(`AdminLead/ClientList`);

    return temp?.data
  }
export const claimtransactionbuyerlist = async (userId: string) => {
 
  let temp = await getRequests<any>(`Transaction/GetTransactionListForBuyerTeam?userId=${userId}`);

  return temp?.data
}
export const claimtransactionbtn = async (data: any) => {


let temp_data
  await postRequests(`Transaction/TransactionClaimBuyerTeam`, data, {

    successful: (v: any) => {
   return  temp_data =v
    },
    unprocessable: (v: any) => {
      return temp_data = v
    }
  })

  return temp_data
}

/**
 * To get list of transactions based on leadid
 * @returns list of transactions,
 */
export const transactionList = async (leadid: string) => {
  
  let temp = await getRequests<any>(`Transaction/GetTransactionList?id=${leadid}`);
 
  return temp?.data
}

/**
 * To get list of Misions based on leadid
 * @returns list of missions,
 */
export const missionsList = async (offerid: string) => {

  let temp = await getRequests<any>(`Transaction/GetMissionListByOfferId?offer_id=1`);

  return temp?.data
}
export const getmissionlistbyid = async (userID: string) => {

  let temp = await getRequests<any>(`Transaction/GetMissionListForBuyerTeam?userId=${userID}`);

  return temp?.data
}

export const gettransactionmissionname = async (userID: string,id:any) => {
  let temp = await getRequests<any>(`Transaction/GetTransactionMissionName?userId=${userID}&offerId=${id}`);
  // console.log("datatatatatatatatta", temp);
  return temp?.data
}

export const updatemissionstatus = async (data: any) => {
  let temp_data
    await postRequests(`Transaction/UpdateTransactionMissionStatus`, data, {
        successful: (v: any) => {
     return  temp_data =v
      },
      unprocessable: (v: any) => {
        return temp_data = v
      }
    })
      return temp_data
  }
export const getmissionstatus = async (id:any) => {
  let temp = await getRequests<any>(`Transaction/GetTransactionMissionStatus?OfferId=${id}`);

  return temp?.data
}
export const getemailworkflow = async (id:any) => {
  let temp = await getRequests<any>(`Transaction/GetEmailWorkFlowStatus?offerrId=${id}`);

  return temp?.data
}














