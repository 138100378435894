import { ActionType } from './actions'

export const __reducer = <a>(initial:a,handler:(state:a,action:any) => a) =>
(state:a|void,action:any) => {
  if(action.type === ActionType.CLEAR)
  {
    return initial
  }
  else
  {
    return handler(state || initial,action)
  }
}

export const _reducer = <a>(initial:a,actions:[string,(state:a,action:any) => a][]) =>
  __reducer(initial,(state,action) => {
    for(let x of actions)
    {
      if(x[0] === action.type)
      {
        return x[1](state,action)
      }
    }
    return state
  })
