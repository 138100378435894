import React, { useState, RefObject, useRef } from "react";
import { DispatchProp, connect, useDispatch } from "react-redux";
import { CreateIdxUser } from "../../api/idx";
import { State } from "../../store";
import { Link } from "react-router-dom";
import House from "../../assets/icons/House 2.svg";
import Navbar from "./Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { navigate_to, notification_success } from "../../state/actions";
import Celebration from "../../assets/icons/Celebration.png";
import { useHistory } from 'react-router-dom';
import { errorMessage } from "./Util/Toaster";

type Props = DispatchProp<any>;
type InputRefType = RefObject<HTMLButtonElement>;
const Register: React.FC<Props> = (props) => {
  const [isloading, setisLoading] = useState(false);
  const [showPassword, setshowPassword] = useState({
    password: false,
    cpassword: false,
  });
  const [inputValues, setInputValue] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirmPassword: "",
    leadRole: "Buyer",
    checkbox: false,
    
  });

  const [validation, setValidation] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirmPassword: "",
    checkbox: false,
  });

  const [showModal, setShowModal] = useState(false); 
  const history = useHistory();
  const inputRef: InputRefType = useRef(null);
  /* This function runs when enter on the login button */
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      inputRef.current?.click(); 
    }
  };
  //handle submit updates
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setInputValue({ ...inputValues, [name]: value });
  };

  const checkboxHandle = () => {
    setInputValue({ ...inputValues, checkbox: !inputValues.checkbox });
  };

  const checkValidation = () => {
    let errors = JSON.parse(JSON.stringify(validation));

    // First Name validation
    switch (true) {
      case !inputValues.first_name.trim():
        errors.first_name = "First Name is required";
        break;
        case !/^[a-zA-Z]+$/.test(inputValues.first_name):
    errors.first_name = "First Name can only contain letters";
    break;
      case inputValues.first_name.length < 2:
        errors.first_name = "First Name must be at least 2 characters";
        break;
      case inputValues.first_name.length > 25:
        errors.first_name = "First Name cannot exceed 25 characters";
        break;
      default:
        errors.first_name = "";
    }

    // Last Name validation
    switch (true) {
      case !inputValues.last_name.trim():
        errors.last_name = "Last Name is required";
        break;
        case !/^[a-zA-Z]+$/.test(inputValues.last_name):
    errors.last_name = "First Name can only contain letters";
    break;
      case inputValues.last_name.length < 2:
        errors.last_name = "Last Name must be at least 2 characters";
        break;
      case inputValues.last_name.length > 25:
        errors.last_name = "Last Name cannot exceed 25 characters";
        break;
      default:
        errors.last_name = "";
    }

    // Email validation
    const emailCond = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    switch (true) {
      case !inputValues.email.trim():
        errors.email = "Email is required";
        break;
      case !emailCond.test(inputValues.email):
        errors.email = "Please enter a valid email address";
        break;
      default:
        errors.email = "";
    }

    // Password validation
    const passwordCond = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}$/;
    const password = inputValues.password;
    switch (true) {
      case !password:
        errors.password = "Password is required";
        break;
      case password.length < 6:
        errors.password = "Password must be longer than 6 characters";
        break;
      case !passwordCond.test(password):
        errors.password =
          "Password contain at least one lowercase, one uppercase, and one number";
        break;
      default:
        errors.password = "";
    }

    // Match Password validation
    switch (true) {
      case !inputValues.confirmPassword:
        errors.confirmPassword = "Password confirmation is required";
        break;
      case inputValues.confirmPassword !== password:
        errors.confirmPassword =
          "Password does not match confirmation password";
        break;
      default:
        errors.confirmPassword = "";
    }

    // Checkbox validation
    switch (true) {
      case !inputValues.checkbox:
        errors.checkbox = "You must agree with the terms and conditions";
        break;
      default:
        errors.checkbox = "";
    }
    return errors;
  };

  const handleRole = (e: any, data: string) => {
    setInputValue({ ...inputValues, leadRole: data });
  };

  const dispatch = useDispatch()
  

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let validations = await checkValidation();

    if (Object.values(validations).every((value) => value === "")) {
      setisLoading(true);
      let required_user_details = {
        first_name: inputValues.first_name,
        last_name: inputValues.last_name,
        email: inputValues.email,
        password: inputValues.password,
        leadRole: inputValues.leadRole,
        phone: "",
        department: "",
        relationshipStatus: "",
        dateOfBirth: null,
        tShirtSize: "",
        address1: "",
        address2: "",
        country: "",
        city: "",
        state: "",
        zip: "",
        agreeTermAndCondition: inputValues.checkbox,
      };

      let api_data = await CreateIdxUser(required_user_details);
      setisLoading(false);
      setValidation(() => ({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirmPassword: "",
        checkbox: false,
      }));
      console.log(api_data,"weifwf");
      if (api_data?.statusCode === "success") {
    // toast.success("User Registration Succesfull.")
    // setTimeout(() => {
      
    //   dispatch(navigate_to('/login'));
    // }, 2000);

        setShowModal(true);

        setValidation(() => ({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          confirmPassword: "",
          checkbox: false,
        }));
        setInputValue(() => ({
          first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirmPassword: "",
    leadRole: "",
    checkbox: false,
        }))
      
       
      } else {
        errorMessage('Please create an account using a new email.')
        setValidation(() => ({
          first_name: "",
          last_name: "",
          email: '',
          password: "",
          confirmPassword: "",
          checkbox: false,
        }));
      }
    } else {
      setValidation((prevState) => ({
        ...prevState,
        first_name: validations.first_name,
        last_name: validations.last_name,
        email: validations.email,
        password: validations.password,
        confirmPassword: validations.confirmPassword,
        checkbox: validations.checkbox,
      }));
    }
  };
  const closeModalAndRedirect = () => {
    setShowModal(false);
    history.push('/login');
  }

  return (
    <>
      <Navbar />
      {/* Account created CSS of this is same as reset password make sure if you do any change that reflect in reset password popup as well */}
      {showModal && (
      <div
        className="modal fade resetPassword show"
        id="exampleModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div className="modal-content">
            <div className="modalCloseIcon" onClick={closeModalAndRedirect}>
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                <path d="M3 30L30 3" stroke="#1E1D1E" stroke-width="1.52" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M30 30L3 3" stroke="#1E1D1E" stroke-width="1.52" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            <div className="modal-body d-flex align-items-center justify-content-center">
              <div className="text-center">
                <img src={Celebration} alt="lock icon img"/>
                <h1 className="title">Account created</h1>
                <p className="mt-5"><b>Your account has been created successfully.</b></p>
                <p className="mt-4"><b>Need assistance?</b> Just click the purple chat icon at the bottom of your screen to be<br/> connected to our concierge team.  </p>
                <button type="button" className="btn btn-secondary closePopupBtn-white" data-dismiss="modal" onClick={closeModalAndRedirect}>Done</button>
              </div>
            </div>
          </div>
        </div>
    </div>
      )}

      <div className="login-bg signup-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="signup-heading">Create new account</h1>
            </div>
            <div className="col-md-6 pr-md-5">
              <div className="card card-left overflow-hidden">
                <div className="card-body py-0">
                  <div className="row">
                    <div className="col pt-4 pb-5 login-btn">
                      <section className="signup">
                        <div className="row">
                          <div className="col-lg-12 col-xl-12">
                            <div className="text-black">
                              <div className="card-content mx-1 mx-md-4">
                                <img src={House} />
                                <h1 className="left-card-title">
                                  What brings you to Milford?
                                </h1>
                                <p className="left-card-paragraph">
                                  Tell us your goals to receive tailored emails.
                                </p>
                                <div className="row">
                                  <div className="col-6">
                                    <button
                                      className={inputValues?.leadRole === "Buyer" ? "btn btn-custom-style sell-btn active" : "btn btn-custom-style sell-btn"} 
                                      onClick={(e: any) =>
                                        handleRole(e, "Buyer")
                                      }
                                    >
                                      Looking to Buy
                                    </button>
                                  </div>
                                  <div className="col-6">
                                    <button
                                      className={inputValues?.leadRole === "Seller" ? "btn btn-custom-style sell-btn active" : "btn btn-custom-style sell-btn"}
                                      onClick={(e: any) =>
                                        handleRole(e, "Seller")
                                      }
                                    >
                                      Ready to Sell
                                    </button>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-12">
                                    <button
                                      className={inputValues?.leadRole === "Buyer/Seller" ? "btn btn-custom-style buy-sell-btn active" : "btn btn-custom-style buy-sell-btn"}
                                      onClick={(e: any) =>
                                        handleRole(e, "Buyer/Seller")
                                      }
                                    >
                                      Buy & Sell
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
              <span className="login-option">
                <span className="login-option-text">
                  Already have an account ?
                </span>
                <Link to="/login" className="loginSignup login-option-btn ml-2">
                  Sign in
                </Link>
              </span>
            </div>
            <div className="col-md-6 pl-md-5">
              <div className="card overflow-hidden">
                <div className="card-body py-0">
                  <div className="row">
                    <div className="col py-5 login-btn">
                      <section className="signup">
                        <div className="row">
                          <div className="col-lg-12 col-xl-12">
                            <div className="text-black">
                              <form
                                className="mx-1 mx-md-4"
                                id="registrationForm"
                                onSubmit={(e: any) => handleSubmit(e)}
                              >
                                <div className="row gy-4">
                                  <div className="col-md-6 ">
                                      <div className="form-outline flex-fill mb-0">
                                        <i className="fas fa-user fa-lg me-3 fa-fw"></i>
                                        <label
                                          className="form-label"
                                          htmlFor="form3Example1c"
                                        >
                                          First Name
                                        </label>
                                        <input
                                          type="text"
                                          name="first_name"
                                          id="first_name"
                                          className="form-control"
                                          placeholder="Your First Name"
                                          onChange={(e) => handleChange(e)}
                                          value={inputValues.first_name}
                                          onKeyDown={handleKeyPress}
                                        />
                                      </div>
                                      {validation.first_name && (
                                        <p className="regiter-errorMsg">
                                          {validation.first_name}
                                        </p>
                                      )}
                                  </div>
                                  <div className="col-md-6">
                                      <div className="form-outline flex-fill mb-0">
                                        <i className="fas fa-user fa-lg me-3 fa-fw"></i>
                                        <label
                                          className="form-label"
                                          htmlFor="form3Example1c"
                                        >
                                          Last Name
                                        </label>
                                        <input
                                          type="text"
                                          name="last_name"
                                          id="last_name"
                                          className="form-control"
                                          placeholder="Your Last Name"
                                          onChange={(e) => handleChange(e)}
                                          onKeyDown={handleKeyPress}
                                          value={inputValues.last_name}
                                        />
                                      </div>
                                      {validation.last_name && (
                                        <p className="regiter-errorMsg">
                                          {validation.last_name}
                                        </p>
                                      )}
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-outline flex-fill mb-0">
                                      <i className="fas fa-envelope fa-lg me-3 fa-fw"></i>
                                      <label
                                        className="form-label"
                                        htmlFor="form3Example3c"
                                      >
                                        Email
                                      </label>
                                      <input
                                        type="text"
                                        name="email"
                                        className="form-control"
                                        placeholder="Your Email Address"
                                        onChange={(e) => handleChange(e)}
                                        onKeyDown={handleKeyPress}
                                        value={inputValues.email?.toLocaleLowerCase()}
                                      />
                                    </div>
                                    {validation.email && (
                                      <p className="regiter-errorMsg">
                                        {validation.email}
                                      </p>
                                    )}
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-outline flex-fill mb-0">
                                      <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                                      <label
                                        className="form-label"
                                        htmlFor="form3Example4c"
                                      >
                                        Password
                                      </label>
                                      <input
                                        type={
                                          showPassword.password
                                            ? "text"
                                            : "password"
                                        }
                                        className="form-control"
                                        name="password"
                                        placeholder="Your Password"
                                        onChange={(e) => handleChange(e)}
                                        onKeyDown={handleKeyPress}
                                        value={inputValues.password}
                                      />
                                      <i
                                        className={`fas ${
                                          showPassword.password
                                            ? "fa-eye-slash"
                                            : "fa-eye"
                                        } password-toggle`}
                                        onClick={() =>
                                          setshowPassword((prevState) => ({
                                            ...prevState,
                                            password: !showPassword.password,
                                          }))
                                        }
                                      ></i>
                                    </div>
                                    {validation.password && (
                                      <p className="regiter-errorMsg position-relative">
                                        {validation.password}
                                      </p>
                                    )}
                                  </div>
                                  <div className="col-md-12">
                                    <div className="form-outline flex-fill mb-0">
                                      <i className="fas fa-key fa-lg me-3 fa-fw"></i>
                                      <label
                                        className="form-label"
                                        htmlFor="form3Example4cd"
                                      >
                                        Confirm password
                                      </label>
                                      <input
                                        type={
                                          showPassword.cpassword
                                            ? "text"
                                            : "password"
                                        }
                                        className="form-control input-field"
                                        name="confirmPassword"
                                        placeholder="Confirm Your Password"
                                        onChange={(e) => handleChange(e)}
                                        onKeyDown={handleKeyPress}
                                        value={inputValues.confirmPassword}
                                      />
                                      <i
                                        className={`fas ${
                                          showPassword.cpassword
                                            ? "fa-eye-slash"
                                            : "fa-eye"
                                        } password-toggle`}
                                        onClick={() =>
                                          setshowPassword((prevState) => ({
                                            ...prevState,
                                            cpassword: !showPassword.cpassword,
                                          }))
                                        }
                                      ></i>
                                    </div>
                                  {validation.confirmPassword && (
                                    <p className="regiter-errorMsg">
                                      {validation.confirmPassword}
                                    </p>
                                  )}
                                  </div>
                                </div>


                                <div className="form-check pl-3 d-flex mt-4 agreeCheckbox">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="checkbox"
                                    checked={inputValues.checkbox}
                                    onChange={checkboxHandle}
                                  />
                                  <label
                                    className="form-check-label ml-2"
                                    htmlFor="form2Example3"
                                  >
                                    I Agree with <span className="fw-bolder">Terms</span> and <span className="fw-bolder"> Privacy Policy</span> 
   
                                  </label>
                                  {validation.checkbox && (
                                    <p className="regiter-errorMsg-checkbox">
                                      {validation.checkbox}
                                    </p>
                                  )}
                                </div>

                                <div className="d-flex justify-content-center mb-2 mb-lg-4 mt-4">
                                  <button
                                    type="submit"
                                    className="btn btn-lg signupBtn"
                                    id="submit-button"
                                    ref={inputRef}
                                  >
                                    {isloading
                                      ? "Loading..."
                                      : "Create account"}
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
    </>
  );
};

export default connect((_: State) => ({}))(Register);