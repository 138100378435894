import React, { useCallback, useState, useEffect } from "react";
import Modal from "../../../components/Modal";
import moment from "moment";
import useToggle from "../../../hooks/toggle";
import store, { stor1 } from "../../../store";
import { useDispatch } from "react-redux";

type Props = {
  canvasmodal: boolean;
  setCanvasModal: () => void;
  htmlContent: any;
  setHtmlContent: any;
  selecteddocumentesign: any,
  setselectedDocumentEsign: any,
  type: string
};

const Esign: React.FC<Props> = (props) => {
  const { canvasmodal, setCanvasModal, htmlContent, setHtmlContent , selecteddocumentesign , setselectedDocumentEsign, type } = props;
  // console.log("canvasmodalcanvasmodalcanvasmodal",canvasmodal);
  const [imageURL, setImageURL] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [signatureType, setSignatureType] = useState("draw");
  const [canvasId, setCanvasId] = useState("");
  const [signatureList, setSignatureList] = useState<any>([]);
  const [esignrender, setesignRender] = useToggle(false);

const dispatch = useDispatch()

  const draw = (x: any, y: any, isDragging: any, id: any, event: any) => {
    const canvas: HTMLCanvasElement = document.getElementById(id) as HTMLCanvasElement;
    let ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");
    if (ctx !== null) {
      ctx.lineWidth = 4;
      var element = event?.target?.localName;
      if (isDragging && element === "canvas") {
        ctx.lineTo(x, y);
        ctx.stroke();
      } else {
        ctx.beginPath();
        ctx.moveTo(x, y);
      }
    }
  };

  const onContentBlur = useCallback((evt) => {
    var id = evt?.target?.id;
    var value = evt?.target?.value;
    var type = evt?.target?.type;
    // switch (type) {
    //   case "text":
    //     document.getElementById(id).setAttribute("value", value)
    //     break;

    //   case "checkbox":
    //     value = evt.target.checked;
    //     if (value) {
    //       document.getElementById(id).setAttribute("checked", value);
    //     } else {
    //       document.getElementById(id).removeAttribute("checked");
    //     }
    //     break;

    //   case "date":
    //     document.getElementById(id).setAttribute("value", value)
    //     break;
    //   case "radio":
    //     value = evt.target.checked;
    //     if (value) {
    //       document.getElementById(id).setAttribute("checked", value);
    //     } else {
    //       document.getElementById(id).removeAttribute("checked");
    //     }
    //     break;
    //   case "textarea":
    //     document.getElementById(id).innerHTML = value;
    //     break;
    //   case "canvas":
    //     const canvas = document.getElementById('myCanvas');
    //     const ctx = canvas.getContext('2d');
    //     ctx.font = '30px Arial';
    //     ctx.fillText(value, 10, 50);
    //     break;

    //   default:
    //     console.log("not handled for " + type);
    //     break;
    // }
    setHtmlContent(evt?.currentTarget?.innerHTML);
  }, []);

  const handleMouseDown = (event: any) => {
    var id = event?.target?.id;
    var element = event?.target?.localName;
    if (element === "canvas" && signatureType === "draw") {
      setCanvasId(id);
      if (!signatureList.includes(id)) setSignatureList([...signatureList, id]);
      const rect = event?.target?.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      setIsDrawing(true);
      draw(x, y, false, id, event);
    }
  };

  const handleMouseUp = (event: any) => {
    if (isDrawing) {
      setIsDrawing(false);
    }
  };

  const handleMouseMove = (event: any) => {
    var id = event?.target?.id;
    var element = event?.target?.localName;
    if (isDrawing && element === "canvas" && signatureType === "draw") {
      const rect = event?.target?.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      draw(x, y, true, id, event);
    }
  };

  const clearSignature = () => {
    if (canvasId) {
      const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
      if(canvas){
        canvas.style.backgroundImage = ``;
      canvas.style.backgroundSize = "";
      const ctx = canvas.getContext("2d");
      if(ctx){
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
      // setHtmlContent(document.getElementById('html-content-div').innerHTML);
      }
    }
  };

  const getCurrentDateTime = () => {
    const currentDateTime = moment().format("MM-DD-YYYY");
    return currentDateTime;
  };

  useEffect(() => {
    async function esignrender() {
      if (canvasmodal === true) {
        // console.log("esignrendercalled");
        setCanvasModal();
        signatureList.forEach((item: any) => {
          const canvas = document.getElementById(item) as HTMLCanvasElement;
          if (canvas) {
            const base64String = canvas.toDataURL("image/png");
            setselectedDocumentEsign({...selecteddocumentesign , image_base64:base64String })
            // console.log("base64String", base64String);
            canvas.style.backgroundImage = `url(${base64String})`;
            canvas.style.backgroundSize = "cover";

            //  Use DOMParser to convert the HTML string to a Document object
            if(type === "buyer"){
              const imgElementbuyer1 = document.getElementById("buyer1");
              imgElementbuyer1
              ? ((imgElementbuyer1 as HTMLInputElement).src = base64String)
              : "";
            }
            else if(type === "buyeragent"){
              const imgElementbuyer2 = document.getElementById("buyer2");
imgElementbuyer2
              ? ((imgElementbuyer2 as HTMLInputElement).src = base64String)
              : "";
            }
            else if(type === "seller"){
              const imgElementbuyer1 = document.getElementById("buyer1");
              imgElementbuyer1
              ? ((imgElementbuyer1 as HTMLInputElement).src = base64String)
              : "";
              localStorage.setItem('sellersign',base64String)
              dispatch({
                type: 'SET',
                payload: base64String,
              });
            }
            
            // const imgElementbuyer3 = document.getElementById("buyer3");
            // const imgElementbuyer4 = document.getElementById("buyer4");
            // const imgElementbuyer5 = document.getElementById("seller1");
            // const imgElementbuyer6 = document.getElementById("seller2");
            // const imgElementbuyer7 = document.getElementById("seller3");
            // const imgElementbuyer8 = document.getElementById("seller4");

            // Access the img element's properties or manipulate its attributes

            // You can also modify the attributes like this:
            // imgElementbuyer3
            //   ? ((imgElementbuyer3 as HTMLInputElement).src = base64String)
            //   : "";
            // imgElementbuyer4
            //   ? ((imgElementbuyer4 as HTMLInputElement).src = base64String)
            //   : "";
            // imgElementbuyer5
            //   ? ((imgElementbuyer5 as HTMLInputElement).src = base64String)
            //   : "";
            // imgElementbuyer6
            //   ? ((imgElementbuyer6 as HTMLInputElement).src = base64String)
            //   : "";
            // imgElementbuyer7
            //   ? ((imgElementbuyer7 as HTMLInputElement).src = base64String)
            //   : "";
            // imgElementbuyer8
            //   ? ((imgElementbuyer8 as HTMLInputElement).src = base64String)
            //   : "";
          }
        });
      }
    }
    esignrender();
  }, [esignrender]);


  const addSignatures = () => {
  // debugger
    let current_date = getCurrentDateTime();
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    // Find the img element using querySelector
    doc.getElementById("date1")?.setAttribute("value", current_date);
    const htmlcontentdata = doc.documentElement.outerHTML;
    setHtmlContent(htmlcontentdata);
    setesignRender();
  };

  return (
    <div className="App">
      <Modal
        className={"transaction-modal"}
        size="lg"
        show={canvasmodal}
        onClose={() => setCanvasModal()}
        header={""}
        body={
          <React.Fragment>
            <div style={{ marginTop: "0px" }}>
              <div
                style={{
                  overflowX: "scroll",
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                <canvas
                  id="html-content-div"
                  style={{ border: "1px solid #000" }}
                  contentEditable="false"
                  onBlur={onContentBlur}
                  onMouseDown={handleMouseDown}
                  onMouseUp={(event: any) => {
                    if (isDrawing) handleMouseUp(event);
                  }}
                  onMouseMove={handleMouseMove}
                ></canvas>
              </div>
            </div>
            <div className="signature-footer">
           <button className="btn signsave-btn" onClick={() => addSignatures()}>Save</button>
           <button className="btn clear-btn" onClick={() => clearSignature()}>Clear</button>
</div>

          </React.Fragment>
        }
      />
    </div>
  );
};

export default Esign;
