export type EmailContact = {
  name:string
  email:string
}

export type Email = {
  from:string
  content:string
  subject:string
  to:EmailContact[]
  cc:EmailContact[]
  bcc:EmailContact[]
}

export type EmailUserContact = {
  from:string
  content:string
  subject:string
  emailFor:EmailContact[]
  cc:EmailContact[]
  bcc:EmailContact[]
}

export const defaultEmail = {
  from:'',
  subject:'',
  content:'',
  to:[],
  cc:[],
  bcc:[],
}

export type responseLead = {
  id:'',
  stage:'',
}

export type Sms = {
  sender: string,
  recipients: Array<string>,
  sendToGroup: boolean,
  messageBody: string
}
