import React, { useEffect, useState } from 'react'
import leadBanner from "../../../assets/headerpage-icon/lead-banner.jpg";
import leadsubBanner from "../../../assets/headerpage-icon/leaqd-subbanner.png";
import textureImg from "../../../assets/headerpage-icon/texture-img.png";
import leadContact from "../../../assets/headerpage-icon/lead-contact.png";
import { NewNavbar } from './NewNavbar'
import { NewFooter } from './NewFooter'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { successMessage } from '../Util/Toaster';
import { ContactApi } from '../../../api/idx';
import { formatUSPhoneNumber } from '../../../util/stringformat';
import { CSpinner } from '@coreui/react';

export interface Contactus {
    first_name: string | null;
    last_name: string | null;
    phone: string | null;
    email: string | null;
    message: string | null;
  }
const LeadGeneration = () => { 
  const notify = () => toast("Contact Submitted Succesfully");
  let [showClass, setShow] = useState<boolean>(false);
  let [spinLoading, setSpinLoading] = useState<boolean>(false);
  const [contact, setContact] = useState<Contactus>({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    message: ""
  });
  
  const [validation, setValidation] = useState<Contactus>({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    message: ""
  });
  
  useEffect(() => {
    let user: any = localStorage.getItem("user");
    let user_id = JSON.parse(user);
    if (user_id !== null) {
     
      setContact({...contact, first_name:user_id?.first_name, last_name:user_id?.last_name,email:user_id?.email})
    }  
  }, [])
  const checkValidation = () => {
    let errors = JSON.parse(JSON.stringify(validation)); 
// debugger
    if (contact.first_name === null ) {  
      errors.first_name = "First Name is required";
    } else {
      switch (true) {
        case contact.first_name == "":
          errors.first_name = "First Name is required";
          break;
        case !/^[a-zA-Z]+$/.test(contact.first_name):
          errors.first_name = "First Name can only contain letters";
          break;
        case contact.first_name.length < 2:
          errors.first_name = "First Name must be at least 2 characters";
          break;
        case contact.first_name.length > 25:
          errors.first_name = "First Name cannot exceed 25 characters";
          break;
        default:
          errors.first_name = "";
          validation.first_name = "";
          
      }
    }

    // Last Name validation
    if (contact.last_name === null) {
      errors.last_name = "Last Name is required";
    } else {
      switch (true) {
        case !contact.last_name.trim():
          errors.last_name = "Last Name is required";
          break;
        case !/^[a-zA-Z]+$/.test(contact.last_name):
          errors.last_name = "Last Name can only contain letters";
          break;
        case contact.last_name.length < 2:
          errors.last_name = "Last Name must be at least 2 characters";
          break;
        case contact.last_name.length > 25:
          errors.last_name = "Last Name cannot exceed 25 characters";
          break;
        default:
          errors.last_name = "";
          validation.last_name = "";
      }
    }

    // Email validation
    if (contact.email === null) {
      errors.email = "Email is required";
    } else {
      const emailCond = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      switch (true) {
        case !contact.email.trim():
          errors.email = "Email is required";
          break;
        case !emailCond.test(contact.email):
          errors.email = "Please enter a valid email address";
          break;
        default:
          errors.email = "";
          validation.email = "";
      }
    }
    // phone number validation
    if (contact.phone === null) {
      errors.phone = "Phone number is required";
    } else {
      switch (true) {
        case !contact.phone.trim():
          errors.phone = "Contact Number is required";
          break;
        // case !/^\d+$/.test(contact.phone):
        //   errors.phone = "Contact number can only contain numbers";
        //   break;
        case contact.phone.length !== 14:
          errors.phone = "Contact number must be 10 digits";
          break;
        default:
          errors.phone = "";
          validation.phone = "";
      }
    }

    return errors;
  };

  const handleChange =  async(e: any) => {
     
    const { name } = e.target; 
    setContact({ ...contact, [name]: e.target.value });
    await checkValidation();
    
    } 

  const handleSubmit = async () => { 
    let validations = await checkValidation();
    if (Object.values(validations).every((value) => value == "")) {
      setSpinLoading(true)
      let res = await ContactApi(contact);
      setShow(true);
      notify();
      setSpinLoading(false)

      
      switch (res) {
        case "Your  Contact successfully":

          successMessage("Your request has been submitted succesfully");
          setValidation(() => ({
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            message: ""
          }));
          setContact({
            first_name: "",
            last_name: "",
            phone: "",
            email: "",
            message: ""
          });
           
          case "Data save successfully":
            setValidation(() => ({
              first_name: "",
              last_name: "",
              phone: "",
              email: "",
              message: ""
            }));
            setContact({
              first_name: "",
              last_name: "",
              phone: "",
              email: "",
              message: ""
            });
      }

    } else {
      
      setValidation((prevState) => ({
        ...prevState,
        first_name: validations.first_name,
        last_name: validations.last_name,
        email: validations.email,
        phone: validations.phone,
        message: validations.message
      }));
    }
  };
    
    return (
        <>
            <div className="leadGeneration">
                <NewNavbar />
                <div className="lead-body">
                    <div className="lead-bg">
                        <div className="lead-container">
                            <div className="row">
                                <div className="col-md-10">
                                    <div className="banner-heading">
                                        <h1>Find the Home That Speaks to You. </h1>
                                        <p>Our expert team is here to help you start the conversation.  </p>
                                        <Link
                                            className='btn main-btn'
                                            to="/contactus">
                                            Contact Us
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="lead-banner-img">
                                <img src={leadBanner} className='' alt="lead-banner" />
                            </div>
                        </div>
                    </div>

                    <div className="lead-state">
                        <div className="lead-container">
                            <div className="row align-items-center">
                                <div className="col-lg-7 col-md-12">
                                    <div className="lead-subheading">
                                        <h3>Redefining Real Estate</h3>
                                        <p>When it comes to finding your dream home, who you work with matters. At Milford Real Estate, we’re setting a new standard for our industry — one that is centered around our commitment to transparency, exceptional service and always doing right by our clients.  </p>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-md-12 ">
                                    <div className="lead-bg-img">
                                        <img src={leadsubBanner} className='img-fluid' alt="lead-sub-banner" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="lead-texture">
                        <div className="lead-container">
                            <div className="row align-items-center">
                                <div className="col-lg-5 col-md-12">
                                    <div className="texture-end">
                                        <img src={textureImg} alt="" className='img-fluid' />
                                    </div>

                                </div>
                                <div className="col-lg-7 col-md-12">
                                    <div className="lead-subheading left-spacing">
                                        <p>We know the local market like the backs of our hands, and we put our knowledge to work to help our clients through every step of the process. Whether you’re buying or selling, our old-school work ethic and fresh, innovative approach ensure an exceptional experience that you won’t soon forget.  </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="lead-contactus">
                        <div className="lead-container">
                            <div className="row align-items-center gy-5">
                                <div className="col-lg-5 col-md-12">
                                    <div className="lead-subheading">
                                        <h3>Get In Touch</h3>
                                        <p>Take on the real estate market with confidence with our team of expert agents by your side. Let’s get started.  </p>
                                        <img src={leadContact} className='img-fluid' alt="" />
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-12">
                                    <div className="lead-contact-form">
                                        <div className="row ">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">First Name</label>
                                                    <input type="text" className="form-control"  name="first_name"
                                                        onChange={(e: any) => {
                                                        handleChange(e);
                                                        }}
                                                        value={contact.first_name ? contact.first_name : ""} />
                                                        <Validation state={validation.first_name} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Last Name </label>
                                                    <input type="text" className="form-control"
                                                     name="last_name"
                                                     
                                                     onChange={(e) => handleChange(e)}
                                                     value={contact.last_name ? contact.last_name : ""}
                                                   />
                                                   <Validation state={validation.last_name} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Email</label>
                                                    <input type="text" className="form-control" 
                                                     name="email"
                                                     onChange={(e) => handleChange(e)}
                                                     value={contact.email ? contact.email : ""}
                                                   />
                                                   <Validation state={validation.email} />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Phone</label>
                                                    <input type="phone" className="form-control" 
                                                    minLength={10}  maxLength={14}
                                                     name="phone"
                                                     onChange={(e) => handleChange(e)}
                                                     value={contact?.phone ? formatUSPhoneNumber(contact?.phone) : ""} 
                                                   />
                                                   <Validation state={validation.phone} />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label htmlFor="">Message</label>
                                                    <textarea rows={8} className='form-control'  name="message"
                                                    defaultValue={""}
                                                    onChange={(e) => handleChange(e)}
                                                    value={contact.message ? contact.message : ""}></textarea>
                                                    
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                               <div className="d-inline" onClick={() => handleSubmit()}>
                                               { spinLoading ? <button className='btn main-btn' disabled>Submit Inquiry<CSpinner color="light" size="sm" className={ 'ml-1' } />  </button> :  <button className='btn main-btn'>Submit Inquiry</button> }
                              
                                               </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NewFooter />
            </div>
        </>
    )
}

export default LeadGeneration

export const Validation = ({ state }: { state: any }) => {
    return state && <p style={{ color: "red" }}>{state}</p>;
};


