import React from 'react'
import { CFormGroup,CLabel,CSelect,CInvalidFeedback } from '@coreui/react'

export type Props<T> =
{
  icon?:React.ReactNode
  options:T[]
  projection:(_:T)=>string
  label?:string
  value:T|null
  onChange:(_:T)=>void
  error?:string
  placeholder?:string
  required?:boolean
  parseValue?:(_:T) => string
  disableEmpty?:boolean
  inline?:boolean
  readOnly?:boolean
  disabled?:boolean
  className?:string
  // customProp?:boolean
}
type DropdownState =
{
  blurred:boolean
}
export default class Dropdown<T> extends React.Component<Props<T>,DropdownState>
{
  constructor(props:Props<T>)
  {
    super(props)
    this.state = {
      blurred:false,
    }
  }

  validate()
  {
    return !!(
      this.state.blurred
      && (!this.props.value)
    )
  }

  render()
  {
    const getValue = this.props.parseValue ? this.props.parseValue : (v:T) => JSON.stringify(v)
    return (
      <CFormGroup className={
        ([] as string[]).concat(
          this.props.inline ? ['d-flex align-items-center'] : [],
          this.props.className ? [this.props.className] : []
        ).join(' ')
      }>
        {
          this.props.icon || this.props.label ?
            <CLabel className={ this.props.inline ? ['mb-0','mr-2'].join(' ') : '' }>
              {
                this.props.icon ?
                  <div className="label-icon">{ this.props.icon }</div>
                :
                  null
              }
              { this.props.label }
              {
                this.props.required ?
                  <span className="required-indicator"> *</span>
                :
                  null
              }
            </CLabel>
          :
            null
        }
        <div className="input-validation-wrapper">
          <CSelect
            readOnly={this.props.readOnly}
            disabled={this.props.disabled || this.props.readOnly}
            onChange={
              // TODO: onChange type does not allow null/undefined so can't handle empty selection
              (e:React.ChangeEvent<HTMLSelectElement>) => this.props.onChange(
                ((value,parseValue) =>
                  value == 'null'
                  ? null!
                  : parseValue
                  ? this.props.options.find((x) => parseValue(x) == value)
                  : JSON.parse(value)
                )(e.target.value,this.props.parseValue)
              )
            }
            onBlur={ () => this.setState({ blurred:true }) }
            invalid={ !!this.props.error }
            value={ this.props.value == null ? '' : getValue(this.props.value) }
          >
            { !this.props.disableEmpty ? <option value="null">{ this.props.placeholder || '--SELECT--' }</option> : null }
            {
              this.props.options.map((v) =>{
              return  <option
                  value={ getValue(v) }
                  key={ getValue(v) }
                  //disabled={this.props.customProp &&  String(v) ==="completed" }
                  dangerouslySetInnerHTML={ { __html:this.props.projection(v) } }
                ></option>}
              )
            }
          </CSelect>
          {
            this.props.error ?
              <CInvalidFeedback>{ this.props.error }</CInvalidFeedback>
            :
              null
          }
        </div>
      </CFormGroup>
    )
  }
}
