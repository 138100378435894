import { ActionType } from './actions'
import { _reducer } from './_common'
import { Lead,LeadSearchCriteria,Paginator,Unclaimed } from '../types'
import { defaultLeadSearchCriteria } from '../types/lead'
import { defaultPaginator } from '../types/pagination'

type LeadStatus = {
  id:string
  display:string
  active:string|null
}

type Relationship = {
  id:string
  display:string
  active:string|null
}

type TShirtSize = {
  id:string
  display:string
  active:string|null
}

type ResponseLevel = {
  id:string
  display:string
  active:string|null
}

type ClosingBagOption = {
  id:string
  display:string
  active:string|null
}

type LeadSource = {
  id:string
  display:string
  active:string|null
}

type LeadSubSource = {
  id:string
  display:string
  active:string|null
}

type EngagementLevel = {
  id:string
  display:string
  active:string|null
}

type LeadResponseLevel = {
  id:string
  display:string
  active:string|null
}

type LenderStatusLevel = {
  id:string
  display:string
  active:string|null
}

type LenderList = {
  firstName:string|null
  id:string
  lastName:string|null
}

type AgentSubSource = {
  id:string
  display:string
  active:string|null
}

type ReferralList = {
  firstname: string|null,
  lastanme: string|null,
  email: string|null,
  phone: string|null
}

export interface StateLeads
{
  loading:boolean,
  lead:Lead|null
  unclaimed:Unclaimed[]
  leadStatuses:LeadStatus[]
  relationships:Relationship[]
  tShirtSizes:TShirtSize[]
  responseLevels:ResponseLevel[]
  closingBagOptions:ClosingBagOption[]
  leadSources:LeadSource[]
  leadSubSources:LeadSubSource[]
  engagementLevel:EngagementLevel[]
  responseLevel:LeadResponseLevel[]
  leadStatus:LeadStatus[]
  lenderStatus:LenderStatusLevel[]
  lenderList:LenderList[]
  referralList:ReferralList[]
  agentSubSource:AgentSubSource[]
  leadSearchCriteria:LeadSearchCriteria
  leadPaginator:Paginator
  showLeadSlideIn:boolean
}

const defaultState : StateLeads = {
  loading:false,
  lead:null,
  unclaimed:[],
  leadStatuses:[],
  relationships:[],
  tShirtSizes:[],
  responseLevels:[],
  closingBagOptions:[],
  leadSources:[],
  leadSubSources:[],
  engagementLevel:[],
  responseLevel:[],
  leadStatus:[],
  lenderStatus:[],
  lenderList:[],
  referralList:[],
  agentSubSource:[],
  leadSearchCriteria:defaultLeadSearchCriteria,
  leadPaginator:defaultPaginator,
  showLeadSlideIn:false,
}

export const reducerLeads = _reducer<StateLeads>(defaultState,[
  [ActionType.LEAD_SEARCH_CRITERIA_SET, (state,action) => ({
    ...state,
    leadSearchCriteria:action.leadSearchCriteria,
  })],
  [ActionType.LEAD_PAGINATOR_SET, (state,action) => ({
    ...state,
    leadPaginator:action.leadPaginator,
  })],
  [ActionType.LEADS_FETCH, (state,_) => ({
    ...state,
    loading:true,
  })],
  [ActionType.LEADS_FETCH_SUCCESSFUL, (state,action) => ({
    ...state,
    loading:false,
    leads:action.leads,
  })],
  [ActionType.LEADS_FETCH_ERROR, (state,action) => ({
    ...state,
    loading:false,
  })],
  [ActionType.LEAD_FETCH_SUCCESSFUL, (state,action) => ({
    ...state,
    loading:false,
    lead:action.lead
  })],
  [ActionType.LEADS_UNCLAIMED_UPDATE, (state,action) => ({
    ...state,
    unclaimed:action.unclaimed,
  })],
  [ActionType.SET_LEAD_STATUSES, (state,action) => ({
    ...state,
    leadStatuses:action.statuses,
  })],
  [ActionType.SET_RELATIONSHIPS, (state,action) => ({
    ...state,
    relationships:action.relationships,
  })],
  [ActionType.SET_T_SHIRT_SIZES, (state,action) => ({
    ...state,
    tShirtSizes:action.tShirtSizes,
  })],
  [ActionType.SET_RESPONSE_LEVELS, (state,action) => ({
    ...state,
    responseLevels:action.responseLevels,
  })],
  [ActionType.SET_CLOSING_BAG_OPTIONS, (state,action) => ({
    ...state,
    closingBagOptions:action.closingBagOptions,
  })],
  [ActionType.SET_LEAD_SOURCES, (state,action) => {
    return {
      ...state,
      leadSources:action.leadSources,
    }
  }],
  [ActionType.SET_LEAD_SUB_SOURCES, (state,action) => ({
    ...state,
    leadSubSources:action.leadSubSources,
  })],
  [ActionType.SET_ENGAGEMENT_lEVEL, (state,action) => {
    return {
      ...state,
      engagementLevel:action.engagementLevel,
    }
  }],
  [ActionType.SET_RESPONSE_LEVEL, (state,action) => ({
    ...state,
    responseLevel:action.responseLevel,
  })],
  [ActionType.SET_LEAD_STATUS, (state,action) => ({
    ...state,
    leadStatus:action.leadStatus,
  })],
  [ActionType.SET_LENDER_STATUS, (state,action) => ({
    ...state,
    lenderStatus:action.lenderStatus,
  })],
  [ActionType.SET_LENDER_List, (state,action) => ({
    ...state,
    lenderList:action.lenderList,
  })],
  [ActionType.SET_AGENT_SUBSOURCE, (state,action) => ({
    ...state,
    agentSubSource:action.agentSubSource,
  })],
  [ActionType.SET_REFERRAL_LIST, (state,action) => ({
    ...state,
    referralList:action.referralList,
  })],
  [ ActionType.NAVIGATED,(state) => ({
    ...state,
    lead:null,
  })],
  [ActionType.LOGOUT,() => defaultState],
  [ActionType.SET_SHOW_LEAD_SLIDE_IN, (state,action) => ({
    ...state,
    showLeadSlideIn:action.showLeadSlideIn,
  })],
])
