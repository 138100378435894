import { Lead,Unclaimed,User,FromServer, LeadSearchCriteria, Paginator, SortedPaginator } from '../types'
import { parseInstant } from '../util/datetime'

export enum ActionType
{
  LOGIN_NEEDED='LOGIN_NEEDED',
  LOGIN='LOGIN',
  LOGOUT='LOGOUT',
  CLEAR='CLEAR',
  REDIRECT='REDIRECT',

  LEAD_SEARCH_CRITERIA_SET='leads_search_criteria_set',
  LEAD_PAGINATOR_SET='leads_paginator_set',
  LEADS_FETCH='leads_fetch',
  LEADS_FETCH_SUCCESSFUL='leads_fetch_successful',
  LEADS_FETCH_ERROR='leads_fetch_error',
  LEAD_FETCH='lead_fetch',
  LEAD_FETCH_SUCCESSFUL='lead_fetch_success',
  LEAD_FETCH_ERROR='lead_fetch_error',

  LEADS_UNCLAIMED_UPDATE='leads_unclaimed_update',
  LEAD_CLAIMED='leads_claimed',

  USERS_PAGINATOR_SET='users_paginator_set',
  USERS_FETCH='users_fetch',
  USERS_FETCH_SUCCESSFUL='users_fetch_successful',
  USERS_FETCH_ERROR='users_fetch_error',
  USER_FETCH='user_fetch',
  USER_FETCH_SUCCESSFUL='user_fetch_success',
  USER_FECH_IDX='user_fetch_idx',
  USER_FETCH_ERROR='user_fetch_error',
  USER_DELETE='user_delete',

  NAVIGATE_TO='navigate_to',
  NOT_FOUND='not_found',
  SET_TITLE='set_title',
  SET_LEAD_STATUSES='set_lead_statuses',
  SET_RELATIONSHIPS='set_relationships',
  SET_T_SHIRT_SIZES='set_t_shirt_sizes',
  SET_RESPONSE_LEVELS='set_response_levels',
  SET_CLOSING_BAG_OPTIONS='set_closing_bag_options',
  SET_LEAD_SOURCES='set_lead_sources',
  SET_LEAD_SUB_SOURCES='set_lead_sub_sources',
  SET_ENGAGEMENT_lEVEL='set_engagement_level',
  SET_RESPONSE_LEVEL='set_response_level',
  SET_LEAD_STATUS='set_lead_status',

  SET_LENDER_STATUS='set_lender_status',
  SET_LENDER_List='set_lender_list',
  SET_REFERRAL_LIST = 'set_referral_list',
  SET_AGENT_SUBSOURCE='set_agent_subsource',

  SET_SHOW_LEAD_SLIDE_IN='set_show_lead_slide_in',
  

  WEBSOCKET_CONNECT='websocket_connect',
  WEBSOCKET_DISCONNECT='websocket_disconnect',

  NAVIGATED='navigated',

  NOTIFICATION_SUCCESS='notification_success',
  NOTIFICATION_DISMISS='notification_dismiss',

  UPDATE_LOCATION = 'updateLocation',
  UPATE_ADDRESS = 'updateAddress',
  UPDATE_OFFERID = 'updateoffer',
  SEARCH_BAR='searchbar'
}

function _action<T>(name:ActionType,action:(...m:any[])=>T) {
  return ((...args:any[]) => {
    let temp = action(...args);
    return {
      type: name,...temp
    }
  })
}

export const loginNeeded = _action(ActionType.LOGIN_NEEDED,(afterLoginURL:string) => ({ afterLoginURL:afterLoginURL }))
export const redirect = _action(ActionType.REDIRECT,() => ({}))
export const login_success = _action(ActionType.LOGIN,(v:any) => v)
export const logout = _action(ActionType.LOGOUT,()=>({}))

export const navigate_to = _action(ActionType.NAVIGATE_TO,(url:string)=>({ url:url }))
export const not_found = _action(ActionType.NOT_FOUND,() => ({ }))
export const leadClaimed = _action(ActionType.LEAD_CLAIMED,(id,auto_pop) => ({ id:id }))

export const setLeadSearchCriteria = _action(ActionType.LEAD_SEARCH_CRITERIA_SET,(leadSearchCriteria:LeadSearchCriteria) => ({ leadSearchCriteria:leadSearchCriteria }))
export const setLeadPaginator = _action(ActionType.LEAD_PAGINATOR_SET,(leadPaginator:Paginator) => ({ leadPaginator:leadPaginator }))
export const fetchLeads = _action(ActionType.LEADS_FETCH,() => ({}))
export const fetchLeadsError = _action(ActionType.LEADS_FETCH_ERROR,() => ({}))
export const fetchLeadsSuccessful = _action(ActionType.LEADS_FETCH_SUCCESSFUL,(leads:Lead[]) => ({ leads:leads }))
export const fetchLead = _action(ActionType.LEAD_FETCH,() => ({}))
export const fetchLeadSuccess = _action(ActionType.LEAD_FETCH_SUCCESSFUL,(lead:Lead) => ({ lead:lead }))
export const fetchLeadError = _action(ActionType.LEAD_FETCH_ERROR,() => ({}))

export const setUnclaimedLeads =
  _action(ActionType.LEADS_UNCLAIMED_UPDATE,(leads:FromServer<Unclaimed>[]) => ({
    unclaimed:leads.map((v) => ({...v,received:parseInstant(v.received)}))
  }))

export const setUsersPaginator = _action(ActionType.USERS_PAGINATOR_SET,(usersPaginator:SortedPaginator) => ({ usersPaginator:usersPaginator }))
export const fetchUsers = _action(ActionType.USERS_FETCH,() => ({}))
export const fetchUsersError = _action(ActionType.USERS_FETCH_ERROR,() => ({}))
export const fetchUsersSuccessful = _action(ActionType.USERS_FETCH_SUCCESSFUL,(users:User[]) => ({ users:users }))

export const fetchUser = _action(ActionType.USER_FETCH,()=> ({}))
export const fetchUserSuccess = _action(ActionType.USER_FETCH_SUCCESSFUL,(user:User) => ({ user:user }))
export const fetchIdxUserSuccess = _action(ActionType.USER_FECH_IDX,(user:User) => ({ user:user }))
export const fetchUserError = _action(ActionType.USER_FETCH_ERROR,() => ({}))
export const deleteuser = _action(ActionType.USER_DELETE,(id:string) => ({id:id}))

export const websocket_connect = _action(ActionType.WEBSOCKET_CONNECT,() => ({}))
export const websocket_disconnect = _action(ActionType.WEBSOCKET_DISCONNECT,() =>({}))

export const setTitle = _action(ActionType.SET_TITLE,(v:string) => ({ title:v }))

export const setLeadStatuses = _action(ActionType.SET_LEAD_STATUSES,(v:any) => ({ statuses:v }))
export const setRelationships = _action(ActionType.SET_RELATIONSHIPS,(v:any) => ({ relationships:v }))
export const setTShirtSizes = _action(ActionType.SET_T_SHIRT_SIZES,(v:any) => ({ tShirtSizes:v }))
export const setResponseLevels = _action(ActionType.SET_RESPONSE_LEVELS,(v:any) => ({ responseLevels:v }))
export const setClosingBagOptions = _action(ActionType.SET_CLOSING_BAG_OPTIONS,(v:any) => ({ closingBagOptions:v }))
export const setLeadSources = _action(ActionType.SET_LEAD_SOURCES,(v:any) => ({ leadSources:v }))
export const setLeadSubSources = _action(ActionType.SET_LEAD_SUB_SOURCES,(v:any) => ({ leadSubSources:v }))
export const setEngagementLevel = _action(ActionType.SET_ENGAGEMENT_lEVEL,(v:any) => ({ engagementLevel:v }))
export const setResponseLevel = _action(ActionType.SET_RESPONSE_LEVEL,(v:any) => ({ responseLevel:v }))
export const setLeadStatus = _action(ActionType.SET_LEAD_STATUS,(v:any) => ({ leadStatus:v }))
export const setLenderStatus = _action(ActionType.SET_LENDER_STATUS,(v:any) => ({ lenderStatus:v }))
export const setLenderList = _action(ActionType.SET_LENDER_List,(v:any) => ({  lenderList:v }))
export const setAgentSubsource = _action(ActionType.SET_AGENT_SUBSOURCE,(v:any) => ({  agentSubSource:v }))
export const setReferralList = _action(ActionType.SET_REFERRAL_LIST,(v:any) => ({  referralList:v }))

export const setShowLeadSlideIn = _action(ActionType.SET_SHOW_LEAD_SLIDE_IN,(v:boolean) => ({ showLeadSlideIn:v }))

export const navigated = _action(ActionType.NAVIGATED,() => ({}))

export const notification_success = _action(ActionType.NOTIFICATION_SUCCESS,(v:any) => v)
export const notification_dismiss = _action(ActionType.NOTIFICATION_DISMISS,(id:string) => ({id:id}))

export const setNewLocation = _action(ActionType.UPDATE_LOCATION,(v:any) => ({  locationdata:v }))
export const setnewofferID = _action(ActionType.UPDATE_OFFERID,(v:any) => ({  id:v }))
export const setsaveoldaddress = _action(ActionType.UPATE_ADDRESS,(v:any) => ({  add_save:v }))
export const setsearchbaraddress = _action(ActionType.SEARCH_BAR,(v:any) => ({  searchbardata:v }))
