import React, { useState, useEffect } from "react";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import SingleProduct from "../SingleProduct";
// import { navigate_to } from "../../../state/actions";
import { connect, DispatchProp } from "react-redux";
import { State } from "../../../store";
import {
  // createUserSavedList,
  // getUserList,
  // getUserListData,
  saveFavroutes,
  // savedPropertyToUserList,
} from "../../../api/idx";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import noimage from "../../../assets/icons/noimage.svg";
// import savelistproduct from "../../../assets/icons/savedlistimg.svg";
// import { currentuser } from "../../../util/currentuser";

type Props = {
  propertylisting: any;
  currentPage?: number;
  setfavoritePropertyList?: any;
  favoritepropertylist?: any;
  listtype?: string;
} & DispatchProp<any>;

const ProductListing: React.FC<Props> = (props) => {
  const { favoritepropertylist, setfavoritePropertyList, listtype } = props;
  const [toggle, setToggle] = useState<boolean>(false);
  const [like, setLike] = useState<boolean>(false);
  const [handletogglelike, setHandleToggleLike] = useState<boolean>();
  const [handlefavoritelike, setHandleFavoriteLike] = useState<boolean>();
  const history = useHistory();

  useEffect(() => {
    async function anonymous() {
      if (props.propertylisting.favorites === true) {
        setHandleToggleLike(false);
        setToggle(true);
      }
      if (props.propertylisting.favorites === false) {
        setHandleToggleLike(true);
        setToggle(false);
      }
      if (props.propertylisting.dislikes === true) {
        setHandleFavoriteLike(false);
        setLike(true);
      }
      if (props.propertylisting.dislikes === false) {
        setHandleFavoriteLike(true);
        setLike(false);
      }
    }
    anonymous();
  }, [props.currentPage]);

  const handleFavorites = async (e: any) => {
    e.stopPropagation();
    let user: any = localStorage.getItem("user");
    let user_id = JSON.parse(user);
    if (user_id === null) {
      toast.error("Please login");
    } else {
      if (toggle === true) {
        if (listtype !== "favorite") {
          setHandleToggleLike(true);
          setToggle(false);
        }
        let data = {
          userid: user_id?.id,
          propertiesid: props.propertylisting.listingid,
          favorites: false,
          dislikes: false,
          recently_viewed: false,
        };
        let response = await saveFavroutes(data, "remove");
        switch (response) {
          case "Your Property Remove successfully":
            setfavoritePropertyList ? setfavoritePropertyList(!favoritepropertylist) : null;
            toast.success("Property has been removed from favourites list");
            break;
        }
      }
      if (toggle === false) {
        setHandleToggleLike(false);
        setToggle(true);
        let data = {
          userid: user_id?.id,
          propertiesid: props.propertylisting.listingid,
          favorites: true,
          dislikes: false,
          recently_viewed: false,
        };
        let response = await saveFavroutes(data, "add");
        switch (response) {
          case "Your Property Save successfully":
            toast.success("Property has been added to favourites");
            // setAddtoFavorite(true);
            break;
        }
      }
    }
  };

  const handleDislike = async (e: any) => {
    e.stopPropagation();
    let user: any = localStorage.getItem("user");
    let user_id = JSON.parse(user);

    if (user_id === null) {
      toast.error("Please login");
    } else {
      if (like === true) {
        if (listtype !== "dislike") {
          setLike(false);
          setHandleFavoriteLike(true);
        }
        let data = {
          userid: user_id?.id,
          propertiesid: props.propertylisting.listingid,
          favorites: false,
          dislikes: false,
          recently_viewed: false,
        };
        let response = await saveFavroutes(data, "remove");
        switch (response) {
          case "Your Property Remove successfully":
            setfavoritePropertyList
              ? setfavoritePropertyList(!favoritepropertylist)
              : null;
            toast.success("Property has been removed from dislike list");
            break;
        }
      }
      if (like === false) {
        setLike(true);
        setHandleFavoriteLike(false);
        let data = {
          userid: user_id?.id,
          propertiesid: props.propertylisting.listingid,
          favorites: false,
          dislikes: true,
          recently_viewed: false,
        };
        let response = await saveFavroutes(data, "add");
        switch (response) {
          case "Your Property Save successfully":
            toast.success("Property has been added to dislike list");
            break;
        }
      }
    }
  };

  const handleNavigation = () => {
    let path = props?.propertylisting?.listingid;
    // history.push(`/singleproduct/${path}`);
    window.open(`/singleproduct/${path}`, "_blank");
  };

  return (
    <>
      {/* <!-- Saved List Modal Pop up end--> */}
      <div className="card productcard border-0 rounded-0 shadow" onClick={() => handleNavigation()} >
        <span className="sale-txt">For Sale</span>
        {/* {handletogglelike === true ? (
          <div onClick={(e) => handleDislike(e)}>
            {like === true ? (
              <span className={"dislike-right"}>
                <i className={"fa-solid fa-xmark dislike-icon"}></i>
              </span>
            ) : (
              <span className={"dislike"}>
                <i className={"fa-solid fa-xmark dislike-icon"}></i>
              </span>
            )}
          </div>
        ) : null} */}
        {handlefavoritelike === true ? (
          <div onClick={(e) => handleFavorites(e)}>
            <span className="wishlist">
              <i
                className={
                  toggle === true
                    ? "fa-solid fa-heart wishlist-icon"
                    : "fa-regular fa-heart wishlist-regular-icon"
                }
              ></i>
            </span>
          </div>
        ) : null}
        {props?.propertylisting?.mediaList !== null ? props.propertylisting?.mediaList ? (
          props.propertylisting?.mediaList.length > 0 ? (
            <img
              src={props.propertylisting?.mediaList[0]?.mediaurl}
              className="featured-idx-img"
            />
          ) : (
            <img src={noimage} className="featured-idx-img" />
          )
        ) : null : (
          <img src={noimage} className="featured-idx-img" />
          )}

        <div className="align-items-center g-0">
          <div className="card-body mt-3 mb-3">
            <div className="content-row">
              <div className="content-col-left">
                <div className="card-address" data-testid="card-address">
                  <div className="truncate-line" data-testid="card-address-1">
                    {props.propertylisting?.street_address && (
                      <>
                        <span className="street-name">
                          {props.propertylisting.street_address.split(",")[0]}
                        </span>

                        <div
                          className="truncate-line"
                          data-testid="card-address-2"
                        >
                          {props?.propertylisting?.city}, &nbsp;
                          {props?.propertylisting?.state} &nbsp;
                          {props?.propertylisting?.zip}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="content-col-right">
                <h1 className="card-price">
                  {new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(props.propertylisting?.price)}
                </h1>
              </div>
            </div>

            <div className="cHVLag">
              <ul>
                {
                  <li>
                    <span>{props.propertylisting.number_of_beds ? props.propertylisting.number_of_beds : 0 }</span>bed
                  </li>
                }
                {
                  <li>
                    <span>{props.propertylisting.number_of_baths ? props.propertylisting.number_of_baths : 0 }</span>bath
                  </li>
                }
                {
                  <li>
                    <span>
                      {props.propertylisting.buildingareatotal ? props.propertylisting.buildingareatotal : 0}
                    </span>
                    sqft
                  </li>
                }
              </ul>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default connect((state: State) => ({}))(ProductListing);
