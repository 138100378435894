export const queryString = {
    parse: function (queryString) {
        if (!queryString) return {};

        return queryString
            .slice(1) // Remove leading '?'
            .split('&')
            .reduce((params, pair) => {
                const [key, value] = pair.split('=');
                const decodedKey = decodeURIComponent(key);
                const decodedValue = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';

                if (decodedKey) {
                    if (params[decodedKey]) {
                        if (Array.isArray(params[decodedKey])) {
                            params[decodedKey].push(decodedValue);
                        } else {
                            params[decodedKey] = [params[decodedKey], decodedValue];
                        }
                    } else {
                        params[decodedKey] = decodedValue;
                    }
                }
                return params;
            }, {});
    },

    stringify: function (params) {
        if (!params || typeof params !== 'object') return '';

        return Object.keys(params)
            .map(key => {
                const encodedKey = encodeURIComponent(key);
                if (Array.isArray(params[key])) {
                    return params[key]
                        .map(value => `${encodedKey}=${encodeURIComponent(value)}`)
                        .join('&');
                } else {
                    return `${encodedKey}=${encodeURIComponent(params[key])}`;
                }
            })
            .join('&');
    }
};
