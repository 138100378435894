// @ts-nocheck
import axios, { AxiosRequestConfig,AxiosResponse } from 'axios'
import Store from '../store'
import { logout } from '../state/actions'
import { REACT_APP_BASE_URL1 , REACT_APP_BASE_URL2, REACT_APP_BASE_URL3 } from "../config"
import React from 'react'

export type { AxiosResponse }

export const isAxiosError = (e:any) => axios.isAxiosError(e)

export type ResponseHandlers<T,E> = {
  successful:(v:T) => void
  unprocessable?:(v:E) => void
  notimplemented?:(v:E) => void
  forbidden?:(v:E) => void
}

const baseurl1 = REACT_APP_BASE_URL1;
const baseurl2 = REACT_APP_BASE_URL2;
// const baseurl3 = REACT_APP_BASE_URL3;
const instance = axios.create()

instance.interceptors.request.use(
  config => {
    config.headers = {
      ...config.headers,
      ...authHeader(),
    }
    return config
  },
  error => Promise.reject(error)
)

instance.interceptors.response.use(function (response) {
  return response
}, async function (error) {
  if(error.response.status === 401)
  {
    Store.store.dispatch(logout())
  }
  return Promise.reject(error)
})

export function authHeader() {
  const temp = Store.store.getState().auth 
  // const newtoken = localStorage.getItem('token')
 
  return {
    'Authorization': temp ? `Bearer ${temp.token}` : ""
  }
}

export const getRequests = <T>(url:string,params?:object,options?:Omit<AxiosRequestConfig,"params">) : Promise<AxiosResponse<T>> => {
  return instance.get(`${baseurl2}/${url}`,{
    ...options,
    params:params
  })
}

export const getofferformrequest = <T>(url:string,params?:object,options?:Omit<AxiosRequestConfig,"params">) : Promise<AxiosResponse<T>> => {
  return instance.get(`${baseurl2}/${url}`,{
    ...options,
    params:params
  })
}

export const getRequest = <T>(url:string,params?:object,options?:Omit<AxiosRequestConfig,"params">) : Promise<AxiosResponse<T>> => {
  return instance.get(`/api/v1/${url}`,{
    ...options,
    params:params
  })
}


export const postRequests = async <T,E>(url:string, data:{[key: string]:any},overrides?:ResponseHandlers<AxiosResponse<T>,AxiosResponse<E>>,config?:AxiosRequestConfig) => {
  const handlers = {
    'successful':(_x:any) => undefined,
    'unprocessable':(x:any) => console.log('unprocessable',x),
    'notimplemented':(x:any) => console.log('notimplemented',x),
    'forbidden':(x:any) => console.log('forbidden',x),
    ...overrides,
  }
  try
  {
    await handlers.successful(await instance.post(`${baseurl2}/${url}`,data,config))
  }
  catch(e)
  {
    // TODO: throws if `e` does not have a `response` property
    switch(e.response ? e.response.status : 0)
    {
      case 403:
        handlers.forbidden(e.response.data)
        break;
      case 400:
        handlers.unprocessable(e.response.data)
        break
      case 501:
        handlers.notimplemented(e.response.data)
        break
        case 500:
          handlers.notimplemented(e.response.data)
          break
      default:
        throw e
    }
  }
}


export const createUser = async <T,E>(url:string, data:{[key: string]:any},overrides?:ResponseHandlers<AxiosResponse<T>,AxiosResponse<E>>,config?:AxiosRequestConfig) => {
  const handlers = {
    'successful':(_x:any) => undefined,
    'unprocessable':(x:any) => console.log('unprocessable',x),
    'notimplemented':(x:any) => console.log('notimplemented',x),
    'forbidden':(x:any) => console.log('forbidden',x),
    ...overrides,
  }
  try
  {
    await handlers.successful(await instance.post(`${baseurl2}/${url}`,data,config))
  }
  catch(e)
  {
    // TODO: throws if `e` does not have a `response` property
    switch(e.response ? e.response.status : 0)
    {
      case 403:
        handlers.forbidden(e.response.data)
        break;
      case 400:
        handlers.unprocessable(e.response.data)
        break
      case 501:
        handlers.notimplemented(e.response.data)
        break
        case 500:
          handlers.notimplemented(e.response.data)
          break
      default:
        throw e
    }
  }
}

export const postRequest = async <T,E>(url:string, data:{[key: string]:any},overrides?:ResponseHandlers<AxiosResponse<T>,AxiosResponse<E>>,config?:AxiosRequestConfig) => {
  const handlers = {
    'successful':(_x:any) => undefined,
    'unprocessable':(x:any) => console.log('unprocessable',x),
    'notimplemented':(x:any) => console.log('notimplemented',x),
    'forbidden':(x:any) => console.log('forbidden',x),
    ...overrides,
  }
  try
  {
    await handlers.successful(await instance.post(`${baseurl1}/${url}`,data,config))
  }
  catch(e)
  {
    // TODO: throws if `e` does not have a `response` property
    switch(e.response ? e.response.status : 0)
    {
      case 403:
        handlers.forbidden(e.response.data)
        break;
      case 400:
        handlers.unprocessable(e.response.data)
        break
      case 501:
        handlers.notimplemented(e.response.data)
        break
      default:
        throw e
    }
  }
}

export const deleteRequest = async <T,E>(url:string, overrides?:ResponseHandlers<AxiosResponse<T>,AxiosResponse<E>>) => {
  const handlers = {
    'successful':(_x:any) => undefined,
    'unprocessable':(x:any) => console.log('unprocessable',x),
    'notimplemented':(x:any) => console.log('notimplemented',x),
    'forbidden':(x:any) => console.log('forbidden',x),
    ...overrides,
  }
  try
  {
    await handlers.successful(await instance.delete(`/api/v1/${url}`))
  }
  catch(e)
  {
    // TODO: throws if `e` does not have a `response` property
    switch(e.response ? e.response.status : 0)
    {
      case 403:
        handlers.forbidden(e.response.data)
        break;
      case 400:
        handlers.unprocessable(e.response.data)
        break
      case 501:
        handlers.notimplemented(e.response.data)
        break
      default:
        throw e
    }
  }
}

export const putRequests = async <T,E>(url:string, data?:{[key: string]:any},overrides?:ResponseHandlers<AxiosResponse<T>,AxiosResponse<E>>,config?:AxiosRequestConfig) => {
  const handlers = {
    'successful':(_x:any) => undefined,
    'unprocessable':(x:any) => console.log('unprocessable',x),
    'notimplemented':(x:any) => console.log('notimplemented',x),
    'forbidden':(x:any) => console.log('forbidden',x),
    ...overrides,
  }
  try
  {
    await handlers.successful(await instance.put(`${baseurl2}/${url}`,data,config))
  }
  catch(e)
  {
    // TODO: throws if `e` does not have a `response` property
    switch(e.response ? e.response.status : 0)
    {
      case 403:
        handlers.forbidden(e.response.data)
        break;
      case 400:
        handlers.unprocessable(e.response.data)
        break
      case 501:
        handlers.notimplemented(e.response.data)
        break
        case 500:
          handlers.notimplemented(e.response.data)
          break
      default:
        throw e
    }
  }
}
