import React, { useState, useEffect } from "react";
import { changePassword } from "../../api/idx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./Navbar";

export interface UserDetails {
  email: string | null;
  password: string | null;
  cpassword: string | null;
}


const Createnewpassword = () => {
  const [userdetails, setUserDetails] = useState<UserDetails>({
    email: "",
    password: "",
    cpassword: "",
  });
  const [validation, setValidation] = useState<any>({
    password: "",
    cpassword: "",
  });
  const [showPassword, setshowPassword] = useState({
    password: false,
    cpassword: false,
  });

  useEffect(() => {
    async function anonymous() {
      const url = new URL(window.location.href);
      const email = url.searchParams.get("email");
      setUserDetails({ ...userdetails, email: email });
    }
    anonymous();
  }, []);

  const handleInput = (e: any) => {
    let { name, value } = e.target;
    setUserDetails({ ...userdetails, [name]: value });
  };

  /* It is used to check the validation of the form */
  const checkValidation = () => {
    let errors = JSON.parse(JSON.stringify(validation));

    // Password validation
    const passwordCond = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,}$/;
    const password = userdetails?.password;
    const confirmPassword = userdetails?.cpassword;

    switch (true) {
      case !password:
        errors.password = "Password is required";
        break;
      case password ? password.length < 6 : "":
        errors.password = "Password must be longer than 6 characters";
        break;
      case password ? !passwordCond.test(password) : "":
        errors.password =
          "Password must contain at least one lowercase, one uppercase, and one number";
        break;
      default:
        errors.password = "";
    }

    // Confirm password validation
    switch (true) {
      case !confirmPassword:
        errors.cpassword = "Confirm password is required";
        break;
      case confirmPassword !== password:
        errors.cpassword = "Passwords do not match";
        break;
      default:
        errors.cpassword = "";
    }

    return errors;
  };

  const handleConfirm = async (e: any) => {
    e.preventDefault();
    let validations = await checkValidation();
    if (Object.values(validations).every((value) => value === "")) {
      let data = {
        email: userdetails?.email,
        newPassword: userdetails?.password,
      };
      let api_data: any = await changePassword(data);

      switch (api_data?.statusCode) {
        case "success":
          setValidation({ password: "", cpassword: "" });
          toast.success("Password has been reset successfully");
          break;
        case "failed":
          toast.error(api_data?.message);
          break;
      }
    } else {
      setValidation({
        password: validations.password,
        cpassword: validations.cpassword,
      });
    }
  };

  return (
    <>
      <Navbar />
      <div className="login-bg" id="createnewpass">
        <svg
          className="tringle-bg"
          xmlns="http://www.w3.org/2000/svg"
          width="1011"
          height="985"
          viewBox="0 0 1011 985"
          fill="none"
        >
          <path d="M94 1210L1011 0H-125V1193L94 1210Z" fill="white" />
        </svg>
        <div className="row justify-content-center">
          <div className="col-md">
            <div className="container">
              <h1 className="reset-heading">Reset Password</h1>
              <div className="card overflow-hidden">
                <div className="card-body py-0">
                  <div className="row">
                    <section className="login" id="login">
                      <div className="row justify-content-center">
                        <div className="col-md">
                          <div className="container">
                            <div className="col py-5 login-btn">
                              <div className="d-flex align-items-center justify-content-center">
                              </div>
                              <section className="signup createnew-password">
                                <div className="row">
                                  <div className="col-lg-12 col-xl-12">
                                    <div className="text-black">
                                      <p className=" h2 fw-bold mx-1 mx-md-4 mt-4 mb-2">
                                        Create New Password
                                      </p>
                                      <p className="mb-5 mx-1 mx-md-4">
                                        Your new password must be different from
                                        previous used password
                                      </p>

                                      <form className="mx-1 mx-md-4">
                                        <div className="row gy-4">
                                          <div className="col-md-12">
                                            <div className="form-outline flex-fill mb-0">
                                              <i className="fas fa-lock fa-lg me-3 fa-fw"></i>
                                              <label
                                                className="form-label"
                                                htmlFor="form3Example4c"
                                              >
                                                Password
                                              </label>
                                              <input
                                                type={
                                                  showPassword.password
                                                    ? "text"
                                                    : "password"
                                                }
                                                className="form-control"
                                                placeholder="Enter Your Password"
                                                name="password"
                                                onChange={(e) => handleInput(e)}
                                                value={
                                                  userdetails.password
                                                    ? userdetails.password
                                                    : ""
                                                }
                                              />
                                              <i
                                                className={`fas ${
                                                  showPassword.password
                                                    ? "fa-eye-slash"
                                                    : "fa-eye"
                                                } password-toggle`}
                                                onClick={() =>
                                                  setshowPassword(
                                                    (prevState) => ({
                                                      ...prevState,
                                                      password:
                                                        !showPassword.password,
                                                    })
                                                  )
                                                }
                                              ></i>
                                            </div>
                                          {validation.password && (
                                            <p className="regiter-errorMsg">
                                              {validation.password}
                                            </p>
                                          )}
                                          </div>
                                          <div className="col-md-12">
                                            <div className="form-outline flex-fill mb-0">
                                              <i className="fas fa-key fa-lg me-3 fa-fw"></i>
                                              <label
                                                className="form-label"
                                                htmlFor="form3Example4cd"
                                              >
                                                Confirm password
                                              </label>
                                              <input
                                                type={
                                                  showPassword.cpassword
                                                    ? "text"
                                                    : "password"
                                                }
                                                className="form-control"
                                                placeholder="Confirm Your Password"
                                                name="cpassword"
                                                onChange={(e) => handleInput(e)}
                                                value={
                                                  userdetails.cpassword
                                                    ? userdetails.cpassword
                                                    : ""
                                                }
                                              />
                                              <i
                                                className={`fas ${
                                                  showPassword.cpassword
                                                    ? "fa-eye-slash"
                                                    : "fa-eye"
                                                } password-toggle`}
                                                onClick={() =>
                                                  setshowPassword(
                                                    (prevState) => ({
                                                      ...prevState,
                                                      cpassword:
                                                        !showPassword.cpassword,
                                                    })
                                                  )
                                                }
                                              ></i>
                                            </div>
                                            {validation.cpassword && (
                                              <p className="regiter-errorMsg">
                                                {validation.cpassword}
                                              </p>
                                            )}
                                          </div>
                                        </div>

                                        
                                         

                                        <div className="d-flex justify-content-center mb-2 mb-lg-4 mt-5">
                                          <button
                                            type="button"
                                            className="btn btn-lg signupBtn"
                                            onClick={(e: any) =>
                                              handleConfirm(e)
                                            }
                                          >
                                            Confirm
                                          </button>
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Createnewpassword;
