import React from 'react'
import { CButton } from '@coreui/react'

type Props = {
  onClick:(v:React.MouseEvent<HTMLButtonElement>)=>void
  color?:'primary'|'secondary'
  size?:'sm'|'lg'
  className?:string
}

const PrimaryButton : React.FC<Props> = (props) => {
  return (
    <CButton
    className={ props.className }
    color={ props.color ? props.color : 'primary' } size={ props.size ? props.size : '' } onClick={ (v:React.MouseEvent<HTMLButtonElement>) => { props.onClick(v) } }>{ props.children }</CButton>
  )
}

export default PrimaryButton
