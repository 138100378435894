export type Paginator =
{
  page:number
  per_page:number
  has_more:boolean
  total_records:number
}

export type PaginatedData<T> = {
  data:T[]
  hasMore:boolean
  totalRecords:number
  statuscode?:string
}

export const defaultPaginator =
{
  page:1,
  per_page:20,
  has_more:true,
  total_records:0,
}

/** To get sorted users data agent */
export type SortedPaginator =
{
  page: number,
  per_page: number,
  has_more: boolean,
  total_records: number,
}

export type SortedPaginatorData<T> = {
  userdata:T[]
  hasMore:boolean
  totalRecords:number
}

export const defaulSortedtPaginator =
{
  page: 1,
  per_page: 20,
  has_more: true,
  total_records: 0,
}


